#lesson-page-controller {
    .active-lesson {
        background: var(--ion-color-primary);
    }
    
    .active-lesson .btn-orange-soft {
        background-color: #007773;
    }
    
    .active-lesson svg {
        color: white
    }
    
    .active-lesson .btn-orange-soft {
        color: white !important
    }

    ion-accordion ion-item {
        --padding-start: 0;
        --inner-padding-end: 0;
    }


    ion-item.border-active-2 {
        --background: rgba(0, 175, 170, 0.05);
    }
    

    @media only screen and (min-width: 992px) {

        ion-segment ion-segment-button {
            --color: var(--ion-color-secondary);
            font-size: 15px;
            font-weight: 600;
        }

        .comments ion-button.response-button {
            opacity: 0;
        }

        .comments .media-body:hover ion-button.response-button {
            opacity: 1;
        }


        #lesson-title {
            border-bottom: 2px solid var(--ion-color-secondary);
            width: fit-content;
            padding-right: 55px;
        }

        .segment-single {
            display: none;
        }
        
    }

    @media only screen and (max-width: 992px) {

        .comments ion-button.response-button {
            opacity: 0;
        }

        .comments .media-body:hover ion-button.response-button {
            opacity: 1;
        }
        
    }


}

ion-item .show-on-hover {
    transition: 100ms ease-in-out opacity;
    pointer-events: none;
    opacity: 0;
}

ion-item:hover .show-on-hover {
    opacity: 1 !important;
    pointer-events: all !important;
}