.activateOnScroll {
    opacity: 0;
    transition: .4s opacity,.8s transform cubic-bezier(.07,.89,.79,.95),.8s;
    transform: translateY(48px);
}

@for $i from 0 through 50 {
    .td-#{$i} {
        transition-delay: ($i * 100) + ms;
    }
}

