@media only screen and (max-width: 992px) {

    .fade-header-arrow ion-toolbar {
        padding-left: 0 !important;
    }
    
    .fade-header-arrow ion-title {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    
    .fade-header {
        font-size: 18px;
        font-family: Nunito;
        font-weight: 700;
        opacity: 1;
    }
    
    .fade-header-long {   
        text-overflow: inherit;
        white-space: normal;
        line-height: 20px;
        font-weight: 500;
        max-height: 40px;
    }
    
    .fade-header-long strong {   
        font-weight: 700;
    }

    .navbar-brand-img {
        max-height: 3rem;
    }

    ion-header ion-toolbar ion-buttons ion-button {
        --padding-end: 6px !important;
        --padding-start: 6px !important;
        --padding-top: 6px !important;
        --padding-bottom: 6px !important;
        width: 42px !important;
        height: 42px !important;
    }

    ion-header ion-toolbar ion-buttons ion-button ion-icon {
        font-size: 23px;
    }

}


