/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

 :root {
	--bs-blue: #196ECD;
	--bs-indigo: #6610f2;
	--bs-purple: #B8B2FD;
	--bs-pink: #d63384;
	--bs-red: #dc3545;
	--bs-orange: #FFC78B;
	--bs-yellow: #ffc107;
	--bs-green: #198754;
	--bs-teal: #4A8F9F;
	--bs-cyan: #9EF3F6;
	--bs-white: #FFFFFF;
	--bs-gray: #8C98A4;
	--bs-gray-dark: #77838F;
	--bs-gray-100: #F7F9FB;
	--bs-gray-200: #E7E7EC;
	--bs-gray-300: #D9E2EF;
	--bs-gray-400: #C6D3E6;
	--bs-gray-500: #C8C8D6;
	--bs-gray-600: #8C98A4;
	--bs-gray-700: #949DA6;
	--bs-gray-800: #77838F;
	--bs-gray-900: #2F2D51;
	--bs-primary: #090761;
	--bs-secondary: #949DA6;
	--bs-primary-desat: #6C8AEC;
	--bs-black: #161C2D;
	--bs-gray-200: #E7E7EC;
	--bs-gray-800: #77838F;
	--bs-white: #FFFFFF;
	--bs-dark: #2F2D51;
	--bs-success: #DEF2D7;
	--bs-info: #CDE9F6;
	--bs-warning: #F7F3D7;
	--bs-danger: #ECC8C5;
	--bs-light: #F7F9FB;
	--bs-orange: #FFC78B;
	--bs-blue: #196ECD;
	--bs-purple: #B8B2FD;
	--bs-teal: #4A8F9F;
	--bs-ice: #9CF6DB;
	--bs-cyan: #9EF3F6;
	--bs-helio: #B253F6;
	--bs-white-ice: #F0FDF8;
	--bs-tropaz: #2D4F93;
	--bs-roman: #E0685C;
	--bs-tangerine: #FF9574;
	--bs-gigas: #4E35A3;
	--bs-lavender: #ffebf1;
	--bs-sienna: #E7643E;
	--bs-coral: #FE805D;
	--bs-catskill: #F9FBFC;
	--bs-bossanova: #452B4F;
	--bs-denim: #146ECF;
	--bs-turbo: #FEB800;
	--bs-bright-sun: #FFD438;
	--bs-shark: #282A2E;
	--bs-dodger: #377DFF;
	--bs-cloud-burst: #21325B;
	--bs-porsche: #F0B26B;
	--bs-biscay: #183F63;
	--bs-mongoose: #BDA588;
	--bs-ebony: #252837;
	--bs-alizarin: #EB1C24;
	--bs-chestnut: #CF574B;
	--bs-portgore: #212041;
	--bs-primary-rgb: 9, 7, 97;
	--bs-secondary-rgb: 148, 157, 166;
	--bs-primary-desat-rgb: 108, 138, 236;
	--bs-black-rgb: 22, 28, 45;
	--bs-gray-200-rgb: 231, 231, 236;
	--bs-gray-800-rgb: 119, 131, 143;
	--bs-white-rgb: 255, 255, 255;
	--bs-dark-rgb: 47, 45, 81;
	--bs-success-rgb: 222, 242, 215;
	--bs-info-rgb: 205, 233, 246;
	--bs-warning-rgb: 247, 243, 215;
	--bs-danger-rgb: 236, 200, 197;
	--bs-light-rgb: 247, 249, 251;
	--bs-orange-rgb: 255, 199, 139;
	--bs-blue-rgb: 25, 110, 205;
	--bs-purple-rgb: 184, 178, 253;
	--bs-teal-rgb: 74, 143, 159;
	--bs-ice-rgb: 156, 246, 219;
	--bs-cyan-rgb: 158, 243, 246;
	--bs-helio-rgb: 178, 83, 246;
	--bs-white-ice-rgb: 240, 253, 248;
	--bs-tropaz-rgb: 45, 79, 147;
	--bs-roman-rgb: 224, 104, 92;
	--bs-tangerine-rgb: 255, 149, 116;
	--bs-gigas-rgb: 78, 53, 163;
	--bs-lavender-rgb: 255, 235, 241;
	--bs-sienna-rgb: 231, 100, 62;
	--bs-coral-rgb: 254, 128, 93;
	--bs-catskill-rgb: 249, 251, 252;
	--bs-bossanova-rgb: 69, 43, 79;
	--bs-denim-rgb: 20, 110, 207;
	--bs-turbo-rgb: 254, 184, 0;
	--bs-bright-sun-rgb: 255, 212, 56;
	--bs-shark-rgb: 40, 42, 46;
	--bs-dodger-rgb: 55, 125, 255;
	--bs-cloud-burst-rgb: 33, 50, 91;
	--bs-porsche-rgb: 240, 178, 107;
	--bs-biscay-rgb: 24, 63, 99;
	--bs-mongoose-rgb: 189, 165, 136;
	--bs-ebony-rgb: 37, 40, 55;
	--bs-alizarin-rgb: 235, 28, 36;
	--bs-chestnut-rgb: 207, 87, 75;
	--bs-portgore-rgb: 33, 32, 65;
	--bs-white-rgb: 255, 255, 255;
	--bs-black-rgb: 22, 28, 45;
	--bs-body-color-rgb: 119, 131, 143;
	--bs-body-bg-rgb: 247, 249, 251;
	--bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	--bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	--bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
	--bs-body-font-family: Jost, sans-serif;
	--bs-body-font-size: 1rem;
	--bs-body-font-weight: 400;
	--bs-body-line-height: 1.6;
	--bs-body-color: #77838F;
	--bs-body-bg: #F7F9FB
}

*,
::after,
::before {
	box-sizing: border-box
}

@media (prefers-reduced-motion:no-preference) {
	:root {
		scroll-behavior: smooth
	}
}

body {
	margin: 0;
	font-family: var(--bs-body-font-family);
	font-size: var(--bs-body-font-size);
	font-weight: var(--bs-body-font-weight);
	line-height: var(--bs-body-line-height);
	color: var(--bs-body-color);
	text-align: var(--bs-body-text-align);
	background-color: var(--bs-body-bg);
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(22, 28, 45, 0)
}

hr {
	margin: 1rem 0;
	color: inherit;
	background-color: currentColor;
	border: 0;
	opacity: .25
}

hr:not([size]) {
	height: 1px
}

.cart-collaterals .h2,
.cart-collaterals h2,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: .9rem;
	font-weight: 500;
	line-height: 1;
	color: #2f2d51
}

.h1,
h1 {
	font-size: calc(1.3437rem + 1.1244vw)
}

@media (min-width:1200px) {
	.h1,
	h1 {
		font-size: 2.187rem
	}
}

.h2,
h2 {
	font-size: calc(1.3125rem + .75vw)
}

@media (min-width:1200px) {
	.h2,
	h2 {
		font-size: 1.875rem
	}
}

.cart-collaterals .h2,
.cart-collaterals h2,
.h3,
h3 {
	font-size: calc(1.2625rem + .15vw)
}

@media (min-width:1200px) {
	.cart-collaterals .h2,
	.cart-collaterals h2,
	.h3,
	h3 {
		font-size: 1.375rem
	}
}

.h4,
h4 {
	font-size: 1.25rem
}

.h5,
h5 {
	font-size: 1.125rem
}

.h6,
h6 {
	font-size: 1rem
}

p {
	margin-top: 0;
	margin-bottom: 1rem
}

abbr[data-bs-original-title],
abbr[title] {
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
	cursor: help;
	-webkit-text-decoration-skip-ink: none;
	text-decoration-skip-ink: none
}

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit
}

ol,
ul {
	padding-left: 2rem
}

dl,
ol,
ul {
	margin-top: 0;
	margin-bottom: 1rem
}

ol ol,
ol ul,
ul ol,
ul ul {
	margin-bottom: 0
}

dt {
	font-weight: 700
}

dd {
	margin-bottom: .5rem;
	margin-left: 0
}

blockquote {
	margin: 0 0 1rem
}

b,
strong {
	font-weight: bolder
}

.small,
small {
	font-size: 88.2%
}

.mark,
mark {
	padding: .2em;
	background-color: #fcf8e3
}

sub,
sup {
	position: relative;
	font-size: .75em;
	line-height: 0;
	vertical-align: baseline
}

sub {
	bottom: -.25em
}

sup {
	top: -.5em
}

a {
	color: #090761;
	text-decoration: none
}

a:hover {
	color: #090761;
	text-decoration: none
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
	color: inherit;
	text-decoration: none
}

code,
kbd,
pre,
samp {
	font-family: var(--bs-font-monospace);
	font-size: 1em;
	direction: ltr;
	unicode-bidi: bidi-override
}

pre {
	display: block;
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
	font-size: .8125rem
}

pre code {
	font-size: inherit;
	color: inherit;
	word-break: normal
}

code {
	font-size: .8125rem;
	color: #d63384;
	word-wrap: break-word
}

a>code {
	color: inherit
}

kbd {
	padding: .2rem .4rem;
	font-size: .8125rem;
	color: #fff;
	background-color: #2f2d51;
	border-radius: .2rem
}

kbd kbd {
	padding: 0;
	font-size: 1em;
	font-weight: 700
}

figure {
	margin: 0 0 1rem
}

img,
svg {
	vertical-align: middle
}

table {
	caption-side: bottom;
	border-collapse: collapse
}

caption {
	padding-top: .5rem;
	padding-bottom: .5rem;
	color: #8c98a4;
	text-align: left
}

th {
	text-align: inherit;
	text-align: -webkit-match-parent
}

tbody,
td,
tfoot,
th,
thead,
tr {
	border-color: inherit;
	border-style: solid;
	border-width: 0
}

label {
	display: inline-block
}

button {
	border-radius: 0
}

button:focus:not(:focus-visible) {
	outline: 0
}

button,
input,
optgroup,
select,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit
}

button,
select {
	text-transform: none
}

[role=button] {
	cursor: pointer
}

select {
	word-wrap: normal
}

select:disabled {
	opacity: 1
}

[list]::-webkit-calendar-picker-indicator {
	display: none
}

[type=button],
[type=reset],
[type=submit],
button {
	-webkit-appearance: button
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
	cursor: pointer
}

::-moz-focus-inner {
	padding: 0;
	border-style: none
}

textarea {
	resize: vertical
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0
}

legend {
	float: left;
	width: 100%;
	padding: 0;
	margin-bottom: .5rem;
	font-size: calc(1.275rem + .3vw);
	line-height: inherit
}

@media (min-width:1200px) {
	legend {
		font-size: 1.5rem
	}
}

legend+* {
	clear: left
}

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
	padding: 0
}

::-webkit-inner-spin-button {
	height: auto
}

[type=search] {
	outline-offset: -2px;
	-webkit-appearance: textfield
}

::-webkit-search-decoration {
	-webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
	padding: 0
}

::file-selector-button {
	font: inherit
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button
}

output {
	display: inline-block
}

iframe {
	border: 0
}

summary {
	display: list-item;
	cursor: pointer
}

progress {
	vertical-align: baseline
}

[hidden] {
	display: none!important
}

.lead {
	font-size: 1.125rem;
	font-weight: 400
}

.display-1 {
	font-size: calc(1.5625rem + 3.75vw);
	font-weight: 300;
	line-height: 1
}

@media (min-width:1200px) {
	.display-1 {
		font-size: 4.375rem
	}
}

.display-2 {
	font-size: calc(1.5rem + 3vw);
	font-weight: 300;
	line-height: 1
}

@media (min-width:1200px) {
	.display-2 {
		font-size: 3.75rem
	}
}

.display-3 {
	font-size: calc(1.425rem + 2.1vw);
	font-weight: 300;
	line-height: 1
}

@media (min-width:1200px) {
	.display-3 {
		font-size: 3rem
	}
}

.display-4 {
	font-size: calc(1.375rem + 1.5vw);
	font-weight: 300;
	line-height: 1
}

@media (min-width:1200px) {
	.display-4 {
		font-size: 2.5rem
	}
}

.display-5 {
	font-size: calc(1.4687rem + 2.6244vw);
	font-weight: 300;
	line-height: 1
}

@media (min-width:1200px) {
	.display-5 {
		font-size: 3.437rem
	}
}

.display-6 {
	font-size: calc(1.4375rem + 2.25vw);
	font-weight: 300;
	line-height: 1
}

@media (min-width:1200px) {
	.display-6 {
		font-size: 3.125rem
	}
}

.list-unstyled {
	padding-left: 0;
	list-style: none
}

.list-inline {
	padding-left: 0;
	list-style: none
}

.list-inline-item {
	display: inline-block
}

.list-inline-item:not(:last-child) {
	margin-right: .5rem
}

.initialism {
	font-size: 88.2%;
	text-transform: uppercase
}

.blockquote {
	margin-bottom: 1rem;
	font-size: 1.25rem
}

.blockquote>:last-child {
	margin-bottom: 0
}

.blockquote-footer {
	margin-top: -1rem;
	margin-bottom: 1rem;
	font-size: 88.2%;
	color: #8c98a4
}

.blockquote-footer::before {
	content: "\2014\00A0"
}

.img-fluid {
	max-width: 100%;
	height: auto
}

.img-thumbnail {
	padding: .25rem;
	background-color: #f7f9fb;
	border: 1px solid #d9e2ef;
	border-radius: .25rem;
	box-shadow: 0 .125rem .25rem rgba(22, 28, 45, .075);
	max-width: 100%;
	height: auto
}

.figure {
	display: inline-block
}

.figure-img {
	margin-bottom: .5rem;
	line-height: 1
}

.figure-caption {
	font-size: 88.2%;
	color: #8c98a4
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
	width: 100%;
	padding-right: var(--bs-gutter-x, 15px);
	padding-left: var(--bs-gutter-x, 15px);
	margin-right: auto;
	margin-left: auto
}

@media (min-width:576px) {
	.container,
	.container-sm {
		max-width: 540px
	}
}

@media (min-width:768px) {
	.container,
	.container-md,
	.container-sm {
		max-width: 720px
	}
}

@media (min-width:992px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm {
		max-width: 960px
	}
}

@media (min-width:1200px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl {
		max-width: 1200px
	}
}

.row {
	--bs-gutter-x: 30px;
	--bs-gutter-y: 0;
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(-1 * var(--bs-gutter-y));
	margin-right: calc(-.5 * var(--bs-gutter-x));
	margin-left: calc(-.5 * var(--bs-gutter-x))
}

.row>* {
	flex-shrink: 0;
	width: 100%;
	max-width: 100%;
	padding-right: calc(var(--bs-gutter-x) * .5);
	padding-left: calc(var(--bs-gutter-x) * .5);
	margin-top: var(--bs-gutter-y)
}

.col {
	-webkit-box-flex: 1;
	flex: 1 0 0%
}

.row-cols-auto>* {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: auto
}

.row-cols-1>* {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: 100%
}

.row-cols-2>* {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: 50%
}

.row-cols-3>* {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: 33.33333%
}

.row-cols-4>* {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: 25%
}

.row-cols-5>* {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: 20%
}

.row-cols-6>* {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: 16.66667%
}

.col-auto {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: auto
}

.col-1 {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: 8.33333%
}

.col-2 {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: 16.66667%
}

.col-3 {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: 25%
}

.col-4 {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: 33.33333%
}

.col-5 {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: 41.66667%
}

.col-6 {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: 50%
}

.col-7 {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: 58.33333%
}

.col-8 {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: 66.66667%
}

.col-9 {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: 75%
}

.col-10 {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: 83.33333%
}

.col-11 {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: 91.66667%
}

.col-12 {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: 100%
}

.offset-1 {
	margin-left: 8.33333%
}

.offset-2 {
	margin-left: 16.66667%
}

.offset-3 {
	margin-left: 25%
}

.offset-4 {
	margin-left: 33.33333%
}

.offset-5 {
	margin-left: 41.66667%
}

.offset-6 {
	margin-left: 50%
}

.offset-7 {
	margin-left: 58.33333%
}

.offset-8 {
	margin-left: 66.66667%
}

.offset-9 {
	margin-left: 75%
}

.offset-10 {
	margin-left: 83.33333%
}

.offset-11 {
	margin-left: 91.66667%
}

.g-0,
.gx-0 {
	--bs-gutter-x: 0
}

.g-0,
.gy-0 {
	--bs-gutter-y: 0
}

.g-1,
.gx-1 {
	--bs-gutter-x: 0.25rem
}

.g-1,
.gy-1 {
	--bs-gutter-y: 0.25rem
}

.g-2,
.gx-2 {
	--bs-gutter-x: 0.5rem
}

.g-2,
.gy-2 {
	--bs-gutter-y: 0.5rem
}

.g-3,
.gx-3 {
	--bs-gutter-x: 0.75rem
}

.g-3,
.gy-3 {
	--bs-gutter-y: 0.75rem
}

.g-4,
.gx-4 {
	--bs-gutter-x: 1rem
}

.g-4,
.gy-4 {
	--bs-gutter-y: 1rem
}

.g-5,
.gx-5 {
	--bs-gutter-x: 1.5rem
}

.g-5,
.gy-5 {
	--bs-gutter-y: 1.5rem
}

.g-6,
.gx-6 {
	--bs-gutter-x: 2rem
}

.g-6,
.gy-6 {
	--bs-gutter-y: 2rem
}

.g-7,
.gx-7 {
	--bs-gutter-x: 2.5rem
}

.g-7,
.gy-7 {
	--bs-gutter-y: 2.5rem
}

.g-8,
.gx-8 {
	--bs-gutter-x: 3rem
}

.g-8,
.gy-8 {
	--bs-gutter-y: 3rem
}

.g-9,
.gx-9 {
	--bs-gutter-x: 4rem
}

.g-9,
.gy-9 {
	--bs-gutter-y: 4rem
}

.g-10,
.gx-10 {
	--bs-gutter-x: 5rem
}

.g-10,
.gy-10 {
	--bs-gutter-y: 5rem
}

.g-11,
.gx-11 {
	--bs-gutter-x: 6rem
}

.g-11,
.gy-11 {
	--bs-gutter-y: 6rem
}

.g-12,
.gx-12 {
	--bs-gutter-x: 8rem
}

.g-12,
.gy-12 {
	--bs-gutter-y: 8rem
}

.g-13,
.gx-13 {
	--bs-gutter-x: 10rem
}

.g-13,
.gy-13 {
	--bs-gutter-y: 10rem
}

.g-14,
.gx-14 {
	--bs-gutter-x: 12rem
}

.g-14,
.gy-14 {
	--bs-gutter-y: 12rem
}

.g-15,
.gx-15 {
	--bs-gutter-x: 16rem
}

.g-15,
.gy-15 {
	--bs-gutter-y: 16rem
}

.g-16,
.gx-16 {
	--bs-gutter-x: 25rem
}

.g-16,
.gy-16 {
	--bs-gutter-y: 25rem
}

@media (min-width:576px) {
	.col-sm {
		-webkit-box-flex: 1;
		flex: 1 0 0%
	}
	.row-cols-sm-auto>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: auto
	}
	.row-cols-sm-1>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 100%
	}
	.row-cols-sm-2>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 50%
	}
	.row-cols-sm-3>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 33.33333%
	}
	.row-cols-sm-4>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 25%
	}
	.row-cols-sm-5>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 20%
	}
	.row-cols-sm-6>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 16.66667%
	}
	.col-sm-auto {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: auto
	}
	.col-sm-1 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 8.33333%
	}
	.col-sm-2 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 16.66667%
	}
	.col-sm-3 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 25%
	}
	.col-sm-4 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 33.33333%
	}
	.col-sm-5 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 41.66667%
	}
	.col-sm-6 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 50%
	}
	.col-sm-7 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 58.33333%
	}
	.col-sm-8 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 66.66667%
	}
	.col-sm-9 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 75%
	}
	.col-sm-10 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 83.33333%
	}
	.col-sm-11 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 91.66667%
	}
	.col-sm-12 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 100%
	}
	.offset-sm-0 {
		margin-left: 0
	}
	.offset-sm-1 {
		margin-left: 8.33333%
	}
	.offset-sm-2 {
		margin-left: 16.66667%
	}
	.offset-sm-3 {
		margin-left: 25%
	}
	.offset-sm-4 {
		margin-left: 33.33333%
	}
	.offset-sm-5 {
		margin-left: 41.66667%
	}
	.offset-sm-6 {
		margin-left: 50%
	}
	.offset-sm-7 {
		margin-left: 58.33333%
	}
	.offset-sm-8 {
		margin-left: 66.66667%
	}
	.offset-sm-9 {
		margin-left: 75%
	}
	.offset-sm-10 {
		margin-left: 83.33333%
	}
	.offset-sm-11 {
		margin-left: 91.66667%
	}
	.g-sm-0,
	.gx-sm-0 {
		--bs-gutter-x: 0
	}
	.g-sm-0,
	.gy-sm-0 {
		--bs-gutter-y: 0
	}
	.g-sm-1,
	.gx-sm-1 {
		--bs-gutter-x: 0.25rem
	}
	.g-sm-1,
	.gy-sm-1 {
		--bs-gutter-y: 0.25rem
	}
	.g-sm-2,
	.gx-sm-2 {
		--bs-gutter-x: 0.5rem
	}
	.g-sm-2,
	.gy-sm-2 {
		--bs-gutter-y: 0.5rem
	}
	.g-sm-3,
	.gx-sm-3 {
		--bs-gutter-x: 0.75rem
	}
	.g-sm-3,
	.gy-sm-3 {
		--bs-gutter-y: 0.75rem
	}
	.g-sm-4,
	.gx-sm-4 {
		--bs-gutter-x: 1rem
	}
	.g-sm-4,
	.gy-sm-4 {
		--bs-gutter-y: 1rem
	}
	.g-sm-5,
	.gx-sm-5 {
		--bs-gutter-x: 1.5rem
	}
	.g-sm-5,
	.gy-sm-5 {
		--bs-gutter-y: 1.5rem
	}
	.g-sm-6,
	.gx-sm-6 {
		--bs-gutter-x: 2rem
	}
	.g-sm-6,
	.gy-sm-6 {
		--bs-gutter-y: 2rem
	}
	.g-sm-7,
	.gx-sm-7 {
		--bs-gutter-x: 2.5rem
	}
	.g-sm-7,
	.gy-sm-7 {
		--bs-gutter-y: 2.5rem
	}
	.g-sm-8,
	.gx-sm-8 {
		--bs-gutter-x: 3rem
	}
	.g-sm-8,
	.gy-sm-8 {
		--bs-gutter-y: 3rem
	}
	.g-sm-9,
	.gx-sm-9 {
		--bs-gutter-x: 4rem
	}
	.g-sm-9,
	.gy-sm-9 {
		--bs-gutter-y: 4rem
	}
	.g-sm-10,
	.gx-sm-10 {
		--bs-gutter-x: 5rem
	}
	.g-sm-10,
	.gy-sm-10 {
		--bs-gutter-y: 5rem
	}
	.g-sm-11,
	.gx-sm-11 {
		--bs-gutter-x: 6rem
	}
	.g-sm-11,
	.gy-sm-11 {
		--bs-gutter-y: 6rem
	}
	.g-sm-12,
	.gx-sm-12 {
		--bs-gutter-x: 8rem
	}
	.g-sm-12,
	.gy-sm-12 {
		--bs-gutter-y: 8rem
	}
	.g-sm-13,
	.gx-sm-13 {
		--bs-gutter-x: 10rem
	}
	.g-sm-13,
	.gy-sm-13 {
		--bs-gutter-y: 10rem
	}
	.g-sm-14,
	.gx-sm-14 {
		--bs-gutter-x: 12rem
	}
	.g-sm-14,
	.gy-sm-14 {
		--bs-gutter-y: 12rem
	}
	.g-sm-15,
	.gx-sm-15 {
		--bs-gutter-x: 16rem
	}
	.g-sm-15,
	.gy-sm-15 {
		--bs-gutter-y: 16rem
	}
	.g-sm-16,
	.gx-sm-16 {
		--bs-gutter-x: 25rem
	}
	.g-sm-16,
	.gy-sm-16 {
		--bs-gutter-y: 25rem
	}
}

@media (min-width:768px) {
	.col-md {
		-webkit-box-flex: 1;
		flex: 1 0 0%
	}
	.row-cols-md-auto>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: auto
	}
	.row-cols-md-1>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 100%
	}
	.row-cols-md-2>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 50%
	}
	.row-cols-md-3>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 33.33333%
	}
	.row-cols-md-4>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 25%
	}
	.row-cols-md-5>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 20%
	}
	.row-cols-md-6>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 16.66667%
	}
	.col-md-auto {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: auto
	}
	.col-md-1 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 8.33333%
	}
	.col-md-2 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 16.66667%
	}
	.col-md-3 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 25%
	}
	.col-md-4 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 33.33333%
	}
	.col-md-5 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 41.66667%
	}
	.col-md-6 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 50%
	}
	.col-md-7 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 58.33333%
	}
	.col-md-8 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 66.66667%
	}
	.col-md-9 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 75%
	}
	.col-md-10 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 83.33333%
	}
	.col-md-11 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 91.66667%
	}
	.col-md-12 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 100%
	}
	.offset-md-0 {
		margin-left: 0
	}
	.offset-md-1 {
		margin-left: 8.33333%
	}
	.offset-md-2 {
		margin-left: 16.66667%
	}
	.offset-md-3 {
		margin-left: 25%
	}
	.offset-md-4 {
		margin-left: 33.33333%
	}
	.offset-md-5 {
		margin-left: 41.66667%
	}
	.offset-md-6 {
		margin-left: 50%
	}
	.offset-md-7 {
		margin-left: 58.33333%
	}
	.offset-md-8 {
		margin-left: 66.66667%
	}
	.offset-md-9 {
		margin-left: 75%
	}
	.offset-md-10 {
		margin-left: 83.33333%
	}
	.offset-md-11 {
		margin-left: 91.66667%
	}
	.g-md-0,
	.gx-md-0 {
		--bs-gutter-x: 0
	}
	.g-md-0,
	.gy-md-0 {
		--bs-gutter-y: 0
	}
	.g-md-1,
	.gx-md-1 {
		--bs-gutter-x: 0.25rem
	}
	.g-md-1,
	.gy-md-1 {
		--bs-gutter-y: 0.25rem
	}
	.g-md-2,
	.gx-md-2 {
		--bs-gutter-x: 0.5rem
	}
	.g-md-2,
	.gy-md-2 {
		--bs-gutter-y: 0.5rem
	}
	.g-md-3,
	.gx-md-3 {
		--bs-gutter-x: 0.75rem
	}
	.g-md-3,
	.gy-md-3 {
		--bs-gutter-y: 0.75rem
	}
	.g-md-4,
	.gx-md-4 {
		--bs-gutter-x: 1rem
	}
	.g-md-4,
	.gy-md-4 {
		--bs-gutter-y: 1rem
	}
	.g-md-5,
	.gx-md-5 {
		--bs-gutter-x: 1.5rem
	}
	.g-md-5,
	.gy-md-5 {
		--bs-gutter-y: 1.5rem
	}
	.g-md-6,
	.gx-md-6 {
		--bs-gutter-x: 2rem
	}
	.g-md-6,
	.gy-md-6 {
		--bs-gutter-y: 2rem
	}
	.g-md-7,
	.gx-md-7 {
		--bs-gutter-x: 2.5rem
	}
	.g-md-7,
	.gy-md-7 {
		--bs-gutter-y: 2.5rem
	}
	.g-md-8,
	.gx-md-8 {
		--bs-gutter-x: 3rem
	}
	.g-md-8,
	.gy-md-8 {
		--bs-gutter-y: 3rem
	}
	.g-md-9,
	.gx-md-9 {
		--bs-gutter-x: 4rem
	}
	.g-md-9,
	.gy-md-9 {
		--bs-gutter-y: 4rem
	}
	.g-md-10,
	.gx-md-10 {
		--bs-gutter-x: 5rem
	}
	.g-md-10,
	.gy-md-10 {
		--bs-gutter-y: 5rem
	}
	.g-md-11,
	.gx-md-11 {
		--bs-gutter-x: 6rem
	}
	.g-md-11,
	.gy-md-11 {
		--bs-gutter-y: 6rem
	}
	.g-md-12,
	.gx-md-12 {
		--bs-gutter-x: 8rem
	}
	.g-md-12,
	.gy-md-12 {
		--bs-gutter-y: 8rem
	}
	.g-md-13,
	.gx-md-13 {
		--bs-gutter-x: 10rem
	}
	.g-md-13,
	.gy-md-13 {
		--bs-gutter-y: 10rem
	}
	.g-md-14,
	.gx-md-14 {
		--bs-gutter-x: 12rem
	}
	.g-md-14,
	.gy-md-14 {
		--bs-gutter-y: 12rem
	}
	.g-md-15,
	.gx-md-15 {
		--bs-gutter-x: 16rem
	}
	.g-md-15,
	.gy-md-15 {
		--bs-gutter-y: 16rem
	}
	.g-md-16,
	.gx-md-16 {
		--bs-gutter-x: 25rem
	}
	.g-md-16,
	.gy-md-16 {
		--bs-gutter-y: 25rem
	}
}

@media (min-width:992px) {
	.col-lg {
		-webkit-box-flex: 1;
		flex: 1 0 0%
	}
	.row-cols-lg-auto>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: auto
	}
	.row-cols-lg-1>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 100%
	}
	.row-cols-lg-2>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 50%
	}
	.row-cols-lg-3>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 33.33333%
	}
	.row-cols-lg-4>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 25%
	}
	.row-cols-lg-5>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 20%
	}
	.row-cols-lg-6>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 16.66667%
	}
	.col-lg-auto {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: auto
	}
	.col-lg-1 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 8.33333%
	}
	.col-lg-2 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 16.66667%
	}
	.col-lg-3 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 25%
	}
	.col-lg-4 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 33.33333%
	}
	.col-lg-5 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 41.66667%
	}
	.col-lg-6 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 50%
	}
	.col-lg-7 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 58.33333%
	}
	.col-lg-8 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 66.66667%
	}
	.col-lg-9 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 75%
	}
	.col-lg-10 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 83.33333%
	}
	.col-lg-11 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 91.66667%
	}
	.col-lg-12 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 100%
	}
	.offset-lg-0 {
		margin-left: 0
	}
	.offset-lg-1 {
		margin-left: 8.33333%
	}
	.offset-lg-2 {
		margin-left: 16.66667%
	}
	.offset-lg-3 {
		margin-left: 25%
	}
	.offset-lg-4 {
		margin-left: 33.33333%
	}
	.offset-lg-5 {
		margin-left: 41.66667%
	}
	.offset-lg-6 {
		margin-left: 50%
	}
	.offset-lg-7 {
		margin-left: 58.33333%
	}
	.offset-lg-8 {
		margin-left: 66.66667%
	}
	.offset-lg-9 {
		margin-left: 75%
	}
	.offset-lg-10 {
		margin-left: 83.33333%
	}
	.offset-lg-11 {
		margin-left: 91.66667%
	}
	.g-lg-0,
	.gx-lg-0 {
		--bs-gutter-x: 0
	}
	.g-lg-0,
	.gy-lg-0 {
		--bs-gutter-y: 0
	}
	.g-lg-1,
	.gx-lg-1 {
		--bs-gutter-x: 0.25rem
	}
	.g-lg-1,
	.gy-lg-1 {
		--bs-gutter-y: 0.25rem
	}
	.g-lg-2,
	.gx-lg-2 {
		--bs-gutter-x: 0.5rem
	}
	.g-lg-2,
	.gy-lg-2 {
		--bs-gutter-y: 0.5rem
	}
	.g-lg-3,
	.gx-lg-3 {
		--bs-gutter-x: 0.75rem
	}
	.g-lg-3,
	.gy-lg-3 {
		--bs-gutter-y: 0.75rem
	}
	.g-lg-4,
	.gx-lg-4 {
		--bs-gutter-x: 1rem
	}
	.g-lg-4,
	.gy-lg-4 {
		--bs-gutter-y: 1rem
	}
	.g-lg-5,
	.gx-lg-5 {
		--bs-gutter-x: 1.5rem
	}
	.g-lg-5,
	.gy-lg-5 {
		--bs-gutter-y: 1.5rem
	}
	.g-lg-6,
	.gx-lg-6 {
		--bs-gutter-x: 2rem
	}
	.g-lg-6,
	.gy-lg-6 {
		--bs-gutter-y: 2rem
	}
	.g-lg-7,
	.gx-lg-7 {
		--bs-gutter-x: 2.5rem
	}
	.g-lg-7,
	.gy-lg-7 {
		--bs-gutter-y: 2.5rem
	}
	.g-lg-8,
	.gx-lg-8 {
		--bs-gutter-x: 3rem
	}
	.g-lg-8,
	.gy-lg-8 {
		--bs-gutter-y: 3rem
	}
	.g-lg-9,
	.gx-lg-9 {
		--bs-gutter-x: 4rem
	}
	.g-lg-9,
	.gy-lg-9 {
		--bs-gutter-y: 4rem
	}
	.g-lg-10,
	.gx-lg-10 {
		--bs-gutter-x: 5rem
	}
	.g-lg-10,
	.gy-lg-10 {
		--bs-gutter-y: 5rem
	}
	.g-lg-11,
	.gx-lg-11 {
		--bs-gutter-x: 6rem
	}
	.g-lg-11,
	.gy-lg-11 {
		--bs-gutter-y: 6rem
	}
	.g-lg-12,
	.gx-lg-12 {
		--bs-gutter-x: 8rem
	}
	.g-lg-12,
	.gy-lg-12 {
		--bs-gutter-y: 8rem
	}
	.g-lg-13,
	.gx-lg-13 {
		--bs-gutter-x: 10rem
	}
	.g-lg-13,
	.gy-lg-13 {
		--bs-gutter-y: 10rem
	}
	.g-lg-14,
	.gx-lg-14 {
		--bs-gutter-x: 12rem
	}
	.g-lg-14,
	.gy-lg-14 {
		--bs-gutter-y: 12rem
	}
	.g-lg-15,
	.gx-lg-15 {
		--bs-gutter-x: 16rem
	}
	.g-lg-15,
	.gy-lg-15 {
		--bs-gutter-y: 16rem
	}
	.g-lg-16,
	.gx-lg-16 {
		--bs-gutter-x: 25rem
	}
	.g-lg-16,
	.gy-lg-16 {
		--bs-gutter-y: 25rem
	}
}

@media (min-width:1200px) {
	.col-xl {
		-webkit-box-flex: 1;
		flex: 1 0 0%
	}
	.row-cols-xl-auto>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: auto
	}
	.row-cols-xl-1>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 100%
	}
	.row-cols-xl-2>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 50%
	}
	.row-cols-xl-3>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 33.33333%
	}
	.row-cols-xl-4>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 25%
	}
	.row-cols-xl-5>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 20%
	}
	.row-cols-xl-6>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 16.66667%
	}
	.col-xl-auto {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: auto
	}
	.col-xl-1 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 8.33333%
	}
	.col-xl-2 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 16.66667%
	}
	.col-xl-3 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 25%
	}
	.col-xl-4 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 33.33333%
	}
	.col-xl-5 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 41.66667%
	}
	.col-xl-6 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 50%
	}
	.col-xl-7 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 58.33333%
	}
	.col-xl-8 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 66.66667%
	}
	.col-xl-9 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 75%
	}
	.col-xl-10 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 83.33333%
	}
	.col-xl-11 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 91.66667%
	}
	.col-xl-12 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 100%
	}
	.offset-xl-0 {
		margin-left: 0
	}
	.offset-xl-1 {
		margin-left: 8.33333%
	}
	.offset-xl-2 {
		margin-left: 16.66667%
	}
	.offset-xl-3 {
		margin-left: 25%
	}
	.offset-xl-4 {
		margin-left: 33.33333%
	}
	.offset-xl-5 {
		margin-left: 41.66667%
	}
	.offset-xl-6 {
		margin-left: 50%
	}
	.offset-xl-7 {
		margin-left: 58.33333%
	}
	.offset-xl-8 {
		margin-left: 66.66667%
	}
	.offset-xl-9 {
		margin-left: 75%
	}
	.offset-xl-10 {
		margin-left: 83.33333%
	}
	.offset-xl-11 {
		margin-left: 91.66667%
	}
	.g-xl-0,
	.gx-xl-0 {
		--bs-gutter-x: 0
	}
	.g-xl-0,
	.gy-xl-0 {
		--bs-gutter-y: 0
	}
	.g-xl-1,
	.gx-xl-1 {
		--bs-gutter-x: 0.25rem
	}
	.g-xl-1,
	.gy-xl-1 {
		--bs-gutter-y: 0.25rem
	}
	.g-xl-2,
	.gx-xl-2 {
		--bs-gutter-x: 0.5rem
	}
	.g-xl-2,
	.gy-xl-2 {
		--bs-gutter-y: 0.5rem
	}
	.g-xl-3,
	.gx-xl-3 {
		--bs-gutter-x: 0.75rem
	}
	.g-xl-3,
	.gy-xl-3 {
		--bs-gutter-y: 0.75rem
	}
	.g-xl-4,
	.gx-xl-4 {
		--bs-gutter-x: 1rem
	}
	.g-xl-4,
	.gy-xl-4 {
		--bs-gutter-y: 1rem
	}
	.g-xl-5,
	.gx-xl-5 {
		--bs-gutter-x: 1.5rem
	}
	.g-xl-5,
	.gy-xl-5 {
		--bs-gutter-y: 1.5rem
	}
	.g-xl-6,
	.gx-xl-6 {
		--bs-gutter-x: 2rem
	}
	.g-xl-6,
	.gy-xl-6 {
		--bs-gutter-y: 2rem
	}
	.g-xl-7,
	.gx-xl-7 {
		--bs-gutter-x: 2.5rem
	}
	.g-xl-7,
	.gy-xl-7 {
		--bs-gutter-y: 2.5rem
	}
	.g-xl-8,
	.gx-xl-8 {
		--bs-gutter-x: 3rem
	}
	.g-xl-8,
	.gy-xl-8 {
		--bs-gutter-y: 3rem
	}
	.g-xl-9,
	.gx-xl-9 {
		--bs-gutter-x: 4rem
	}
	.g-xl-9,
	.gy-xl-9 {
		--bs-gutter-y: 4rem
	}
	.g-xl-10,
	.gx-xl-10 {
		--bs-gutter-x: 5rem
	}
	.g-xl-10,
	.gy-xl-10 {
		--bs-gutter-y: 5rem
	}
	.g-xl-11,
	.gx-xl-11 {
		--bs-gutter-x: 6rem
	}
	.g-xl-11,
	.gy-xl-11 {
		--bs-gutter-y: 6rem
	}
	.g-xl-12,
	.gx-xl-12 {
		--bs-gutter-x: 8rem
	}
	.g-xl-12,
	.gy-xl-12 {
		--bs-gutter-y: 8rem
	}
	.g-xl-13,
	.gx-xl-13 {
		--bs-gutter-x: 10rem
	}
	.g-xl-13,
	.gy-xl-13 {
		--bs-gutter-y: 10rem
	}
	.g-xl-14,
	.gx-xl-14 {
		--bs-gutter-x: 12rem
	}
	.g-xl-14,
	.gy-xl-14 {
		--bs-gutter-y: 12rem
	}
	.g-xl-15,
	.gx-xl-15 {
		--bs-gutter-x: 16rem
	}
	.g-xl-15,
	.gy-xl-15 {
		--bs-gutter-y: 16rem
	}
	.g-xl-16,
	.gx-xl-16 {
		--bs-gutter-x: 25rem
	}
	.g-xl-16,
	.gy-xl-16 {
		--bs-gutter-y: 25rem
	}
}

@media (min-width:1480px) {
	.col-wd {
		-webkit-box-flex: 1;
		flex: 1 0 0%
	}
	.row-cols-wd-auto>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: auto
	}
	.row-cols-wd-1>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 100%
	}
	.row-cols-wd-2>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 50%
	}
	.row-cols-wd-3>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 33.33333%
	}
	.row-cols-wd-4>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 25%
	}
	.row-cols-wd-5>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 20%
	}
	.row-cols-wd-6>* {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 16.66667%
	}
	.col-wd-auto {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: auto
	}
	.col-wd-1 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 8.33333%
	}
	.col-wd-2 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 16.66667%
	}
	.col-wd-3 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 25%
	}
	.col-wd-4 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 33.33333%
	}
	.col-wd-5 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 41.66667%
	}
	.col-wd-6 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 50%
	}
	.col-wd-7 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 58.33333%
	}
	.col-wd-8 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 66.66667%
	}
	.col-wd-9 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 75%
	}
	.col-wd-10 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 83.33333%
	}
	.col-wd-11 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 91.66667%
	}
	.col-wd-12 {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 100%
	}
	.offset-wd-0 {
		margin-left: 0
	}
	.offset-wd-1 {
		margin-left: 8.33333%
	}
	.offset-wd-2 {
		margin-left: 16.66667%
	}
	.offset-wd-3 {
		margin-left: 25%
	}
	.offset-wd-4 {
		margin-left: 33.33333%
	}
	.offset-wd-5 {
		margin-left: 41.66667%
	}
	.offset-wd-6 {
		margin-left: 50%
	}
	.offset-wd-7 {
		margin-left: 58.33333%
	}
	.offset-wd-8 {
		margin-left: 66.66667%
	}
	.offset-wd-9 {
		margin-left: 75%
	}
	.offset-wd-10 {
		margin-left: 83.33333%
	}
	.offset-wd-11 {
		margin-left: 91.66667%
	}
	.g-wd-0,
	.gx-wd-0 {
		--bs-gutter-x: 0
	}
	.g-wd-0,
	.gy-wd-0 {
		--bs-gutter-y: 0
	}
	.g-wd-1,
	.gx-wd-1 {
		--bs-gutter-x: 0.25rem
	}
	.g-wd-1,
	.gy-wd-1 {
		--bs-gutter-y: 0.25rem
	}
	.g-wd-2,
	.gx-wd-2 {
		--bs-gutter-x: 0.5rem
	}
	.g-wd-2,
	.gy-wd-2 {
		--bs-gutter-y: 0.5rem
	}
	.g-wd-3,
	.gx-wd-3 {
		--bs-gutter-x: 0.75rem
	}
	.g-wd-3,
	.gy-wd-3 {
		--bs-gutter-y: 0.75rem
	}
	.g-wd-4,
	.gx-wd-4 {
		--bs-gutter-x: 1rem
	}
	.g-wd-4,
	.gy-wd-4 {
		--bs-gutter-y: 1rem
	}
	.g-wd-5,
	.gx-wd-5 {
		--bs-gutter-x: 1.5rem
	}
	.g-wd-5,
	.gy-wd-5 {
		--bs-gutter-y: 1.5rem
	}
	.g-wd-6,
	.gx-wd-6 {
		--bs-gutter-x: 2rem
	}
	.g-wd-6,
	.gy-wd-6 {
		--bs-gutter-y: 2rem
	}
	.g-wd-7,
	.gx-wd-7 {
		--bs-gutter-x: 2.5rem
	}
	.g-wd-7,
	.gy-wd-7 {
		--bs-gutter-y: 2.5rem
	}
	.g-wd-8,
	.gx-wd-8 {
		--bs-gutter-x: 3rem
	}
	.g-wd-8,
	.gy-wd-8 {
		--bs-gutter-y: 3rem
	}
	.g-wd-9,
	.gx-wd-9 {
		--bs-gutter-x: 4rem
	}
	.g-wd-9,
	.gy-wd-9 {
		--bs-gutter-y: 4rem
	}
	.g-wd-10,
	.gx-wd-10 {
		--bs-gutter-x: 5rem
	}
	.g-wd-10,
	.gy-wd-10 {
		--bs-gutter-y: 5rem
	}
	.g-wd-11,
	.gx-wd-11 {
		--bs-gutter-x: 6rem
	}
	.g-wd-11,
	.gy-wd-11 {
		--bs-gutter-y: 6rem
	}
	.g-wd-12,
	.gx-wd-12 {
		--bs-gutter-x: 8rem
	}
	.g-wd-12,
	.gy-wd-12 {
		--bs-gutter-y: 8rem
	}
	.g-wd-13,
	.gx-wd-13 {
		--bs-gutter-x: 10rem
	}
	.g-wd-13,
	.gy-wd-13 {
		--bs-gutter-y: 10rem
	}
	.g-wd-14,
	.gx-wd-14 {
		--bs-gutter-x: 12rem
	}
	.g-wd-14,
	.gy-wd-14 {
		--bs-gutter-y: 12rem
	}
	.g-wd-15,
	.gx-wd-15 {
		--bs-gutter-x: 16rem
	}
	.g-wd-15,
	.gy-wd-15 {
		--bs-gutter-y: 16rem
	}
	.g-wd-16,
	.gx-wd-16 {
		--bs-gutter-x: 25rem
	}
	.g-wd-16,
	.gy-wd-16 {
		--bs-gutter-y: 25rem
	}
}

.table {
	--bs-table-bg: #FFFFFF;
	--bs-table-accent-bg: #F7F9FB;
	--bs-table-striped-color: #77838F;
	--bs-table-striped-bg: rgba(22, 28, 45, 0.05);
	--bs-table-active-color: #77838F;
	--bs-table-active-bg: rgba(22, 28, 45, 0.1);
	--bs-table-hover-color: #77838F;
	--bs-table-hover-bg: rgba(22, 28, 45, 0.075);
	width: 100%;
	margin-bottom: 1rem;
	color: #77838f;
	vertical-align: top;
	border-color: #e7e7ec
}

.table>:not(caption)>*>* {
	padding: .5rem .5rem;
	background-color: var(--bs-table-bg);
	border-bottom-width: 1px;
	box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)
}

.table>tbody {
	vertical-align: inherit
}

.table>thead {
	vertical-align: bottom
}

.table>:not(:first-child) {
	border-top: 2px solid currentColor
}

.caption-top {
	caption-side: top
}

.table-sm>:not(caption)>*>* {
	padding: .25rem .25rem
}

.table-bordered>:not(caption)>* {
	border-width: 1px 0
}

.table-bordered>:not(caption)>*>* {
	border-width: 0 1px
}

.table-borderless>:not(caption)>*>* {
	border-bottom-width: 0
}

.table-borderless>:not(:first-child) {
	border-top-width: 0
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
	--bs-table-accent-bg: var(--bs-table-striped-bg);
	color: var(--bs-table-striped-color)
}

.table-active {
	--bs-table-accent-bg: var(--bs-table-active-bg);
	color: var(--bs-table-active-color)
}

.table-hover>tbody>tr:hover>* {
	--bs-table-accent-bg: var(--bs-table-hover-bg);
	color: var(--bs-table-hover-color)
}

.table-primary {
	--bs-table-bg: #cecddf;
	--bs-table-striped-bg: #c5c4d6;
	--bs-table-striped-color: #161C2D;
	--bs-table-active-bg: #bcbbcd;
	--bs-table-active-color: #161C2D;
	--bs-table-hover-bg: #c0c0d2;
	--bs-table-hover-color: #161C2D;
	color: #161c2d;
	border-color: #bcbbcd
}

.table-secondary {
	--bs-table-bg: #eaebed;
	--bs-table-striped-bg: #dfe1e3;
	--bs-table-striped-color: #161C2D;
	--bs-table-active-bg: #d5d6da;
	--bs-table-active-color: #161C2D;
	--bs-table-hover-bg: #dadbdf;
	--bs-table-hover-color: #161C2D;
	color: #161c2d;
	border-color: #d5d6da
}

.table-success {
	--bs-table-bg: #f8fcf7;
	--bs-table-striped-bg: #edf1ed;
	--bs-table-striped-color: #161C2D;
	--bs-table-active-bg: #e1e6e3;
	--bs-table-active-color: #161C2D;
	--bs-table-hover-bg: #e7ebe8;
	--bs-table-hover-color: #161C2D;
	color: #161c2d;
	border-color: #e1e6e3
}

.table-info {
	--bs-table-bg: #f5fbfd;
	--bs-table-striped-bg: #eaf0f3;
	--bs-table-striped-color: #161C2D;
	--bs-table-active-bg: #dfe5e8;
	--bs-table-active-color: #161C2D;
	--bs-table-hover-bg: #e4eaed;
	--bs-table-hover-color: #161C2D;
	color: #161c2d;
	border-color: #dfe5e8
}

.table-warning {
	--bs-table-bg: #fdfdf7;
	--bs-table-striped-bg: #f1f2ed;
	--bs-table-striped-color: #161C2D;
	--bs-table-active-bg: #e6e7e3;
	--bs-table-active-color: #161C2D;
	--bs-table-hover-bg: #ecece8;
	--bs-table-hover-color: #161C2D;
	color: #161c2d;
	border-color: #e6e7e3
}

.table-danger {
	--bs-table-bg: #fbf4f3;
	--bs-table-striped-bg: #f0e9e9;
	--bs-table-striped-color: #161C2D;
	--bs-table-active-bg: #e4dedf;
	--bs-table-active-color: #161C2D;
	--bs-table-hover-bg: #eae4e4;
	--bs-table-hover-color: #161C2D;
	color: #161c2d;
	border-color: #e4dedf
}

.table-light {
	--bs-table-bg: #F7F9FB;
	--bs-table-striped-bg: #eceef1;
	--bs-table-striped-color: #161C2D;
	--bs-table-active-bg: #e1e3e6;
	--bs-table-active-color: #161C2D;
	--bs-table-hover-bg: #e6e8ec;
	--bs-table-hover-color: #161C2D;
	color: #161c2d;
	border-color: #e1e3e6
}

.table-dark {
	--bs-table-bg: #2F2D51;
	--bs-table-striped-bg: #39385a;
	--bs-table-striped-color: #FFFFFF;
	--bs-table-active-bg: #444262;
	--bs-table-active-color: #FFFFFF;
	--bs-table-hover-bg: #3f3d5e;
	--bs-table-hover-color: #FFFFFF;
	color: #fff;
	border-color: #444262
}

.table-responsive {
	overflow-x: auto;
	-webkit-overflow-scrolling: touch
}

@media (max-width:575.98px) {
	.table-responsive-sm {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch
	}
}

@media (max-width:767.98px) {
	.table-responsive-md {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch
	}
}

@media (max-width:991.98px) {
	.table-responsive-lg {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch
	}
}

@media (max-width:1199.98px) {
	.table-responsive-xl {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch
	}
}

@media (max-width:1479.98px) {
	.table-responsive-wd {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch
	}
}

.form-label {
	margin-bottom: .5rem
}

.col-form-label {
	padding-top: calc(1rem + 1px);
	padding-bottom: calc(1rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1.64
}

.col-form-label-lg {
	padding-top: calc(1.122rem + 1px);
	padding-bottom: calc(1.122rem + 1px);
	font-size: 1rem
}

.col-form-label-sm {
	padding-top: calc(.5625rem + 1px);
	padding-bottom: calc(.5625rem + 1px);
	font-size: 1rem
}

.form-text {
	margin-top: .25rem;
	font-size: 88.2%;
	color: #8c98a4
}

.form-control,
.shop_table.cart tbody td.actions .input-text,
form.woocommerce-checkout .form-row .input-text {
	display: block;
	width: 100%;
	padding: 1rem 1.25rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.64;
	color: #77838f;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #e7e7ec;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: .25rem;
	box-shadow: inset 0 1px 2px rgba(22, 28, 45, .075);
	-webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.form-control,
	.shop_table.cart tbody td.actions .input-text,
	form.woocommerce-checkout .form-row .input-text {
		-webkit-transition: none;
		transition: none
	}
}

.form-control[type=file],
.shop_table.cart tbody td.actions .input-text[type=file],
form.woocommerce-checkout .form-row .input-text[type=file] {
	overflow: hidden
}

.form-control[type=file]:not(:disabled):not([readonly]),
.shop_table.cart tbody td.actions .input-text[type=file]:not(:disabled):not([readonly]),
form.woocommerce-checkout .form-row .input-text[type=file]:not(:disabled):not([readonly]) {
	cursor: pointer
}

.form-control:focus,
.shop_table.cart tbody td.actions .input-text:focus,
form.woocommerce-checkout .form-row .input-text:focus {
	color: #77838f;
	background-color: #fff;
	border-color: #090761;
	outline: 0;
	box-shadow: inset 0 1px 2px rgba(22, 28, 45, .075), 0 0 0 0 transparent
}

.form-control::-webkit-date-and-time-value,
.shop_table.cart tbody td.actions .input-text::-webkit-date-and-time-value,
form.woocommerce-checkout .form-row .input-text::-webkit-date-and-time-value {
	height: 1.64em
}

.form-control::-webkit-input-placeholder,
.shop_table.cart tbody td.actions .input-text::-webkit-input-placeholder,
form.woocommerce-checkout .form-row .input-text::-webkit-input-placeholder {
	color: #8c98a4;
	opacity: 1
}

.form-control::-moz-placeholder,
.shop_table.cart tbody td.actions .input-text::-moz-placeholder,
form.woocommerce-checkout .form-row .input-text::-moz-placeholder {
	color: #8c98a4;
	opacity: 1
}

.form-control:-ms-input-placeholder,
.shop_table.cart tbody td.actions .input-text:-ms-input-placeholder,
form.woocommerce-checkout .form-row .input-text:-ms-input-placeholder {
	color: #8c98a4;
	opacity: 1
}

.form-control::-ms-input-placeholder,
.shop_table.cart tbody td.actions .input-text::-ms-input-placeholder,
form.woocommerce-checkout .form-row .input-text::-ms-input-placeholder {
	color: #8c98a4;
	opacity: 1
}

.form-control::placeholder,
.shop_table.cart tbody td.actions .input-text::placeholder,
form.woocommerce-checkout .form-row .input-text::placeholder {
	color: #8c98a4;
	opacity: 1
}

.form-control:disabled,
.form-control[readonly],
.shop_table.cart tbody td.actions .input-text:disabled,
.shop_table.cart tbody td.actions .input-text[readonly],
form.woocommerce-checkout .form-row .input-text:disabled,
form.woocommerce-checkout .form-row .input-text[readonly] {
	background-color: #e7e7ec;
	opacity: 1
}

.form-control::file-selector-button,
.shop_table.cart tbody td.actions .input-text::file-selector-button,
form.woocommerce-checkout .form-row .input-text::file-selector-button {
	padding: 1rem 1.25rem;
	margin: -1rem -1.25rem;
	-webkit-margin-end: 1.25rem;
	margin-inline-end: 1.25rem;
	color: #77838f;
	background-color: #fff;
	pointer-events: none;
	border-color: inherit;
	border-style: solid;
	border-width: 0;
	border-inline-end-width: 1px;
	border-radius: 0;
	-webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.form-control::file-selector-button,
	.shop_table.cart tbody td.actions .input-text::file-selector-button,
	form.woocommerce-checkout .form-row .input-text::file-selector-button {
		-webkit-transition: none;
		transition: none
	}
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button,
.shop_table.cart tbody td.actions .input-text:hover:not(:disabled):not([readonly])::file-selector-button,
form.woocommerce-checkout .form-row .input-text:hover:not(:disabled):not([readonly])::file-selector-button {
	background-color: #f2f2f2
}

.form-control::-webkit-file-upload-button,
.shop_table.cart tbody td.actions .input-text::-webkit-file-upload-button,
form.woocommerce-checkout .form-row .input-text::-webkit-file-upload-button {
	padding: 1rem 1.25rem;
	margin: -1rem -1.25rem;
	-webkit-margin-end: 1.25rem;
	margin-inline-end: 1.25rem;
	color: #77838f;
	background-color: #fff;
	pointer-events: none;
	border-color: inherit;
	border-style: solid;
	border-width: 0;
	border-inline-end-width: 1px;
	border-radius: 0;
	-webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.form-control::-webkit-file-upload-button,
	.shop_table.cart tbody td.actions .input-text::-webkit-file-upload-button,
	form.woocommerce-checkout .form-row .input-text::-webkit-file-upload-button {
		-webkit-transition: none;
		transition: none
	}
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.shop_table.cart tbody td.actions .input-text:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
form.woocommerce-checkout .form-row .input-text:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
	background-color: #f2f2f2
}

.form-control-plaintext {
	display: block;
	width: 100%;
	padding: 1rem 0;
	margin-bottom: 0;
	line-height: 1.64;
	color: #77838f;
	background-color: transparent;
	border: solid transparent;
	border-width: 1px 0
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm,
.shop_table.cart tbody td.actions .form-control-plaintext.input-text {
	padding-right: 0;
	padding-left: 0
}

.form-control-sm,
.shop_table.cart tbody td.actions .input-text {
	min-height: calc(1.888em + 1.125rem + 2px);
	padding: .5625rem 1rem;
	font-size: 1rem;
	border-radius: .25rem
}

.form-control-sm::file-selector-button,
.shop_table.cart tbody td.actions .input-text::file-selector-button {
	padding: .5625rem 1rem;
	margin: -.5625rem -1rem;
	-webkit-margin-end: 1rem;
	margin-inline-end: 1rem
}

.form-control-sm::-webkit-file-upload-button,
.shop_table.cart tbody td.actions .input-text::-webkit-file-upload-button {
	padding: .5625rem 1rem;
	margin: -.5625rem -1rem;
	-webkit-margin-end: 1rem;
	margin-inline-end: 1rem
}

.form-control-lg {
	min-height: calc(1.64em + 2.244rem + 2px);
	padding: 1.122rem 1.5rem;
	font-size: 1rem;
	border-radius: .25rem
}

.form-control-lg::file-selector-button {
	padding: 1.122rem 1.5rem;
	margin: -1.122rem -1.5rem;
	-webkit-margin-end: 1.5rem;
	margin-inline-end: 1.5rem
}

.form-control-lg::-webkit-file-upload-button {
	padding: 1.122rem 1.5rem;
	margin: -1.122rem -1.5rem;
	-webkit-margin-end: 1.5rem;
	margin-inline-end: 1.5rem
}

.shop_table.cart tbody td.actions textarea.input-text,
form.woocommerce-checkout .form-row textarea.input-text,
textarea.form-control {
	min-height: calc(1.64em + 2rem + 2px)
}

.shop_table.cart tbody td.actions textarea.input-text,
textarea.form-control-sm {
	min-height: calc(1.888em + 1.125rem + 2px)
}

textarea.form-control-lg {
	min-height: calc(1.64em + 2.244rem + 2px)
}

.form-control-color {
	width: 3rem;
	height: auto;
	padding: 1rem
}

.form-control-color:not(:disabled):not([readonly]) {
	cursor: pointer
}

.form-control-color::-moz-color-swatch {
	height: 1.64em;
	border-radius: .25rem
}

.form-control-color::-webkit-color-swatch {
	height: 1.64em;
	border-radius: .25rem
}

.form-select,
form.woocommerce-checkout #billing_country,
form.woocommerce-checkout #shipping_country {
	display: block;
	width: 100%;
	padding: 1rem 3.75rem 1rem 1.25rem;
	-moz-padding-start: calc(1.25rem - 3px);
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.64;
	color: #77838f;
	background-color: #fff;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%2377838F' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right 1.25rem center;
	background-size: 16px 12px;
	border: 1px solid #e7e7ec;
	border-radius: .25rem;
	box-shadow: inset 0 1px 2px rgba(22, 28, 45, .075);
	-webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none
}

@media (prefers-reduced-motion:reduce) {
	.form-select,
	form.woocommerce-checkout #billing_country,
	form.woocommerce-checkout #shipping_country {
		-webkit-transition: none;
		transition: none
	}
}

.form-select:focus,
form.woocommerce-checkout #billing_country:focus,
form.woocommerce-checkout #shipping_country:focus {
	border-color: #090761;
	outline: 0;
	box-shadow: inset 0 1px 2px rgba(22, 28, 45, .075), 0 0 0 0 transparent
}

.form-select[multiple],
.form-select[size]:not([size="1"]),
form.woocommerce-checkout #billing_country[multiple],
form.woocommerce-checkout #billing_country[size]:not([size="1"]),
form.woocommerce-checkout #shipping_country[multiple],
form.woocommerce-checkout #shipping_country[size]:not([size="1"]) {
	padding-right: 1.25rem;
	background-image: none
}

.form-select:disabled,
form.woocommerce-checkout #billing_country:disabled,
form.woocommerce-checkout #shipping_country:disabled {
	background-color: #e7e7ec
}

.form-select:-moz-focusring,
form.woocommerce-checkout #billing_country:-moz-focusring,
form.woocommerce-checkout #shipping_country:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #77838f
}

.form-select-sm {
	padding-top: .7rem;
	padding-bottom: .7rem;
	padding-left: 1rem;
	font-size: 1rem;
	border-radius: .25rem
}

.form-select-lg {
	padding-top: 1.122rem;
	padding-bottom: 1.122rem;
	padding-left: 1.5rem;
	font-size: 1rem;
	border-radius: .25rem
}

.form-check {
	display: block;
	min-height: 1.6rem;
	padding-left: 1.5em;
	margin-bottom: .125rem
}

.form-check .form-check-input {
	float: left;
	margin-left: -1.5em
}

.form-check-input {
	width: 1em;
	height: 1em;
	margin-top: .3em;
	vertical-align: top;
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	border: 1px solid rgba(22, 28, 45, .25);
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-print-color-adjust: exact;
	color-adjust: exact
}

.form-check-input[type=checkbox] {
	border-radius: .25em
}

.form-check-input[type=radio] {
	border-radius: 50%
}

.form-check-input:active {
	-webkit-filter: brightness(90%);
	filter: brightness(90%)
}

.form-check-input:focus {
	border-color: #090761;
	outline: 0;
	box-shadow: 0 0 0 0 transparent
}

.form-check-input:checked {
	background-color: #090761;
	border-color: #090761
}

.form-check-input:checked[type=checkbox] {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}

.form-check-input:checked[type=radio] {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FFFFFF'/%3e%3c/svg%3e")
}

.form-check-input[type=checkbox]:indeterminate {
	background-color: #090761;
	border-color: #090761;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
}

.form-check-input:disabled {
	pointer-events: none;
	-webkit-filter: none;
	filter: none;
	opacity: .5
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
	opacity: .5
}

.form-switch {
	padding-left: 2.5em
}

.form-switch .form-check-input {
	width: 2em;
	margin-left: -2.5em;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%2822, 28, 45, 0.25%29'/%3e%3c/svg%3e");
	background-position: left center;
	border-radius: 2em;
	-webkit-transition: background-position .15s ease-in-out;
	transition: background-position .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.form-switch .form-check-input {
		-webkit-transition: none;
		transition: none
	}
}

.form-switch .form-check-input:focus {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23090761'/%3e%3c/svg%3e")
}

.form-switch .form-check-input:checked {
	background-position: right center;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e")
}

.form-check-inline {
	display: inline-block;
	margin-right: 1rem
}

.btn-check {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none
}

.btn-check:disabled+.btn,
.btn-check[disabled]+.btn,
.cart-collaterals .btn-check:disabled+.checkout-button,
.cart-collaterals .btn-check[disabled]+.checkout-button,
.shop_table.cart .btn-check:disabled+input.button,
.shop_table.cart .btn-check[disabled]+input.button,
form.woocommerce-checkout .place-order .btn-check:disabled+.button,
form.woocommerce-checkout .place-order .btn-check[disabled]+.button {
	pointer-events: none;
	-webkit-filter: none;
	filter: none;
	opacity: .65
}

.form-range {
	width: 100%;
	height: 1rem;
	padding: 0;
	background-color: transparent;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none
}

.form-range:focus {
	outline: 0
}

.form-range:focus::-webkit-slider-thumb {
	box-shadow: 0 0 0 1px #f7f9fb, 0 0 0 0 transparent
}

.form-range:focus::-moz-range-thumb {
	box-shadow: 0 0 0 1px #f7f9fb, 0 0 0 0 transparent
}

.form-range::-moz-focus-outer {
	border: 0
}

.form-range::-webkit-slider-thumb {
	width: 1rem;
	height: 1rem;
	margin-top: -.25rem;
	background-color: #090761;
	border: 0;
	border-radius: 1rem;
	box-shadow: 0 .1rem .25rem rgba(22, 28, 45, .1);
	-webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	-webkit-appearance: none;
	appearance: none
}

@media (prefers-reduced-motion:reduce) {
	.form-range::-webkit-slider-thumb {
		-webkit-transition: none;
		transition: none
	}
}

.form-range::-webkit-slider-thumb:active {
	background-color: #b5b5d0
}

.form-range::-webkit-slider-runnable-track {
	width: 100%;
	height: .5rem;
	color: transparent;
	cursor: pointer;
	background-color: #d9e2ef;
	border-color: transparent;
	border-radius: 1rem;
	box-shadow: inset 0 1px 2px rgba(22, 28, 45, .075)
}

.form-range::-moz-range-thumb {
	width: 1rem;
	height: 1rem;
	background-color: #090761;
	border: 0;
	border-radius: 1rem;
	box-shadow: 0 .1rem .25rem rgba(22, 28, 45, .1);
	-moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	-moz-appearance: none;
	appearance: none
}

@media (prefers-reduced-motion:reduce) {
	.form-range::-moz-range-thumb {
		-moz-transition: none;
		transition: none
	}
}

.form-range::-moz-range-thumb:active {
	background-color: #b5b5d0
}

.form-range::-moz-range-track {
	width: 100%;
	height: .5rem;
	color: transparent;
	cursor: pointer;
	background-color: #d9e2ef;
	border-color: transparent;
	border-radius: 1rem;
	box-shadow: inset 0 1px 2px rgba(22, 28, 45, .075)
}

.form-range:disabled {
	pointer-events: none
}

.form-range:disabled::-webkit-slider-thumb {
	background-color: #c8c8d6
}

.form-range:disabled::-moz-range-thumb {
	background-color: #c8c8d6
}

.form-floating {
	position: relative
}

.form-floating>.form-control,
.form-floating>.form-select,
.shop_table.cart tbody td.actions .form-floating>.input-text,
form.woocommerce-checkout .form-floating>#billing_country,
form.woocommerce-checkout .form-floating>#shipping_country,
form.woocommerce-checkout .form-row .form-floating>.input-text {
	height: calc(3.5rem + 2px);
	line-height: 1.25
}

.form-floating>label {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	padding: 1rem 1.25rem;
	pointer-events: none;
	border: 1px solid transparent;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
	-webkit-transition: opacity .1s ease-in-out, -webkit-transform .1s ease-in-out;
	transition: opacity .1s ease-in-out, -webkit-transform .1s ease-in-out;
	transition: opacity .1s ease-in-out, transform .1s ease-in-out;
	transition: opacity .1s ease-in-out, transform .1s ease-in-out, -webkit-transform .1s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.form-floating>label {
		-webkit-transition: none;
		transition: none
	}
}

.form-floating>.form-control,
.shop_table.cart tbody td.actions .form-floating>.input-text,
form.woocommerce-checkout .form-row .form-floating>.input-text {
	padding: 1rem 1.25rem
}

.form-floating>.form-control::-webkit-input-placeholder,
.shop_table.cart tbody td.actions .form-floating>.input-text::-webkit-input-placeholder,
form.woocommerce-checkout .form-row .form-floating>.input-text::-webkit-input-placeholder {
	color: transparent
}

.form-floating>.form-control::-moz-placeholder,
.shop_table.cart tbody td.actions .form-floating>.input-text::-moz-placeholder,
form.woocommerce-checkout .form-row .form-floating>.input-text::-moz-placeholder {
	color: transparent
}

.form-floating>.form-control:-ms-input-placeholder,
.shop_table.cart tbody td.actions .form-floating>.input-text:-ms-input-placeholder,
form.woocommerce-checkout .form-row .form-floating>.input-text:-ms-input-placeholder {
	color: transparent
}

.form-floating>.form-control::-ms-input-placeholder,
.shop_table.cart tbody td.actions .form-floating>.input-text::-ms-input-placeholder,
form.woocommerce-checkout .form-row .form-floating>.input-text::-ms-input-placeholder {
	color: transparent
}

.form-floating>.form-control::placeholder,
.shop_table.cart tbody td.actions .form-floating>.input-text::placeholder,
form.woocommerce-checkout .form-row .form-floating>.input-text::placeholder {
	color: transparent
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown),
.shop_table.cart tbody td.actions .form-floating>.input-text:focus,
.shop_table.cart tbody td.actions .form-floating>.input-text:not(:placeholder-shown),
form.woocommerce-checkout .form-row .form-floating>.input-text:focus,
form.woocommerce-checkout .form-row .form-floating>.input-text:not(:placeholder-shown) {
	padding-top: 1.625rem;
	padding-bottom: .625rem
}

.form-floating>.form-control:-webkit-autofill,
.shop_table.cart tbody td.actions .form-floating>.input-text:-webkit-autofill,
form.woocommerce-checkout .form-row .form-floating>.input-text:-webkit-autofill {
	padding-top: 1.625rem;
	padding-bottom: .625rem
}

.form-floating>.form-select,
form.woocommerce-checkout .form-floating>#billing_country,
form.woocommerce-checkout .form-floating>#shipping_country {
	padding-top: 1.625rem;
	padding-bottom: .625rem
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label,
.shop_table.cart tbody td.actions .form-floating>.input-text:focus~label,
.shop_table.cart tbody td.actions .form-floating>.input-text:not(:placeholder-shown)~label,
form.woocommerce-checkout .form-floating>#billing_country~label,
form.woocommerce-checkout .form-floating>#shipping_country~label,
form.woocommerce-checkout .form-row .form-floating>.input-text:focus~label,
form.woocommerce-checkout .form-row .form-floating>.input-text:not(:placeholder-shown)~label {
	opacity: .65;
	-webkit-transform: scale(.85) translateY(-.5rem) translateX(.15rem);
	transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control:-webkit-autofill~label,
.shop_table.cart tbody td.actions .form-floating>.input-text:-webkit-autofill~label,
form.woocommerce-checkout .form-row .form-floating>.input-text:-webkit-autofill~label {
	opacity: .65;
	-webkit-transform: scale(.85) translateY(-.5rem) translateX(.15rem);
	transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.input-group {
	position: relative;
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	-webkit-box-align: stretch;
	align-items: stretch;
	width: 100%
}

.input-group>.form-control,
.input-group>.form-select,
.shop_table.cart tbody td.actions .input-group>.input-text,
form.woocommerce-checkout .form-row .input-group>.input-text,
form.woocommerce-checkout .input-group>#billing_country,
form.woocommerce-checkout .input-group>#shipping_country {
	position: relative;
	-webkit-box-flex: 1;
	flex: 1 1 auto;
	width: 1%;
	min-width: 0
}

.input-group>.form-control:focus,
.input-group>.form-select:focus,
.shop_table.cart tbody td.actions .input-group>.input-text:focus,
form.woocommerce-checkout .form-row .input-group>.input-text:focus,
form.woocommerce-checkout .input-group>#billing_country:focus,
form.woocommerce-checkout .input-group>#shipping_country:focus {
	z-index: 3
}

.cart-collaterals .input-group .checkout-button,
.input-group .btn,
.input-group .cart-collaterals .checkout-button,
.input-group .shop_table.cart input.button,
.input-group form.woocommerce-checkout .place-order .button,
.shop_table.cart .input-group input.button,
form.woocommerce-checkout .place-order .input-group .button {
	position: relative;
	z-index: 2
}

.cart-collaterals .input-group .checkout-button:focus,
.input-group .btn:focus,
.input-group .cart-collaterals .checkout-button:focus,
.input-group .shop_table.cart input.button:focus,
.input-group form.woocommerce-checkout .place-order .button:focus,
.shop_table.cart .input-group input.button:focus,
form.woocommerce-checkout .place-order .input-group .button:focus {
	z-index: 3
}

.input-group-text {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	padding: 1rem 1.25rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.64;
	color: #8c98a4;
	text-align: center;
	white-space: nowrap;
	background-color: #fff;
	border: 1px solid #e7e7ec;
	border-radius: .25rem
}

.cart-collaterals .input-group-lg>.checkout-button,
.input-group-lg>.btn,
.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text,
.shop_table.cart .input-group-lg>input.button,
.shop_table.cart tbody td.actions .input-group-lg>.input-text,
form.woocommerce-checkout .form-row .input-group-lg>.input-text,
form.woocommerce-checkout .input-group-lg>#billing_country,
form.woocommerce-checkout .input-group-lg>#shipping_country,
form.woocommerce-checkout .place-order .input-group-lg>.button {
	padding: 1.122rem 1.5rem;
	font-size: 1rem;
	border-radius: .25rem
}

.cart-collaterals .input-group-sm>.checkout-button,
.input-group-sm>.btn,
.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text,
.shop_table.cart .input-group-sm>input.button,
.shop_table.cart tbody td.actions .input-group-sm>.input-text,
form.woocommerce-checkout .form-row .input-group-sm>.input-text,
form.woocommerce-checkout .input-group-sm>#billing_country,
form.woocommerce-checkout .input-group-sm>#shipping_country,
form.woocommerce-checkout .place-order .input-group-sm>.button {
	padding: .5625rem 1rem;
	font-size: 1rem;
	border-radius: .25rem
}

.input-group-lg>.form-select,
.input-group-sm>.form-select,
form.woocommerce-checkout .input-group-lg>#billing_country,
form.woocommerce-checkout .input-group-lg>#shipping_country,
form.woocommerce-checkout .input-group-sm>#billing_country,
form.woocommerce-checkout .input-group-sm>#shipping_country {
	padding-right: 5rem
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
	margin-left: -1px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}

.valid-feedback {
	display: none;
	width: 100%;
	margin-top: .25rem;
	font-size: 88.2%;
	color: #def2d7
}

.valid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: .25rem .5rem;
	margin-top: .1rem;
	font-size: .875rem;
	color: #161c2d;
	background-color: rgba(222, 242, 215, .9);
	border-radius: .25rem
}

.is-valid~.valid-feedback,
.is-valid~.valid-tooltip,
.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip {
	display: block
}

.form-control.is-valid,
.shop_table.cart tbody td.actions .is-valid.input-text,
.shop_table.cart tbody td.actions .was-validated .input-text:valid,
.was-validated .form-control:valid,
.was-validated .shop_table.cart tbody td.actions .input-text:valid,
.was-validated form.woocommerce-checkout .form-row .input-text:valid,
form.woocommerce-checkout .form-row .is-valid.input-text,
form.woocommerce-checkout .form-row .was-validated .input-text:valid {
	border-color: #def2d7
}

.form-control.is-valid:focus,
.shop_table.cart tbody td.actions .is-valid.input-text:focus,
.shop_table.cart tbody td.actions .was-validated .input-text:valid:focus,
.was-validated .form-control:valid:focus,
.was-validated .shop_table.cart tbody td.actions .input-text:valid:focus,
.was-validated form.woocommerce-checkout .form-row .input-text:valid:focus,
form.woocommerce-checkout .form-row .is-valid.input-text:focus,
form.woocommerce-checkout .form-row .was-validated .input-text:valid:focus {
	border-color: #def2d7;
	box-shadow: 0 0 0 0 rgba(222, 242, 215, .25)
}

.form-select.is-valid,
.was-validated .form-select:valid,
.was-validated form.woocommerce-checkout #billing_country:valid,
.was-validated form.woocommerce-checkout #shipping_country:valid,
form.woocommerce-checkout .is-valid#billing_country,
form.woocommerce-checkout .is-valid#shipping_country,
form.woocommerce-checkout .was-validated #billing_country:valid,
form.woocommerce-checkout .was-validated #shipping_country:valid {
	border-color: #def2d7
}

.form-select.is-valid:focus,
.was-validated .form-select:valid:focus,
.was-validated form.woocommerce-checkout #billing_country:valid:focus,
.was-validated form.woocommerce-checkout #shipping_country:valid:focus,
form.woocommerce-checkout .is-valid#billing_country:focus,
form.woocommerce-checkout .is-valid#shipping_country:focus,
form.woocommerce-checkout .was-validated #billing_country:valid:focus,
form.woocommerce-checkout .was-validated #shipping_country:valid:focus {
	border-color: #def2d7;
	box-shadow: 0 0 0 0 rgba(222, 242, 215, .25)
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
	border-color: #def2d7
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
	background-color: #def2d7
}

.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
	box-shadow: 0 0 0 0 rgba(222, 242, 215, .25)
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
	color: #def2d7
}

.form-check-inline .form-check-input~.valid-feedback {
	margin-left: .5em
}

.input-group .form-control.is-valid,
.input-group .form-select.is-valid,
.input-group .shop_table.cart tbody td.actions .is-valid.input-text,
.input-group form.woocommerce-checkout .form-row .is-valid.input-text,
.input-group form.woocommerce-checkout .is-valid#billing_country,
.input-group form.woocommerce-checkout .is-valid#shipping_country,
.shop_table.cart tbody td.actions .input-group .is-valid.input-text,
.shop_table.cart tbody td.actions .was-validated .input-group .input-text:valid,
.was-validated .input-group .form-control:valid,
.was-validated .input-group .form-select:valid,
.was-validated .input-group .shop_table.cart tbody td.actions .input-text:valid,
.was-validated .input-group form.woocommerce-checkout #billing_country:valid,
.was-validated .input-group form.woocommerce-checkout #shipping_country:valid,
.was-validated .input-group form.woocommerce-checkout .form-row .input-text:valid,
form.woocommerce-checkout .form-row .input-group .is-valid.input-text,
form.woocommerce-checkout .form-row .was-validated .input-group .input-text:valid,
form.woocommerce-checkout .input-group .is-valid#billing_country,
form.woocommerce-checkout .input-group .is-valid#shipping_country,
form.woocommerce-checkout .was-validated .input-group #billing_country:valid,
form.woocommerce-checkout .was-validated .input-group #shipping_country:valid {
	z-index: 1
}

.input-group .form-control.is-valid:focus,
.input-group .form-select.is-valid:focus,
.input-group .shop_table.cart tbody td.actions .is-valid.input-text:focus,
.input-group form.woocommerce-checkout .form-row .is-valid.input-text:focus,
.input-group form.woocommerce-checkout .is-valid#billing_country:focus,
.input-group form.woocommerce-checkout .is-valid#shipping_country:focus,
.shop_table.cart tbody td.actions .input-group .is-valid.input-text:focus,
.shop_table.cart tbody td.actions .was-validated .input-group .input-text:valid:focus,
.was-validated .input-group .form-control:valid:focus,
.was-validated .input-group .form-select:valid:focus,
.was-validated .input-group .shop_table.cart tbody td.actions .input-text:valid:focus,
.was-validated .input-group form.woocommerce-checkout #billing_country:valid:focus,
.was-validated .input-group form.woocommerce-checkout #shipping_country:valid:focus,
.was-validated .input-group form.woocommerce-checkout .form-row .input-text:valid:focus,
form.woocommerce-checkout .form-row .input-group .is-valid.input-text:focus,
form.woocommerce-checkout .form-row .was-validated .input-group .input-text:valid:focus,
form.woocommerce-checkout .input-group .is-valid#billing_country:focus,
form.woocommerce-checkout .input-group .is-valid#shipping_country:focus,
form.woocommerce-checkout .was-validated .input-group #billing_country:valid:focus,
form.woocommerce-checkout .was-validated .input-group #shipping_country:valid:focus {
	z-index: 3
}

.invalid-feedback {
	display: none;
	width: 100%;
	margin-top: .25rem;
	font-size: 88.2%;
	color: #ecc8c5
}

.invalid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: .25rem .5rem;
	margin-top: .1rem;
	font-size: .875rem;
	color: #161c2d;
	background-color: rgba(236, 200, 197, .9);
	border-radius: .25rem
}

.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {
	display: block
}

.form-control.is-invalid,
.shop_table.cart tbody td.actions .is-invalid.input-text,
.shop_table.cart tbody td.actions .was-validated .input-text:invalid,
.was-validated .form-control:invalid,
.was-validated .shop_table.cart tbody td.actions .input-text:invalid,
.was-validated form.woocommerce-checkout .form-row .input-text:invalid,
form.woocommerce-checkout .form-row .is-invalid.input-text,
form.woocommerce-checkout .form-row .was-validated .input-text:invalid {
	border-color: #ecc8c5
}

.form-control.is-invalid:focus,
.shop_table.cart tbody td.actions .is-invalid.input-text:focus,
.shop_table.cart tbody td.actions .was-validated .input-text:invalid:focus,
.was-validated .form-control:invalid:focus,
.was-validated .shop_table.cart tbody td.actions .input-text:invalid:focus,
.was-validated form.woocommerce-checkout .form-row .input-text:invalid:focus,
form.woocommerce-checkout .form-row .is-invalid.input-text:focus,
form.woocommerce-checkout .form-row .was-validated .input-text:invalid:focus {
	border-color: #ecc8c5;
	box-shadow: 0 0 0 0 rgba(236, 200, 197, .25)
}

.form-select.is-invalid,
.was-validated .form-select:invalid,
.was-validated form.woocommerce-checkout #billing_country:invalid,
.was-validated form.woocommerce-checkout #shipping_country:invalid,
form.woocommerce-checkout .is-invalid#billing_country,
form.woocommerce-checkout .is-invalid#shipping_country,
form.woocommerce-checkout .was-validated #billing_country:invalid,
form.woocommerce-checkout .was-validated #shipping_country:invalid {
	border-color: #ecc8c5
}

.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus,
.was-validated form.woocommerce-checkout #billing_country:invalid:focus,
.was-validated form.woocommerce-checkout #shipping_country:invalid:focus,
form.woocommerce-checkout .is-invalid#billing_country:focus,
form.woocommerce-checkout .is-invalid#shipping_country:focus,
form.woocommerce-checkout .was-validated #billing_country:invalid:focus,
form.woocommerce-checkout .was-validated #shipping_country:invalid:focus {
	border-color: #ecc8c5;
	box-shadow: 0 0 0 0 rgba(236, 200, 197, .25)
}

.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
	border-color: #ecc8c5
}

.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
	background-color: #ecc8c5
}

.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
	box-shadow: 0 0 0 0 rgba(236, 200, 197, .25)
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
	color: #ecc8c5
}

.form-check-inline .form-check-input~.invalid-feedback {
	margin-left: .5em
}

.input-group .form-control.is-invalid,
.input-group .form-select.is-invalid,
.input-group .shop_table.cart tbody td.actions .is-invalid.input-text,
.input-group form.woocommerce-checkout .form-row .is-invalid.input-text,
.input-group form.woocommerce-checkout .is-invalid#billing_country,
.input-group form.woocommerce-checkout .is-invalid#shipping_country,
.shop_table.cart tbody td.actions .input-group .is-invalid.input-text,
.shop_table.cart tbody td.actions .was-validated .input-group .input-text:invalid,
.was-validated .input-group .form-control:invalid,
.was-validated .input-group .form-select:invalid,
.was-validated .input-group .shop_table.cart tbody td.actions .input-text:invalid,
.was-validated .input-group form.woocommerce-checkout #billing_country:invalid,
.was-validated .input-group form.woocommerce-checkout #shipping_country:invalid,
.was-validated .input-group form.woocommerce-checkout .form-row .input-text:invalid,
form.woocommerce-checkout .form-row .input-group .is-invalid.input-text,
form.woocommerce-checkout .form-row .was-validated .input-group .input-text:invalid,
form.woocommerce-checkout .input-group .is-invalid#billing_country,
form.woocommerce-checkout .input-group .is-invalid#shipping_country,
form.woocommerce-checkout .was-validated .input-group #billing_country:invalid,
form.woocommerce-checkout .was-validated .input-group #shipping_country:invalid {
	z-index: 2
}

.input-group .form-control.is-invalid:focus,
.input-group .form-select.is-invalid:focus,
.input-group .shop_table.cart tbody td.actions .is-invalid.input-text:focus,
.input-group form.woocommerce-checkout .form-row .is-invalid.input-text:focus,
.input-group form.woocommerce-checkout .is-invalid#billing_country:focus,
.input-group form.woocommerce-checkout .is-invalid#shipping_country:focus,
.shop_table.cart tbody td.actions .input-group .is-invalid.input-text:focus,
.shop_table.cart tbody td.actions .was-validated .input-group .input-text:invalid:focus,
.was-validated .input-group .form-control:invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.was-validated .input-group .shop_table.cart tbody td.actions .input-text:invalid:focus,
.was-validated .input-group form.woocommerce-checkout #billing_country:invalid:focus,
.was-validated .input-group form.woocommerce-checkout #shipping_country:invalid:focus,
.was-validated .input-group form.woocommerce-checkout .form-row .input-text:invalid:focus,
form.woocommerce-checkout .form-row .input-group .is-invalid.input-text:focus,
form.woocommerce-checkout .form-row .was-validated .input-group .input-text:invalid:focus,
form.woocommerce-checkout .input-group .is-invalid#billing_country:focus,
form.woocommerce-checkout .input-group .is-invalid#shipping_country:focus,
form.woocommerce-checkout .was-validated .input-group #billing_country:invalid:focus,
form.woocommerce-checkout .was-validated .input-group #shipping_country:invalid:focus {
	z-index: 3
}

.btn,
.cart-collaterals .checkout-button,
.shop_table.cart input.button,
form.woocommerce-checkout .place-order .button {
	display: inline-block;
	font-weight: 500;
	line-height: 1.64;
	color: #77838f;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 1rem 1.25rem;
	font-size: 1rem;
	border-radius: .25rem;
	-webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.btn,
	.cart-collaterals .checkout-button,
	.shop_table.cart input.button,
	form.woocommerce-checkout .place-order .button {
		-webkit-transition: none;
		transition: none
	}
}

.btn:hover,
.cart-collaterals .checkout-button:hover,
.shop_table.cart input.button:hover,
form.woocommerce-checkout .place-order .button:hover {
	color: #77838f
}

.btn-check:focus+.btn,
.btn:focus,
.cart-collaterals .btn-check:focus+.checkout-button,
.cart-collaterals .checkout-button:focus,
.shop_table.cart .btn-check:focus+input.button,
.shop_table.cart input.button:focus,
form.woocommerce-checkout .place-order .btn-check:focus+.button,
form.woocommerce-checkout .place-order .button:focus {
	outline: 0;
	box-shadow: 0 0 0 0 transparent
}

.btn-check:active+.btn,
.btn-check:checked+.btn,
.btn.active,
.btn:active,
.cart-collaterals .active.checkout-button,
.cart-collaterals .btn-check:active+.checkout-button,
.cart-collaterals .btn-check:checked+.checkout-button,
.cart-collaterals .checkout-button:active,
.shop_table.cart .btn-check:active+input.button,
.shop_table.cart .btn-check:checked+input.button,
.shop_table.cart input.active.button,
.shop_table.cart input.button:active,
form.woocommerce-checkout .place-order .active.button,
form.woocommerce-checkout .place-order .btn-check:active+.button,
form.woocommerce-checkout .place-order .btn-check:checked+.button,
form.woocommerce-checkout .place-order .button:active {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125)
}

.btn-check:active+.btn:focus,
.btn-check:checked+.btn:focus,
.btn.active:focus,
.btn:active:focus,
.cart-collaterals .active.checkout-button:focus,
.cart-collaterals .btn-check:active+.checkout-button:focus,
.cart-collaterals .btn-check:checked+.checkout-button:focus,
.cart-collaterals .checkout-button:active:focus,
.shop_table.cart .btn-check:active+input.button:focus,
.shop_table.cart .btn-check:checked+input.button:focus,
.shop_table.cart input.active.button:focus,
.shop_table.cart input.button:active:focus,
form.woocommerce-checkout .place-order .active.button:focus,
form.woocommerce-checkout .place-order .btn-check:active+.button:focus,
form.woocommerce-checkout .place-order .btn-check:checked+.button:focus,
form.woocommerce-checkout .place-order .button:active:focus {
	box-shadow: 0 0 0 0 transparent, inset 0 3px 5px rgba(22, 28, 45, .125)
}

.btn.disabled,
.btn:disabled,
.cart-collaterals .checkout-button:disabled,
.cart-collaterals .disabled.checkout-button,
.cart-collaterals fieldset:disabled .checkout-button,
.shop_table.cart fieldset:disabled input.button,
.shop_table.cart input.button:disabled,
.shop_table.cart input.disabled.button,
fieldset:disabled .btn,
fieldset:disabled .cart-collaterals .checkout-button,
fieldset:disabled .shop_table.cart input.button,
fieldset:disabled form.woocommerce-checkout .place-order .button,
form.woocommerce-checkout .place-order .button:disabled,
form.woocommerce-checkout .place-order .disabled.button,
form.woocommerce-checkout .place-order fieldset:disabled .button {
	pointer-events: none;
	opacity: .65;
	box-shadow: none
}

.btn-primary,
.cart-collaterals .checkout-button,
form.woocommerce-checkout .place-order .button {
	color: #fff;
	background-color: #090761;
	border-color: #090761;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-primary:hover,
.cart-collaterals .checkout-button:hover,
form.woocommerce-checkout .place-order .button:hover {
	color: #fff;
	background-color: #080652;
	border-color: #07064e
}

.btn-check:focus+.btn-primary,
.btn-primary:focus,
.cart-collaterals .btn-check:focus+.checkout-button,
.cart-collaterals .checkout-button:focus,
form.woocommerce-checkout .place-order .btn-check:focus+.button,
form.woocommerce-checkout .place-order .button:focus {
	color: #fff;
	background-color: #080652;
	border-color: #07064e;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(46, 44, 121, .5)
}

.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.cart-collaterals .active.checkout-button,
.cart-collaterals .btn-check:active+.checkout-button,
.cart-collaterals .btn-check:checked+.checkout-button,
.cart-collaterals .checkout-button:active,
.cart-collaterals .show>.dropdown-toggle.checkout-button,
.show>.btn-primary.dropdown-toggle,
form.woocommerce-checkout .place-order .active.button,
form.woocommerce-checkout .place-order .btn-check:active+.button,
form.woocommerce-checkout .place-order .btn-check:checked+.button,
form.woocommerce-checkout .place-order .button:active,
form.woocommerce-checkout .place-order .show>.dropdown-toggle.button {
	color: #fff;
	background-color: #07064e;
	border-color: #070549
}

.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.cart-collaterals .active.checkout-button:focus,
.cart-collaterals .btn-check:active+.checkout-button:focus,
.cart-collaterals .btn-check:checked+.checkout-button:focus,
.cart-collaterals .checkout-button:active:focus,
.cart-collaterals .show>.dropdown-toggle.checkout-button:focus,
.show>.btn-primary.dropdown-toggle:focus,
form.woocommerce-checkout .place-order .active.button:focus,
form.woocommerce-checkout .place-order .btn-check:active+.button:focus,
form.woocommerce-checkout .place-order .btn-check:checked+.button:focus,
form.woocommerce-checkout .place-order .button:active:focus,
form.woocommerce-checkout .place-order .show>.dropdown-toggle.button:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(46, 44, 121, .5)
}

.btn-primary.disabled,
.btn-primary:disabled,
.cart-collaterals .checkout-button:disabled,
.cart-collaterals .disabled.checkout-button,
form.woocommerce-checkout .place-order .button:disabled,
form.woocommerce-checkout .place-order .disabled.button {
	color: #fff;
	background-color: #090761;
	border-color: #090761
}

.btn-secondary {
	color: #161c2d;
	background-color: #949da6;
	border-color: #949da6;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-secondary:hover {
	color: #161c2d;
	background-color: #a4acb3;
	border-color: #9fa7af
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
	color: #161c2d;
	background-color: #a4acb3;
	border-color: #9fa7af;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(129, 138, 148, .5)
}

.btn-check:active+.btn-secondary,
.btn-check:checked+.btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show>.btn-secondary.dropdown-toggle {
	color: #161c2d;
	background-color: #a9b1b8;
	border-color: #9fa7af
}

.btn-check:active+.btn-secondary:focus,
.btn-check:checked+.btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(129, 138, 148, .5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
	color: #161c2d;
	background-color: #949da6;
	border-color: #949da6
}

.btn-primary-desat {
	color: #161c2d;
	background-color: #6c8aec;
	border-color: #6c8aec;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-primary-desat:hover {
	color: #161c2d;
	background-color: #829cef;
	border-color: #7b96ee
}

.btn-check:focus+.btn-primary-desat,
.btn-primary-desat:focus {
	color: #161c2d;
	background-color: #829cef;
	border-color: #7b96ee;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(95, 122, 207, .5)
}

.btn-check:active+.btn-primary-desat,
.btn-check:checked+.btn-primary-desat,
.btn-primary-desat.active,
.btn-primary-desat:active,
.show>.btn-primary-desat.dropdown-toggle {
	color: #161c2d;
	background-color: #89a1f0;
	border-color: #7b96ee
}

.btn-check:active+.btn-primary-desat:focus,
.btn-check:checked+.btn-primary-desat:focus,
.btn-primary-desat.active:focus,
.btn-primary-desat:active:focus,
.show>.btn-primary-desat.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(95, 122, 207, .5)
}

.btn-primary-desat.disabled,
.btn-primary-desat:disabled {
	color: #161c2d;
	background-color: #6c8aec;
	border-color: #6c8aec
}

.btn-black {
	color: #fff;
	background-color: #161c2d;
	border-color: #161c2d;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-black:hover {
	color: #fff;
	background-color: #131826;
	border-color: #121624
}

.btn-black:focus,
.btn-check:focus+.btn-black {
	color: #fff;
	background-color: #131826;
	border-color: #121624;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(57, 62, 77, .5)
}

.btn-black.active,
.btn-black:active,
.btn-check:active+.btn-black,
.btn-check:checked+.btn-black,
.show>.btn-black.dropdown-toggle {
	color: #fff;
	background-color: #121624;
	border-color: #111522
}

.btn-black.active:focus,
.btn-black:active:focus,
.btn-check:active+.btn-black:focus,
.btn-check:checked+.btn-black:focus,
.show>.btn-black.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(57, 62, 77, .5)
}

.btn-black.disabled,
.btn-black:disabled {
	color: #fff;
	background-color: #161c2d;
	border-color: #161c2d
}

.btn-gray-200 {
	color: #161c2d;
	background-color: #e7e7ec;
	border-color: #e7e7ec;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-gray-200:hover {
	color: #161c2d;
	background-color: #ebebef;
	border-color: #e9e9ee
}

.btn-check:focus+.btn-gray-200,
.btn-gray-200:focus {
	color: #161c2d;
	background-color: #ebebef;
	border-color: #e9e9ee;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(200, 201, 207, .5)
}

.btn-check:active+.btn-gray-200,
.btn-check:checked+.btn-gray-200,
.btn-gray-200.active,
.btn-gray-200:active,
.show>.btn-gray-200.dropdown-toggle {
	color: #161c2d;
	background-color: #ececf0;
	border-color: #e9e9ee
}

.btn-check:active+.btn-gray-200:focus,
.btn-check:checked+.btn-gray-200:focus,
.btn-gray-200.active:focus,
.btn-gray-200:active:focus,
.show>.btn-gray-200.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(200, 201, 207, .5)
}

.btn-gray-200.disabled,
.btn-gray-200:disabled {
	color: #161c2d;
	background-color: #e7e7ec;
	border-color: #e7e7ec
}

.btn-gray-800 {
	color: #161c2d;
	background-color: #77838f;
	border-color: #77838f;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-gray-800:hover {
	color: #161c2d;
	background-color: #8b96a0;
	border-color: #858f9a
}

.btn-check:focus+.btn-gray-800,
.btn-gray-800:focus {
	color: #161c2d;
	background-color: #8b96a0;
	border-color: #858f9a;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(104, 116, 128, .5)
}

.btn-check:active+.btn-gray-800,
.btn-check:checked+.btn-gray-800,
.btn-gray-800.active,
.btn-gray-800:active,
.show>.btn-gray-800.dropdown-toggle {
	color: #161c2d;
	background-color: #929ca5;
	border-color: #858f9a
}

.btn-check:active+.btn-gray-800:focus,
.btn-check:checked+.btn-gray-800:focus,
.btn-gray-800.active:focus,
.btn-gray-800:active:focus,
.show>.btn-gray-800.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(104, 116, 128, .5)
}

.btn-gray-800.disabled,
.btn-gray-800:disabled {
	color: #161c2d;
	background-color: #77838f;
	border-color: #77838f
}

.btn-white {
	color: #161c2d;
	background-color: #fff;
	border-color: #fff;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-white:hover {
	color: #161c2d;
	background-color: #fff;
	border-color: #fff
}

.btn-check:focus+.btn-white,
.btn-white:focus {
	color: #161c2d;
	background-color: #fff;
	border-color: #fff;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(220, 221, 224, .5)
}

.btn-check:active+.btn-white,
.btn-check:checked+.btn-white,
.btn-white.active,
.btn-white:active,
.show>.btn-white.dropdown-toggle {
	color: #161c2d;
	background-color: #fff;
	border-color: #fff
}

.btn-check:active+.btn-white:focus,
.btn-check:checked+.btn-white:focus,
.btn-white.active:focus,
.btn-white:active:focus,
.show>.btn-white.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(220, 221, 224, .5)
}

.btn-white.disabled,
.btn-white:disabled {
	color: #161c2d;
	background-color: #fff;
	border-color: #fff
}

.btn-dark {
	color: #fff;
	background-color: #2f2d51;
	border-color: #2f2d51;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-dark:hover {
	color: #fff;
	background-color: #282645;
	border-color: #262441
}

.btn-check:focus+.btn-dark,
.btn-dark:focus {
	color: #fff;
	background-color: #282645;
	border-color: #262441;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(78, 77, 107, .5)
}

.btn-check:active+.btn-dark,
.btn-check:checked+.btn-dark,
.btn-dark.active,
.btn-dark:active,
.show>.btn-dark.dropdown-toggle {
	color: #fff;
	background-color: #262441;
	border-color: #23223d
}

.btn-check:active+.btn-dark:focus,
.btn-check:checked+.btn-dark:focus,
.btn-dark.active:focus,
.btn-dark:active:focus,
.show>.btn-dark.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(78, 77, 107, .5)
}

.btn-dark.disabled,
.btn-dark:disabled {
	color: #fff;
	background-color: #2f2d51;
	border-color: #2f2d51
}

.btn-success {
	color: #161c2d;
	background-color: #def2d7;
	border-color: #def2d7;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-success:hover {
	color: #161c2d;
	background-color: #e3f4dd;
	border-color: #e1f3db
}

.btn-check:focus+.btn-success,
.btn-success:focus {
	color: #161c2d;
	background-color: #e3f4dd;
	border-color: #e1f3db;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(192, 210, 190, .5)
}

.btn-check:active+.btn-success,
.btn-check:checked+.btn-success,
.btn-success.active,
.btn-success:active,
.show>.btn-success.dropdown-toggle {
	color: #161c2d;
	background-color: #e5f5df;
	border-color: #e1f3db
}

.btn-check:active+.btn-success:focus,
.btn-check:checked+.btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show>.btn-success.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(192, 210, 190, .5)
}

.btn-success.disabled,
.btn-success:disabled {
	color: #161c2d;
	background-color: #def2d7;
	border-color: #def2d7
}

.btn-info {
	color: #161c2d;
	background-color: #cde9f6;
	border-color: #cde9f6;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-info:hover {
	color: #161c2d;
	background-color: #d5ecf7;
	border-color: #d2ebf7
}

.btn-check:focus+.btn-info,
.btn-info:focus {
	color: #161c2d;
	background-color: #d5ecf7;
	border-color: #d2ebf7;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(178, 202, 216, .5)
}

.btn-check:active+.btn-info,
.btn-check:checked+.btn-info,
.btn-info.active,
.btn-info:active,
.show>.btn-info.dropdown-toggle {
	color: #161c2d;
	background-color: #d7edf8;
	border-color: #d2ebf7
}

.btn-check:active+.btn-info:focus,
.btn-check:checked+.btn-info:focus,
.btn-info.active:focus,
.btn-info:active:focus,
.show>.btn-info.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(178, 202, 216, .5)
}

.btn-info.disabled,
.btn-info:disabled {
	color: #161c2d;
	background-color: #cde9f6;
	border-color: #cde9f6
}

.btn-warning {
	color: #161c2d;
	background-color: #f7f3d7;
	border-color: #f7f3d7;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-warning:hover {
	color: #161c2d;
	background-color: #f8f5dd;
	border-color: #f8f4db
}

.btn-check:focus+.btn-warning,
.btn-warning:focus {
	color: #161c2d;
	background-color: #f8f5dd;
	border-color: #f8f4db;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(213, 211, 190, .5)
}

.btn-check:active+.btn-warning,
.btn-check:checked+.btn-warning,
.btn-warning.active,
.btn-warning:active,
.show>.btn-warning.dropdown-toggle {
	color: #161c2d;
	background-color: #f9f5df;
	border-color: #f8f4db
}

.btn-check:active+.btn-warning:focus,
.btn-check:checked+.btn-warning:focus,
.btn-warning.active:focus,
.btn-warning:active:focus,
.show>.btn-warning.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(213, 211, 190, .5)
}

.btn-warning.disabled,
.btn-warning:disabled {
	color: #161c2d;
	background-color: #f7f3d7;
	border-color: #f7f3d7
}

.btn-danger {
	color: #161c2d;
	background-color: #ecc8c5;
	border-color: #ecc8c5;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-danger:hover {
	color: #161c2d;
	background-color: #efd0ce;
	border-color: #eececb
}

.btn-check:focus+.btn-danger,
.btn-danger:focus {
	color: #161c2d;
	background-color: #efd0ce;
	border-color: #eececb;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(204, 174, 174, .5)
}

.btn-check:active+.btn-danger,
.btn-check:checked+.btn-danger,
.btn-danger.active,
.btn-danger:active,
.show>.btn-danger.dropdown-toggle {
	color: #161c2d;
	background-color: #f0d3d1;
	border-color: #eececb
}

.btn-check:active+.btn-danger:focus,
.btn-check:checked+.btn-danger:focus,
.btn-danger.active:focus,
.btn-danger:active:focus,
.show>.btn-danger.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(204, 174, 174, .5)
}

.btn-danger.disabled,
.btn-danger:disabled {
	color: #161c2d;
	background-color: #ecc8c5;
	border-color: #ecc8c5
}

.btn-light {
	color: #161c2d;
	background-color: #f7f9fb;
	border-color: #f7f9fb;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-light:hover {
	color: #161c2d;
	background-color: #f8fafc;
	border-color: #f8fafb
}

.btn-check:focus+.btn-light,
.btn-light:focus {
	color: #161c2d;
	background-color: #f8fafc;
	border-color: #f8fafb;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(213, 216, 220, .5)
}

.btn-check:active+.btn-light,
.btn-check:checked+.btn-light,
.btn-light.active,
.btn-light:active,
.show>.btn-light.dropdown-toggle {
	color: #161c2d;
	background-color: #f9fafc;
	border-color: #f8fafb
}

.btn-check:active+.btn-light:focus,
.btn-check:checked+.btn-light:focus,
.btn-light.active:focus,
.btn-light:active:focus,
.show>.btn-light.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(213, 216, 220, .5)
}

.btn-light.disabled,
.btn-light:disabled {
	color: #161c2d;
	background-color: #f7f9fb;
	border-color: #f7f9fb
}

.btn-orange {
	color: #161c2d;
	background-color: #ffc78b;
	border-color: #ffc78b;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-orange:hover {
	color: #161c2d;
	background-color: #ffcf9c;
	border-color: #ffcd97
}

.btn-check:focus+.btn-orange,
.btn-orange:focus {
	color: #161c2d;
	background-color: #ffcf9c;
	border-color: #ffcd97;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(220, 173, 125, .5)
}

.btn-check:active+.btn-orange,
.btn-check:checked+.btn-orange,
.btn-orange.active,
.btn-orange:active,
.show>.btn-orange.dropdown-toggle {
	color: #161c2d;
	background-color: #ffd2a2;
	border-color: #ffcd97
}

.btn-check:active+.btn-orange:focus,
.btn-check:checked+.btn-orange:focus,
.btn-orange.active:focus,
.btn-orange:active:focus,
.show>.btn-orange.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(220, 173, 125, .5)
}

.btn-orange.disabled,
.btn-orange:disabled {
	color: #161c2d;
	background-color: #ffc78b;
	border-color: #ffc78b
}

.btn-blue,
.shop_table.cart .coupon input.button {
	color: #fff;
	background-color: #196ecd;
	border-color: #196ecd;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-blue:hover,
.shop_table.cart .coupon input.button:hover {
	color: #fff;
	background-color: #155eae;
	border-color: #1458a4
}

.btn-blue:focus,
.btn-check:focus+.btn-blue,
.shop_table.cart .coupon .btn-check:focus+input.button,
.shop_table.cart .coupon input.button:focus {
	color: #fff;
	background-color: #155eae;
	border-color: #1458a4;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(60, 132, 213, .5)
}

.btn-blue.active,
.btn-blue:active,
.btn-check:active+.btn-blue,
.btn-check:checked+.btn-blue,
.shop_table.cart .coupon .btn-check:active+input.button,
.shop_table.cart .coupon .btn-check:checked+input.button,
.shop_table.cart .coupon .show>input.dropdown-toggle.button,
.shop_table.cart .coupon input.active.button,
.shop_table.cart .coupon input.button:active,
.show>.btn-blue.dropdown-toggle {
	color: #fff;
	background-color: #1458a4;
	border-color: #13539a
}

.btn-blue.active:focus,
.btn-blue:active:focus,
.btn-check:active+.btn-blue:focus,
.btn-check:checked+.btn-blue:focus,
.shop_table.cart .coupon .btn-check:active+input.button:focus,
.shop_table.cart .coupon .btn-check:checked+input.button:focus,
.shop_table.cart .coupon .show>input.dropdown-toggle.button:focus,
.shop_table.cart .coupon input.active.button:focus,
.shop_table.cart .coupon input.button:active:focus,
.show>.btn-blue.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(60, 132, 213, .5)
}

.btn-blue.disabled,
.btn-blue:disabled,
.shop_table.cart .coupon input.button:disabled,
.shop_table.cart .coupon input.disabled.button {
	color: #fff;
	background-color: #196ecd;
	border-color: #196ecd
}

.btn-purple {
	color: #161c2d;
	background-color: #b8b2fd;
	border-color: #b8b2fd;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-purple:hover {
	color: #161c2d;
	background-color: #c3befd;
	border-color: #bfbafd
}

.btn-check:focus+.btn-purple,
.btn-purple:focus {
	color: #161c2d;
	background-color: #c3befd;
	border-color: #bfbafd;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(160, 156, 222, .5)
}

.btn-check:active+.btn-purple,
.btn-check:checked+.btn-purple,
.btn-purple.active,
.btn-purple:active,
.show>.btn-purple.dropdown-toggle {
	color: #161c2d;
	background-color: #c6c1fd;
	border-color: #bfbafd
}

.btn-check:active+.btn-purple:focus,
.btn-check:checked+.btn-purple:focus,
.btn-purple.active:focus,
.btn-purple:active:focus,
.show>.btn-purple.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(160, 156, 222, .5)
}

.btn-purple.disabled,
.btn-purple:disabled {
	color: #161c2d;
	background-color: #b8b2fd;
	border-color: #b8b2fd
}

.btn-teal {
	color: #161c2d;
	background-color: #4a8f9f;
	border-color: #4a8f9f;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-teal:hover {
	color: #161c2d;
	background-color: #65a0ad;
	border-color: #5c9aa9
}

.btn-check:focus+.btn-teal,
.btn-teal:focus {
	color: #161c2d;
	background-color: #65a0ad;
	border-color: #5c9aa9;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(66, 126, 142, .5)
}

.btn-check:active+.btn-teal,
.btn-check:checked+.btn-teal,
.btn-teal.active,
.btn-teal:active,
.show>.btn-teal.dropdown-toggle {
	color: #161c2d;
	background-color: #6ea5b2;
	border-color: #5c9aa9
}

.btn-check:active+.btn-teal:focus,
.btn-check:checked+.btn-teal:focus,
.btn-teal.active:focus,
.btn-teal:active:focus,
.show>.btn-teal.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(66, 126, 142, .5)
}

.btn-teal.disabled,
.btn-teal:disabled {
	color: #161c2d;
	background-color: #4a8f9f;
	border-color: #4a8f9f
}

.btn-ice {
	color: #161c2d;
	background-color: #9cf6db;
	border-color: #9cf6db;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-ice:hover {
	color: #161c2d;
	background-color: #abf7e0;
	border-color: #a6f7df
}

.btn-check:focus+.btn-ice,
.btn-ice:focus {
	color: #161c2d;
	background-color: #abf7e0;
	border-color: #a6f7df;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(136, 213, 193, .5)
}

.btn-check:active+.btn-ice,
.btn-check:checked+.btn-ice,
.btn-ice.active,
.btn-ice:active,
.show>.btn-ice.dropdown-toggle {
	color: #161c2d;
	background-color: #b0f8e2;
	border-color: #a6f7df
}

.btn-check:active+.btn-ice:focus,
.btn-check:checked+.btn-ice:focus,
.btn-ice.active:focus,
.btn-ice:active:focus,
.show>.btn-ice.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(136, 213, 193, .5)
}

.btn-ice.disabled,
.btn-ice:disabled {
	color: #161c2d;
	background-color: #9cf6db;
	border-color: #9cf6db
}

.btn-cyan {
	color: #161c2d;
	background-color: #9ef3f6;
	border-color: #9ef3f6;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-cyan:hover {
	color: #161c2d;
	background-color: #adf5f7;
	border-color: #a8f4f7
}

.btn-check:focus+.btn-cyan,
.btn-cyan:focus {
	color: #161c2d;
	background-color: #adf5f7;
	border-color: #a8f4f7;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(138, 211, 216, .5)
}

.btn-check:active+.btn-cyan,
.btn-check:checked+.btn-cyan,
.btn-cyan.active,
.btn-cyan:active,
.show>.btn-cyan.dropdown-toggle {
	color: #161c2d;
	background-color: #b1f5f8;
	border-color: #a8f4f7
}

.btn-check:active+.btn-cyan:focus,
.btn-check:checked+.btn-cyan:focus,
.btn-cyan.active:focus,
.btn-cyan:active:focus,
.show>.btn-cyan.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(138, 211, 216, .5)
}

.btn-cyan.disabled,
.btn-cyan:disabled {
	color: #161c2d;
	background-color: #9ef3f6;
	border-color: #9ef3f6
}

.btn-helio {
	color: #161c2d;
	background-color: #b253f6;
	border-color: #b253f6;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-helio:hover {
	color: #161c2d;
	background-color: #be6df7;
	border-color: #ba64f7
}

.btn-check:focus+.btn-helio,
.btn-helio:focus {
	color: #161c2d;
	background-color: #be6df7;
	border-color: #ba64f7;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(155, 75, 216, .5)
}

.btn-check:active+.btn-helio,
.btn-check:checked+.btn-helio,
.btn-helio.active,
.btn-helio:active,
.show>.btn-helio.dropdown-toggle {
	color: #161c2d;
	background-color: #c175f8;
	border-color: #ba64f7
}

.btn-check:active+.btn-helio:focus,
.btn-check:checked+.btn-helio:focus,
.btn-helio.active:focus,
.btn-helio:active:focus,
.show>.btn-helio.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(155, 75, 216, .5)
}

.btn-helio.disabled,
.btn-helio:disabled {
	color: #161c2d;
	background-color: #b253f6;
	border-color: #b253f6
}

.btn-white-ice {
	color: #161c2d;
	background-color: #f0fdf8;
	border-color: #f0fdf8;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-white-ice:hover {
	color: #161c2d;
	background-color: #f2fdf9;
	border-color: #f2fdf9
}

.btn-check:focus+.btn-white-ice,
.btn-white-ice:focus {
	color: #161c2d;
	background-color: #f2fdf9;
	border-color: #f2fdf9;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(207, 219, 218, .5)
}

.btn-check:active+.btn-white-ice,
.btn-check:checked+.btn-white-ice,
.btn-white-ice.active,
.btn-white-ice:active,
.show>.btn-white-ice.dropdown-toggle {
	color: #161c2d;
	background-color: #f3fdf9;
	border-color: #f2fdf9
}

.btn-check:active+.btn-white-ice:focus,
.btn-check:checked+.btn-white-ice:focus,
.btn-white-ice.active:focus,
.btn-white-ice:active:focus,
.show>.btn-white-ice.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(207, 219, 218, .5)
}

.btn-white-ice.disabled,
.btn-white-ice:disabled {
	color: #161c2d;
	background-color: #f0fdf8;
	border-color: #f0fdf8
}

.btn-tropaz {
	color: #fff;
	background-color: #2d4f93;
	border-color: #2d4f93;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-tropaz:hover {
	color: #fff;
	background-color: #26437d;
	border-color: #243f76
}

.btn-check:focus+.btn-tropaz,
.btn-tropaz:focus {
	color: #fff;
	background-color: #26437d;
	border-color: #243f76;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(77, 105, 163, .5)
}

.btn-check:active+.btn-tropaz,
.btn-check:checked+.btn-tropaz,
.btn-tropaz.active,
.btn-tropaz:active,
.show>.btn-tropaz.dropdown-toggle {
	color: #fff;
	background-color: #243f76;
	border-color: #223b6e
}

.btn-check:active+.btn-tropaz:focus,
.btn-check:checked+.btn-tropaz:focus,
.btn-tropaz.active:focus,
.btn-tropaz:active:focus,
.show>.btn-tropaz.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(77, 105, 163, .5)
}

.btn-tropaz.disabled,
.btn-tropaz:disabled {
	color: #fff;
	background-color: #2d4f93;
	border-color: #2d4f93
}

.btn-roman {
	color: #161c2d;
	background-color: #e0685c;
	border-color: #e0685c;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-roman:hover {
	color: #161c2d;
	background-color: #e57f74;
	border-color: #e3776c
}

.btn-check:focus+.btn-roman,
.btn-roman:focus {
	color: #161c2d;
	background-color: #e57f74;
	border-color: #e3776c;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(194, 93, 85, .5)
}

.btn-check:active+.btn-roman,
.btn-check:checked+.btn-roman,
.btn-roman.active,
.btn-roman:active,
.show>.btn-roman.dropdown-toggle {
	color: #161c2d;
	background-color: #e6867d;
	border-color: #e3776c
}

.btn-check:active+.btn-roman:focus,
.btn-check:checked+.btn-roman:focus,
.btn-roman.active:focus,
.btn-roman:active:focus,
.show>.btn-roman.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(194, 93, 85, .5)
}

.btn-roman.disabled,
.btn-roman:disabled {
	color: #161c2d;
	background-color: #e0685c;
	border-color: #e0685c
}

.btn-tangerine {
	color: #161c2d;
	background-color: #ff9574;
	border-color: #ff9574;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-tangerine:hover {
	color: #161c2d;
	background-color: #ffa589;
	border-color: #ffa082
}

.btn-check:focus+.btn-tangerine,
.btn-tangerine:focus {
	color: #161c2d;
	background-color: #ffa589;
	border-color: #ffa082;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(220, 131, 105, .5)
}

.btn-check:active+.btn-tangerine,
.btn-check:checked+.btn-tangerine,
.btn-tangerine.active,
.btn-tangerine:active,
.show>.btn-tangerine.dropdown-toggle {
	color: #161c2d;
	background-color: #ffaa90;
	border-color: #ffa082
}

.btn-check:active+.btn-tangerine:focus,
.btn-check:checked+.btn-tangerine:focus,
.btn-tangerine.active:focus,
.btn-tangerine:active:focus,
.show>.btn-tangerine.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(220, 131, 105, .5)
}

.btn-tangerine.disabled,
.btn-tangerine:disabled {
	color: #161c2d;
	background-color: #ff9574;
	border-color: #ff9574
}

.btn-gigas {
	color: #fff;
	background-color: #4e35a3;
	border-color: #4e35a3;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-gigas:hover {
	color: #fff;
	background-color: #422d8b;
	border-color: #3e2a82
}

.btn-check:focus+.btn-gigas,
.btn-gigas:focus {
	color: #fff;
	background-color: #422d8b;
	border-color: #3e2a82;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(105, 83, 177, .5)
}

.btn-check:active+.btn-gigas,
.btn-check:checked+.btn-gigas,
.btn-gigas.active,
.btn-gigas:active,
.show>.btn-gigas.dropdown-toggle {
	color: #fff;
	background-color: #3e2a82;
	border-color: #3b287a
}

.btn-check:active+.btn-gigas:focus,
.btn-check:checked+.btn-gigas:focus,
.btn-gigas.active:focus,
.btn-gigas:active:focus,
.show>.btn-gigas.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(105, 83, 177, .5)
}

.btn-gigas.disabled,
.btn-gigas:disabled {
	color: #fff;
	background-color: #4e35a3;
	border-color: #4e35a3
}

.btn-lavender {
	color: #161c2d;
	background-color: #ffebf1;
	border-color: #ffebf1;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-lavender:hover {
	color: #161c2d;
	background-color: #ffeef3;
	border-color: #ffedf2
}

.btn-check:focus+.btn-lavender,
.btn-lavender:focus {
	color: #161c2d;
	background-color: #ffeef3;
	border-color: #ffedf2;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(220, 204, 212, .5)
}

.btn-check:active+.btn-lavender,
.btn-check:checked+.btn-lavender,
.btn-lavender.active,
.btn-lavender:active,
.show>.btn-lavender.dropdown-toggle {
	color: #161c2d;
	background-color: #ffeff4;
	border-color: #ffedf2
}

.btn-check:active+.btn-lavender:focus,
.btn-check:checked+.btn-lavender:focus,
.btn-lavender.active:focus,
.btn-lavender:active:focus,
.show>.btn-lavender.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(220, 204, 212, .5)
}

.btn-lavender.disabled,
.btn-lavender:disabled {
	color: #161c2d;
	background-color: #ffebf1;
	border-color: #ffebf1
}

.btn-sienna {
	color: #161c2d;
	background-color: #e7643e;
	border-color: #e7643e;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-sienna:hover {
	color: #161c2d;
	background-color: #eb7b5b;
	border-color: #e97451
}

.btn-check:focus+.btn-sienna,
.btn-sienna:focus {
	color: #161c2d;
	background-color: #eb7b5b;
	border-color: #e97451;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(200, 89, 59, .5)
}

.btn-check:active+.btn-sienna,
.btn-check:checked+.btn-sienna,
.btn-sienna.active,
.btn-sienna:active,
.show>.btn-sienna.dropdown-toggle {
	color: #161c2d;
	background-color: #ec8365;
	border-color: #e97451
}

.btn-check:active+.btn-sienna:focus,
.btn-check:checked+.btn-sienna:focus,
.btn-sienna.active:focus,
.btn-sienna:active:focus,
.show>.btn-sienna.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(200, 89, 59, .5)
}

.btn-sienna.disabled,
.btn-sienna:disabled {
	color: #161c2d;
	background-color: #e7643e;
	border-color: #e7643e
}

.btn-coral {
	color: #161c2d;
	background-color: #fe805d;
	border-color: #fe805d;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-coral:hover {
	color: #161c2d;
	background-color: #fe9375;
	border-color: #fe8d6d
}

.btn-check:focus+.btn-coral,
.btn-coral:focus {
	color: #161c2d;
	background-color: #fe9375;
	border-color: #fe8d6d;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(219, 113, 86, .5)
}

.btn-check:active+.btn-coral,
.btn-check:checked+.btn-coral,
.btn-coral.active,
.btn-coral:active,
.show>.btn-coral.dropdown-toggle {
	color: #161c2d;
	background-color: #fe997d;
	border-color: #fe8d6d
}

.btn-check:active+.btn-coral:focus,
.btn-check:checked+.btn-coral:focus,
.btn-coral.active:focus,
.btn-coral:active:focus,
.show>.btn-coral.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(219, 113, 86, .5)
}

.btn-coral.disabled,
.btn-coral:disabled {
	color: #161c2d;
	background-color: #fe805d;
	border-color: #fe805d
}

.btn-catskill {
	color: #161c2d;
	background-color: #f9fbfc;
	border-color: #f9fbfc;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-catskill:hover {
	color: #161c2d;
	background-color: #fafcfc;
	border-color: #fafbfc
}

.btn-catskill:focus,
.btn-check:focus+.btn-catskill {
	color: #161c2d;
	background-color: #fafcfc;
	border-color: #fafbfc;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(215, 218, 221, .5)
}

.btn-catskill.active,
.btn-catskill:active,
.btn-check:active+.btn-catskill,
.btn-check:checked+.btn-catskill,
.show>.btn-catskill.dropdown-toggle {
	color: #161c2d;
	background-color: #fafcfd;
	border-color: #fafbfc
}

.btn-catskill.active:focus,
.btn-catskill:active:focus,
.btn-check:active+.btn-catskill:focus,
.btn-check:checked+.btn-catskill:focus,
.show>.btn-catskill.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(215, 218, 221, .5)
}

.btn-catskill.disabled,
.btn-catskill:disabled {
	color: #161c2d;
	background-color: #f9fbfc;
	border-color: #f9fbfc
}

.btn-bossanova {
	color: #fff;
	background-color: #452b4f;
	border-color: #452b4f;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-bossanova:hover {
	color: #fff;
	background-color: #3b2543;
	border-color: #37223f
}

.btn-bossanova:focus,
.btn-check:focus+.btn-bossanova {
	color: #fff;
	background-color: #3b2543;
	border-color: #37223f;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(97, 75, 105, .5)
}

.btn-bossanova.active,
.btn-bossanova:active,
.btn-check:active+.btn-bossanova,
.btn-check:checked+.btn-bossanova,
.show>.btn-bossanova.dropdown-toggle {
	color: #fff;
	background-color: #37223f;
	border-color: #34203b
}

.btn-bossanova.active:focus,
.btn-bossanova:active:focus,
.btn-check:active+.btn-bossanova:focus,
.btn-check:checked+.btn-bossanova:focus,
.show>.btn-bossanova.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(97, 75, 105, .5)
}

.btn-bossanova.disabled,
.btn-bossanova:disabled {
	color: #fff;
	background-color: #452b4f;
	border-color: #452b4f
}

.btn-denim {
	color: #fff;
	background-color: #146ecf;
	border-color: #146ecf;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-denim:hover {
	color: #fff;
	background-color: #115eb0;
	border-color: #1058a6
}

.btn-check:focus+.btn-denim,
.btn-denim:focus {
	color: #fff;
	background-color: #115eb0;
	border-color: #1058a6;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(55, 132, 214, .5)
}

.btn-check:active+.btn-denim,
.btn-check:checked+.btn-denim,
.btn-denim.active,
.btn-denim:active,
.show>.btn-denim.dropdown-toggle {
	color: #fff;
	background-color: #1058a6;
	border-color: #0f539b
}

.btn-check:active+.btn-denim:focus,
.btn-check:checked+.btn-denim:focus,
.btn-denim.active:focus,
.btn-denim:active:focus,
.show>.btn-denim.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(55, 132, 214, .5)
}

.btn-denim.disabled,
.btn-denim:disabled {
	color: #fff;
	background-color: #146ecf;
	border-color: #146ecf
}

.btn-turbo {
	color: #161c2d;
	background-color: #feb800;
	border-color: #feb800;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-turbo:hover {
	color: #161c2d;
	background-color: #fec326;
	border-color: #febf1a
}

.btn-check:focus+.btn-turbo,
.btn-turbo:focus {
	color: #161c2d;
	background-color: #fec326;
	border-color: #febf1a;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(219, 161, 7, .5)
}

.btn-check:active+.btn-turbo,
.btn-check:checked+.btn-turbo,
.btn-turbo.active,
.btn-turbo:active,
.show>.btn-turbo.dropdown-toggle {
	color: #161c2d;
	background-color: #fec633;
	border-color: #febf1a
}

.btn-check:active+.btn-turbo:focus,
.btn-check:checked+.btn-turbo:focus,
.btn-turbo.active:focus,
.btn-turbo:active:focus,
.show>.btn-turbo.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(219, 161, 7, .5)
}

.btn-turbo.disabled,
.btn-turbo:disabled {
	color: #161c2d;
	background-color: #feb800;
	border-color: #feb800
}

.btn-bright-sun {
	color: #161c2d;
	background-color: #ffd438;
	border-color: #ffd438;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-bright-sun:hover {
	color: #161c2d;
	background-color: #ffda56;
	border-color: #ffd84c
}

.btn-bright-sun:focus,
.btn-check:focus+.btn-bright-sun {
	color: #161c2d;
	background-color: #ffda56;
	border-color: #ffd84c;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(220, 184, 54, .5)
}

.btn-bright-sun.active,
.btn-bright-sun:active,
.btn-check:active+.btn-bright-sun,
.btn-check:checked+.btn-bright-sun,
.show>.btn-bright-sun.dropdown-toggle {
	color: #161c2d;
	background-color: #ffdd60;
	border-color: #ffd84c
}

.btn-bright-sun.active:focus,
.btn-bright-sun:active:focus,
.btn-check:active+.btn-bright-sun:focus,
.btn-check:checked+.btn-bright-sun:focus,
.show>.btn-bright-sun.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(220, 184, 54, .5)
}

.btn-bright-sun.disabled,
.btn-bright-sun:disabled {
	color: #161c2d;
	background-color: #ffd438;
	border-color: #ffd438
}

.btn-shark {
	color: #fff;
	background-color: #282a2e;
	border-color: #282a2e;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-shark:hover {
	color: #fff;
	background-color: #222427;
	border-color: #202225
}

.btn-check:focus+.btn-shark,
.btn-shark:focus {
	color: #fff;
	background-color: #222427;
	border-color: #202225;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(72, 74, 77, .5)
}

.btn-check:active+.btn-shark,
.btn-check:checked+.btn-shark,
.btn-shark.active,
.btn-shark:active,
.show>.btn-shark.dropdown-toggle {
	color: #fff;
	background-color: #202225;
	border-color: #1e2023
}

.btn-check:active+.btn-shark:focus,
.btn-check:checked+.btn-shark:focus,
.btn-shark.active:focus,
.btn-shark:active:focus,
.show>.btn-shark.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(72, 74, 77, .5)
}

.btn-shark.disabled,
.btn-shark:disabled {
	color: #fff;
	background-color: #282a2e;
	border-color: #282a2e
}

.btn-dodger {
	color: #161c2d;
	background-color: #377dff;
	border-color: #377dff;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-dodger:hover {
	color: #161c2d;
	background-color: #5591ff;
	border-color: #4b8aff
}

.btn-check:focus+.btn-dodger,
.btn-dodger:focus {
	color: #161c2d;
	background-color: #5591ff;
	border-color: #4b8aff;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(50, 110, 224, .5)
}

.btn-check:active+.btn-dodger,
.btn-check:checked+.btn-dodger,
.btn-dodger.active,
.btn-dodger:active,
.show>.btn-dodger.dropdown-toggle {
	color: #161c2d;
	background-color: #5f97ff;
	border-color: #4b8aff
}

.btn-check:active+.btn-dodger:focus,
.btn-check:checked+.btn-dodger:focus,
.btn-dodger.active:focus,
.btn-dodger:active:focus,
.show>.btn-dodger.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(50, 110, 224, .5)
}

.btn-dodger.disabled,
.btn-dodger:disabled {
	color: #161c2d;
	background-color: #377dff;
	border-color: #377dff
}

.btn-cloud-burst {
	color: #fff;
	background-color: #21325b;
	border-color: #21325b;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-cloud-burst:hover {
	color: #fff;
	background-color: #1c2b4d;
	border-color: #1a2849
}

.btn-check:focus+.btn-cloud-burst,
.btn-cloud-burst:focus {
	color: #fff;
	background-color: #1c2b4d;
	border-color: #1a2849;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(66, 81, 116, .5)
}

.btn-check:active+.btn-cloud-burst,
.btn-check:checked+.btn-cloud-burst,
.btn-cloud-burst.active,
.btn-cloud-burst:active,
.show>.btn-cloud-burst.dropdown-toggle {
	color: #fff;
	background-color: #1a2849;
	border-color: #192644
}

.btn-check:active+.btn-cloud-burst:focus,
.btn-check:checked+.btn-cloud-burst:focus,
.btn-cloud-burst.active:focus,
.btn-cloud-burst:active:focus,
.show>.btn-cloud-burst.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(66, 81, 116, .5)
}

.btn-cloud-burst.disabled,
.btn-cloud-burst:disabled {
	color: #fff;
	background-color: #21325b;
	border-color: #21325b
}

.btn-porsche {
	color: #161c2d;
	background-color: #f0b26b;
	border-color: #f0b26b;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-porsche:hover {
	color: #161c2d;
	background-color: #f2be81;
	border-color: #f2ba7a
}

.btn-check:focus+.btn-porsche,
.btn-porsche:focus {
	color: #161c2d;
	background-color: #f2be81;
	border-color: #f2ba7a;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(207, 156, 98, .5)
}

.btn-check:active+.btn-porsche,
.btn-check:checked+.btn-porsche,
.btn-porsche.active,
.btn-porsche:active,
.show>.btn-porsche.dropdown-toggle {
	color: #161c2d;
	background-color: #f3c189;
	border-color: #f2ba7a
}

.btn-check:active+.btn-porsche:focus,
.btn-check:checked+.btn-porsche:focus,
.btn-porsche.active:focus,
.btn-porsche:active:focus,
.show>.btn-porsche.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(207, 156, 98, .5)
}

.btn-porsche.disabled,
.btn-porsche:disabled {
	color: #161c2d;
	background-color: #f0b26b;
	border-color: #f0b26b
}

.btn-biscay {
	color: #fff;
	background-color: #183f63;
	border-color: #183f63;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-biscay:hover {
	color: #fff;
	background-color: #143654;
	border-color: #13324f
}

.btn-biscay:focus,
.btn-check:focus+.btn-biscay {
	color: #fff;
	background-color: #143654;
	border-color: #13324f;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(59, 92, 122, .5)
}

.btn-biscay.active,
.btn-biscay:active,
.btn-check:active+.btn-biscay,
.btn-check:checked+.btn-biscay,
.show>.btn-biscay.dropdown-toggle {
	color: #fff;
	background-color: #13324f;
	border-color: #122f4a
}

.btn-biscay.active:focus,
.btn-biscay:active:focus,
.btn-check:active+.btn-biscay:focus,
.btn-check:checked+.btn-biscay:focus,
.show>.btn-biscay.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(59, 92, 122, .5)
}

.btn-biscay.disabled,
.btn-biscay:disabled {
	color: #fff;
	background-color: #183f63;
	border-color: #183f63
}

.btn-mongoose {
	color: #161c2d;
	background-color: #bda588;
	border-color: #bda588;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-mongoose:hover {
	color: #161c2d;
	background-color: #c7b39a;
	border-color: #c4ae94
}

.btn-check:focus+.btn-mongoose,
.btn-mongoose:focus {
	color: #161c2d;
	background-color: #c7b39a;
	border-color: #c4ae94;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(164, 144, 122, .5)
}

.btn-check:active+.btn-mongoose,
.btn-check:checked+.btn-mongoose,
.btn-mongoose.active,
.btn-mongoose:active,
.show>.btn-mongoose.dropdown-toggle {
	color: #161c2d;
	background-color: #cab7a0;
	border-color: #c4ae94
}

.btn-check:active+.btn-mongoose:focus,
.btn-check:checked+.btn-mongoose:focus,
.btn-mongoose.active:focus,
.btn-mongoose:active:focus,
.show>.btn-mongoose.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(164, 144, 122, .5)
}

.btn-mongoose.disabled,
.btn-mongoose:disabled {
	color: #161c2d;
	background-color: #bda588;
	border-color: #bda588
}

.btn-ebony {
	color: #fff;
	background-color: #252837;
	border-color: #252837;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-ebony:hover {
	color: #fff;
	background-color: #1f222f;
	border-color: #1e202c
}

.btn-check:focus+.btn-ebony,
.btn-ebony:focus {
	color: #fff;
	background-color: #1f222f;
	border-color: #1e202c;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(70, 72, 85, .5)
}

.btn-check:active+.btn-ebony,
.btn-check:checked+.btn-ebony,
.btn-ebony.active,
.btn-ebony:active,
.show>.btn-ebony.dropdown-toggle {
	color: #fff;
	background-color: #1e202c;
	border-color: #1c1e29
}

.btn-check:active+.btn-ebony:focus,
.btn-check:checked+.btn-ebony:focus,
.btn-ebony.active:focus,
.btn-ebony:active:focus,
.show>.btn-ebony.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(70, 72, 85, .5)
}

.btn-ebony.disabled,
.btn-ebony:disabled {
	color: #fff;
	background-color: #252837;
	border-color: #252837
}

.btn-alizarin {
	color: #fff;
	background-color: #eb1c24;
	border-color: #eb1c24;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-alizarin:hover {
	color: #fff;
	background-color: #c8181f;
	border-color: #bc161d
}

.btn-alizarin:focus,
.btn-check:focus+.btn-alizarin {
	color: #fff;
	background-color: #c8181f;
	border-color: #bc161d;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(238, 62, 69, .5)
}

.btn-alizarin.active,
.btn-alizarin:active,
.btn-check:active+.btn-alizarin,
.btn-check:checked+.btn-alizarin,
.show>.btn-alizarin.dropdown-toggle {
	color: #fff;
	background-color: #bc161d;
	border-color: #b0151b
}

.btn-alizarin.active:focus,
.btn-alizarin:active:focus,
.btn-check:active+.btn-alizarin:focus,
.btn-check:checked+.btn-alizarin:focus,
.show>.btn-alizarin.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(238, 62, 69, .5)
}

.btn-alizarin.disabled,
.btn-alizarin:disabled {
	color: #fff;
	background-color: #eb1c24;
	border-color: #eb1c24
}

.btn-chestnut {
	color: #fff;
	background-color: #cf574b;
	border-color: #cf574b;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-chestnut:hover {
	color: #fff;
	background-color: #b04a40;
	border-color: #a6463c
}

.btn-check:focus+.btn-chestnut,
.btn-chestnut:focus {
	color: #fff;
	background-color: #b04a40;
	border-color: #a6463c;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(214, 112, 102, .5)
}

.btn-check:active+.btn-chestnut,
.btn-check:checked+.btn-chestnut,
.btn-chestnut.active,
.btn-chestnut:active,
.show>.btn-chestnut.dropdown-toggle {
	color: #fff;
	background-color: #a6463c;
	border-color: #9b4138
}

.btn-check:active+.btn-chestnut:focus,
.btn-check:checked+.btn-chestnut:focus,
.btn-chestnut.active:focus,
.btn-chestnut:active:focus,
.show>.btn-chestnut.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(214, 112, 102, .5)
}

.btn-chestnut.disabled,
.btn-chestnut:disabled {
	color: #fff;
	background-color: #cf574b;
	border-color: #cf574b
}

.btn-portgore {
	color: #fff;
	background-color: #212041;
	border-color: #212041;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.btn-portgore:hover {
	color: #fff;
	background-color: #1c1b37;
	border-color: #1a1a34
}

.btn-check:focus+.btn-portgore,
.btn-portgore:focus {
	color: #fff;
	background-color: #1c1b37;
	border-color: #1a1a34;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(66, 65, 94, .5)
}

.btn-check:active+.btn-portgore,
.btn-check:checked+.btn-portgore,
.btn-portgore.active,
.btn-portgore:active,
.show>.btn-portgore.dropdown-toggle {
	color: #fff;
	background-color: #1a1a34;
	border-color: #191831
}

.btn-check:active+.btn-portgore:focus,
.btn-check:checked+.btn-portgore:focus,
.btn-portgore.active:focus,
.btn-portgore:active:focus,
.show>.btn-portgore.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(66, 65, 94, .5)
}

.btn-portgore.disabled,
.btn-portgore:disabled {
	color: #fff;
	background-color: #212041;
	border-color: #212041
}

.btn-outline-primary {
	color: #090761;
	border-color: #090761
}

.btn-outline-primary:hover {
	color: #fff;
	background-color: #090761;
	border-color: #090761
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
	box-shadow: 0 0 0 0 rgba(9, 7, 97, .5)
}

.btn-check:active+.btn-outline-primary,
.btn-check:checked+.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
	color: #fff;
	background-color: #090761;
	border-color: #090761
}

.btn-check:active+.btn-outline-primary:focus,
.btn-check:checked+.btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(9, 7, 97, .5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
	color: #090761;
	background-color: transparent
}

.btn-outline-secondary {
	color: #949da6;
	border-color: #949da6
}

.btn-outline-secondary:hover {
	color: #161c2d;
	background-color: #949da6;
	border-color: #949da6
}

.btn-check:focus+.btn-outline-secondary,
.btn-outline-secondary:focus {
	box-shadow: 0 0 0 0 rgba(148, 157, 166, .5)
}

.btn-check:active+.btn-outline-secondary,
.btn-check:checked+.btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
	color: #161c2d;
	background-color: #949da6;
	border-color: #949da6
}

.btn-check:active+.btn-outline-secondary:focus,
.btn-check:checked+.btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(148, 157, 166, .5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
	color: #949da6;
	background-color: transparent
}

.btn-outline-primary-desat {
	color: #6c8aec;
	border-color: #6c8aec
}

.btn-outline-primary-desat:hover {
	color: #161c2d;
	background-color: #6c8aec;
	border-color: #6c8aec
}

.btn-check:focus+.btn-outline-primary-desat,
.btn-outline-primary-desat:focus {
	box-shadow: 0 0 0 0 rgba(108, 138, 236, .5)
}

.btn-check:active+.btn-outline-primary-desat,
.btn-check:checked+.btn-outline-primary-desat,
.btn-outline-primary-desat.active,
.btn-outline-primary-desat.dropdown-toggle.show,
.btn-outline-primary-desat:active {
	color: #161c2d;
	background-color: #6c8aec;
	border-color: #6c8aec
}

.btn-check:active+.btn-outline-primary-desat:focus,
.btn-check:checked+.btn-outline-primary-desat:focus,
.btn-outline-primary-desat.active:focus,
.btn-outline-primary-desat.dropdown-toggle.show:focus,
.btn-outline-primary-desat:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(108, 138, 236, .5)
}

.btn-outline-primary-desat.disabled,
.btn-outline-primary-desat:disabled {
	color: #6c8aec;
	background-color: transparent
}

.btn-outline-black {
	color: #161c2d;
	border-color: #161c2d
}

.btn-outline-black:hover {
	color: #fff;
	background-color: #161c2d;
	border-color: #161c2d
}

.btn-check:focus+.btn-outline-black,
.btn-outline-black:focus {
	box-shadow: 0 0 0 0 rgba(22, 28, 45, .5)
}

.btn-check:active+.btn-outline-black,
.btn-check:checked+.btn-outline-black,
.btn-outline-black.active,
.btn-outline-black.dropdown-toggle.show,
.btn-outline-black:active {
	color: #fff;
	background-color: #161c2d;
	border-color: #161c2d
}

.btn-check:active+.btn-outline-black:focus,
.btn-check:checked+.btn-outline-black:focus,
.btn-outline-black.active:focus,
.btn-outline-black.dropdown-toggle.show:focus,
.btn-outline-black:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(22, 28, 45, .5)
}

.btn-outline-black.disabled,
.btn-outline-black:disabled {
	color: #161c2d;
	background-color: transparent
}

.btn-outline-gray-200 {
	color: #e7e7ec;
	border-color: #e7e7ec
}

.btn-outline-gray-200:hover {
	color: #161c2d;
	background-color: #e7e7ec;
	border-color: #e7e7ec
}

.btn-check:focus+.btn-outline-gray-200,
.btn-outline-gray-200:focus {
	box-shadow: 0 0 0 0 rgba(231, 231, 236, .5)
}

.btn-check:active+.btn-outline-gray-200,
.btn-check:checked+.btn-outline-gray-200,
.btn-outline-gray-200.active,
.btn-outline-gray-200.dropdown-toggle.show,
.btn-outline-gray-200:active {
	color: #161c2d;
	background-color: #e7e7ec;
	border-color: #e7e7ec
}

.btn-check:active+.btn-outline-gray-200:focus,
.btn-check:checked+.btn-outline-gray-200:focus,
.btn-outline-gray-200.active:focus,
.btn-outline-gray-200.dropdown-toggle.show:focus,
.btn-outline-gray-200:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(231, 231, 236, .5)
}

.btn-outline-gray-200.disabled,
.btn-outline-gray-200:disabled {
	color: #e7e7ec;
	background-color: transparent
}

.btn-outline-gray-800 {
	color: #77838f;
	border-color: #77838f
}

.btn-outline-gray-800:hover {
	color: #161c2d;
	background-color: #77838f;
	border-color: #77838f
}

.btn-check:focus+.btn-outline-gray-800,
.btn-outline-gray-800:focus {
	box-shadow: 0 0 0 0 rgba(119, 131, 143, .5)
}

.btn-check:active+.btn-outline-gray-800,
.btn-check:checked+.btn-outline-gray-800,
.btn-outline-gray-800.active,
.btn-outline-gray-800.dropdown-toggle.show,
.btn-outline-gray-800:active {
	color: #161c2d;
	background-color: #77838f;
	border-color: #77838f
}

.btn-check:active+.btn-outline-gray-800:focus,
.btn-check:checked+.btn-outline-gray-800:focus,
.btn-outline-gray-800.active:focus,
.btn-outline-gray-800.dropdown-toggle.show:focus,
.btn-outline-gray-800:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(119, 131, 143, .5)
}

.btn-outline-gray-800.disabled,
.btn-outline-gray-800:disabled {
	color: #77838f;
	background-color: transparent
}

.btn-outline-white {
	color: #fff;
	border-color: #fff
}

.btn-outline-white:hover {
	color: #161c2d;
	background-color: #fff;
	border-color: #fff
}

.btn-check:focus+.btn-outline-white,
.btn-outline-white:focus {
	box-shadow: 0 0 0 0 rgba(255, 255, 255, .5)
}

.btn-check:active+.btn-outline-white,
.btn-check:checked+.btn-outline-white,
.btn-outline-white.active,
.btn-outline-white.dropdown-toggle.show,
.btn-outline-white:active {
	color: #161c2d;
	background-color: #fff;
	border-color: #fff
}

.btn-check:active+.btn-outline-white:focus,
.btn-check:checked+.btn-outline-white:focus,
.btn-outline-white.active:focus,
.btn-outline-white.dropdown-toggle.show:focus,
.btn-outline-white:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(255, 255, 255, .5)
}

.btn-outline-white.disabled,
.btn-outline-white:disabled {
	color: #fff;
	background-color: transparent
}

.btn-outline-dark {
	color: #2f2d51;
	border-color: #2f2d51
}

.btn-outline-dark:hover {
	color: #fff;
	background-color: #2f2d51;
	border-color: #2f2d51
}

.btn-check:focus+.btn-outline-dark,
.btn-outline-dark:focus {
	box-shadow: 0 0 0 0 rgba(47, 45, 81, .5)
}

.btn-check:active+.btn-outline-dark,
.btn-check:checked+.btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active {
	color: #fff;
	background-color: #2f2d51;
	border-color: #2f2d51
}

.btn-check:active+.btn-outline-dark:focus,
.btn-check:checked+.btn-outline-dark:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus,
.btn-outline-dark:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(47, 45, 81, .5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
	color: #2f2d51;
	background-color: transparent
}

.btn-outline-success {
	color: #def2d7;
	border-color: #def2d7
}

.btn-outline-success:hover {
	color: #161c2d;
	background-color: #def2d7;
	border-color: #def2d7
}

.btn-check:focus+.btn-outline-success,
.btn-outline-success:focus {
	box-shadow: 0 0 0 0 rgba(222, 242, 215, .5)
}

.btn-check:active+.btn-outline-success,
.btn-check:checked+.btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
	color: #161c2d;
	background-color: #def2d7;
	border-color: #def2d7
}

.btn-check:active+.btn-outline-success:focus,
.btn-check:checked+.btn-outline-success:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus,
.btn-outline-success:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(222, 242, 215, .5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
	color: #def2d7;
	background-color: transparent
}

.btn-outline-info {
	color: #cde9f6;
	border-color: #cde9f6
}

.btn-outline-info:hover {
	color: #161c2d;
	background-color: #cde9f6;
	border-color: #cde9f6
}

.btn-check:focus+.btn-outline-info,
.btn-outline-info:focus {
	box-shadow: 0 0 0 0 rgba(205, 233, 246, .5)
}

.btn-check:active+.btn-outline-info,
.btn-check:checked+.btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
	color: #161c2d;
	background-color: #cde9f6;
	border-color: #cde9f6
}

.btn-check:active+.btn-outline-info:focus,
.btn-check:checked+.btn-outline-info:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus,
.btn-outline-info:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(205, 233, 246, .5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
	color: #cde9f6;
	background-color: transparent
}

.btn-outline-warning {
	color: #f7f3d7;
	border-color: #f7f3d7
}

.btn-outline-warning:hover {
	color: #161c2d;
	background-color: #f7f3d7;
	border-color: #f7f3d7
}

.btn-check:focus+.btn-outline-warning,
.btn-outline-warning:focus {
	box-shadow: 0 0 0 0 rgba(247, 243, 215, .5)
}

.btn-check:active+.btn-outline-warning,
.btn-check:checked+.btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
	color: #161c2d;
	background-color: #f7f3d7;
	border-color: #f7f3d7
}

.btn-check:active+.btn-outline-warning:focus,
.btn-check:checked+.btn-outline-warning:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus,
.btn-outline-warning:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(247, 243, 215, .5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
	color: #f7f3d7;
	background-color: transparent
}

.btn-outline-danger {
	color: #ecc8c5;
	border-color: #ecc8c5
}

.btn-outline-danger:hover {
	color: #161c2d;
	background-color: #ecc8c5;
	border-color: #ecc8c5
}

.btn-check:focus+.btn-outline-danger,
.btn-outline-danger:focus {
	box-shadow: 0 0 0 0 rgba(236, 200, 197, .5)
}

.btn-check:active+.btn-outline-danger,
.btn-check:checked+.btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
	color: #161c2d;
	background-color: #ecc8c5;
	border-color: #ecc8c5
}

.btn-check:active+.btn-outline-danger:focus,
.btn-check:checked+.btn-outline-danger:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus,
.btn-outline-danger:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(236, 200, 197, .5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
	color: #ecc8c5;
	background-color: transparent
}

.btn-outline-light {
	color: #f7f9fb;
	border-color: #f7f9fb
}

.btn-outline-light:hover {
	color: #161c2d;
	background-color: #f7f9fb;
	border-color: #f7f9fb
}

.btn-check:focus+.btn-outline-light,
.btn-outline-light:focus {
	box-shadow: 0 0 0 0 rgba(247, 249, 251, .5)
}

.btn-check:active+.btn-outline-light,
.btn-check:checked+.btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active {
	color: #161c2d;
	background-color: #f7f9fb;
	border-color: #f7f9fb
}

.btn-check:active+.btn-outline-light:focus,
.btn-check:checked+.btn-outline-light:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus,
.btn-outline-light:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(247, 249, 251, .5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
	color: #f7f9fb;
	background-color: transparent
}

.btn-outline-orange {
	color: #ffc78b;
	border-color: #ffc78b
}

.btn-outline-orange:hover {
	color: #161c2d;
	background-color: #ffc78b;
	border-color: #ffc78b
}

.btn-check:focus+.btn-outline-orange,
.btn-outline-orange:focus {
	box-shadow: 0 0 0 0 rgba(255, 199, 139, .5)
}

.btn-check:active+.btn-outline-orange,
.btn-check:checked+.btn-outline-orange,
.btn-outline-orange.active,
.btn-outline-orange.dropdown-toggle.show,
.btn-outline-orange:active {
	color: #161c2d;
	background-color: #ffc78b;
	border-color: #ffc78b
}

.btn-check:active+.btn-outline-orange:focus,
.btn-check:checked+.btn-outline-orange:focus,
.btn-outline-orange.active:focus,
.btn-outline-orange.dropdown-toggle.show:focus,
.btn-outline-orange:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(255, 199, 139, .5)
}

.btn-outline-orange.disabled,
.btn-outline-orange:disabled {
	color: #ffc78b;
	background-color: transparent
}

.btn-outline-blue {
	color: #196ecd;
	border-color: #196ecd
}

.btn-outline-blue:hover {
	color: #fff;
	background-color: #196ecd;
	border-color: #196ecd
}

.btn-check:focus+.btn-outline-blue,
.btn-outline-blue:focus {
	box-shadow: 0 0 0 0 rgba(25, 110, 205, .5)
}

.btn-check:active+.btn-outline-blue,
.btn-check:checked+.btn-outline-blue,
.btn-outline-blue.active,
.btn-outline-blue.dropdown-toggle.show,
.btn-outline-blue:active {
	color: #fff;
	background-color: #196ecd;
	border-color: #196ecd
}

.btn-check:active+.btn-outline-blue:focus,
.btn-check:checked+.btn-outline-blue:focus,
.btn-outline-blue.active:focus,
.btn-outline-blue.dropdown-toggle.show:focus,
.btn-outline-blue:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(25, 110, 205, .5)
}

.btn-outline-blue.disabled,
.btn-outline-blue:disabled {
	color: #196ecd;
	background-color: transparent
}

.btn-outline-purple {
	color: #b8b2fd;
	border-color: #b8b2fd
}

.btn-outline-purple:hover {
	color: #161c2d;
	background-color: #b8b2fd;
	border-color: #b8b2fd
}

.btn-check:focus+.btn-outline-purple,
.btn-outline-purple:focus {
	box-shadow: 0 0 0 0 rgba(184, 178, 253, .5)
}

.btn-check:active+.btn-outline-purple,
.btn-check:checked+.btn-outline-purple,
.btn-outline-purple.active,
.btn-outline-purple.dropdown-toggle.show,
.btn-outline-purple:active {
	color: #161c2d;
	background-color: #b8b2fd;
	border-color: #b8b2fd
}

.btn-check:active+.btn-outline-purple:focus,
.btn-check:checked+.btn-outline-purple:focus,
.btn-outline-purple.active:focus,
.btn-outline-purple.dropdown-toggle.show:focus,
.btn-outline-purple:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(184, 178, 253, .5)
}

.btn-outline-purple.disabled,
.btn-outline-purple:disabled {
	color: #b8b2fd;
	background-color: transparent
}

.btn-outline-teal {
	color: #4a8f9f;
	border-color: #4a8f9f
}

.btn-outline-teal:hover {
	color: #161c2d;
	background-color: #4a8f9f;
	border-color: #4a8f9f
}

.btn-check:focus+.btn-outline-teal,
.btn-outline-teal:focus {
	box-shadow: 0 0 0 0 rgba(74, 143, 159, .5)
}

.btn-check:active+.btn-outline-teal,
.btn-check:checked+.btn-outline-teal,
.btn-outline-teal.active,
.btn-outline-teal.dropdown-toggle.show,
.btn-outline-teal:active {
	color: #161c2d;
	background-color: #4a8f9f;
	border-color: #4a8f9f
}

.btn-check:active+.btn-outline-teal:focus,
.btn-check:checked+.btn-outline-teal:focus,
.btn-outline-teal.active:focus,
.btn-outline-teal.dropdown-toggle.show:focus,
.btn-outline-teal:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(74, 143, 159, .5)
}

.btn-outline-teal.disabled,
.btn-outline-teal:disabled {
	color: #4a8f9f;
	background-color: transparent
}

.btn-outline-ice {
	color: #9cf6db;
	border-color: #9cf6db
}

.btn-outline-ice:hover {
	color: #161c2d;
	background-color: #9cf6db;
	border-color: #9cf6db
}

.btn-check:focus+.btn-outline-ice,
.btn-outline-ice:focus {
	box-shadow: 0 0 0 0 rgba(156, 246, 219, .5)
}

.btn-check:active+.btn-outline-ice,
.btn-check:checked+.btn-outline-ice,
.btn-outline-ice.active,
.btn-outline-ice.dropdown-toggle.show,
.btn-outline-ice:active {
	color: #161c2d;
	background-color: #9cf6db;
	border-color: #9cf6db
}

.btn-check:active+.btn-outline-ice:focus,
.btn-check:checked+.btn-outline-ice:focus,
.btn-outline-ice.active:focus,
.btn-outline-ice.dropdown-toggle.show:focus,
.btn-outline-ice:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(156, 246, 219, .5)
}

.btn-outline-ice.disabled,
.btn-outline-ice:disabled {
	color: #9cf6db;
	background-color: transparent
}

.btn-outline-cyan {
	color: #9ef3f6;
	border-color: #9ef3f6
}

.btn-outline-cyan:hover {
	color: #161c2d;
	background-color: #9ef3f6;
	border-color: #9ef3f6
}

.btn-check:focus+.btn-outline-cyan,
.btn-outline-cyan:focus {
	box-shadow: 0 0 0 0 rgba(158, 243, 246, .5)
}

.btn-check:active+.btn-outline-cyan,
.btn-check:checked+.btn-outline-cyan,
.btn-outline-cyan.active,
.btn-outline-cyan.dropdown-toggle.show,
.btn-outline-cyan:active {
	color: #161c2d;
	background-color: #9ef3f6;
	border-color: #9ef3f6
}

.btn-check:active+.btn-outline-cyan:focus,
.btn-check:checked+.btn-outline-cyan:focus,
.btn-outline-cyan.active:focus,
.btn-outline-cyan.dropdown-toggle.show:focus,
.btn-outline-cyan:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(158, 243, 246, .5)
}

.btn-outline-cyan.disabled,
.btn-outline-cyan:disabled {
	color: #9ef3f6;
	background-color: transparent
}

.btn-outline-helio {
	color: #b253f6;
	border-color: #b253f6
}

.btn-outline-helio:hover {
	color: #161c2d;
	background-color: #b253f6;
	border-color: #b253f6
}

.btn-check:focus+.btn-outline-helio,
.btn-outline-helio:focus {
	box-shadow: 0 0 0 0 rgba(178, 83, 246, .5)
}

.btn-check:active+.btn-outline-helio,
.btn-check:checked+.btn-outline-helio,
.btn-outline-helio.active,
.btn-outline-helio.dropdown-toggle.show,
.btn-outline-helio:active {
	color: #161c2d;
	background-color: #b253f6;
	border-color: #b253f6
}

.btn-check:active+.btn-outline-helio:focus,
.btn-check:checked+.btn-outline-helio:focus,
.btn-outline-helio.active:focus,
.btn-outline-helio.dropdown-toggle.show:focus,
.btn-outline-helio:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(178, 83, 246, .5)
}

.btn-outline-helio.disabled,
.btn-outline-helio:disabled {
	color: #b253f6;
	background-color: transparent
}

.btn-outline-white-ice {
	color: #f0fdf8;
	border-color: #f0fdf8
}

.btn-outline-white-ice:hover {
	color: #161c2d;
	background-color: #f0fdf8;
	border-color: #f0fdf8
}

.btn-check:focus+.btn-outline-white-ice,
.btn-outline-white-ice:focus {
	box-shadow: 0 0 0 0 rgba(240, 253, 248, .5)
}

.btn-check:active+.btn-outline-white-ice,
.btn-check:checked+.btn-outline-white-ice,
.btn-outline-white-ice.active,
.btn-outline-white-ice.dropdown-toggle.show,
.btn-outline-white-ice:active {
	color: #161c2d;
	background-color: #f0fdf8;
	border-color: #f0fdf8
}

.btn-check:active+.btn-outline-white-ice:focus,
.btn-check:checked+.btn-outline-white-ice:focus,
.btn-outline-white-ice.active:focus,
.btn-outline-white-ice.dropdown-toggle.show:focus,
.btn-outline-white-ice:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(240, 253, 248, .5)
}

.btn-outline-white-ice.disabled,
.btn-outline-white-ice:disabled {
	color: #f0fdf8;
	background-color: transparent
}

.btn-outline-tropaz {
	color: #2d4f93;
	border-color: #2d4f93
}

.btn-outline-tropaz:hover {
	color: #fff;
	background-color: #2d4f93;
	border-color: #2d4f93
}

.btn-check:focus+.btn-outline-tropaz,
.btn-outline-tropaz:focus {
	box-shadow: 0 0 0 0 rgba(45, 79, 147, .5)
}

.btn-check:active+.btn-outline-tropaz,
.btn-check:checked+.btn-outline-tropaz,
.btn-outline-tropaz.active,
.btn-outline-tropaz.dropdown-toggle.show,
.btn-outline-tropaz:active {
	color: #fff;
	background-color: #2d4f93;
	border-color: #2d4f93
}

.btn-check:active+.btn-outline-tropaz:focus,
.btn-check:checked+.btn-outline-tropaz:focus,
.btn-outline-tropaz.active:focus,
.btn-outline-tropaz.dropdown-toggle.show:focus,
.btn-outline-tropaz:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(45, 79, 147, .5)
}

.btn-outline-tropaz.disabled,
.btn-outline-tropaz:disabled {
	color: #2d4f93;
	background-color: transparent
}

.btn-outline-roman {
	color: #e0685c;
	border-color: #e0685c
}

.btn-outline-roman:hover {
	color: #161c2d;
	background-color: #e0685c;
	border-color: #e0685c
}

.btn-check:focus+.btn-outline-roman,
.btn-outline-roman:focus {
	box-shadow: 0 0 0 0 rgba(224, 104, 92, .5)
}

.btn-check:active+.btn-outline-roman,
.btn-check:checked+.btn-outline-roman,
.btn-outline-roman.active,
.btn-outline-roman.dropdown-toggle.show,
.btn-outline-roman:active {
	color: #161c2d;
	background-color: #e0685c;
	border-color: #e0685c
}

.btn-check:active+.btn-outline-roman:focus,
.btn-check:checked+.btn-outline-roman:focus,
.btn-outline-roman.active:focus,
.btn-outline-roman.dropdown-toggle.show:focus,
.btn-outline-roman:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(224, 104, 92, .5)
}

.btn-outline-roman.disabled,
.btn-outline-roman:disabled {
	color: #e0685c;
	background-color: transparent
}

.btn-outline-tangerine {
	color: #ff9574;
	border-color: #ff9574
}

.btn-outline-tangerine:hover {
	color: #161c2d;
	background-color: #ff9574;
	border-color: #ff9574
}

.btn-check:focus+.btn-outline-tangerine,
.btn-outline-tangerine:focus {
	box-shadow: 0 0 0 0 rgba(255, 149, 116, .5)
}

.btn-check:active+.btn-outline-tangerine,
.btn-check:checked+.btn-outline-tangerine,
.btn-outline-tangerine.active,
.btn-outline-tangerine.dropdown-toggle.show,
.btn-outline-tangerine:active {
	color: #161c2d;
	background-color: #ff9574;
	border-color: #ff9574
}

.btn-check:active+.btn-outline-tangerine:focus,
.btn-check:checked+.btn-outline-tangerine:focus,
.btn-outline-tangerine.active:focus,
.btn-outline-tangerine.dropdown-toggle.show:focus,
.btn-outline-tangerine:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(255, 149, 116, .5)
}

.btn-outline-tangerine.disabled,
.btn-outline-tangerine:disabled {
	color: #ff9574;
	background-color: transparent
}

.btn-outline-gigas {
	color: #4e35a3;
	border-color: #4e35a3
}

.btn-outline-gigas:hover {
	color: #fff;
	background-color: #4e35a3;
	border-color: #4e35a3
}

.btn-check:focus+.btn-outline-gigas,
.btn-outline-gigas:focus {
	box-shadow: 0 0 0 0 rgba(78, 53, 163, .5)
}

.btn-check:active+.btn-outline-gigas,
.btn-check:checked+.btn-outline-gigas,
.btn-outline-gigas.active,
.btn-outline-gigas.dropdown-toggle.show,
.btn-outline-gigas:active {
	color: #fff;
	background-color: #4e35a3;
	border-color: #4e35a3
}

.btn-check:active+.btn-outline-gigas:focus,
.btn-check:checked+.btn-outline-gigas:focus,
.btn-outline-gigas.active:focus,
.btn-outline-gigas.dropdown-toggle.show:focus,
.btn-outline-gigas:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(78, 53, 163, .5)
}

.btn-outline-gigas.disabled,
.btn-outline-gigas:disabled {
	color: #4e35a3;
	background-color: transparent
}

.btn-outline-lavender {
	color: #ffebf1;
	border-color: #ffebf1
}

.btn-outline-lavender:hover {
	color: #161c2d;
	background-color: #ffebf1;
	border-color: #ffebf1
}

.btn-check:focus+.btn-outline-lavender,
.btn-outline-lavender:focus {
	box-shadow: 0 0 0 0 rgba(255, 235, 241, .5)
}

.btn-check:active+.btn-outline-lavender,
.btn-check:checked+.btn-outline-lavender,
.btn-outline-lavender.active,
.btn-outline-lavender.dropdown-toggle.show,
.btn-outline-lavender:active {
	color: #161c2d;
	background-color: #ffebf1;
	border-color: #ffebf1
}

.btn-check:active+.btn-outline-lavender:focus,
.btn-check:checked+.btn-outline-lavender:focus,
.btn-outline-lavender.active:focus,
.btn-outline-lavender.dropdown-toggle.show:focus,
.btn-outline-lavender:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(255, 235, 241, .5)
}

.btn-outline-lavender.disabled,
.btn-outline-lavender:disabled {
	color: #ffebf1;
	background-color: transparent
}

.btn-outline-sienna {
	color: #e7643e;
	border-color: #e7643e
}

.btn-outline-sienna:hover {
	color: #161c2d;
	background-color: #e7643e;
	border-color: #e7643e
}

.btn-check:focus+.btn-outline-sienna,
.btn-outline-sienna:focus {
	box-shadow: 0 0 0 0 rgba(231, 100, 62, .5)
}

.btn-check:active+.btn-outline-sienna,
.btn-check:checked+.btn-outline-sienna,
.btn-outline-sienna.active,
.btn-outline-sienna.dropdown-toggle.show,
.btn-outline-sienna:active {
	color: #161c2d;
	background-color: #e7643e;
	border-color: #e7643e
}

.btn-check:active+.btn-outline-sienna:focus,
.btn-check:checked+.btn-outline-sienna:focus,
.btn-outline-sienna.active:focus,
.btn-outline-sienna.dropdown-toggle.show:focus,
.btn-outline-sienna:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(231, 100, 62, .5)
}

.btn-outline-sienna.disabled,
.btn-outline-sienna:disabled {
	color: #e7643e;
	background-color: transparent
}

.btn-outline-coral {
	color: #fe805d;
	border-color: #fe805d
}

.btn-outline-coral:hover {
	color: #161c2d;
	background-color: #fe805d;
	border-color: #fe805d
}

.btn-check:focus+.btn-outline-coral,
.btn-outline-coral:focus {
	box-shadow: 0 0 0 0 rgba(254, 128, 93, .5)
}

.btn-check:active+.btn-outline-coral,
.btn-check:checked+.btn-outline-coral,
.btn-outline-coral.active,
.btn-outline-coral.dropdown-toggle.show,
.btn-outline-coral:active {
	color: #161c2d;
	background-color: #fe805d;
	border-color: #fe805d
}

.btn-check:active+.btn-outline-coral:focus,
.btn-check:checked+.btn-outline-coral:focus,
.btn-outline-coral.active:focus,
.btn-outline-coral.dropdown-toggle.show:focus,
.btn-outline-coral:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(254, 128, 93, .5)
}

.btn-outline-coral.disabled,
.btn-outline-coral:disabled {
	color: #fe805d;
	background-color: transparent
}

.btn-outline-catskill {
	color: #f9fbfc;
	border-color: #f9fbfc
}

.btn-outline-catskill:hover {
	color: #161c2d;
	background-color: #f9fbfc;
	border-color: #f9fbfc
}

.btn-check:focus+.btn-outline-catskill,
.btn-outline-catskill:focus {
	box-shadow: 0 0 0 0 rgba(249, 251, 252, .5)
}

.btn-check:active+.btn-outline-catskill,
.btn-check:checked+.btn-outline-catskill,
.btn-outline-catskill.active,
.btn-outline-catskill.dropdown-toggle.show,
.btn-outline-catskill:active {
	color: #161c2d;
	background-color: #f9fbfc;
	border-color: #f9fbfc
}

.btn-check:active+.btn-outline-catskill:focus,
.btn-check:checked+.btn-outline-catskill:focus,
.btn-outline-catskill.active:focus,
.btn-outline-catskill.dropdown-toggle.show:focus,
.btn-outline-catskill:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(249, 251, 252, .5)
}

.btn-outline-catskill.disabled,
.btn-outline-catskill:disabled {
	color: #f9fbfc;
	background-color: transparent
}

.btn-outline-bossanova {
	color: #452b4f;
	border-color: #452b4f
}

.btn-outline-bossanova:hover {
	color: #fff;
	background-color: #452b4f;
	border-color: #452b4f
}

.btn-check:focus+.btn-outline-bossanova,
.btn-outline-bossanova:focus {
	box-shadow: 0 0 0 0 rgba(69, 43, 79, .5)
}

.btn-check:active+.btn-outline-bossanova,
.btn-check:checked+.btn-outline-bossanova,
.btn-outline-bossanova.active,
.btn-outline-bossanova.dropdown-toggle.show,
.btn-outline-bossanova:active {
	color: #fff;
	background-color: #452b4f;
	border-color: #452b4f
}

.btn-check:active+.btn-outline-bossanova:focus,
.btn-check:checked+.btn-outline-bossanova:focus,
.btn-outline-bossanova.active:focus,
.btn-outline-bossanova.dropdown-toggle.show:focus,
.btn-outline-bossanova:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(69, 43, 79, .5)
}

.btn-outline-bossanova.disabled,
.btn-outline-bossanova:disabled {
	color: #452b4f;
	background-color: transparent
}

.btn-outline-denim {
	color: #146ecf;
	border-color: #146ecf
}

.btn-outline-denim:hover {
	color: #fff;
	background-color: #146ecf;
	border-color: #146ecf
}

.btn-check:focus+.btn-outline-denim,
.btn-outline-denim:focus {
	box-shadow: 0 0 0 0 rgba(20, 110, 207, .5)
}

.btn-check:active+.btn-outline-denim,
.btn-check:checked+.btn-outline-denim,
.btn-outline-denim.active,
.btn-outline-denim.dropdown-toggle.show,
.btn-outline-denim:active {
	color: #fff;
	background-color: #146ecf;
	border-color: #146ecf
}

.btn-check:active+.btn-outline-denim:focus,
.btn-check:checked+.btn-outline-denim:focus,
.btn-outline-denim.active:focus,
.btn-outline-denim.dropdown-toggle.show:focus,
.btn-outline-denim:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(20, 110, 207, .5)
}

.btn-outline-denim.disabled,
.btn-outline-denim:disabled {
	color: #146ecf;
	background-color: transparent
}

.btn-outline-turbo {
	color: #feb800;
	border-color: #feb800
}

.btn-outline-turbo:hover {
	color: #161c2d;
	background-color: #feb800;
	border-color: #feb800
}

.btn-check:focus+.btn-outline-turbo,
.btn-outline-turbo:focus {
	box-shadow: 0 0 0 0 rgba(254, 184, 0, .5)
}

.btn-check:active+.btn-outline-turbo,
.btn-check:checked+.btn-outline-turbo,
.btn-outline-turbo.active,
.btn-outline-turbo.dropdown-toggle.show,
.btn-outline-turbo:active {
	color: #161c2d;
	background-color: #feb800;
	border-color: #feb800
}

.btn-check:active+.btn-outline-turbo:focus,
.btn-check:checked+.btn-outline-turbo:focus,
.btn-outline-turbo.active:focus,
.btn-outline-turbo.dropdown-toggle.show:focus,
.btn-outline-turbo:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(254, 184, 0, .5)
}

.btn-outline-turbo.disabled,
.btn-outline-turbo:disabled {
	color: #feb800;
	background-color: transparent
}

.btn-outline-bright-sun {
	color: #ffd438;
	border-color: #ffd438
}

.btn-outline-bright-sun:hover {
	color: #161c2d;
	background-color: #ffd438;
	border-color: #ffd438
}

.btn-check:focus+.btn-outline-bright-sun,
.btn-outline-bright-sun:focus {
	box-shadow: 0 0 0 0 rgba(255, 212, 56, .5)
}

.btn-check:active+.btn-outline-bright-sun,
.btn-check:checked+.btn-outline-bright-sun,
.btn-outline-bright-sun.active,
.btn-outline-bright-sun.dropdown-toggle.show,
.btn-outline-bright-sun:active {
	color: #161c2d;
	background-color: #ffd438;
	border-color: #ffd438
}

.btn-check:active+.btn-outline-bright-sun:focus,
.btn-check:checked+.btn-outline-bright-sun:focus,
.btn-outline-bright-sun.active:focus,
.btn-outline-bright-sun.dropdown-toggle.show:focus,
.btn-outline-bright-sun:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(255, 212, 56, .5)
}

.btn-outline-bright-sun.disabled,
.btn-outline-bright-sun:disabled {
	color: #ffd438;
	background-color: transparent
}

.btn-outline-shark {
	color: #282a2e;
	border-color: #282a2e
}

.btn-outline-shark:hover {
	color: #fff;
	background-color: #282a2e;
	border-color: #282a2e
}

.btn-check:focus+.btn-outline-shark,
.btn-outline-shark:focus {
	box-shadow: 0 0 0 0 rgba(40, 42, 46, .5)
}

.btn-check:active+.btn-outline-shark,
.btn-check:checked+.btn-outline-shark,
.btn-outline-shark.active,
.btn-outline-shark.dropdown-toggle.show,
.btn-outline-shark:active {
	color: #fff;
	background-color: #282a2e;
	border-color: #282a2e
}

.btn-check:active+.btn-outline-shark:focus,
.btn-check:checked+.btn-outline-shark:focus,
.btn-outline-shark.active:focus,
.btn-outline-shark.dropdown-toggle.show:focus,
.btn-outline-shark:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(40, 42, 46, .5)
}

.btn-outline-shark.disabled,
.btn-outline-shark:disabled {
	color: #282a2e;
	background-color: transparent
}

.btn-outline-dodger {
	color: #377dff;
	border-color: #377dff
}

.btn-outline-dodger:hover {
	color: #161c2d;
	background-color: #377dff;
	border-color: #377dff
}

.btn-check:focus+.btn-outline-dodger,
.btn-outline-dodger:focus {
	box-shadow: 0 0 0 0 rgba(55, 125, 255, .5)
}

.btn-check:active+.btn-outline-dodger,
.btn-check:checked+.btn-outline-dodger,
.btn-outline-dodger.active,
.btn-outline-dodger.dropdown-toggle.show,
.btn-outline-dodger:active {
	color: #161c2d;
	background-color: #377dff;
	border-color: #377dff
}

.btn-check:active+.btn-outline-dodger:focus,
.btn-check:checked+.btn-outline-dodger:focus,
.btn-outline-dodger.active:focus,
.btn-outline-dodger.dropdown-toggle.show:focus,
.btn-outline-dodger:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(55, 125, 255, .5)
}

.btn-outline-dodger.disabled,
.btn-outline-dodger:disabled {
	color: #377dff;
	background-color: transparent
}

.btn-outline-cloud-burst {
	color: #21325b;
	border-color: #21325b
}

.btn-outline-cloud-burst:hover {
	color: #fff;
	background-color: #21325b;
	border-color: #21325b
}

.btn-check:focus+.btn-outline-cloud-burst,
.btn-outline-cloud-burst:focus {
	box-shadow: 0 0 0 0 rgba(33, 50, 91, .5)
}

.btn-check:active+.btn-outline-cloud-burst,
.btn-check:checked+.btn-outline-cloud-burst,
.btn-outline-cloud-burst.active,
.btn-outline-cloud-burst.dropdown-toggle.show,
.btn-outline-cloud-burst:active {
	color: #fff;
	background-color: #21325b;
	border-color: #21325b
}

.btn-check:active+.btn-outline-cloud-burst:focus,
.btn-check:checked+.btn-outline-cloud-burst:focus,
.btn-outline-cloud-burst.active:focus,
.btn-outline-cloud-burst.dropdown-toggle.show:focus,
.btn-outline-cloud-burst:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(33, 50, 91, .5)
}

.btn-outline-cloud-burst.disabled,
.btn-outline-cloud-burst:disabled {
	color: #21325b;
	background-color: transparent
}

.btn-outline-porsche {
	color: #f0b26b;
	border-color: #f0b26b
}

.btn-outline-porsche:hover {
	color: #161c2d;
	background-color: #f0b26b;
	border-color: #f0b26b
}

.btn-check:focus+.btn-outline-porsche,
.btn-outline-porsche:focus {
	box-shadow: 0 0 0 0 rgba(240, 178, 107, .5)
}

.btn-check:active+.btn-outline-porsche,
.btn-check:checked+.btn-outline-porsche,
.btn-outline-porsche.active,
.btn-outline-porsche.dropdown-toggle.show,
.btn-outline-porsche:active {
	color: #161c2d;
	background-color: #f0b26b;
	border-color: #f0b26b
}

.btn-check:active+.btn-outline-porsche:focus,
.btn-check:checked+.btn-outline-porsche:focus,
.btn-outline-porsche.active:focus,
.btn-outline-porsche.dropdown-toggle.show:focus,
.btn-outline-porsche:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(240, 178, 107, .5)
}

.btn-outline-porsche.disabled,
.btn-outline-porsche:disabled {
	color: #f0b26b;
	background-color: transparent
}

.btn-outline-biscay {
	color: #183f63;
	border-color: #183f63
}

.btn-outline-biscay:hover {
	color: #fff;
	background-color: #183f63;
	border-color: #183f63
}

.btn-check:focus+.btn-outline-biscay,
.btn-outline-biscay:focus {
	box-shadow: 0 0 0 0 rgba(24, 63, 99, .5)
}

.btn-check:active+.btn-outline-biscay,
.btn-check:checked+.btn-outline-biscay,
.btn-outline-biscay.active,
.btn-outline-biscay.dropdown-toggle.show,
.btn-outline-biscay:active {
	color: #fff;
	background-color: #183f63;
	border-color: #183f63
}

.btn-check:active+.btn-outline-biscay:focus,
.btn-check:checked+.btn-outline-biscay:focus,
.btn-outline-biscay.active:focus,
.btn-outline-biscay.dropdown-toggle.show:focus,
.btn-outline-biscay:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(24, 63, 99, .5)
}

.btn-outline-biscay.disabled,
.btn-outline-biscay:disabled {
	color: #183f63;
	background-color: transparent
}

.btn-outline-mongoose {
	color: #bda588;
	border-color: #bda588
}

.btn-outline-mongoose:hover {
	color: #161c2d;
	background-color: #bda588;
	border-color: #bda588
}

.btn-check:focus+.btn-outline-mongoose,
.btn-outline-mongoose:focus {
	box-shadow: 0 0 0 0 rgba(189, 165, 136, .5)
}

.btn-check:active+.btn-outline-mongoose,
.btn-check:checked+.btn-outline-mongoose,
.btn-outline-mongoose.active,
.btn-outline-mongoose.dropdown-toggle.show,
.btn-outline-mongoose:active {
	color: #161c2d;
	background-color: #bda588;
	border-color: #bda588
}

.btn-check:active+.btn-outline-mongoose:focus,
.btn-check:checked+.btn-outline-mongoose:focus,
.btn-outline-mongoose.active:focus,
.btn-outline-mongoose.dropdown-toggle.show:focus,
.btn-outline-mongoose:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(189, 165, 136, .5)
}

.btn-outline-mongoose.disabled,
.btn-outline-mongoose:disabled {
	color: #bda588;
	background-color: transparent
}

.btn-outline-ebony {
	color: #252837;
	border-color: #252837
}

.btn-outline-ebony:hover {
	color: #fff;
	background-color: #252837;
	border-color: #252837
}

.btn-check:focus+.btn-outline-ebony,
.btn-outline-ebony:focus {
	box-shadow: 0 0 0 0 rgba(37, 40, 55, .5)
}

.btn-check:active+.btn-outline-ebony,
.btn-check:checked+.btn-outline-ebony,
.btn-outline-ebony.active,
.btn-outline-ebony.dropdown-toggle.show,
.btn-outline-ebony:active {
	color: #fff;
	background-color: #252837;
	border-color: #252837
}

.btn-check:active+.btn-outline-ebony:focus,
.btn-check:checked+.btn-outline-ebony:focus,
.btn-outline-ebony.active:focus,
.btn-outline-ebony.dropdown-toggle.show:focus,
.btn-outline-ebony:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(37, 40, 55, .5)
}

.btn-outline-ebony.disabled,
.btn-outline-ebony:disabled {
	color: #252837;
	background-color: transparent
}

.btn-outline-alizarin {
	color: #eb1c24;
	border-color: #eb1c24
}

.btn-outline-alizarin:hover {
	color: #fff;
	background-color: #eb1c24;
	border-color: #eb1c24
}

.btn-check:focus+.btn-outline-alizarin,
.btn-outline-alizarin:focus {
	box-shadow: 0 0 0 0 rgba(235, 28, 36, .5)
}

.btn-check:active+.btn-outline-alizarin,
.btn-check:checked+.btn-outline-alizarin,
.btn-outline-alizarin.active,
.btn-outline-alizarin.dropdown-toggle.show,
.btn-outline-alizarin:active {
	color: #fff;
	background-color: #eb1c24;
	border-color: #eb1c24
}

.btn-check:active+.btn-outline-alizarin:focus,
.btn-check:checked+.btn-outline-alizarin:focus,
.btn-outline-alizarin.active:focus,
.btn-outline-alizarin.dropdown-toggle.show:focus,
.btn-outline-alizarin:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(235, 28, 36, .5)
}

.btn-outline-alizarin.disabled,
.btn-outline-alizarin:disabled {
	color: #eb1c24;
	background-color: transparent
}

.btn-outline-chestnut {
	color: #cf574b;
	border-color: #cf574b
}

.btn-outline-chestnut:hover {
	color: #fff;
	background-color: #cf574b;
	border-color: #cf574b
}

.btn-check:focus+.btn-outline-chestnut,
.btn-outline-chestnut:focus {
	box-shadow: 0 0 0 0 rgba(207, 87, 75, .5)
}

.btn-check:active+.btn-outline-chestnut,
.btn-check:checked+.btn-outline-chestnut,
.btn-outline-chestnut.active,
.btn-outline-chestnut.dropdown-toggle.show,
.btn-outline-chestnut:active {
	color: #fff;
	background-color: #cf574b;
	border-color: #cf574b
}

.btn-check:active+.btn-outline-chestnut:focus,
.btn-check:checked+.btn-outline-chestnut:focus,
.btn-outline-chestnut.active:focus,
.btn-outline-chestnut.dropdown-toggle.show:focus,
.btn-outline-chestnut:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(207, 87, 75, .5)
}

.btn-outline-chestnut.disabled,
.btn-outline-chestnut:disabled {
	color: #cf574b;
	background-color: transparent
}

.btn-outline-portgore {
	color: #212041;
	border-color: #212041
}

.btn-outline-portgore:hover {
	color: #fff;
	background-color: #212041;
	border-color: #212041
}

.btn-check:focus+.btn-outline-portgore,
.btn-outline-portgore:focus {
	box-shadow: 0 0 0 0 rgba(33, 32, 65, .5)
}

.btn-check:active+.btn-outline-portgore,
.btn-check:checked+.btn-outline-portgore,
.btn-outline-portgore.active,
.btn-outline-portgore.dropdown-toggle.show,
.btn-outline-portgore:active {
	color: #fff;
	background-color: #212041;
	border-color: #212041
}

.btn-check:active+.btn-outline-portgore:focus,
.btn-check:checked+.btn-outline-portgore:focus,
.btn-outline-portgore.active:focus,
.btn-outline-portgore.dropdown-toggle.show:focus,
.btn-outline-portgore:active:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(33, 32, 65, .5)
}

.btn-outline-portgore.disabled,
.btn-outline-portgore:disabled {
	color: #212041;
	background-color: transparent
}

.btn-link {
	font-weight: 400;
	color: #090761;
	text-decoration: none
}

.btn-link:hover {
	color: #090761;
	text-decoration: none
}

.btn-link:focus {
	text-decoration: none
}

.btn-link.disabled,
.btn-link:disabled {
	color: #8c98a4
}

.btn-group-lg>.btn,
.btn-lg,
.cart-collaterals .btn-group-lg>.checkout-button,
.shop_table.cart .btn-group-lg>input.button,
form.woocommerce-checkout .place-order .btn-group-lg>.button {
	padding: 1.122rem 1.5rem;
	font-size: 1rem;
	border-radius: .25rem
}

.btn-group-sm>.btn,
.btn-sm,
.cart-collaterals .btn-group-sm>.checkout-button,
.shop_table.cart input.button,
form.woocommerce-checkout .place-order .btn-group-sm>.button {
	padding: .5625rem 1rem;
	font-size: 1rem;
	border-radius: .25rem
}

.fade {
	-webkit-transition: opacity .15s linear;
	transition: opacity .15s linear
}

@media (prefers-reduced-motion:reduce) {
	.fade {
		-webkit-transition: none;
		transition: none
	}
}

.fade:not(.show) {
	opacity: 0
}

.collapse:not(.show) {
	display: none
}

.collapsing {
	height: 0;
	overflow: hidden;
	-webkit-transition: height .35s ease;
	transition: height .35s ease
}

@media (prefers-reduced-motion:reduce) {
	.collapsing {
		-webkit-transition: none;
		transition: none
	}
}

.collapsing.collapse-horizontal {
	width: 0;
	height: auto;
	-webkit-transition: width .35s ease;
	transition: width .35s ease
}

@media (prefers-reduced-motion:reduce) {
	.collapsing.collapse-horizontal {
		-webkit-transition: none;
		transition: none
	}
}

.dropdown,
.dropend,
.dropstart,
.dropup {
	position: relative
}

.dropdown-toggle {
	white-space: nowrap
}

.dropdown-toggle::after {
	display: inline-block;
	margin-left: .255em;
	vertical-align: .255em;
	content: "";
	border-top: .3em solid;
	border-right: .3em solid transparent;
	border-bottom: 0;
	border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
	margin-left: 0
}

.dropdown-menu {
	position: absolute;
	z-index: 1000;
	display: none;
	min-width: 16.25rem;
	padding: 1.625rem 1.75rem;
	margin: 0;
	font-size: .875rem;
	color: #77838f;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 0 solid rgba(22, 28, 45, .15);
	border-radius: .25rem;
	box-shadow: 0 .5rem .937rem rgba(140, 152, 164, .1)
}

.dropdown-menu[data-bs-popper] {
	top: 100%;
	left: 0;
	margin-top: 0
}

.dropdown-menu-start {
	--bs-position: start
}

.dropdown-menu-start[data-bs-popper] {
	right: auto;
	left: 0
}

.dropdown-menu-end {
	--bs-position: end
}

.dropdown-menu-end[data-bs-popper] {
	right: 0;
	left: auto
}

@media (min-width:576px) {
	.dropdown-menu-sm-start {
		--bs-position: start
	}
	.dropdown-menu-sm-start[data-bs-popper] {
		right: auto;
		left: 0
	}
	.dropdown-menu-sm-end {
		--bs-position: end
	}
	.dropdown-menu-sm-end[data-bs-popper] {
		right: 0;
		left: auto
	}
}

@media (min-width:768px) {
	.dropdown-menu-md-start {
		--bs-position: start
	}
	.dropdown-menu-md-start[data-bs-popper] {
		right: auto;
		left: 0
	}
	.dropdown-menu-md-end {
		--bs-position: end
	}
	.dropdown-menu-md-end[data-bs-popper] {
		right: 0;
		left: auto
	}
}

@media (min-width:992px) {
	.dropdown-menu-lg-start {
		--bs-position: start
	}
	.dropdown-menu-lg-start[data-bs-popper] {
		right: auto;
		left: 0
	}
	.dropdown-menu-lg-end {
		--bs-position: end
	}
	.dropdown-menu-lg-end[data-bs-popper] {
		right: 0;
		left: auto
	}
}

@media (min-width:1200px) {
	.dropdown-menu-xl-start {
		--bs-position: start
	}
	.dropdown-menu-xl-start[data-bs-popper] {
		right: auto;
		left: 0
	}
	.dropdown-menu-xl-end {
		--bs-position: end
	}
	.dropdown-menu-xl-end[data-bs-popper] {
		right: 0;
		left: auto
	}
}

@media (min-width:1480px) {
	.dropdown-menu-wd-start {
		--bs-position: start
	}
	.dropdown-menu-wd-start[data-bs-popper] {
		right: auto;
		left: 0
	}
	.dropdown-menu-wd-end {
		--bs-position: end
	}
	.dropdown-menu-wd-end[data-bs-popper] {
		right: 0;
		left: auto
	}
}

.dropup .dropdown-menu[data-bs-popper] {
	top: auto;
	bottom: 100%;
	margin-top: 0;
	margin-bottom: 0
}

.dropup .dropdown-toggle::after {
	display: inline-block;
	margin-left: .255em;
	vertical-align: .255em;
	content: "";
	border-top: 0;
	border-right: .3em solid transparent;
	border-bottom: .3em solid;
	border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
	margin-left: 0
}

.dropend .dropdown-menu[data-bs-popper] {
	top: 0;
	right: auto;
	left: 100%;
	margin-top: 0;
	margin-left: 0
}

.dropend .dropdown-toggle::after {
	display: inline-block;
	margin-left: .255em;
	vertical-align: .255em;
	content: "";
	border-top: .3em solid transparent;
	border-right: 0;
	border-bottom: .3em solid transparent;
	border-left: .3em solid
}

.dropend .dropdown-toggle:empty::after {
	margin-left: 0
}

.dropend .dropdown-toggle::after {
	vertical-align: 0
}

.dropstart .dropdown-menu[data-bs-popper] {
	top: 0;
	right: 100%;
	left: auto;
	margin-top: 0;
	margin-right: 0
}

.dropstart .dropdown-toggle::after {
	display: inline-block;
	margin-left: .255em;
	vertical-align: .255em;
	content: ""
}

.dropstart .dropdown-toggle::after {
	display: none
}

.dropstart .dropdown-toggle::before {
	display: inline-block;
	margin-right: .255em;
	vertical-align: .255em;
	content: "";
	border-top: .3em solid transparent;
	border-right: .3em solid;
	border-bottom: .3em solid transparent
}

.dropstart .dropdown-toggle:empty::after {
	margin-left: 0
}

.dropstart .dropdown-toggle::before {
	vertical-align: 0
}

.dropdown-divider {
	height: 0;
	margin: .5rem 0;
	overflow: hidden;
	border-top: 1px solid rgba(22, 28, 45, .15)
}

.dropdown-item {
	display: block;
	width: 100%;
	padding: 0 1.75rem;
	clear: both;
	font-weight: 400;
	color: #949da6;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0
}

.dropdown-item:focus,
.dropdown-item:hover {
	color: #090761;
	background-color: none
}

.dropdown-item.active,
.dropdown-item:active {
	color: #090761;
	text-decoration: none;
	background-color: none
}

.dropdown-item.disabled,
.dropdown-item:disabled {
	color: #c8c8d6;
	pointer-events: none;
	background-color: transparent
}

.dropdown-menu.show {
	display: block
}

.dropdown-header {
	display: block;
	padding: 1.625rem 1.75rem;
	margin-bottom: 0;
	font-size: .875rem;
	color: #2f2d51;
	white-space: nowrap
}

.dropdown-item-text {
	display: block;
	padding: 0 1.75rem;
	color: #949da6
}

.dropdown-menu-dark {
	color: #d9e2ef;
	background-color: #77838f;
	border-color: rgba(22, 28, 45, .15)
}

.dropdown-menu-dark .dropdown-item {
	color: #d9e2ef
}

.dropdown-menu-dark .dropdown-item:focus,
.dropdown-menu-dark .dropdown-item:hover {
	color: #fff;
	background-color: rgba(255, 255, 255, .15)
}

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
	color: #090761;
	background-color: none
}

.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
	color: #c8c8d6
}

.dropdown-menu-dark .dropdown-divider {
	border-color: rgba(22, 28, 45, .15)
}

.dropdown-menu-dark .dropdown-item-text {
	color: #d9e2ef
}

.dropdown-menu-dark .dropdown-header {
	color: #c8c8d6
}

.btn-group,
.btn-group-vertical {
	position: relative;
	display: -webkit-inline-box;
	display: inline-flex;
	vertical-align: middle
}

.btn-group-vertical>.btn,
.btn-group>.btn,
.cart-collaterals .btn-group-vertical>.checkout-button,
.cart-collaterals .btn-group>.checkout-button,
.shop_table.cart .btn-group-vertical>input.button,
.shop_table.cart .btn-group>input.button,
form.woocommerce-checkout .place-order .btn-group-vertical>.button,
form.woocommerce-checkout .place-order .btn-group>.button {
	position: relative;
	-webkit-box-flex: 1;
	flex: 1 1 auto
}

.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover,
.cart-collaterals .btn-group-vertical>.active.checkout-button,
.cart-collaterals .btn-group-vertical>.btn-check:checked+.checkout-button,
.cart-collaterals .btn-group-vertical>.btn-check:focus+.checkout-button,
.cart-collaterals .btn-group-vertical>.checkout-button:active,
.cart-collaterals .btn-group-vertical>.checkout-button:focus,
.cart-collaterals .btn-group-vertical>.checkout-button:hover,
.cart-collaterals .btn-group>.active.checkout-button,
.cart-collaterals .btn-group>.btn-check:checked+.checkout-button,
.cart-collaterals .btn-group>.btn-check:focus+.checkout-button,
.cart-collaterals .btn-group>.checkout-button:active,
.cart-collaterals .btn-group>.checkout-button:focus,
.cart-collaterals .btn-group>.checkout-button:hover,
.shop_table.cart .btn-group-vertical>.btn-check:checked+input.button,
.shop_table.cart .btn-group-vertical>.btn-check:focus+input.button,
.shop_table.cart .btn-group-vertical>input.active.button,
.shop_table.cart .btn-group-vertical>input.button:active,
.shop_table.cart .btn-group-vertical>input.button:focus,
.shop_table.cart .btn-group-vertical>input.button:hover,
.shop_table.cart .btn-group>.btn-check:checked+input.button,
.shop_table.cart .btn-group>.btn-check:focus+input.button,
.shop_table.cart .btn-group>input.active.button,
.shop_table.cart .btn-group>input.button:active,
.shop_table.cart .btn-group>input.button:focus,
.shop_table.cart .btn-group>input.button:hover,
form.woocommerce-checkout .place-order .btn-group-vertical>.active.button,
form.woocommerce-checkout .place-order .btn-group-vertical>.btn-check:checked+.button,
form.woocommerce-checkout .place-order .btn-group-vertical>.btn-check:focus+.button,
form.woocommerce-checkout .place-order .btn-group-vertical>.button:active,
form.woocommerce-checkout .place-order .btn-group-vertical>.button:focus,
form.woocommerce-checkout .place-order .btn-group-vertical>.button:hover,
form.woocommerce-checkout .place-order .btn-group>.active.button,
form.woocommerce-checkout .place-order .btn-group>.btn-check:checked+.button,
form.woocommerce-checkout .place-order .btn-group>.btn-check:focus+.button,
form.woocommerce-checkout .place-order .btn-group>.button:active,
form.woocommerce-checkout .place-order .btn-group>.button:focus,
form.woocommerce-checkout .place-order .btn-group>.button:hover {
	z-index: 1
}

.btn-toolbar {
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	-webkit-box-pack: start;
	justify-content: flex-start
}

.btn-toolbar .input-group {
	width: auto
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child),
.cart-collaterals .btn-group>.checkout-button:not(:first-child),
.shop_table.cart .btn-group>input.button:not(:first-child),
form.woocommerce-checkout .place-order .btn-group>.button:not(:first-child) {
	margin-left: -1px
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.cart-collaterals .btn-group>.btn-group:not(:last-child)>.checkout-button,
.cart-collaterals .btn-group>.checkout-button:not(:last-child):not(.dropdown-toggle),
.shop_table.cart .btn-group>.btn-group:not(:last-child)>input.button,
.shop_table.cart .btn-group>input.button:not(:last-child):not(.dropdown-toggle),
form.woocommerce-checkout .place-order .btn-group>.btn-group:not(:last-child)>.button,
form.woocommerce-checkout .place-order .btn-group>.button:not(:last-child):not(.dropdown-toggle) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:nth-child(n+3),
.btn-group>:not(.btn-check)+.btn,
.cart-collaterals .btn-group>.btn-group:not(:first-child)>.checkout-button,
.cart-collaterals .btn-group>.checkout-button:nth-child(n+3),
.cart-collaterals .btn-group>:not(.btn-check)+.checkout-button,
.shop_table.cart .btn-group>.btn-group:not(:first-child)>input.button,
.shop_table.cart .btn-group>:not(.btn-check)+input.button,
.shop_table.cart .btn-group>input.button:nth-child(n+3),
form.woocommerce-checkout .place-order .btn-group>.btn-group:not(:first-child)>.button,
form.woocommerce-checkout .place-order .btn-group>.button:nth-child(n+3),
form.woocommerce-checkout .place-order .btn-group>:not(.btn-check)+.button {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}

.dropdown-toggle-split {
	padding-right: .9375rem;
	padding-left: .9375rem
}

.dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
	margin-left: 0
}

.dropstart .dropdown-toggle-split::before {
	margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split,
.cart-collaterals .btn-group-sm>.checkout-button+.dropdown-toggle-split,
.shop_table.cart .btn-group-sm>input.button+.dropdown-toggle-split,
.shop_table.cart input.button+.dropdown-toggle-split,
form.woocommerce-checkout .place-order .btn-group-sm>.button+.dropdown-toggle-split {
	padding-right: .75rem;
	padding-left: .75rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split,
.cart-collaterals .btn-group-lg>.checkout-button+.dropdown-toggle-split,
.shop_table.cart .btn-group-lg>input.button+.dropdown-toggle-split,
form.woocommerce-checkout .place-order .btn-group-lg>.button+.dropdown-toggle-split {
	padding-right: 1.125rem;
	padding-left: 1.125rem
}

.btn-group.show .dropdown-toggle {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125)
}

.btn-group.show .dropdown-toggle.btn-link {
	box-shadow: none
}

.btn-group-vertical {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	-webkit-box-align: start;
	align-items: flex-start;
	-webkit-box-pack: center;
	justify-content: center
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group,
.cart-collaterals .btn-group-vertical>.checkout-button,
.shop_table.cart .btn-group-vertical>input.button,
form.woocommerce-checkout .place-order .btn-group-vertical>.button {
	width: 100%
}

.btn-group-vertical>.btn-group:not(:first-child),
.btn-group-vertical>.btn:not(:first-child),
.cart-collaterals .btn-group-vertical>.checkout-button:not(:first-child),
.shop_table.cart .btn-group-vertical>input.button:not(:first-child),
form.woocommerce-checkout .place-order .btn-group-vertical>.button:not(:first-child) {
	margin-top: -1px
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.cart-collaterals .btn-group-vertical>.btn-group:not(:last-child)>.checkout-button,
.cart-collaterals .btn-group-vertical>.checkout-button:not(:last-child):not(.dropdown-toggle),
.shop_table.cart .btn-group-vertical>.btn-group:not(:last-child)>input.button,
.shop_table.cart .btn-group-vertical>input.button:not(:last-child):not(.dropdown-toggle),
form.woocommerce-checkout .place-order .btn-group-vertical>.btn-group:not(:last-child)>.button,
form.woocommerce-checkout .place-order .btn-group-vertical>.button:not(:last-child):not(.dropdown-toggle) {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn~.btn,
.cart-collaterals .btn-group-vertical>.btn-group:not(:first-child)>.checkout-button,
.cart-collaterals .btn-group-vertical>.btn~.checkout-button,
.cart-collaterals .btn-group-vertical>.checkout-button~.btn,
.cart-collaterals .btn-group-vertical>.checkout-button~.checkout-button,
.cart-collaterals .shop_table.cart .btn-group-vertical>.checkout-button~input.button,
.cart-collaterals .shop_table.cart .btn-group-vertical>input.button~.checkout-button,
.cart-collaterals form.woocommerce-checkout .place-order .btn-group-vertical>.button~.checkout-button,
.cart-collaterals form.woocommerce-checkout .place-order .btn-group-vertical>.checkout-button~.button,
.shop_table.cart .btn-group-vertical>.btn-group:not(:first-child)>input.button,
.shop_table.cart .btn-group-vertical>.btn~input.button,
.shop_table.cart .btn-group-vertical>input.button~.btn,
.shop_table.cart .btn-group-vertical>input.button~input.button,
.shop_table.cart .cart-collaterals .btn-group-vertical>.checkout-button~input.button,
.shop_table.cart .cart-collaterals .btn-group-vertical>input.button~.checkout-button,
form.woocommerce-checkout .place-order .btn-group-vertical>.btn-group:not(:first-child)>.button,
form.woocommerce-checkout .place-order .btn-group-vertical>.btn~.button,
form.woocommerce-checkout .place-order .btn-group-vertical>.button~.btn,
form.woocommerce-checkout .place-order .btn-group-vertical>.button~.button,
form.woocommerce-checkout .place-order .cart-collaterals .btn-group-vertical>.button~.checkout-button,
form.woocommerce-checkout .place-order .cart-collaterals .btn-group-vertical>.checkout-button~.button,
form.woocommerce-checkout .place-order .shop_table.cart .btn-group-vertical>.button~input.button,
form.woocommerce-checkout .place-order .shop_table.cart .btn-group-vertical>input.button~.button {
	border-top-left-radius: 0;
	border-top-right-radius: 0
}

.nav {
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none
}

.nav-link {
	display: block;
	padding: 1.5rem 1rem;
	color: #090761;
	-webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.nav-link {
		-webkit-transition: none;
		transition: none
	}
}

.nav-link:focus,
.nav-link:hover {
	color: #090761
}

.nav-link.disabled {
	color: #8c98a4;
	pointer-events: none;
	cursor: default
}

.nav-tabs {
	border-bottom: 1px solid #d9e2ef
}

.nav-tabs .nav-link {
	margin-bottom: -1px;
	background: 0 0;
	border: 1px solid transparent;
	border-top-left-radius: .25rem;
	border-top-right-radius: .25rem
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
	border-color: #e7e7ec #e7e7ec #d9e2ef;
	isolation: isolate
}

.nav-tabs .nav-link.disabled {
	color: #8c98a4;
	background-color: transparent;
	border-color: transparent
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	color: #949da6;
	background-color: #f7f9fb;
	border-color: #d9e2ef #d9e2ef #f7f9fb
}

.nav-tabs .dropdown-menu {
	margin-top: -1px;
	border-top-left-radius: 0;
	border-top-right-radius: 0
}

.nav-pills .nav-link {
	background: 0 0;
	border: 0;
	border-radius: .25rem
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
	color: #fff;
	background-color: #090761
}

.nav-fill .nav-item,
.nav-fill>.nav-link {
	-webkit-box-flex: 1;
	flex: 1 1 auto;
	text-align: center
}

.nav-justified .nav-item,
.nav-justified>.nav-link {
	flex-basis: 0;
	-webkit-box-flex: 1;
	flex-grow: 1;
	text-align: center
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
	width: 100%
}

.tab-content>.tab-pane {
	display: none
}

.tab-content>.active {
	display: block
}

.navbar {
	position: relative;
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	justify-content: space-between;
	padding-top: 1.25rem;
	padding-right: 0;
	padding-bottom: 1.25rem;
	padding-left: 0
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl {
	display: -webkit-box;
	display: flex;
	flex-wrap: inherit;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	justify-content: space-between
}

.navbar-brand {
	padding-top: .4rem;
	padding-bottom: .4rem;
	margin-right: 1rem;
	font-size: calc(1.275rem + .3vw);
	white-space: nowrap
}

@media (min-width:1200px) {
	.navbar-brand {
		font-size: 1.5rem
	}
}

.navbar-nav {
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none
}

.navbar-nav .nav-link {
	padding-right: 0;
	padding-left: 0
}

.navbar-nav .dropdown-menu {
	position: static
}

.navbar-text {
	padding-top: 1.5rem;
	padding-bottom: 1.5rem
}

.navbar-collapse {
	flex-basis: 100%;
	-webkit-box-flex: 1;
	flex-grow: 1;
	-webkit-box-align: center;
	align-items: center
}

.navbar-toggler {
	padding: .25rem .75rem;
	font-size: 1.125rem;
	line-height: 1;
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: .25rem;
	-webkit-transition: box-shadow .15s ease-in-out;
	transition: box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.navbar-toggler {
		-webkit-transition: none;
		transition: none
	}
}

.navbar-toggler:hover {
	text-decoration: none
}

.navbar-toggler:focus {
	text-decoration: none;
	outline: 0;
	box-shadow: 0 0
}

.navbar-toggler-icon {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%
}

.navbar-nav-scroll {
	max-height: var(--bs-scroll-height, 75vh);
	overflow-y: auto
}

@media (min-width:576px) {
	.navbar-expand-sm {
		flex-wrap: nowrap;
		-webkit-box-pack: start;
		justify-content: flex-start
	}
	.navbar-expand-sm .navbar-nav {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-direction: row
	}
	.navbar-expand-sm .navbar-nav .dropdown-menu {
		position: absolute
	}
	.navbar-expand-sm .navbar-nav .nav-link {
		padding-right: 1.64rem;
		padding-left: 1.64rem
	}
	.navbar-expand-sm .navbar-nav-scroll {
		overflow: visible
	}
	.navbar-expand-sm .navbar-collapse {
		display: -webkit-box!important;
		display: flex!important;
		flex-basis: auto
	}
	.navbar-expand-sm .navbar-toggler {
		display: none
	}
	.navbar-expand-sm .offcanvas-header {
		display: none
	}
	.navbar-expand-sm .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		-webkit-box-flex: 1;
		flex-grow: 1;
		visibility: visible!important;
		background-color: transparent;
		border-right: 0;
		border-left: 0;
		-webkit-transition: none;
		transition: none;
		-webkit-transform: none;
		transform: none
	}
	.navbar-expand-sm .offcanvas-bottom,
	.navbar-expand-sm .offcanvas-top {
		height: auto;
		border-top: 0;
		border-bottom: 0
	}
	.navbar-expand-sm .offcanvas-body {
		display: -webkit-box;
		display: flex;
		-webkit-box-flex: 0;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible
	}
}

@media (min-width:768px) {
	.navbar-expand-md {
		flex-wrap: nowrap;
		-webkit-box-pack: start;
		justify-content: flex-start
	}
	.navbar-expand-md .navbar-nav {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-direction: row
	}
	.navbar-expand-md .navbar-nav .dropdown-menu {
		position: absolute
	}
	.navbar-expand-md .navbar-nav .nav-link {
		padding-right: 1.64rem;
		padding-left: 1.64rem
	}
	.navbar-expand-md .navbar-nav-scroll {
		overflow: visible
	}
	.navbar-expand-md .navbar-collapse {
		display: -webkit-box!important;
		display: flex!important;
		flex-basis: auto
	}
	.navbar-expand-md .navbar-toggler {
		display: none
	}
	.navbar-expand-md .offcanvas-header {
		display: none
	}
	.navbar-expand-md .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		-webkit-box-flex: 1;
		flex-grow: 1;
		visibility: visible!important;
		background-color: transparent;
		border-right: 0;
		border-left: 0;
		-webkit-transition: none;
		transition: none;
		-webkit-transform: none;
		transform: none
	}
	.navbar-expand-md .offcanvas-bottom,
	.navbar-expand-md .offcanvas-top {
		height: auto;
		border-top: 0;
		border-bottom: 0
	}
	.navbar-expand-md .offcanvas-body {
		display: -webkit-box;
		display: flex;
		-webkit-box-flex: 0;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible
	}
}

@media (min-width:992px) {
	.navbar-expand-lg {
		flex-wrap: nowrap;
		-webkit-box-pack: start;
		justify-content: flex-start
	}
	.navbar-expand-lg .navbar-nav {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-direction: row
	}
	.navbar-expand-lg .navbar-nav .dropdown-menu {
		position: absolute
	}
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: 1.64rem;
		padding-left: 1.64rem
	}
	.navbar-expand-lg .navbar-nav-scroll {
		overflow: visible
	}
	.navbar-expand-lg .navbar-collapse {
		display: -webkit-box!important;
		display: flex!important;
		flex-basis: auto
	}
	.navbar-expand-lg .navbar-toggler {
		display: none
	}
	.navbar-expand-lg .offcanvas-header {
		display: none
	}
	.navbar-expand-lg .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		-webkit-box-flex: 1;
		flex-grow: 1;
		visibility: visible!important;
		background-color: transparent;
		border-right: 0;
		border-left: 0;
		-webkit-transition: none;
		transition: none;
		-webkit-transform: none;
		transform: none
	}
	.navbar-expand-lg .offcanvas-bottom,
	.navbar-expand-lg .offcanvas-top {
		height: auto;
		border-top: 0;
		border-bottom: 0
	}
	.navbar-expand-lg .offcanvas-body {
		display: -webkit-box;
		display: flex;
		-webkit-box-flex: 0;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible
	}
}

@media (min-width:1200px) {
	.navbar-expand-xl {
		flex-wrap: nowrap;
		-webkit-box-pack: start;
		justify-content: flex-start
	}
	.navbar-expand-xl .navbar-nav {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-direction: row
	}
	.navbar-expand-xl .navbar-nav .dropdown-menu {
		position: absolute
	}
	.navbar-expand-xl .navbar-nav .nav-link {
		padding-right: 1.64rem;
		padding-left: 1.64rem
	}
	.navbar-expand-xl .navbar-nav-scroll {
		overflow: visible
	}
	.navbar-expand-xl .navbar-collapse {
		display: -webkit-box!important;
		display: flex!important;
		flex-basis: auto
	}
	.navbar-expand-xl .navbar-toggler {
		display: none
	}
	.navbar-expand-xl .offcanvas-header {
		display: none
	}
	.navbar-expand-xl .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		-webkit-box-flex: 1;
		flex-grow: 1;
		visibility: visible!important;
		background-color: transparent;
		border-right: 0;
		border-left: 0;
		-webkit-transition: none;
		transition: none;
		-webkit-transform: none;
		transform: none
	}
	.navbar-expand-xl .offcanvas-bottom,
	.navbar-expand-xl .offcanvas-top {
		height: auto;
		border-top: 0;
		border-bottom: 0
	}
	.navbar-expand-xl .offcanvas-body {
		display: -webkit-box;
		display: flex;
		-webkit-box-flex: 0;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible
	}
}

@media (min-width:1480px) {
	.navbar-expand-wd {
		flex-wrap: nowrap;
		-webkit-box-pack: start;
		justify-content: flex-start
	}
	.navbar-expand-wd .navbar-nav {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-direction: row
	}
	.navbar-expand-wd .navbar-nav .dropdown-menu {
		position: absolute
	}
	.navbar-expand-wd .navbar-nav .nav-link {
		padding-right: 1.64rem;
		padding-left: 1.64rem
	}
	.navbar-expand-wd .navbar-nav-scroll {
		overflow: visible
	}
	.navbar-expand-wd .navbar-collapse {
		display: -webkit-box!important;
		display: flex!important;
		flex-basis: auto
	}
	.navbar-expand-wd .navbar-toggler {
		display: none
	}
	.navbar-expand-wd .offcanvas-header {
		display: none
	}
	.navbar-expand-wd .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		-webkit-box-flex: 1;
		flex-grow: 1;
		visibility: visible!important;
		background-color: transparent;
		border-right: 0;
		border-left: 0;
		-webkit-transition: none;
		transition: none;
		-webkit-transform: none;
		transform: none
	}
	.navbar-expand-wd .offcanvas-bottom,
	.navbar-expand-wd .offcanvas-top {
		height: auto;
		border-top: 0;
		border-bottom: 0
	}
	.navbar-expand-wd .offcanvas-body {
		display: -webkit-box;
		display: flex;
		-webkit-box-flex: 0;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible
	}
}

.navbar-expand {
	flex-wrap: nowrap;
	-webkit-box-pack: start;
	justify-content: flex-start
}

.navbar-expand .navbar-nav {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
	position: absolute
}

.navbar-expand .navbar-nav .nav-link {
	padding-right: 1.64rem;
	padding-left: 1.64rem
}

.navbar-expand .navbar-nav-scroll {
	overflow: visible
}

.navbar-expand .navbar-collapse {
	display: -webkit-box!important;
	display: flex!important;
	flex-basis: auto
}

.navbar-expand .navbar-toggler {
	display: none
}

.navbar-expand .offcanvas-header {
	display: none
}

.navbar-expand .offcanvas {
	position: inherit;
	bottom: 0;
	z-index: 1000;
	-webkit-box-flex: 1;
	flex-grow: 1;
	visibility: visible!important;
	background-color: transparent;
	border-right: 0;
	border-left: 0;
	-webkit-transition: none;
	transition: none;
	-webkit-transform: none;
	transform: none
}

.navbar-expand .offcanvas-bottom,
.navbar-expand .offcanvas-top {
	height: auto;
	border-top: 0;
	border-bottom: 0
}

.navbar-expand .offcanvas-body {
	display: -webkit-box;
	display: flex;
	-webkit-box-flex: 0;
	flex-grow: 0;
	padding: 0;
	overflow-y: visible
}

.navbar-light .navbar-brand {
	color: #090761
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
	color: #090761
}

.navbar-light .navbar-nav .nav-link {
	color: #77838f
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
	color: #090761
}

.navbar-light .navbar-nav .nav-link.disabled {
	color: rgba(22, 28, 45, .3)
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link {
	color: #090761
}

.navbar-light .navbar-toggler {
	color: #77838f;
	border-color: transparent
}

.navbar-light .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%2377838F' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-light .navbar-text {
	color: #77838f
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
	color: #090761
}

.navbar-dark .navbar-brand {
	color: #fff
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
	color: #fff
}

.navbar-dark .navbar-nav .nav-link {
	color: #fff
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
	color: #fff
}

.navbar-dark .navbar-nav .nav-link.disabled {
	color: rgba(255, 255, 255, .25)
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show>.nav-link {
	color: #fff
}

.navbar-dark .navbar-toggler {
	color: #fff;
	border-color: transparent
}

.navbar-dark .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23FFFFFF' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-dark .navbar-text {
	color: #fff
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
	color: #fff
}

.card {
	position: relative;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 0 solid #e7e7ec;
	border-radius: .25rem
}

.card>hr {
	margin-right: 0;
	margin-left: 0
}

.card>.list-group {
	border-top: inherit;
	border-bottom: inherit
}

.card>.list-group:first-child {
	border-top-width: 0;
	border-top-left-radius: calc(.25rem - 0px);
	border-top-right-radius: calc(.25rem - 0px)
}

.card>.list-group:last-child {
	border-bottom-width: 0;
	border-bottom-right-radius: calc(.25rem - 0px);
	border-bottom-left-radius: calc(.25rem - 0px)
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
	border-top: 0
}

.card-body {
	-webkit-box-flex: 1;
	flex: 1 1 auto;
	padding: 1.5rem 2rem
}

.card-title {
	margin-bottom: .5rem
}

.card-subtitle {
	margin-top: -.25rem;
	margin-bottom: 0
}

.card-text:last-child {
	margin-bottom: 0
}

.card-link+.card-link {
	margin-left: 2rem
}

.card-header {
	padding: .75rem 2rem;
	margin-bottom: 0;
	background-color: rgba(22, 28, 45, .03);
	border-bottom: 0 solid #e7e7ec
}

.card-header:first-child {
	border-radius: calc(.25rem - 0px) calc(.25rem - 0px) 0 0
}

.card-footer {
	padding: .75rem 2rem;
	background-color: rgba(22, 28, 45, .03);
	border-top: 0 solid #e7e7ec
}

.card-footer:last-child {
	border-radius: 0 0 calc(.25rem - 0px) calc(.25rem - 0px)
}

.card-header-tabs {
	margin-right: -1rem;
	margin-bottom: -.75rem;
	margin-left: -1rem;
	border-bottom: 0
}

.card-header-tabs .nav-link.active {
	background-color: #fff;
	border-bottom-color: #fff
}

.card-header-pills {
	margin-right: -1rem;
	margin-left: -1rem
}

.card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1rem;
	border-radius: calc(.25rem - 0px)
}

.card-img,
.card-img-bottom,
.card-img-top {
	width: 100%
}

.card-img,
.card-img-top {
	border-top-left-radius: calc(.25rem - 0px);
	border-top-right-radius: calc(.25rem - 0px)
}

.card-img,
.card-img-bottom {
	border-bottom-right-radius: calc(.25rem - 0px);
	border-bottom-left-radius: calc(.25rem - 0px)
}

.card-group>.card {
	margin-bottom: 15px
}

@media (min-width:576px) {
	.card-group {
		display: -webkit-box;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-flow: row wrap
	}
	.card-group>.card {
		-webkit-box-flex: 1;
		flex: 1 0 0%;
		margin-bottom: 0
	}
	.card-group>.card+.card {
		margin-left: 0;
		border-left: 0
	}
	.card-group>.card:not(:last-child) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0
	}
	.card-group>.card:not(:last-child) .card-header,
	.card-group>.card:not(:last-child) .card-img-top {
		border-top-right-radius: 0
	}
	.card-group>.card:not(:last-child) .card-footer,
	.card-group>.card:not(:last-child) .card-img-bottom {
		border-bottom-right-radius: 0
	}
	.card-group>.card:not(:first-child) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0
	}
	.card-group>.card:not(:first-child) .card-header,
	.card-group>.card:not(:first-child) .card-img-top {
		border-top-left-radius: 0
	}
	.card-group>.card:not(:first-child) .card-footer,
	.card-group>.card:not(:first-child) .card-img-bottom {
		border-bottom-left-radius: 0
	}
}

.accordion-button {
	position: relative;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	width: 100%;
	padding: 1rem 1.25rem;
	font-size: 1rem;
	color: #77838f;
	text-align: left;
	background-color: #f7f9fb;
	border: 0;
	border-radius: 0;
	overflow-anchor: none;
	-webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease
}

@media (prefers-reduced-motion:reduce) {
	.accordion-button {
		-webkit-transition: none;
		transition: none
	}
}

.accordion-button:not(.collapsed) {
	color: #080657;
	background-color: #e6e6ef;
	box-shadow: inset 0 -1px 0 rgba(22, 28, 45, .125)
}

.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23080657'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	-webkit-transform: rotate(-180deg);
	transform: rotate(-180deg)
}

.accordion-button::after {
	flex-shrink: 0;
	width: 1.25rem;
	height: 1.25rem;
	margin-left: auto;
	content: "";
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2377838F'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-size: 1.25rem;
	-webkit-transition: -webkit-transform .2s ease-in-out;
	transition: -webkit-transform .2s ease-in-out;
	transition: transform .2s ease-in-out;
	transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.accordion-button::after {
		-webkit-transition: none;
		transition: none
	}
}

.accordion-button:hover {
	z-index: 2
}

.accordion-button:focus {
	z-index: 3;
	border-color: #090761;
	outline: 0;
	box-shadow: 0 0 0 0 transparent
}

.accordion-header {
	margin-bottom: 0
}

.accordion-item {
	background-color: #f7f9fb;
	border: 1px solid rgba(22, 28, 45, .125)
}

.accordion-item:first-of-type {
	border-top-left-radius: .25rem;
	border-top-right-radius: .25rem
}

.accordion-item:first-of-type .accordion-button {
	border-top-left-radius: calc(.25rem - 1px);
	border-top-right-radius: calc(.25rem - 1px)
}

.accordion-item:not(:first-of-type) {
	border-top: 0
}

.accordion-item:last-of-type {
	border-bottom-right-radius: .25rem;
	border-bottom-left-radius: .25rem
}

.accordion-item:last-of-type .accordion-button.collapsed {
	border-bottom-right-radius: calc(.25rem - 1px);
	border-bottom-left-radius: calc(.25rem - 1px)
}

.accordion-item:last-of-type .accordion-collapse {
	border-bottom-right-radius: .25rem;
	border-bottom-left-radius: .25rem
}

.accordion-body {
	padding: 1rem 1.25rem
}

.accordion-flush .accordion-collapse {
	border-width: 0
}

.accordion-flush .accordion-item {
	border-right: 0;
	border-left: 0;
	border-radius: 0
}

.accordion-flush .accordion-item:first-child {
	border-top: 0
}

.accordion-flush .accordion-item:last-child {
	border-bottom: 0
}

.accordion-flush .accordion-item .accordion-button {
	border-radius: 0
}

.breadcrumb {
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	padding: .75rem 0;
	margin-bottom: 0;
	font-size: .875rem;
	list-style: none;
	background-color: transparent;
	border-radius: 0
}

.breadcrumb-item+.breadcrumb-item {
	padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item::before {
	float: left;
	padding-right: .5rem;
	color: #949da6;
	content: var(--bs-breadcrumb-divider, "/")
}

.breadcrumb-item.active {
	color: #949da6
}

.pagination {
	display: -webkit-box;
	display: flex;
	padding-left: 0;
	list-style: none
}

.page-link {
	position: relative;
	display: block;
	color: #090761;
	background-color: #fff;
	border: 1px solid #d9e2ef;
	-webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.page-link {
		-webkit-transition: none;
		transition: none
	}
}

.page-link:hover {
	z-index: 2;
	color: #090761;
	background-color: #e7e7ec;
	border-color: #d9e2ef
}

.page-link:focus {
	z-index: 3;
	color: #090761;
	background-color: #e7e7ec;
	outline: 0;
	box-shadow: 0 0 0 0 transparent
}

.page-item:not(:first-child) .page-link {
	margin-left: -1px
}

.page-item.active .page-link {
	z-index: 3;
	color: #fff;
	background-color: #090761;
	border-color: #090761
}

.page-item.disabled .page-link {
	color: #8c98a4;
	pointer-events: none;
	background-color: #fff;
	border-color: #d9e2ef
}

.page-link {
	padding: 1rem 1.25rem
}

.page-item:first-child .page-link {
	border-top-left-radius: .25rem;
	border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
	border-top-right-radius: .25rem;
	border-bottom-right-radius: .25rem
}

.pagination-lg .page-link {
	padding: 1.122rem 1.5rem;
	font-size: 1.125rem
}

.pagination-lg .page-item:first-child .page-link {
	border-top-left-radius: .5rem;
	border-bottom-left-radius: .5rem
}

.pagination-lg .page-item:last-child .page-link {
	border-top-right-radius: .5rem;
	border-bottom-right-radius: .5rem
}

.pagination-sm .page-link {
	padding: .5625rem 1rem;
	font-size: .875rem
}

.pagination-sm .page-item:first-child .page-link {
	border-top-left-radius: .2rem;
	border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
	border-top-right-radius: .2rem;
	border-bottom-right-radius: .2rem
}

.badge {
	display: inline-block;
	padding: .25rem .75rem;
	font-size: .75em;
	font-weight: 700;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: .25rem
}

.badge:empty {
	display: none
}

.btn .badge,
.cart-collaterals .checkout-button .badge,
.shop_table.cart input.button .badge,
form.woocommerce-checkout .place-order .button .badge {
	position: relative;
	top: -1px
}

.alert {
	position: relative;
	padding: 1rem 1rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: .25rem
}

.alert-heading {
	color: inherit
}

.alert-link {
	font-weight: 700
}

.alert-dismissible {
	padding-right: 3rem
}

.alert-dismissible .btn-close {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	padding: 1.25rem 1rem
}

.alert-primary {
	color: #050431;
	background-color: #8483b0;
	border-color: #b5b5d0
}

.alert-primary .alert-link {
	color: #040327
}

.alert-secondary {
	color: #4a4f53;
	background-color: #caced3;
	border-color: #dfe2e4
}

.alert-secondary .alert-link {
	color: #3b3f42
}

.alert-primary-desat {
	color: #364576;
	background-color: #b6c5f6;
	border-color: #d3dcf9
}

.alert-primary-desat .alert-link {
	color: #2b375e
}

.alert-black {
	color: #0b0e17;
	background-color: #8b8e96;
	border-color: #b9bbc0
}

.alert-black .alert-link {
	color: #090b12
}

.alert-gray-200 {
	color: #747476;
	background-color: #f3f3f6;
	border-color: #f8f8f9
}

.alert-gray-200 .alert-link {
	color: #5d5d5e
}

.alert-gray-800 {
	color: #3c4248;
	background-color: #bbc1c7;
	border-color: #d6dadd
}

.alert-gray-800 .alert-link {
	color: #30353a
}

.alert-white {
	color: gray;
	background-color: #fff;
	border-color: #fff
}

.alert-white .alert-link {
	color: #666
}

.alert-dark {
	color: #181729;
	background-color: #9796a8;
	border-color: #c1c0cb
}

.alert-dark .alert-link {
	color: #131221
}

.alert-success {
	color: #6f796c;
	background-color: #eff9eb;
	border-color: #f5fbf3
}

.alert-success .alert-link {
	color: #596156
}

.alert-info {
	color: #67757b;
	background-color: #e6f4fb;
	border-color: #f0f8fc
}

.alert-info .alert-link {
	color: #525e62
}

.alert-warning {
	color: #7c7a6c;
	background-color: #fbf9eb;
	border-color: #fdfbf3
}

.alert-warning .alert-link {
	color: #636256
}

.alert-danger {
	color: #766463;
	background-color: #f6e4e2;
	border-color: #f9efee
}

.alert-danger .alert-link {
	color: #5e504f
}

.alert-light {
	color: #7c7d7e;
	background-color: #fbfcfd;
	border-color: #fdfdfe
}

.alert-light .alert-link {
	color: #636465
}

.alert-orange {
	color: #806446;
	background-color: #ffe3c5;
	border-color: #ffeedc
}

.alert-orange .alert-link {
	color: #665038
}

.alert-blue {
	color: #0d3767;
	background-color: #8cb7e6;
	border-color: #bad4f0
}

.alert-blue .alert-link {
	color: #0a2c52
}

.alert-purple {
	color: #5c597f;
	background-color: #dcd9fe;
	border-color: #eae8fe
}

.alert-purple .alert-link {
	color: #4a4766
}

.alert-teal {
	color: #254850;
	background-color: #a5c7cf;
	border-color: #c9dde2
}

.alert-teal .alert-link {
	color: #1e3a40
}

.alert-ice {
	color: #4e7b6e;
	background-color: #cefbed;
	border-color: #e1fcf4
}

.alert-ice .alert-link {
	color: #3e6258
}

.alert-cyan {
	color: #4f7a7b;
	background-color: #cff9fb;
	border-color: #e2fbfc
}

.alert-cyan .alert-link {
	color: #3f6262
}

.alert-helio {
	color: #592a7b;
	background-color: #d9a9fb;
	border-color: #e8cbfc
}

.alert-helio .alert-link {
	color: #472262
}

.alert-white-ice {
	color: #787f7c;
	background-color: #f8fefc;
	border-color: #fbfefd
}

.alert-white-ice .alert-link {
	color: #606663
}

.alert-tropaz {
	color: #17284a;
	background-color: #96a7c9;
	border-color: #c0cadf
}

.alert-tropaz .alert-link {
	color: #12203b
}

.alert-roman {
	color: #70342e;
	background-color: #f0b4ae;
	border-color: #f6d2ce
}

.alert-roman .alert-link {
	color: #5a2a25
}

.alert-tangerine {
	color: #804b3a;
	background-color: #ffcaba;
	border-color: #ffdfd5
}

.alert-tangerine .alert-link {
	color: #663c2e
}

.alert-gigas {
	color: #271b52;
	background-color: #a79ad1;
	border-color: #cac2e3
}

.alert-gigas .alert-link {
	color: #1f1642
}

.alert-lavender {
	color: #807679;
	background-color: #fff5f8;
	border-color: #fff9fb
}

.alert-lavender .alert-link {
	color: #665e61
}

.alert-sienna {
	color: #74321f;
	background-color: #f3b29f;
	border-color: #f8d1c5
}

.alert-sienna .alert-link {
	color: #5d2819
}

.alert-coral {
	color: #7f402f;
	background-color: #ffc0ae;
	border-color: #ffd9ce
}

.alert-coral .alert-link {
	color: #663326
}

.alert-catskill {
	color: #7d7e7e;
	background-color: #fcfdfe;
	border-color: #fdfefe
}

.alert-catskill .alert-link {
	color: #646565
}

.alert-bossanova {
	color: #231628;
	background-color: #a295a7;
	border-color: #c7bfca
}

.alert-bossanova .alert-link {
	color: #1c1220
}

.alert-denim {
	color: #0a3768;
	background-color: #8ab7e7;
	border-color: #b9d4f1
}

.alert-denim .alert-link {
	color: #082c53
}

.alert-turbo {
	color: #7f5c00;
	background-color: #ffdc80;
	border-color: #ffeab3
}

.alert-turbo .alert-link {
	color: #664a00
}

.alert-bright-sun {
	color: #806a1c;
	background-color: #ffea9c;
	border-color: #fff2c3
}

.alert-bright-sun .alert-link {
	color: #665516
}

.alert-shark {
	color: #141517;
	background-color: #949597;
	border-color: #bfbfc0
}

.alert-shark .alert-link {
	color: #101112
}

.alert-dodger {
	color: #1c3f80;
	background-color: #9bbeff;
	border-color: #c3d8ff
}

.alert-dodger .alert-link {
	color: #163266
}

.alert-cloud-burst {
	color: #11192e;
	background-color: #9099ad;
	border-color: #bcc2ce
}

.alert-cloud-burst .alert-link {
	color: #0e1425
}

.alert-porsche {
	color: #785936;
	background-color: #f8d9b5;
	border-color: #fbe8d3
}

.alert-porsche .alert-link {
	color: #60472b
}

.alert-biscay {
	color: #0c2032;
	background-color: #8c9fb1;
	border-color: #bac5d0
}

.alert-biscay .alert-link {
	color: #0a1a28
}

.alert-mongoose {
	color: #5f5344;
	background-color: #ded2c4;
	border-color: #ebe4db
}

.alert-mongoose .alert-link {
	color: #4c4236
}

.alert-ebony {
	color: #13141c;
	background-color: #92949b;
	border-color: #bebfc3
}

.alert-ebony .alert-link {
	color: #0f1016
}

.alert-alizarin {
	color: #760e12;
	background-color: #f58e92;
	border-color: #f9bbbd
}

.alert-alizarin .alert-link {
	color: #5e0b0e
}

.alert-chestnut {
	color: #682c26;
	background-color: #e7aba5;
	border-color: #f1cdc9
}

.alert-chestnut .alert-link {
	color: #53231e
}

.alert-portgore {
	color: #111021;
	background-color: #9090a0;
	border-color: #bcbcc6
}

.alert-portgore .alert-link {
	color: #0e0d1a
}

@-webkit-keyframes progress-bar-stripes {
	0% {
		background-position-x: 1rem
	}
}

@keyframes progress-bar-stripes {
	0% {
		background-position-x: 1rem
	}
}

.progress {
	display: -webkit-box;
	display: flex;
	height: 1rem;
	overflow: hidden;
	font-size: .75rem;
	background-color: #e7e7ec;
	border-radius: .25rem;
	box-shadow: inset 0 1px 2px rgba(22, 28, 45, .075)
}

.progress-bar {
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	-webkit-box-pack: center;
	justify-content: center;
	overflow: hidden;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	background-color: #090761;
	-webkit-transition: width .6s ease;
	transition: width .6s ease
}

@media (prefers-reduced-motion:reduce) {
	.progress-bar {
		-webkit-transition: none;
		transition: none
	}
}

.progress-bar-striped {
	background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
	background-size: 1rem 1rem
}

.progress-bar-animated {
	-webkit-animation: 1s linear infinite progress-bar-stripes;
	animation: 1s linear infinite progress-bar-stripes
}

@media (prefers-reduced-motion:reduce) {
	.progress-bar-animated {
		-webkit-animation: none;
		animation: none
	}
}

.list-group {
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	border-radius: .25rem
}

.list-group-numbered {
	list-style-type: none;
	counter-reset: section
}

.list-group-numbered>li::before {
	content: counters(section, ".") ". ";
	counter-increment: section
}

.list-group-item-action {
	width: 100%;
	color: #949da6;
	text-align: inherit
}

.list-group-item-action:focus,
.list-group-item-action:hover {
	z-index: 1;
	color: #949da6;
	text-decoration: none;
	background-color: #f7f9fb
}

.list-group-item-action:active {
	color: #77838f;
	background-color: #e7e7ec
}

.list-group-item {
	position: relative;
	display: block;
	padding: 1.5rem 2rem;
	color: #2f2d51;
	background-color: #fff;
	border: 1px solid #e7e7ec
}

.list-group-item:first-child {
	border-top-left-radius: inherit;
	border-top-right-radius: inherit
}

.list-group-item:last-child {
	border-bottom-right-radius: inherit;
	border-bottom-left-radius: inherit
}

.list-group-item.disabled,
.list-group-item:disabled {
	color: #8c98a4;
	pointer-events: none;
	background-color: #fff
}

.list-group-item.active {
	z-index: 2;
	color: #fff;
	background-color: #090761;
	border-color: #090761
}

.list-group-item+.list-group-item {
	border-top-width: 0
}

.list-group-item+.list-group-item.active {
	margin-top: -1px;
	border-top-width: 1px
}

.list-group-horizontal {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	flex-direction: row
}

.list-group-horizontal>.list-group-item:first-child {
	border-bottom-left-radius: .25rem;
	border-top-right-radius: 0
}

.list-group-horizontal>.list-group-item:last-child {
	border-top-right-radius: .25rem;
	border-bottom-left-radius: 0
}

.list-group-horizontal>.list-group-item.active {
	margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
	border-top-width: 1px;
	border-left-width: 0
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
	margin-left: -1px;
	border-left-width: 1px
}

@media (min-width:576px) {
	.list-group-horizontal-sm {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-direction: row
	}
	.list-group-horizontal-sm>.list-group-item:first-child {
		border-bottom-left-radius: .25rem;
		border-top-right-radius: 0
	}
	.list-group-horizontal-sm>.list-group-item:last-child {
		border-top-right-radius: .25rem;
		border-bottom-left-radius: 0
	}
	.list-group-horizontal-sm>.list-group-item.active {
		margin-top: 0
	}
	.list-group-horizontal-sm>.list-group-item+.list-group-item {
		border-top-width: 1px;
		border-left-width: 0
	}
	.list-group-horizontal-sm>.list-group-item+.list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px
	}
}

@media (min-width:768px) {
	.list-group-horizontal-md {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-direction: row
	}
	.list-group-horizontal-md>.list-group-item:first-child {
		border-bottom-left-radius: .25rem;
		border-top-right-radius: 0
	}
	.list-group-horizontal-md>.list-group-item:last-child {
		border-top-right-radius: .25rem;
		border-bottom-left-radius: 0
	}
	.list-group-horizontal-md>.list-group-item.active {
		margin-top: 0
	}
	.list-group-horizontal-md>.list-group-item+.list-group-item {
		border-top-width: 1px;
		border-left-width: 0
	}
	.list-group-horizontal-md>.list-group-item+.list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px
	}
}

@media (min-width:992px) {
	.list-group-horizontal-lg {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-direction: row
	}
	.list-group-horizontal-lg>.list-group-item:first-child {
		border-bottom-left-radius: .25rem;
		border-top-right-radius: 0
	}
	.list-group-horizontal-lg>.list-group-item:last-child {
		border-top-right-radius: .25rem;
		border-bottom-left-radius: 0
	}
	.list-group-horizontal-lg>.list-group-item.active {
		margin-top: 0
	}
	.list-group-horizontal-lg>.list-group-item+.list-group-item {
		border-top-width: 1px;
		border-left-width: 0
	}
	.list-group-horizontal-lg>.list-group-item+.list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px
	}
}

@media (min-width:1200px) {
	.list-group-horizontal-xl {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-direction: row
	}
	.list-group-horizontal-xl>.list-group-item:first-child {
		border-bottom-left-radius: .25rem;
		border-top-right-radius: 0
	}
	.list-group-horizontal-xl>.list-group-item:last-child {
		border-top-right-radius: .25rem;
		border-bottom-left-radius: 0
	}
	.list-group-horizontal-xl>.list-group-item.active {
		margin-top: 0
	}
	.list-group-horizontal-xl>.list-group-item+.list-group-item {
		border-top-width: 1px;
		border-left-width: 0
	}
	.list-group-horizontal-xl>.list-group-item+.list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px
	}
}

@media (min-width:1480px) {
	.list-group-horizontal-wd {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-direction: row
	}
	.list-group-horizontal-wd>.list-group-item:first-child {
		border-bottom-left-radius: .25rem;
		border-top-right-radius: 0
	}
	.list-group-horizontal-wd>.list-group-item:last-child {
		border-top-right-radius: .25rem;
		border-bottom-left-radius: 0
	}
	.list-group-horizontal-wd>.list-group-item.active {
		margin-top: 0
	}
	.list-group-horizontal-wd>.list-group-item+.list-group-item {
		border-top-width: 1px;
		border-left-width: 0
	}
	.list-group-horizontal-wd>.list-group-item+.list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px
	}
}

.list-group-flush {
	border-radius: 0
}

.list-group-flush>.list-group-item {
	border-width: 0 0 1px
}

.list-group-flush>.list-group-item:last-child {
	border-bottom-width: 0
}

.list-group-item-primary {
	color: #05043a;
	background-color: #cecddf
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
	color: #05043a;
	background-color: #b9b9c9
}

.list-group-item-primary.list-group-item-action.active {
	color: #fff;
	background-color: #05043a;
	border-color: #05043a
}

.list-group-item-secondary {
	color: #595e64;
	background-color: #eaebed
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
	color: #595e64;
	background-color: #d3d4d5
}

.list-group-item-secondary.list-group-item-action.active {
	color: #fff;
	background-color: #595e64;
	border-color: #595e64
}

.list-group-item-primary-desat {
	color: #41538e;
	background-color: #e2e8fb
}

.list-group-item-primary-desat.list-group-item-action:focus,
.list-group-item-primary-desat.list-group-item-action:hover {
	color: #41538e;
	background-color: #cbd1e2
}

.list-group-item-primary-desat.list-group-item-action.active {
	color: #fff;
	background-color: #41538e;
	border-color: #41538e
}

.list-group-item-black {
	color: #0d111b;
	background-color: #d0d2d5
}

.list-group-item-black.list-group-item-action:focus,
.list-group-item-black.list-group-item-action:hover {
	color: #0d111b;
	background-color: #bbbdc0
}

.list-group-item-black.list-group-item-action.active {
	color: #fff;
	background-color: #0d111b;
	border-color: #0d111b
}

.list-group-item-gray-200 {
	color: #6a6d79;
	background-color: #fafafb
}

.list-group-item-gray-200.list-group-item-action:focus,
.list-group-item-gray-200.list-group-item-action:hover {
	color: #6a6d79;
	background-color: #e1e1e2
}

.list-group-item-gray-200.list-group-item-action.active {
	color: #fff;
	background-color: #6a6d79;
	border-color: #6a6d79
}

.list-group-item-gray-800 {
	color: #474f56;
	background-color: #e4e6e9
}

.list-group-item-gray-800.list-group-item-action:focus,
.list-group-item-gray-800.list-group-item-action:hover {
	color: #474f56;
	background-color: #cdcfd2
}

.list-group-item-gray-800.list-group-item-action.active {
	color: #fff;
	background-color: #474f56;
	border-color: #474f56
}

.list-group-item-white {
	color: #737781;
	background-color: #fff
}

.list-group-item-white.list-group-item-action:focus,
.list-group-item-white.list-group-item-action:hover {
	color: #737781;
	background-color: #e6e6e6
}

.list-group-item-white.list-group-item-action.active {
	color: #fff;
	background-color: #737781;
	border-color: #737781
}

.list-group-item-dark {
	color: #1c1b31;
	background-color: #d5d5dc
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
	color: #1c1b31;
	background-color: #c0c0c6
}

.list-group-item-dark.list-group-item-action.active {
	color: #fff;
	background-color: #1c1b31;
	border-color: #1c1b31
}

.list-group-item-success {
	color: #667271;
	background-color: #f8fcf7
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
	color: #667271;
	background-color: #dfe3de
}

.list-group-item-success.list-group-item-action.active {
	color: #fff;
	background-color: #667271;
	border-color: #667271
}

.list-group-item-info {
	color: #5f6e7d;
	background-color: #f5fbfd
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
	color: #5f6e7d;
	background-color: #dde2e4
}

.list-group-item-info.list-group-item-action.active {
	color: #fff;
	background-color: #5f6e7d;
	border-color: #5f6e7d
}

.list-group-item-warning {
	color: #707271;
	background-color: #fdfdf7
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
	color: #707271;
	background-color: #e4e4de
}

.list-group-item-warning.list-group-item-action.active {
	color: #fff;
	background-color: #707271;
	border-color: #707271
}

.list-group-item-danger {
	color: #6c616a;
	background-color: #fbf4f3
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
	color: #6c616a;
	background-color: #e2dcdb
}

.list-group-item-danger.list-group-item-action.active {
	color: #fff;
	background-color: #6c616a;
	border-color: #6c616a
}

.list-group-item-light {
	color: #70747f;
	background-color: #fdfefe
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
	color: #70747f;
	background-color: #e4e5e5
}

.list-group-item-light.list-group-item-action.active {
	color: #fff;
	background-color: #70747f;
	border-color: #70747f
}

.list-group-item-orange {
	color: #736053;
	background-color: #fff4e8
}

.list-group-item-orange.list-group-item-action:focus,
.list-group-item-orange.list-group-item-action:hover {
	color: #736053;
	background-color: #e6dcd1
}

.list-group-item-orange.list-group-item-action.active {
	color: #fff;
	background-color: #736053;
	border-color: #736053
}

.list-group-item-blue {
	color: #0f427b;
	background-color: #d1e2f5
}

.list-group-item-blue.list-group-item-action:focus,
.list-group-item-blue.list-group-item-action:hover {
	color: #0f427b;
	background-color: #bccbdd
}

.list-group-item-blue.list-group-item-action.active {
	color: #fff;
	background-color: #0f427b;
	border-color: #0f427b
}

.list-group-item-purple {
	color: #6e6b98;
	background-color: #f1f0ff
}

.list-group-item-purple.list-group-item-action:focus,
.list-group-item-purple.list-group-item-action:hover {
	color: #6e6b98;
	background-color: #d9d8e6
}

.list-group-item-purple.list-group-item-action.active {
	color: #fff;
	background-color: #6e6b98;
	border-color: #6e6b98
}

.list-group-item-teal {
	color: #2c565f;
	background-color: #dbe9ec
}

.list-group-item-teal.list-group-item-action:focus,
.list-group-item-teal.list-group-item-action:hover {
	color: #2c565f;
	background-color: #c5d2d4
}

.list-group-item-teal.list-group-item-action.active {
	color: #fff;
	background-color: #2c565f;
	border-color: #2c565f
}

.list-group-item-ice {
	color: #4c7373;
	background-color: #ebfdf8
}

.list-group-item-ice.list-group-item-action:focus,
.list-group-item-ice.list-group-item-action:hover {
	color: #4c7373;
	background-color: #d4e4df
}

.list-group-item-ice.list-group-item-action.active {
	color: #fff;
	background-color: #4c7373;
	border-color: #4c7373
}

.list-group-item-cyan {
	color: #4c727d;
	background-color: #ecfdfd
}

.list-group-item-cyan.list-group-item-action:focus,
.list-group-item-cyan.list-group-item-action:hover {
	color: #4c727d;
	background-color: #d4e4e4
}

.list-group-item-cyan.list-group-item-action.active {
	color: #fff;
	background-color: #4c727d;
	border-color: #4c727d
}

.list-group-item-helio {
	color: #6b3294;
	background-color: #f0ddfd
}

.list-group-item-helio.list-group-item-action:focus,
.list-group-item-helio.list-group-item-action:hover {
	color: #6b3294;
	background-color: #d8c7e4
}

.list-group-item-helio.list-group-item-action.active {
	color: #fff;
	background-color: #6b3294;
	border-color: #6b3294
}

.list-group-item-white-ice {
	color: #6d767e;
	background-color: #fcfffe
}

.list-group-item-white-ice.list-group-item-action:focus,
.list-group-item-white-ice.list-group-item-action:hover {
	color: #6d767e;
	background-color: #e3e6e5
}

.list-group-item-white-ice.list-group-item-action.active {
	color: #fff;
	background-color: #6d767e;
	border-color: #6d767e
}

.list-group-item-tropaz {
	color: #1b2f58;
	background-color: #d5dce9
}

.list-group-item-tropaz.list-group-item-action:focus,
.list-group-item-tropaz.list-group-item-action:hover {
	color: #1b2f58;
	background-color: #c0c6d2
}

.list-group-item-tropaz.list-group-item-action.active {
	color: #fff;
	background-color: #1b2f58;
	border-color: #1b2f58
}

.list-group-item-roman {
	color: #863e37;
	background-color: #f9e1de
}

.list-group-item-roman.list-group-item-action:focus,
.list-group-item-roman.list-group-item-action:hover {
	color: #863e37;
	background-color: #e0cbc8
}

.list-group-item-roman.list-group-item-action.active {
	color: #fff;
	background-color: #863e37;
	border-color: #863e37
}

.list-group-item-tangerine {
	color: #995946;
	background-color: #ffeae3
}

.list-group-item-tangerine.list-group-item-action:focus,
.list-group-item-tangerine.list-group-item-action:hover {
	color: #995946;
	background-color: #e6d3cc
}

.list-group-item-tangerine.list-group-item-action.active {
	color: #fff;
	background-color: #995946;
	border-color: #995946
}

.list-group-item-gigas {
	color: #2f2062;
	background-color: #dcd7ed
}

.list-group-item-gigas.list-group-item-action:focus,
.list-group-item-gigas.list-group-item-action:hover {
	color: #2f2062;
	background-color: #c6c2d5
}

.list-group-item-gigas.list-group-item-action.active {
	color: #fff;
	background-color: #2f2062;
	border-color: #2f2062
}

.list-group-item-lavender {
	color: #736f7b;
	background-color: #fffbfc
}

.list-group-item-lavender.list-group-item-action:focus,
.list-group-item-lavender.list-group-item-action:hover {
	color: #736f7b;
	background-color: #e6e2e3
}

.list-group-item-lavender.list-group-item-action.active {
	color: #fff;
	background-color: #736f7b;
	border-color: #736f7b
}

.list-group-item-sienna {
	color: #8b3c25;
	background-color: #fae0d8
}

.list-group-item-sienna.list-group-item-action:focus,
.list-group-item-sienna.list-group-item-action:hover {
	color: #8b3c25;
	background-color: #e1cac2
}

.list-group-item-sienna.list-group-item-action.active {
	color: #fff;
	background-color: #8b3c25;
	border-color: #8b3c25
}

.list-group-item-coral {
	color: #984d38;
	background-color: #ffe6df
}

.list-group-item-coral.list-group-item-action:focus,
.list-group-item-coral.list-group-item-action:hover {
	color: #984d38;
	background-color: #e6cfc9
}

.list-group-item-coral.list-group-item-action.active {
	color: #fff;
	background-color: #984d38;
	border-color: #984d38
}

.list-group-item-catskill {
	color: #717580;
	background-color: #fefefe
}

.list-group-item-catskill.list-group-item-action:focus,
.list-group-item-catskill.list-group-item-action:hover {
	color: #717580;
	background-color: #e5e5e5
}

.list-group-item-catskill.list-group-item-action.active {
	color: #fff;
	background-color: #717580;
	border-color: #717580
}

.list-group-item-bossanova {
	color: #291a2f;
	background-color: #dad5dc
}

.list-group-item-bossanova.list-group-item-action:focus,
.list-group-item-bossanova.list-group-item-action:hover {
	color: #291a2f;
	background-color: #c4c0c6
}

.list-group-item-bossanova.list-group-item-action.active {
	color: #fff;
	background-color: #291a2f;
	border-color: #291a2f
}

.list-group-item-denim {
	color: #0c427c;
	background-color: #d0e2f5
}

.list-group-item-denim.list-group-item-action:focus,
.list-group-item-denim.list-group-item-action:hover {
	color: #0c427c;
	background-color: #bbcbdd
}

.list-group-item-denim.list-group-item-action.active {
	color: #fff;
	background-color: #0c427c;
	border-color: #0c427c
}

.list-group-item-turbo {
	color: #986e00;
	background-color: #fff1cc
}

.list-group-item-turbo.list-group-item-action:focus,
.list-group-item-turbo.list-group-item-action:hover {
	color: #986e00;
	background-color: #e6d9b8
}

.list-group-item-turbo.list-group-item-action.active {
	color: #fff;
	background-color: #986e00;
	border-color: #986e00
}

.list-group-item-bright-sun {
	color: #736631;
	background-color: #fff6d7
}

.list-group-item-bright-sun.list-group-item-action:focus,
.list-group-item-bright-sun.list-group-item-action:hover {
	color: #736631;
	background-color: #e6ddc2
}

.list-group-item-bright-sun.list-group-item-action.active {
	color: #fff;
	background-color: #736631;
	border-color: #736631
}

.list-group-item-shark {
	color: #18191c;
	background-color: #d4d4d5
}

.list-group-item-shark.list-group-item-action:focus,
.list-group-item-shark.list-group-item-action:hover {
	color: #18191c;
	background-color: #bfbfc0
}

.list-group-item-shark.list-group-item-action.active {
	color: #fff;
	background-color: #18191c;
	border-color: #18191c
}

.list-group-item-dodger {
	color: #214b99;
	background-color: #d7e5ff
}

.list-group-item-dodger.list-group-item-action:focus,
.list-group-item-dodger.list-group-item-action:hover {
	color: #214b99;
	background-color: #c2cee6
}

.list-group-item-dodger.list-group-item-action.active {
	color: #fff;
	background-color: #214b99;
	border-color: #214b99
}

.list-group-item-cloud-burst {
	color: #141e37;
	background-color: #d3d6de
}

.list-group-item-cloud-burst.list-group-item-action:focus,
.list-group-item-cloud-burst.list-group-item-action:hover {
	color: #141e37;
	background-color: #bec1c8
}

.list-group-item-cloud-burst.list-group-item-action.active {
	color: #fff;
	background-color: #141e37;
	border-color: #141e37
}

.list-group-item-porsche {
	color: #906b40;
	background-color: #fcf0e1
}

.list-group-item-porsche.list-group-item-action:focus,
.list-group-item-porsche.list-group-item-action:hover {
	color: #906b40;
	background-color: #e3d8cb
}

.list-group-item-porsche.list-group-item-action.active {
	color: #fff;
	background-color: #906b40;
	border-color: #906b40
}

.list-group-item-biscay {
	color: #0e263b;
	background-color: #d1d9e0
}

.list-group-item-biscay.list-group-item-action:focus,
.list-group-item-biscay.list-group-item-action:hover {
	color: #0e263b;
	background-color: #bcc3ca
}

.list-group-item-biscay.list-group-item-action.active {
	color: #fff;
	background-color: #0e263b;
	border-color: #0e263b
}

.list-group-item-mongoose {
	color: #716352;
	background-color: #f2ede7
}

.list-group-item-mongoose.list-group-item-action:focus,
.list-group-item-mongoose.list-group-item-action:hover {
	color: #716352;
	background-color: #dad5d0
}

.list-group-item-mongoose.list-group-item-action.active {
	color: #fff;
	background-color: #716352;
	border-color: #716352
}

.list-group-item-ebony {
	color: #161821;
	background-color: #d3d4d7
}

.list-group-item-ebony.list-group-item-action:focus,
.list-group-item-ebony.list-group-item-action:hover {
	color: #161821;
	background-color: #bebfc2
}

.list-group-item-ebony.list-group-item-action.active {
	color: #fff;
	background-color: #161821;
	border-color: #161821
}

.list-group-item-alizarin {
	color: #8d1116;
	background-color: #fbd2d3
}

.list-group-item-alizarin.list-group-item-action:focus,
.list-group-item-alizarin.list-group-item-action:hover {
	color: #8d1116;
	background-color: #e2bdbe
}

.list-group-item-alizarin.list-group-item-action.active {
	color: #fff;
	background-color: #8d1116;
	border-color: #8d1116
}

.list-group-item-chestnut {
	color: #7c342d;
	background-color: #f5dddb
}

.list-group-item-chestnut.list-group-item-action:focus,
.list-group-item-chestnut.list-group-item-action:hover {
	color: #7c342d;
	background-color: #ddc7c5
}

.list-group-item-chestnut.list-group-item-action.active {
	color: #fff;
	background-color: #7c342d;
	border-color: #7c342d
}

.list-group-item-portgore {
	color: #141327;
	background-color: #d3d2d9
}

.list-group-item-portgore.list-group-item-action:focus,
.list-group-item-portgore.list-group-item-action:hover {
	color: #141327;
	background-color: #bebdc3
}

.list-group-item-portgore.list-group-item-action.active {
	color: #fff;
	background-color: #141327;
	border-color: #141327
}

.btn-close {
	box-sizing: content-box;
	width: 1em;
	height: 1em;
	padding: .25em .25em;
	color: #161c2d;
	background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23161C2D'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
	border: 0;
	border-radius: .25rem;
	opacity: .5
}

.btn-close:hover {
	color: #161c2d;
	text-decoration: none;
	opacity: .75
}

.btn-close:focus {
	outline: 0;
	box-shadow: 0 0 0 0 transparent;
	opacity: 1
}

.btn-close.disabled,
.btn-close:disabled {
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	opacity: .25
}

.btn-close-white {
	-webkit-filter: invert(1) grayscale(100%) brightness(200%);
	filter: invert(1) grayscale(100%) brightness(200%)
}

.toast {
	width: 350px;
	max-width: 100%;
	font-size: .875rem;
	pointer-events: auto;
	background-color: rgba(255, 255, 255, .85);
	background-clip: padding-box;
	border: 1px solid rgba(22, 28, 45, .1);
	box-shadow: 0 .5rem .937rem rgba(140, 152, 164, .1);
	border-radius: .25rem
}

.toast.showing {
	opacity: 0
}

.toast:not(.show) {
	display: none
}

.toast-container {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	max-width: 100%;
	pointer-events: none
}

.toast-container>:not(:last-child) {
	margin-bottom: 15px
}

.toast-header {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	padding: .5rem .75rem;
	color: #8c98a4;
	background-color: rgba(255, 255, 255, .85);
	background-clip: padding-box;
	border-bottom: 1px solid rgba(22, 28, 45, .05);
	border-top-left-radius: calc(.25rem - 1px);
	border-top-right-radius: calc(.25rem - 1px)
}

.toast-header .btn-close {
	margin-right: -.375rem;
	margin-left: .75rem
}

.toast-body {
	padding: .75rem;
	word-wrap: break-word
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1055;
	display: none;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	outline: 0
}

.modal-dialog {
	position: relative;
	width: auto;
	margin: .5rem;
	pointer-events: none
}

.modal.fade .modal-dialog {
	-webkit-transition: -webkit-transform .3s ease-out;
	transition: -webkit-transform .3s ease-out;
	transition: transform .3s ease-out;
	transition: transform .3s ease-out, -webkit-transform .3s ease-out;
	-webkit-transform: translate(0, -50px);
	transform: translate(0, -50px)
}

@media (prefers-reduced-motion:reduce) {
	.modal.fade .modal-dialog {
		-webkit-transition: none;
		transition: none
	}
}

.modal.show .modal-dialog {
	-webkit-transform: none;
	transform: none
}

.modal.modal-static .modal-dialog {
	-webkit-transform: scale(1.02);
	transform: scale(1.02)
}

.modal-dialog-scrollable {
	height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
	max-height: 100%;
	overflow: hidden
}

.modal-dialog-scrollable .modal-body {
	overflow-y: auto
}

.modal-dialog-centered {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	min-height: calc(100% - 1rem)
}

.modal-content {
	position: relative;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(22, 28, 45, .2);
	border-radius: .5rem;
	box-shadow: 0 .125rem .25rem rgba(22, 28, 45, .075);
	outline: 0
}

.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1050;
	width: 100vw;
	height: 100vh;
	background-color: #161c2d
}

.modal-backdrop.fade {
	opacity: 0
}

.modal-backdrop.show {
	opacity: .8
}

.modal-header {
	display: -webkit-box;
	display: flex;
	flex-shrink: 0;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	justify-content: space-between;
	padding: 2.5rem 2.5rem;
	border-bottom: 1px solid #e7e7ec;
	border-top-left-radius: calc(.5rem - 1px);
	border-top-right-radius: calc(.5rem - 1px)
}

.modal-header .btn-close {
	padding: 1.25rem 1.25rem;
	margin: -1.25rem -1.25rem -1.25rem auto
}

.modal-title {
	margin-bottom: 0;
	line-height: 1.6
}

.modal-body {
	position: relative;
	-webkit-box-flex: 1;
	flex: 1 1 auto;
	padding: 2.5rem
}

.modal-footer {
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	flex-shrink: 0;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: end;
	justify-content: flex-end;
	padding: 2.25rem;
	border-top: 1px solid #e7e7ec;
	border-bottom-right-radius: calc(.5rem - 1px);
	border-bottom-left-radius: calc(.5rem - 1px)
}

.modal-footer>* {
	margin: .25rem
}

@media (min-width:576px) {
	.modal-dialog {
		max-width: 500px;
		margin: 1.75rem auto
	}
	.modal-dialog-scrollable {
		height: calc(100% - 3.5rem)
	}
	.modal-dialog-centered {
		min-height: calc(100% - 3.5rem)
	}
	.modal-content {
		box-shadow: 0 .5rem .937rem rgba(140, 152, 164, .1)
	}
	.modal-sm {
		max-width: 300px
	}
}

@media (min-width:992px) {
	.modal-lg,
	.modal-xl {
		max-width: 800px
	}
}

@media (min-width:1200px) {
	.modal-xl {
		max-width: 1140px
	}
}

.modal-fullscreen {
	width: 100vw;
	max-width: none;
	height: 100%;
	margin: 0
}

.modal-fullscreen .modal-content {
	height: 100%;
	border: 0;
	border-radius: 0
}

.modal-fullscreen .modal-header {
	border-radius: 0
}

.modal-fullscreen .modal-body {
	overflow-y: auto
}

.modal-fullscreen .modal-footer {
	border-radius: 0
}

@media (max-width:575.98px) {
	.modal-fullscreen-sm-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0
	}
	.modal-fullscreen-sm-down .modal-content {
		height: 100%;
		border: 0;
		border-radius: 0
	}
	.modal-fullscreen-sm-down .modal-header {
		border-radius: 0
	}
	.modal-fullscreen-sm-down .modal-body {
		overflow-y: auto
	}
	.modal-fullscreen-sm-down .modal-footer {
		border-radius: 0
	}
}

@media (max-width:767.98px) {
	.modal-fullscreen-md-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0
	}
	.modal-fullscreen-md-down .modal-content {
		height: 100%;
		border: 0;
		border-radius: 0
	}
	.modal-fullscreen-md-down .modal-header {
		border-radius: 0
	}
	.modal-fullscreen-md-down .modal-body {
		overflow-y: auto
	}
	.modal-fullscreen-md-down .modal-footer {
		border-radius: 0
	}
}

@media (max-width:991.98px) {
	.modal-fullscreen-lg-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0
	}
	.modal-fullscreen-lg-down .modal-content {
		height: 100%;
		border: 0;
		border-radius: 0
	}
	.modal-fullscreen-lg-down .modal-header {
		border-radius: 0
	}
	.modal-fullscreen-lg-down .modal-body {
		overflow-y: auto
	}
	.modal-fullscreen-lg-down .modal-footer {
		border-radius: 0
	}
}

@media (max-width:1199.98px) {
	.modal-fullscreen-xl-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0
	}
	.modal-fullscreen-xl-down .modal-content {
		height: 100%;
		border: 0;
		border-radius: 0
	}
	.modal-fullscreen-xl-down .modal-header {
		border-radius: 0
	}
	.modal-fullscreen-xl-down .modal-body {
		overflow-y: auto
	}
	.modal-fullscreen-xl-down .modal-footer {
		border-radius: 0
	}
}

@media (max-width:1479.98px) {
	.modal-fullscreen-wd-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0
	}
	.modal-fullscreen-wd-down .modal-content {
		height: 100%;
		border: 0;
		border-radius: 0
	}
	.modal-fullscreen-wd-down .modal-header {
		border-radius: 0
	}
	.modal-fullscreen-wd-down .modal-body {
		overflow-y: auto
	}
	.modal-fullscreen-wd-down .modal-footer {
		border-radius: 0
	}
}

.tooltip {
	position: absolute;
	z-index: 1080;
	display: block;
	margin: 0;
	font-family: Jost, sans-serif;
	font-style: normal;
	font-weight: 400;
	line-height: 1.6;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: .875rem;
	word-wrap: break-word;
	opacity: 0
}

.tooltip.show {
	opacity: .9
}

.tooltip .tooltip-arrow {
	position: absolute;
	display: block;
	width: .8rem;
	height: .4rem
}

.tooltip .tooltip-arrow::before {
	position: absolute;
	content: "";
	border-color: transparent;
	border-style: solid
}

.bs-tooltip-auto[data-popper-placement^=top],
.bs-tooltip-top {
	padding: .4rem 0
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
	bottom: 0
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
	top: -1px;
	border-width: .4rem .4rem 0;
	border-top-color: #161c2d
}

.bs-tooltip-auto[data-popper-placement^=right],
.bs-tooltip-end {
	padding: 0 .4rem
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
	left: 0;
	width: .4rem;
	height: .8rem
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
	right: -1px;
	border-width: .4rem .4rem .4rem 0;
	border-right-color: #161c2d
}

.bs-tooltip-auto[data-popper-placement^=bottom],
.bs-tooltip-bottom {
	padding: .4rem 0
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
	top: 0
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
	bottom: -1px;
	border-width: 0 .4rem .4rem;
	border-bottom-color: #161c2d
}

.bs-tooltip-auto[data-popper-placement^=left],
.bs-tooltip-start {
	padding: 0 .4rem
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
	right: 0;
	width: .4rem;
	height: .8rem
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
	left: -1px;
	border-width: .4rem 0 .4rem .4rem;
	border-left-color: #161c2d
}

.tooltip-inner {
	max-width: 200px;
	padding: .25rem .5rem;
	color: #fff;
	text-align: center;
	background-color: #161c2d;
	border-radius: .25rem
}

.popover {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1070;
	display: block;
	max-width: 380px;
	font-family: Jost, sans-serif;
	font-style: normal;
	font-weight: 400;
	line-height: 1.6;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: .875rem;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid transparent;
	border-radius: .25rem;
	box-shadow: 0 1.5rem 4rem rgba(140, 152, 164, .1)
}

.popover .popover-arrow {
	position: absolute;
	display: block;
	width: 1.25rem;
	height: .625rem
}

.popover .popover-arrow::after,
.popover .popover-arrow::before {
	position: absolute;
	display: block;
	content: "";
	border-color: transparent;
	border-style: solid
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow,
.bs-popover-top>.popover-arrow {
	bottom: calc(-.625rem - 1px)
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.bs-popover-top>.popover-arrow::before {
	bottom: 0;
	border-width: .625rem .625rem 0;
	border-top-color: rgba(0, 0, 0, .05)
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.bs-popover-top>.popover-arrow::after {
	bottom: 1px;
	border-width: .625rem .625rem 0;
	border-top-color: #fff
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow,
.bs-popover-end>.popover-arrow {
	left: calc(-.625rem - 1px);
	width: .625rem;
	height: 1.25rem
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.bs-popover-end>.popover-arrow::before {
	left: 0;
	border-width: .625rem .625rem .625rem 0;
	border-right-color: rgba(0, 0, 0, .05)
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.bs-popover-end>.popover-arrow::after {
	left: 1px;
	border-width: .625rem .625rem .625rem 0;
	border-right-color: #fff
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow,
.bs-popover-bottom>.popover-arrow {
	top: calc(-.625rem - 1px)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.bs-popover-bottom>.popover-arrow::before {
	top: 0;
	border-width: 0 .625rem .625rem .625rem;
	border-bottom-color: rgba(0, 0, 0, .05)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.bs-popover-bottom>.popover-arrow::after {
	top: 1px;
	border-width: 0 .625rem .625rem .625rem;
	border-bottom-color: #fff
}

.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
	position: absolute;
	top: 0;
	left: 50%;
	display: block;
	width: 1.25rem;
	margin-left: -.625rem;
	content: "";
	border-bottom: 1px solid transparent
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow,
.bs-popover-start>.popover-arrow {
	right: calc(-.625rem - 1px);
	width: .625rem;
	height: 1.25rem
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.bs-popover-start>.popover-arrow::before {
	right: 0;
	border-width: .625rem 0 .625rem .625rem;
	border-left-color: rgba(0, 0, 0, .05)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.bs-popover-start>.popover-arrow::after {
	right: 1px;
	border-width: .625rem 0 .625rem .625rem;
	border-left-color: #fff
}

.popover-header {
	padding: 1.5rem 2rem;
	margin-bottom: 0;
	font-size: 1rem;
	color: #090761;
	background-color: transparent;
	border-bottom: 1px solid transparent;
	border-top-left-radius: calc(.25rem - 1px);
	border-top-right-radius: calc(.25rem - 1px)
}

.popover-header:empty {
	display: none
}

.popover-body {
	padding: 1.5rem 2rem;
	color: #8c98a4
}

.carousel {
	position: relative
}

.carousel.pointer-event {
	touch-action: pan-y
}

.carousel-inner {
	position: relative;
	width: 100%;
	overflow: hidden
}

.carousel-inner::after {
	display: block;
	clear: both;
	content: ""
}

.carousel-item {
	position: relative;
	display: none;
	float: left;
	width: 100%;
	margin-right: -100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: -webkit-transform .6s ease-in-out;
	transition: -webkit-transform .6s ease-in-out;
	transition: transform .6s ease-in-out;
	transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.carousel-item {
		-webkit-transition: none;
		transition: none
	}
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
	display: block
}

.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
	-webkit-transform: translateX(100%);
	transform: translateX(100%)
}

.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%)
}

.carousel-fade .carousel-item {
	opacity: 0;
	-webkit-transition-property: opacity;
	transition-property: opacity;
	-webkit-transform: none;
	transform: none
}

.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
	z-index: 1;
	opacity: 1
}

.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
	z-index: 0;
	opacity: 0;
	-webkit-transition: opacity 0s .6s;
	transition: opacity 0s .6s
}

@media (prefers-reduced-motion:reduce) {
	.carousel-fade .active.carousel-item-end,
	.carousel-fade .active.carousel-item-start {
		-webkit-transition: none;
		transition: none
	}
}

.carousel-control-next,
.carousel-control-prev {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	width: 15%;
	padding: 0;
	color: #fff;
	text-align: center;
	background: 0 0;
	border: 0;
	opacity: .5;
	-webkit-transition: opacity .15s ease;
	transition: opacity .15s ease
}

@media (prefers-reduced-motion:reduce) {
	.carousel-control-next,
	.carousel-control-prev {
		-webkit-transition: none;
		transition: none
	}
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
	color: #fff;
	text-decoration: none;
	outline: 0;
	opacity: .9
}

.carousel-control-prev {
	left: 0
}

.carousel-control-next {
	right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: 100% 100%
}

.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.carousel-indicators {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	padding: 0;
	margin-right: 15%;
	margin-bottom: 1rem;
	margin-left: 15%;
	list-style: none
}

.carousel-indicators [data-bs-target] {
	box-sizing: content-box;
	-webkit-box-flex: 0;
	flex: 0 1 auto;
	width: 30px;
	height: 3px;
	padding: 0;
	margin-right: 3px;
	margin-left: 3px;
	text-indent: -999px;
	cursor: pointer;
	background-color: #fff;
	background-clip: padding-box;
	border: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	opacity: .5;
	-webkit-transition: opacity .6s ease;
	transition: opacity .6s ease
}

@media (prefers-reduced-motion:reduce) {
	.carousel-indicators [data-bs-target] {
		-webkit-transition: none;
		transition: none
	}
}

.carousel-indicators .active {
	opacity: 1
}

.carousel-caption {
	position: absolute;
	right: 15%;
	bottom: 1.25rem;
	left: 15%;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	color: #fff;
	text-align: center
}

.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
	-webkit-filter: invert(1) grayscale(100);
	filter: invert(1) grayscale(100)
}

.carousel-dark .carousel-indicators [data-bs-target] {
	background-color: #161c2d
}

.carousel-dark .carousel-caption {
	color: #161c2d
}

@-webkit-keyframes spinner-border {
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg)
	}
}

@keyframes spinner-border {
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg)
	}
}

.spinner-border {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: -.125em;
	border: .25em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	-webkit-animation: .75s linear infinite spinner-border;
	animation: .75s linear infinite spinner-border
}

.spinner-border-sm {
	width: 1rem;
	height: 1rem;
	border-width: .2em
}

@-webkit-keyframes spinner-grow {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0)
	}
	50% {
		opacity: 1;
		-webkit-transform: none;
		transform: none
	}
}

@keyframes spinner-grow {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0)
	}
	50% {
		opacity: 1;
		-webkit-transform: none;
		transform: none
	}
}

.spinner-grow {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: -.125em;
	background-color: currentColor;
	border-radius: 50%;
	opacity: 0;
	-webkit-animation: .75s linear infinite spinner-grow;
	animation: .75s linear infinite spinner-grow
}

.spinner-grow-sm {
	width: 1rem;
	height: 1rem
}

@media (prefers-reduced-motion:reduce) {
	.spinner-border,
	.spinner-grow {
		-webkit-animation-duration: 1.5s;
		animation-duration: 1.5s
	}
}

.offcanvas {
	position: fixed;
	bottom: 0;
	z-index: 1045;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	max-width: 100%;
	visibility: hidden;
	background-color: #fff;
	background-clip: padding-box;
	outline: 0;
	box-shadow: 0 .125rem .25rem rgba(22, 28, 45, .075);
	-webkit-transition: -webkit-transform .3s ease-in-out;
	transition: -webkit-transform .3s ease-in-out;
	transition: transform .3s ease-in-out;
	transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.offcanvas {
		-webkit-transition: none;
		transition: none
	}
}

.offcanvas-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: #161c2d
}

.offcanvas-backdrop.fade {
	opacity: 0
}

.offcanvas-backdrop.show {
	opacity: .8
}

.offcanvas-header {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	justify-content: space-between;
	padding: 2.5rem 2.5rem
}

.offcanvas-header .btn-close {
	padding: 1.25rem 1.25rem;
	margin-top: -1.25rem;
	margin-right: -1.25rem;
	margin-bottom: -1.25rem
}

.offcanvas-title {
	margin-bottom: 0;
	line-height: 1.6
}

.offcanvas-body {
	-webkit-box-flex: 1;
	flex-grow: 1;
	padding: 2.5rem 2.5rem;
	overflow-y: auto
}

.offcanvas-start {
	top: 0;
	left: 0;
	width: 400px;
	border-right: 1px solid rgba(22, 28, 45, .2);
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%)
}

.offcanvas-end {
	top: 0;
	right: 0;
	width: 400px;
	border-left: 1px solid rgba(22, 28, 45, .2);
	-webkit-transform: translateX(100%);
	transform: translateX(100%)
}

.offcanvas-top {
	top: 0;
	right: 0;
	left: 0;
	height: 30vh;
	max-height: 100%;
	border-bottom: 1px solid rgba(22, 28, 45, .2);
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%)
}

.offcanvas-bottom {
	right: 0;
	left: 0;
	height: 30vh;
	max-height: 100%;
	border-top: 1px solid rgba(22, 28, 45, .2);
	-webkit-transform: translateY(100%);
	transform: translateY(100%)
}

.offcanvas.show {
	-webkit-transform: none;
	transform: none
}

.placeholder {
	display: inline-block;
	min-height: 1em;
	vertical-align: middle;
	cursor: wait;
	background-color: currentColor;
	opacity: .5
}

.cart-collaterals .placeholder.checkout-button::before,
.placeholder.btn::before,
.shop_table.cart input.placeholder.button::before,
form.woocommerce-checkout .place-order .placeholder.button::before {
	display: inline-block;
	content: ""
}

.placeholder-xs {
	min-height: .6em
}

.placeholder-sm {
	min-height: .8em
}

.placeholder-lg {
	min-height: 1.2em
}

.placeholder-glow .placeholder {
	-webkit-animation: placeholder-glow 2s ease-in-out infinite;
	animation: placeholder-glow 2s ease-in-out infinite
}

@-webkit-keyframes placeholder-glow {
	50% {
		opacity: .2
	}
}

@keyframes placeholder-glow {
	50% {
		opacity: .2
	}
}

.placeholder-wave {
	-webkit-mask-image: linear-gradient(130deg, #161c2d 55%, rgba(0, 0, 0, .8) 75%, #161c2d 95%);
	mask-image: linear-gradient(130deg, #161c2d 55%, rgba(0, 0, 0, .8) 75%, #161c2d 95%);
	-webkit-mask-size: 200% 100%;
	mask-size: 200% 100%;
	-webkit-animation: placeholder-wave 2s linear infinite;
	animation: placeholder-wave 2s linear infinite
}

@-webkit-keyframes placeholder-wave {
	100% {
		-webkit-mask-position: -200% 0;
		mask-position: -200% 0
	}
}

@keyframes placeholder-wave {
	100% {
		-webkit-mask-position: -200% 0;
		mask-position: -200% 0
	}
}

.clearfix::after {
	display: block;
	clear: both;
	content: ""
}

.link-primary {
	color: #090761
}

.link-primary:focus,
.link-primary:hover {
	color: #07064e
}

.link-secondary {
	color: #949da6
}

.link-secondary:focus,
.link-secondary:hover {
	color: #a9b1b8
}

.link-primary-desat {
	color: #6c8aec
}

.link-primary-desat:focus,
.link-primary-desat:hover {
	color: #89a1f0
}

.link-black {
	color: #161c2d
}

.link-black:focus,
.link-black:hover {
	color: #121624
}

.link-gray-200 {
	color: #e7e7ec
}

.link-gray-200:focus,
.link-gray-200:hover {
	color: #ececf0
}

.link-gray-800 {
	color: #77838f
}

.link-gray-800:focus,
.link-gray-800:hover {
	color: #929ca5
}

.link-white {
	color: #fff
}

.link-white:focus,
.link-white:hover {
	color: #fff
}

.link-dark {
	color: #2f2d51
}

.link-dark:focus,
.link-dark:hover {
	color: #262441
}

.link-success {
	color: #def2d7
}

.link-success:focus,
.link-success:hover {
	color: #e5f5df
}

.link-info {
	color: #cde9f6
}

.link-info:focus,
.link-info:hover {
	color: #d7edf8
}

.link-warning {
	color: #f7f3d7
}

.link-warning:focus,
.link-warning:hover {
	color: #f9f5df
}

.link-danger {
	color: #ecc8c5
}

.link-danger:focus,
.link-danger:hover {
	color: #f0d3d1
}

.link-light {
	color: #f7f9fb
}

.link-light:focus,
.link-light:hover {
	color: #f9fafc
}

.link-orange {
	color: #ffc78b
}

.link-orange:focus,
.link-orange:hover {
	color: #ffd2a2
}

.link-blue {
	color: #196ecd
}

.link-blue:focus,
.link-blue:hover {
	color: #1458a4
}

.link-purple {
	color: #b8b2fd
}

.link-purple:focus,
.link-purple:hover {
	color: #c6c1fd
}

.link-teal {
	color: #4a8f9f
}

.link-teal:focus,
.link-teal:hover {
	color: #6ea5b2
}

.link-ice {
	color: #9cf6db
}

.link-ice:focus,
.link-ice:hover {
	color: #b0f8e2
}

.link-cyan {
	color: #9ef3f6
}

.link-cyan:focus,
.link-cyan:hover {
	color: #b1f5f8
}

.link-helio {
	color: #b253f6
}

.link-helio:focus,
.link-helio:hover {
	color: #c175f8
}

.link-white-ice {
	color: #f0fdf8
}

.link-white-ice:focus,
.link-white-ice:hover {
	color: #f3fdf9
}

.link-tropaz {
	color: #2d4f93
}

.link-tropaz:focus,
.link-tropaz:hover {
	color: #243f76
}

.link-roman {
	color: #e0685c
}

.link-roman:focus,
.link-roman:hover {
	color: #e6867d
}

.link-tangerine {
	color: #ff9574
}

.link-tangerine:focus,
.link-tangerine:hover {
	color: #ffaa90
}

.link-gigas {
	color: #4e35a3
}

.link-gigas:focus,
.link-gigas:hover {
	color: #3e2a82
}

.link-lavender {
	color: #ffebf1
}

.link-lavender:focus,
.link-lavender:hover {
	color: #ffeff4
}

.link-sienna {
	color: #e7643e
}

.link-sienna:focus,
.link-sienna:hover {
	color: #ec8365
}

.link-coral {
	color: #fe805d
}

.link-coral:focus,
.link-coral:hover {
	color: #fe997d
}

.link-catskill {
	color: #f9fbfc
}

.link-catskill:focus,
.link-catskill:hover {
	color: #fafcfd
}

.link-bossanova {
	color: #452b4f
}

.link-bossanova:focus,
.link-bossanova:hover {
	color: #37223f
}

.link-denim {
	color: #146ecf
}

.link-denim:focus,
.link-denim:hover {
	color: #1058a6
}

.link-turbo {
	color: #feb800
}

.link-turbo:focus,
.link-turbo:hover {
	color: #fec633
}

.link-bright-sun {
	color: #ffd438
}

.link-bright-sun:focus,
.link-bright-sun:hover {
	color: #ffdd60
}

.link-shark {
	color: #282a2e
}

.link-shark:focus,
.link-shark:hover {
	color: #202225
}

.link-dodger {
	color: #377dff
}

.link-dodger:focus,
.link-dodger:hover {
	color: #5f97ff
}

.link-cloud-burst {
	color: #21325b
}

.link-cloud-burst:focus,
.link-cloud-burst:hover {
	color: #1a2849
}

.link-porsche {
	color: #f0b26b
}

.link-porsche:focus,
.link-porsche:hover {
	color: #f3c189
}

.link-biscay {
	color: #183f63
}

.link-biscay:focus,
.link-biscay:hover {
	color: #13324f
}

.link-mongoose {
	color: #bda588
}

.link-mongoose:focus,
.link-mongoose:hover {
	color: #cab7a0
}

.link-ebony {
	color: #252837
}

.link-ebony:focus,
.link-ebony:hover {
	color: #1e202c
}

.link-alizarin {
	color: #eb1c24
}

.link-alizarin:focus,
.link-alizarin:hover {
	color: #bc161d
}

.link-chestnut {
	color: #cf574b
}

.link-chestnut:focus,
.link-chestnut:hover {
	color: #a6463c
}

.link-portgore {
	color: #212041
}

.link-portgore:focus,
.link-portgore:hover {
	color: #1a1a34
}

.ratio {
	position: relative;
	width: 100%
}

.ratio::before {
	display: block;
	padding-top: var(--bs-aspect-ratio);
	content: ""
}

.ratio>* {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%
}

.ratio-1x1 {
	--bs-aspect-ratio: 100%
}

.ratio-4x3 {
	--bs-aspect-ratio: calc(3 / 4 * 100%)
}

.ratio-16x9 {
	--bs-aspect-ratio: calc(9 / 16 * 100%)
}

.ratio-21x9 {
	--bs-aspect-ratio: calc(9 / 21 * 100%)
}

.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030
}

.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030
}

.sticky-top {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 1020
}

@media (min-width:576px) {
	.sticky-sm-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 1020
	}
}

@media (min-width:768px) {
	.sticky-md-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 1020
	}
}

@media (min-width:992px) {
	.sticky-lg-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 1020
	}
}

@media (min-width:1200px) {
	.sticky-xl-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 1020
	}
}

@media (min-width:1480px) {
	.sticky-wd-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 1020
	}
}

.hstack {
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	flex-direction: row;
	-webkit-box-align: center;
	align-items: center;
	align-self: stretch
}

.vstack {
	display: -webkit-box;
	display: flex;
	-webkit-box-flex: 1;
	flex: 1 1 auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	align-self: stretch
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
	position: absolute!important;
	width: 1px!important;
	height: 1px!important;
	padding: 0!important;
	margin: -1px!important;
	overflow: hidden!important;
	clip: rect(0, 0, 0, 0)!important;
	white-space: nowrap!important;
	border: 0!important
}

.stretched-link::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	content: ""
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap
}

.vr {
	display: inline-block;
	align-self: stretch;
	width: 1px;
	min-height: 1em;
	background-color: currentColor;
	opacity: .25
}

.align-baseline {
	vertical-align: baseline!important
}

.align-top {
	vertical-align: top!important
}

.align-middle {
	vertical-align: middle!important
}

.align-bottom {
	vertical-align: bottom!important
}

.align-text-bottom {
	vertical-align: text-bottom!important
}

.align-text-top {
	vertical-align: text-top!important
}

.float-start {
	float: left!important
}

.float-end {
	float: right!important
}

.float-none {
	float: none!important
}

.opacity-0 {
	opacity: 0!important
}

.opacity-25 {
	opacity: .25!important
}

.opacity-50 {
	opacity: .5!important
}

.opacity-75 {
	opacity: .75!important
}

.opacity-100 {
	opacity: 1!important
}

.overflow-auto {
	overflow: auto!important
}

.overflow-hidden {
	overflow: hidden!important
}

.overflow-visible {
	overflow: visible!important
}

.overflow-scroll {
	overflow: scroll!important
}

.d-inline {
	display: inline!important
}

.d-inline-block {
	display: inline-block!important
}

.d-block {
	display: block!important
}

.d-grid {
	display: grid!important
}

.d-table {
	display: table!important
}

.d-table-row {
	display: table-row!important
}

.d-table-cell {
	display: table-cell!important
}

.d-flex {
	display: -webkit-box!important;
	display: flex!important
}

.d-inline-flex {
	display: -webkit-inline-box!important;
	display: inline-flex!important
}

.d-none {
	display: none!important
}

.pagination .page-link,
.shadow {
	box-shadow: 0 .5rem .937rem rgba(140, 152, 164, .1)!important
}

.shadow-sm {
	box-shadow: 0 .125rem .25rem rgba(22, 28, 45, .075)!important
}

.shadow-lg {
	box-shadow: 0 1.5rem 4rem rgba(140, 152, 164, .1)!important
}

.shadow-none {
	box-shadow: none!important
}

.position-static {
	position: static!important
}

.position-relative {
	position: relative!important
}

.position-absolute {
	position: absolute!important
}

.position-fixed {
	position: fixed!important
}

.position-sticky {
	position: -webkit-sticky!important;
	position: sticky!important
}

.top-0 {
	top: 0!important
}

.top-50 {
	top: 50%!important
}

.top-100 {
	top: 100%!important
}

.bottom-0 {
	bottom: 0!important
}

.bottom-50 {
	bottom: 50%!important
}

.bottom-100 {
	bottom: 100%!important
}

.start-0 {
	left: 0!important
}

.start-50 {
	left: 50%!important
}

.start-100 {
	left: 100%!important
}

.end-0 {
	right: 0!important
}

.end-50 {
	right: 50%!important
}

.end-100 {
	right: 100%!important
}

.translate-middle {
	-webkit-transform: translate(-50%, -50%)!important;
	transform: translate(-50%, -50%)!important
}

.translate-middle-x {
	-webkit-transform: translateX(-50%)!important;
	transform: translateX(-50%)!important
}

.translate-middle-y {
	-webkit-transform: translateY(-50%)!important;
	transform: translateY(-50%)!important
}

.border {
	border: 1px solid #e7e7ec!important
}

.border-0 {
	border: 0!important
}

.border-top {
	border-top: 1px solid #e7e7ec!important
}

.border-top-0 {
	border-top: 0!important
}

.border-end {
	border-right: 1px solid #e7e7ec!important
}

.border-end-0 {
	border-right: 0!important
}

.border-bottom {
	border-bottom: 1px solid #e7e7ec!important
}

.border-bottom-0 {
	border-bottom: 0!important
}

.border-start {
	border-left: 1px solid #e7e7ec!important
}

.border-start-0 {
	border-left: 0!important
}

.border-primary {
	border-color: #090761!important
}

.border-secondary {
	border-color: #949da6!important
}

.border-primary-desat {
	border-color: #6c8aec!important
}

.border-black {
	border-color: #161c2d!important
}

.border-gray-200 {
	border-color: #e7e7ec!important
}

.border-gray-800 {
	border-color: #77838f!important
}

.border-white {
	border-color: #fff!important
}

.border-dark {
	border-color: #2f2d51!important
}

.border-success {
	border-color: #def2d7!important
}

.border-info {
	border-color: #cde9f6!important
}

.border-warning {
	border-color: #f7f3d7!important
}

.border-danger {
	border-color: #ecc8c5!important
}

.border-light {
	border-color: #f7f9fb!important
}

.border-orange {
	border-color: #ffc78b!important
}

.border-blue {
	border-color: #196ecd!important
}

.border-purple {
	border-color: #b8b2fd!important
}

.border-teal {
	border-color: #4a8f9f!important
}

.border-ice {
	border-color: #9cf6db!important
}

.border-cyan {
	border-color: #9ef3f6!important
}

.border-helio {
	border-color: #b253f6!important
}

.border-white-ice {
	border-color: #f0fdf8!important
}

.border-tropaz {
	border-color: #2d4f93!important
}

.border-roman {
	border-color: #e0685c!important
}

.border-tangerine {
	border-color: #ff9574!important
}

.border-gigas {
	border-color: #4e35a3!important
}

.border-lavender {
	border-color: #ffebf1!important
}

.border-sienna {
	border-color: #e7643e!important
}

.border-coral {
	border-color: #fe805d!important
}

.border-catskill {
	border-color: #f9fbfc!important
}

.border-bossanova {
	border-color: #452b4f!important
}

.border-denim {
	border-color: #146ecf!important
}

.border-turbo {
	border-color: #feb800!important
}

.border-bright-sun {
	border-color: #ffd438!important
}

.border-shark {
	border-color: #282a2e!important
}

.border-dodger {
	border-color: #377dff!important
}

.border-cloud-burst {
	border-color: #21325b!important
}

.border-porsche {
	border-color: #f0b26b!important
}

.border-biscay {
	border-color: #183f63!important
}

.border-mongoose {
	border-color: #bda588!important
}

.border-ebony {
	border-color: #252837!important
}

.border-alizarin {
	border-color: #eb1c24!important
}

.border-chestnut {
	border-color: #cf574b!important
}

.border-portgore {
	border-color: #212041!important
}

.border-1 {
	border-width: 1px!important
}

.border-2 {
	border-width: 2px!important
}

.border-3 {
	border-width: 3px!important
}

.border-4 {
	border-width: 4px!important
}

.border-5 {
	border-width: 5px!important
}

.w-25 {
	width: 25%!important
}

.w-50 {
	width: 50%!important
}

.w-75 {
	width: 75%!important
}

.w-100 {
	width: 100%!important
}

.w-auto {
	width: auto!important
}

.mw-100 {
	max-width: 100%!important
}

.vw-100 {
	width: 100vw!important
}

.min-vw-100 {
	min-width: 100vw!important
}

.h-25 {
	height: 25%!important
}

.h-50 {
	height: 50%!important
}

.h-75 {
	height: 75%!important
}

.h-100 {
	height: 100%!important
}

.h-auto {
	height: auto!important
}

.mh-100 {
	max-height: 100%!important
}

.vh-100 {
	height: 100vh!important
}

.min-vh-100 {
	min-height: 100vh!important
}

.flex-fill {
	-webkit-box-flex: 1!important;
	flex: 1 1 auto!important
}

.flex-row {
	-webkit-box-orient: horizontal!important;
	-webkit-box-direction: normal!important;
	flex-direction: row!important
}

.flex-column {
	-webkit-box-orient: vertical!important;
	-webkit-box-direction: normal!important;
	flex-direction: column!important
}

.flex-row-reverse {
	-webkit-box-orient: horizontal!important;
	-webkit-box-direction: reverse!important;
	flex-direction: row-reverse!important
}

.flex-column-reverse {
	-webkit-box-orient: vertical!important;
	-webkit-box-direction: reverse!important;
	flex-direction: column-reverse!important
}

.flex-grow-0 {
	-webkit-box-flex: 0!important;
	flex-grow: 0!important
}

.flex-grow-1 {
	-webkit-box-flex: 1!important;
	flex-grow: 1!important
}

.flex-shrink-0 {
	flex-shrink: 0!important
}

.flex-shrink-1 {
	flex-shrink: 1!important
}

.flex-wrap {
	flex-wrap: wrap!important
}

.flex-nowrap {
	flex-wrap: nowrap!important
}

.flex-wrap-reverse {
	flex-wrap: wrap-reverse!important
}

.gap-0 {
	gap: 0!important
}

.gap-1 {
	gap: .25rem!important
}

.gap-2 {
	gap: .5rem!important
}

.gap-3 {
	gap: .75rem!important
}

.gap-4 {
	gap: 1rem!important
}

.gap-5 {
	gap: 1.5rem!important
}

.gap-6 {
	gap: 2rem!important
}

.gap-7 {
	gap: 2.5rem!important
}

.gap-8 {
	gap: 3rem!important
}

.gap-9 {
	gap: 4rem!important
}

.gap-10 {
	gap: 5rem!important
}

.gap-11 {
	gap: 6rem!important
}

.gap-12 {
	gap: 8rem!important
}

.gap-13 {
	gap: 10rem!important
}

.gap-14 {
	gap: 12rem!important
}

.gap-15 {
	gap: 16rem!important
}

.gap-16 {
	gap: 25rem!important
}

.justify-content-start {
	-webkit-box-pack: start!important;
	justify-content: flex-start!important
}

.justify-content-end {
	-webkit-box-pack: end!important;
	justify-content: flex-end!important
}

.justify-content-center {
	-webkit-box-pack: center!important;
	justify-content: center!important
}

.justify-content-between {
	-webkit-box-pack: justify!important;
	justify-content: space-between!important
}

.justify-content-around {
	justify-content: space-around!important
}

.justify-content-evenly {
	-webkit-box-pack: space-evenly!important;
	justify-content: space-evenly!important
}

.align-items-start {
	-webkit-box-align: start!important;
	align-items: flex-start!important
}

.align-items-end {
	-webkit-box-align: end!important;
	align-items: flex-end!important
}

.align-items-center {
	-webkit-box-align: center!important;
	align-items: center!important
}

.align-items-baseline {
	-webkit-box-align: baseline!important;
	align-items: baseline!important
}

.align-items-stretch {
	-webkit-box-align: stretch!important;
	align-items: stretch!important
}

.align-content-start {
	align-content: flex-start!important
}

.align-content-end {
	align-content: flex-end!important
}

.align-content-center {
	align-content: center!important
}

.align-content-between {
	align-content: space-between!important
}

.align-content-around {
	align-content: space-around!important
}

.align-content-stretch {
	align-content: stretch!important
}

.align-self-auto {
	align-self: auto!important
}

.align-self-start {
	align-self: flex-start!important
}

.align-self-end {
	align-self: flex-end!important
}

.align-self-center {
	align-self: center!important
}

.align-self-baseline {
	align-self: baseline!important
}

.align-self-stretch {
	align-self: stretch!important
}

.order-first {
	-webkit-box-ordinal-group: 0!important;
	order: -1!important
}

.order-0 {
	-webkit-box-ordinal-group: 1!important;
	order: 0!important
}

.order-1 {
	-webkit-box-ordinal-group: 2!important;
	order: 1!important
}

.order-2 {
	-webkit-box-ordinal-group: 3!important;
	order: 2!important
}

.order-3 {
	-webkit-box-ordinal-group: 4!important;
	order: 3!important
}

.order-4 {
	-webkit-box-ordinal-group: 5!important;
	order: 4!important
}

.order-5 {
	-webkit-box-ordinal-group: 6!important;
	order: 5!important
}

.order-last {
	-webkit-box-ordinal-group: 7!important;
	order: 6!important
}

.m-0 {
	margin: 0!important
}

.m-1 {
	margin: .25rem!important
}

.m-2 {
	margin: .5rem!important
}

.m-3 {
	margin: .75rem!important
}

.m-4 {
	margin: 1rem!important
}

.m-5 {
	margin: 1.5rem!important
}

.m-6 {
	margin: 2rem!important
}

.m-7 {
	margin: 2.5rem!important
}

.m-8 {
	margin: 3rem!important
}

.m-9 {
	margin: 4rem!important
}

.m-10 {
	margin: 5rem!important
}

.m-11 {
	margin: 6rem!important
}

.m-12 {
	margin: 8rem!important
}

.m-13 {
	margin: 10rem!important
}

.m-14 {
	margin: 12rem!important
}

.m-15 {
	margin: 16rem!important
}

.m-16 {
	margin: 25rem!important
}

.m-auto {
	margin: auto!important
}

.mx-0 {
	margin-right: 0!important;
	margin-left: 0!important
}

.mx-1 {
	margin-right: .25rem!important;
	margin-left: .25rem!important
}

.mx-2 {
	margin-right: .5rem!important;
	margin-left: .5rem!important
}

.mx-3 {
	margin-right: .75rem!important;
	margin-left: .75rem!important
}

.mx-4 {
	margin-right: 1rem!important;
	margin-left: 1rem!important
}

.mx-5 {
	margin-right: 1.5rem!important;
	margin-left: 1.5rem!important
}

.mx-6 {
	margin-right: 2rem!important;
	margin-left: 2rem!important
}

.mx-7 {
	margin-right: 2.5rem!important;
	margin-left: 2.5rem!important
}

.mx-8 {
	margin-right: 3rem!important;
	margin-left: 3rem!important
}

.mx-9 {
	margin-right: 4rem!important;
	margin-left: 4rem!important
}

.mx-10 {
	margin-right: 5rem!important;
	margin-left: 5rem!important
}

.mx-11 {
	margin-right: 6rem!important;
	margin-left: 6rem!important
}

.mx-12 {
	margin-right: 8rem!important;
	margin-left: 8rem!important
}

.mx-13 {
	margin-right: 10rem!important;
	margin-left: 10rem!important
}

.mx-14 {
	margin-right: 12rem!important;
	margin-left: 12rem!important
}

.mx-15 {
	margin-right: 16rem!important;
	margin-left: 16rem!important
}

.mx-16 {
	margin-right: 25rem!important;
	margin-left: 25rem!important
}

.mx-auto {
	margin-right: auto!important;
	margin-left: auto!important
}

.my-0 {
	margin-top: 0!important;
	margin-bottom: 0!important
}

.my-1 {
	margin-top: .25rem!important;
	margin-bottom: .25rem!important
}

.my-2 {
	margin-top: .5rem!important;
	margin-bottom: .5rem!important
}

.my-3 {
	margin-top: .75rem!important;
	margin-bottom: .75rem!important
}

.my-4 {
	margin-top: 1rem!important;
	margin-bottom: 1rem!important
}

.my-5 {
	margin-top: 1.5rem!important;
	margin-bottom: 1.5rem!important
}

.my-6 {
	margin-top: 2rem!important;
	margin-bottom: 2rem!important
}

.my-7 {
	margin-top: 2.5rem!important;
	margin-bottom: 2.5rem!important
}

.my-8 {
	margin-top: 3rem!important;
	margin-bottom: 3rem!important
}

.my-9 {
	margin-top: 4rem!important;
	margin-bottom: 4rem!important
}

.my-10 {
	margin-top: 5rem!important;
	margin-bottom: 5rem!important
}

.my-11 {
	margin-top: 6rem!important;
	margin-bottom: 6rem!important
}

.my-12 {
	margin-top: 8rem!important;
	margin-bottom: 8rem!important
}

.my-13 {
	margin-top: 10rem!important;
	margin-bottom: 10rem!important
}

.my-14 {
	margin-top: 12rem!important;
	margin-bottom: 12rem!important
}

.my-15 {
	margin-top: 16rem!important;
	margin-bottom: 16rem!important
}

.my-16 {
	margin-top: 25rem!important;
	margin-bottom: 25rem!important
}

.my-auto {
	margin-top: auto!important;
	margin-bottom: auto!important
}

.mt-0 {
	margin-top: 0!important
}

.mt-1 {
	margin-top: .25rem!important
}

.mt-2 {
	margin-top: .5rem!important
}

.mt-3 {
	margin-top: .75rem!important
}

.mt-4 {
	margin-top: 1rem!important
}

.mt-5 {
	margin-top: 1.5rem!important
}

.mt-6 {
	margin-top: 2rem!important
}

.mt-7 {
	margin-top: 2.5rem!important
}

.mt-8 {
	margin-top: 3rem!important
}

.mt-9 {
	margin-top: 4rem!important
}

.mt-10 {
	margin-top: 5rem!important
}

.mt-11 {
	margin-top: 6rem!important
}

.mt-12 {
	margin-top: 8rem!important
}

.mt-13 {
	margin-top: 10rem!important
}

.mt-14 {
	margin-top: 12rem!important
}

.mt-15 {
	margin-top: 16rem!important
}

.mt-16 {
	margin-top: 25rem!important
}

.mt-auto {
	margin-top: auto!important
}

.me-0 {
	margin-right: 0!important
}

.me-1 {
	margin-right: .25rem!important
}

.me-2 {
	margin-right: .5rem!important
}

.me-3 {
	margin-right: .75rem!important
}

.me-4 {
	margin-right: 1rem!important
}

.me-5 {
	margin-right: 1.5rem!important
}

.me-6 {
	margin-right: 2rem!important
}

.me-7 {
	margin-right: 2.5rem!important
}

.me-8 {
	margin-right: 3rem!important
}

.me-9 {
	margin-right: 4rem!important
}

.me-10 {
	margin-right: 5rem!important
}

.me-11 {
	margin-right: 6rem!important
}

.me-12 {
	margin-right: 8rem!important
}

.me-13 {
	margin-right: 10rem!important
}

.me-14 {
	margin-right: 12rem!important
}

.me-15 {
	margin-right: 16rem!important
}

.me-16 {
	margin-right: 25rem!important
}

.me-auto {
	margin-right: auto!important
}

.mb-0 {
	margin-bottom: 0!important
}

.mb-1 {
	margin-bottom: .25rem!important
}

.mb-2 {
	margin-bottom: .5rem!important
}

.mb-3 {
	margin-bottom: .75rem!important
}

.mb-4 {
	margin-bottom: 1rem!important
}

.mb-5 {
	margin-bottom: 1.5rem!important
}

.mb-6 {
	margin-bottom: 2rem!important
}

.mb-7 {
	margin-bottom: 2.5rem!important
}

.mb-8 {
	margin-bottom: 3rem!important
}

.mb-9 {
	margin-bottom: 4rem!important
}

.mb-10 {
	margin-bottom: 5rem!important
}

.mb-11 {
	margin-bottom: 6rem!important
}

.mb-12 {
	margin-bottom: 8rem!important
}

.mb-13 {
	margin-bottom: 10rem!important
}

.mb-14 {
	margin-bottom: 12rem!important
}

.mb-15 {
	margin-bottom: 16rem!important
}

.mb-16 {
	margin-bottom: 25rem!important
}

.mb-auto {
	margin-bottom: auto!important
}

.ms-0 {
	margin-left: 0!important
}

.ms-1 {
	margin-left: .25rem!important
}

.ms-2 {
	margin-left: .5rem!important
}

.ms-3 {
	margin-left: .75rem!important
}

.ms-4 {
	margin-left: 1rem!important
}

.ms-5 {
	margin-left: 1.5rem!important
}

.ms-6 {
	margin-left: 2rem!important
}

.ms-7 {
	margin-left: 2.5rem!important
}

.ms-8 {
	margin-left: 3rem!important
}

.ms-9 {
	margin-left: 4rem!important
}

.ms-10 {
	margin-left: 5rem!important
}

.ms-11 {
	margin-left: 6rem!important
}

.ms-12 {
	margin-left: 8rem!important
}

.ms-13 {
	margin-left: 10rem!important
}

.ms-14 {
	margin-left: 12rem!important
}

.ms-15 {
	margin-left: 16rem!important
}

.ms-16 {
	margin-left: 25rem!important
}

.ms-auto {
	margin-left: auto!important
}

.m-n1 {
	margin: -.25rem!important
}

.m-n2 {
	margin: -.5rem!important
}

.m-n3 {
	margin: -.75rem!important
}

.m-n4 {
	margin: -1rem!important
}

.m-n5 {
	margin: -1.5rem!important
}

.m-n6 {
	margin: -2rem!important
}

.m-n7 {
	margin: -2.5rem!important
}

.m-n8 {
	margin: -3rem!important
}

.m-n9 {
	margin: -4rem!important
}

.m-n10 {
	margin: -5rem!important
}

.m-n11 {
	margin: -6rem!important
}

.m-n12 {
	margin: -8rem!important
}

.m-n13 {
	margin: -10rem!important
}

.m-n14 {
	margin: -12rem!important
}

.m-n15 {
	margin: -16rem!important
}

.m-n16 {
	margin: -25rem!important
}

.mx-n1 {
	margin-right: -.25rem!important;
	margin-left: -.25rem!important
}

.mx-n2 {
	margin-right: -.5rem!important;
	margin-left: -.5rem!important
}

.mx-n3 {
	margin-right: -.75rem!important;
	margin-left: -.75rem!important
}

.mx-n4 {
	margin-right: -1rem!important;
	margin-left: -1rem!important
}

.mx-n5 {
	margin-right: -1.5rem!important;
	margin-left: -1.5rem!important
}

.mx-n6 {
	margin-right: -2rem!important;
	margin-left: -2rem!important
}

.mx-n7 {
	margin-right: -2.5rem!important;
	margin-left: -2.5rem!important
}

.mx-n8 {
	margin-right: -3rem!important;
	margin-left: -3rem!important
}

.mx-n9 {
	margin-right: -4rem!important;
	margin-left: -4rem!important
}

.mx-n10 {
	margin-right: -5rem!important;
	margin-left: -5rem!important
}

.mx-n11 {
	margin-right: -6rem!important;
	margin-left: -6rem!important
}

.mx-n12 {
	margin-right: -8rem!important;
	margin-left: -8rem!important
}

.mx-n13 {
	margin-right: -10rem!important;
	margin-left: -10rem!important
}

.mx-n14 {
	margin-right: -12rem!important;
	margin-left: -12rem!important
}

.mx-n15 {
	margin-right: -16rem!important;
	margin-left: -16rem!important
}

.mx-n16 {
	margin-right: -25rem!important;
	margin-left: -25rem!important
}

.my-n1 {
	margin-top: -.25rem!important;
	margin-bottom: -.25rem!important
}

.my-n2 {
	margin-top: -.5rem!important;
	margin-bottom: -.5rem!important
}

.my-n3 {
	margin-top: -.75rem!important;
	margin-bottom: -.75rem!important
}

.my-n4 {
	margin-top: -1rem!important;
	margin-bottom: -1rem!important
}

.my-n5 {
	margin-top: -1.5rem!important;
	margin-bottom: -1.5rem!important
}

.my-n6 {
	margin-top: -2rem!important;
	margin-bottom: -2rem!important
}

.my-n7 {
	margin-top: -2.5rem!important;
	margin-bottom: -2.5rem!important
}

.my-n8 {
	margin-top: -3rem!important;
	margin-bottom: -3rem!important
}

.my-n9 {
	margin-top: -4rem!important;
	margin-bottom: -4rem!important
}

.my-n10 {
	margin-top: -5rem!important;
	margin-bottom: -5rem!important
}

.my-n11 {
	margin-top: -6rem!important;
	margin-bottom: -6rem!important
}

.my-n12 {
	margin-top: -8rem!important;
	margin-bottom: -8rem!important
}

.my-n13 {
	margin-top: -10rem!important;
	margin-bottom: -10rem!important
}

.my-n14 {
	margin-top: -12rem!important;
	margin-bottom: -12rem!important
}

.my-n15 {
	margin-top: -16rem!important;
	margin-bottom: -16rem!important
}

.my-n16 {
	margin-top: -25rem!important;
	margin-bottom: -25rem!important
}

.mt-n1 {
	margin-top: -.25rem!important
}

.mt-n2 {
	margin-top: -.5rem!important
}

.mt-n3 {
	margin-top: -.75rem!important
}

.mt-n4 {
	margin-top: -1rem!important
}

.mt-n5 {
	margin-top: -1.5rem!important
}

.mt-n6 {
	margin-top: -2rem!important
}

.mt-n7 {
	margin-top: -2.5rem!important
}

.mt-n8 {
	margin-top: -3rem!important
}

.mt-n9 {
	margin-top: -4rem!important
}

.mt-n10 {
	margin-top: -5rem!important
}

.mt-n11 {
	margin-top: -6rem!important
}

.mt-n12 {
	margin-top: -8rem!important
}

.mt-n13 {
	margin-top: -10rem!important
}

.mt-n14 {
	margin-top: -12rem!important
}

.mt-n15 {
	margin-top: -16rem!important
}

.mt-n16 {
	margin-top: -25rem!important
}

.me-n1 {
	margin-right: -.25rem!important
}

.me-n2 {
	margin-right: -.5rem!important
}

.me-n3 {
	margin-right: -.75rem!important
}

.me-n4 {
	margin-right: -1rem!important
}

.me-n5 {
	margin-right: -1.5rem!important
}

.me-n6 {
	margin-right: -2rem!important
}

.me-n7 {
	margin-right: -2.5rem!important
}

.me-n8 {
	margin-right: -3rem!important
}

.me-n9 {
	margin-right: -4rem!important
}

.me-n10 {
	margin-right: -5rem!important
}

.me-n11 {
	margin-right: -6rem!important
}

.me-n12 {
	margin-right: -8rem!important
}

.me-n13 {
	margin-right: -10rem!important
}

.me-n14 {
	margin-right: -12rem!important
}

.me-n15 {
	margin-right: -16rem!important
}

.me-n16 {
	margin-right: -25rem!important
}

.mb-n1 {
	margin-bottom: -.25rem!important
}

.mb-n2 {
	margin-bottom: -.5rem!important
}

.mb-n3 {
	margin-bottom: -.75rem!important
}

.mb-n4 {
	margin-bottom: -1rem!important
}

.mb-n5 {
	margin-bottom: -1.5rem!important
}

.mb-n6 {
	margin-bottom: -2rem!important
}

.mb-n7 {
	margin-bottom: -2.5rem!important
}

.mb-n8 {
	margin-bottom: -3rem!important
}

.mb-n9 {
	margin-bottom: -4rem!important
}

.mb-n10 {
	margin-bottom: -5rem!important
}

.mb-n11 {
	margin-bottom: -6rem!important
}

.mb-n12 {
	margin-bottom: -8rem!important
}

.mb-n13 {
	margin-bottom: -10rem!important
}

.mb-n14 {
	margin-bottom: -12rem!important
}

.mb-n15 {
	margin-bottom: -16rem!important
}

.mb-n16 {
	margin-bottom: -25rem!important
}

.ms-n1 {
	margin-left: -.25rem!important
}

.ms-n2 {
	margin-left: -.5rem!important
}

.ms-n3 {
	margin-left: -.75rem!important
}

.ms-n4 {
	margin-left: -1rem!important
}

.ms-n5 {
	margin-left: -1.5rem!important
}

.ms-n6 {
	margin-left: -2rem!important
}

.ms-n7 {
	margin-left: -2.5rem!important
}

.ms-n8 {
	margin-left: -3rem!important
}

.ms-n9 {
	margin-left: -4rem!important
}

.ms-n10 {
	margin-left: -5rem!important
}

.ms-n11 {
	margin-left: -6rem!important
}

.ms-n12 {
	margin-left: -8rem!important
}

.ms-n13 {
	margin-left: -10rem!important
}

.ms-n14 {
	margin-left: -12rem!important
}

.ms-n15 {
	margin-left: -16rem!important
}

.ms-n16 {
	margin-left: -25rem!important
}

.p-0 {
	padding: 0!important
}

.p-1 {
	padding: .25rem!important
}

.p-2 {
	padding: .5rem!important
}

.p-3 {
	padding: .75rem!important
}

.p-4 {
	padding: 1rem!important
}

.p-5 {
	padding: 1.5rem!important
}

.p-6 {
	padding: 2rem!important
}

.p-7 {
	padding: 2.5rem!important
}

.p-8 {
	padding: 3rem!important
}

.p-9 {
	padding: 4rem!important
}

.p-10 {
	padding: 5rem!important
}

.p-11 {
	padding: 6rem!important
}

.p-12 {
	padding: 8rem!important
}

.p-13 {
	padding: 10rem!important
}

.p-14 {
	padding: 12rem!important
}

.p-15 {
	padding: 16rem!important
}

.p-16 {
	padding: 25rem!important
}

.px-0 {
	padding-right: 0!important;
	padding-left: 0!important
}

.px-1 {
	padding-right: .25rem!important;
	padding-left: .25rem!important
}

.px-2 {
	padding-right: .5rem!important;
	padding-left: .5rem!important
}

.px-3 {
	padding-right: .75rem!important;
	padding-left: .75rem!important
}

.px-4 {
	padding-right: 1rem!important;
	padding-left: 1rem!important
}

.px-5 {
	padding-right: 1.5rem!important;
	padding-left: 1.5rem!important
}

.px-6 {
	padding-right: 2rem!important;
	padding-left: 2rem!important
}

.px-7 {
	padding-right: 2.5rem!important;
	padding-left: 2.5rem!important
}

.px-8 {
	padding-right: 3rem!important;
	padding-left: 3rem!important
}

.px-9 {
	padding-right: 4rem!important;
	padding-left: 4rem!important
}

.px-10 {
	padding-right: 5rem!important;
	padding-left: 5rem!important
}

.px-11 {
	padding-right: 6rem!important;
	padding-left: 6rem!important
}

.px-12 {
	padding-right: 8rem!important;
	padding-left: 8rem!important
}

.px-13 {
	padding-right: 10rem!important;
	padding-left: 10rem!important
}

.px-14 {
	padding-right: 12rem!important;
	padding-left: 12rem!important
}

.px-15 {
	padding-right: 16rem!important;
	padding-left: 16rem!important
}

.px-16 {
	padding-right: 25rem!important;
	padding-left: 25rem!important
}

.py-0 {
	padding-top: 0!important;
	padding-bottom: 0!important
}

.py-1 {
	padding-top: .25rem!important;
	padding-bottom: .25rem!important
}

.py-2 {
	padding-top: .5rem!important;
	padding-bottom: .5rem!important
}

.py-3 {
	padding-top: .75rem!important;
	padding-bottom: .75rem!important
}

.py-4 {
	padding-top: 1rem!important;
	padding-bottom: 1rem!important
}

.py-5 {
	padding-top: 1.5rem!important;
	padding-bottom: 1.5rem!important
}

.py-6 {
	padding-top: 2rem!important;
	padding-bottom: 2rem!important
}

.py-7 {
	padding-top: 2.5rem!important;
	padding-bottom: 2.5rem!important
}

.py-8 {
	padding-top: 3rem!important;
	padding-bottom: 3rem!important
}

.py-9 {
	padding-top: 4rem!important;
	padding-bottom: 4rem!important
}

.py-10 {
	padding-top: 5rem!important;
	padding-bottom: 5rem!important
}

.py-11 {
	padding-top: 6rem!important;
	padding-bottom: 6rem!important
}

.py-12 {
	padding-top: 8rem!important;
	padding-bottom: 8rem!important
}

.py-13 {
	padding-top: 10rem!important;
	padding-bottom: 10rem!important
}

.py-14 {
	padding-top: 12rem!important;
	padding-bottom: 12rem!important
}

.py-15 {
	padding-top: 16rem!important;
	padding-bottom: 16rem!important
}

.py-16 {
	padding-top: 25rem!important;
	padding-bottom: 25rem!important
}

.pt-0 {
	padding-top: 0!important
}

.pt-1 {
	padding-top: .25rem!important
}

.pt-2 {
	padding-top: .5rem!important
}

.pt-3 {
	padding-top: .75rem!important
}

.pt-4 {
	padding-top: 1rem!important
}

.pt-5 {
	padding-top: 1.5rem!important
}

.pt-6 {
	padding-top: 2rem!important
}

.pt-7 {
	padding-top: 2.5rem!important
}

.pt-8 {
	padding-top: 3rem!important
}

.pt-9 {
	padding-top: 4rem!important
}

.pt-10 {
	padding-top: 5rem!important
}

.pt-11 {
	padding-top: 6rem!important
}

.pt-12 {
	padding-top: 8rem!important
}

.pt-13 {
	padding-top: 10rem!important
}

.pt-14 {
	padding-top: 12rem!important
}

.pt-15 {
	padding-top: 16rem!important
}

.pt-16 {
	padding-top: 25rem!important
}

.pe-0 {
	padding-right: 0!important
}

.pe-1 {
	padding-right: .25rem!important
}

.pe-2 {
	padding-right: .5rem!important
}

.pe-3 {
	padding-right: .75rem!important
}

.pe-4 {
	padding-right: 1rem!important
}

.pe-5 {
	padding-right: 1.5rem!important
}

.pe-6 {
	padding-right: 2rem!important
}

.pe-7 {
	padding-right: 2.5rem!important
}

.pe-8 {
	padding-right: 3rem!important
}

.pe-9 {
	padding-right: 4rem!important
}

.pe-10 {
	padding-right: 5rem!important
}

.pe-11 {
	padding-right: 6rem!important
}

.pe-12 {
	padding-right: 8rem!important
}

.pe-13 {
	padding-right: 10rem!important
}

.pe-14 {
	padding-right: 12rem!important
}

.pe-15 {
	padding-right: 16rem!important
}

.pe-16 {
	padding-right: 25rem!important
}

.pb-0 {
	padding-bottom: 0!important
}

.pb-1 {
	padding-bottom: .25rem!important
}

.pb-2 {
	padding-bottom: .5rem!important
}

.pb-3 {
	padding-bottom: .75rem!important
}

.pb-4 {
	padding-bottom: 1rem!important
}

.pb-5 {
	padding-bottom: 1.5rem!important
}

.pb-6 {
	padding-bottom: 2rem!important
}

.pb-7 {
	padding-bottom: 2.5rem!important
}

.pb-8 {
	padding-bottom: 3rem!important
}

.pb-9 {
	padding-bottom: 4rem!important
}

.pb-10 {
	padding-bottom: 5rem!important
}

.pb-11 {
	padding-bottom: 6rem!important
}

.pb-12 {
	padding-bottom: 8rem!important
}

.pb-13 {
	padding-bottom: 10rem!important
}

.pb-14 {
	padding-bottom: 12rem!important
}

.pb-15 {
	padding-bottom: 16rem!important
}

.pb-16 {
	padding-bottom: 25rem!important
}

.ps-0 {
	padding-left: 0!important
}

.ps-1 {
	padding-left: .25rem!important
}

.ps-2 {
	padding-left: .5rem!important
}

.ps-3 {
	padding-left: .75rem!important
}

.ps-4 {
	padding-left: 1rem!important
}

.ps-5 {
	padding-left: 1.5rem!important
}

.ps-6 {
	padding-left: 2rem!important
}

.ps-7 {
	padding-left: 2.5rem!important
}

.ps-8 {
	padding-left: 3rem!important
}

.ps-9 {
	padding-left: 4rem!important
}

.ps-10 {
	padding-left: 5rem!important
}

.ps-11 {
	padding-left: 6rem!important
}

.ps-12 {
	padding-left: 8rem!important
}

.ps-13 {
	padding-left: 10rem!important
}

.ps-14 {
	padding-left: 12rem!important
}

.ps-15 {
	padding-left: 16rem!important
}

.ps-16 {
	padding-left: 25rem!important
}

.font-monospace {
	font-family: var(--bs-font-monospace)!important
}

.fs-1 {
	font-size: calc(1.3437rem + 1.1244vw)!important
}

.fs-2 {
	font-size: calc(1.3125rem + .75vw)!important
}

.fs-3 {
	font-size: calc(1.2625rem + .15vw)!important
}

.fs-4 {
	font-size: 1.25rem!important
}

.fs-5 {
	font-size: 1.125rem!important
}

.fs-6 {
	font-size: 1rem!important
}

.fst-italic {
	font-style: italic!important
}

.fst-normal {
	font-style: normal!important
}

.fw-light {
	font-weight: 300!important
}

.fw-lighter {
	font-weight: lighter!important
}

.fw-normal {
	font-weight: 400!important
}

.fw-bold {
	font-weight: 700!important
}

.fw-bolder {
	font-weight: bolder!important
}

.lh-1 {
	line-height: 1!important
}

.lh-sm {
	line-height: 1.3!important
}

.lh-base {
	line-height: 1.6!important
}

.lh-lg {
	line-height: 1.3!important
}

.text-start {
	text-align: left!important
}

.text-end {
	text-align: right!important
}

.text-center {
	text-align: center!important
}

.text-decoration-none {
	text-decoration: none!important
}

.text-decoration-underline {
	text-decoration: underline!important
}

.text-decoration-line-through {
	text-decoration: line-through!important
}

.text-lowercase {
	text-transform: lowercase!important
}

.text-uppercase {
	text-transform: uppercase!important
}

.text-capitalize {
	text-transform: capitalize!important
}

.text-wrap {
	white-space: normal!important
}

.text-nowrap {
	white-space: nowrap!important
}

.text-break {
	word-wrap: break-word!important;
	word-break: break-word!important
}

.text-primary {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity))!important
}

.text-secondary {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity))!important
}

.text-primary-desat {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-primary-desat-rgb), var(--bs-text-opacity))!important
}

.text-black {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-black-rgb), var(--bs-text-opacity))!important
}

.text-gray-200 {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-gray-200-rgb), var(--bs-text-opacity))!important
}

.text-gray-800 {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-gray-800-rgb), var(--bs-text-opacity))!important
}

.text-white {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-white-rgb), var(--bs-text-opacity))!important
}

.text-dark {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity))!important
}

.text-success {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-success-rgb), var(--bs-text-opacity))!important
}

.text-info {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-info-rgb), var(--bs-text-opacity))!important
}

.text-warning {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity))!important
}

.text-danger {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity))!important
}

.text-light {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-light-rgb), var(--bs-text-opacity))!important
}

.text-orange {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-orange-rgb), var(--bs-text-opacity))!important
}

.text-blue {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-blue-rgb), var(--bs-text-opacity))!important
}

.text-purple {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-purple-rgb), var(--bs-text-opacity))!important
}

.text-teal {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-teal-rgb), var(--bs-text-opacity))!important
}

.text-ice {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-ice-rgb), var(--bs-text-opacity))!important
}

.text-cyan {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-cyan-rgb), var(--bs-text-opacity))!important
}

.text-helio {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-helio-rgb), var(--bs-text-opacity))!important
}

.text-white-ice {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-white-ice-rgb), var(--bs-text-opacity))!important
}

.text-tropaz {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-tropaz-rgb), var(--bs-text-opacity))!important
}

.text-roman {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-roman-rgb), var(--bs-text-opacity))!important
}

.text-tangerine {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-tangerine-rgb), var(--bs-text-opacity))!important
}

.text-gigas {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-gigas-rgb), var(--bs-text-opacity))!important
}

.text-lavender {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-lavender-rgb), var(--bs-text-opacity))!important
}

.text-sienna {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-sienna-rgb), var(--bs-text-opacity))!important
}

.text-coral {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-coral-rgb), var(--bs-text-opacity))!important
}

.text-catskill {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-catskill-rgb), var(--bs-text-opacity))!important
}

.text-bossanova {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-bossanova-rgb), var(--bs-text-opacity))!important
}

.text-denim {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-denim-rgb), var(--bs-text-opacity))!important
}

.text-turbo {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-turbo-rgb), var(--bs-text-opacity))!important
}

.text-bright-sun {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-bright-sun-rgb), var(--bs-text-opacity))!important
}

.text-shark {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-shark-rgb), var(--bs-text-opacity))!important
}

.text-dodger {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-dodger-rgb), var(--bs-text-opacity))!important
}

.text-cloud-burst {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-cloud-burst-rgb), var(--bs-text-opacity))!important
}

.text-porsche {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-porsche-rgb), var(--bs-text-opacity))!important
}

.text-biscay {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-biscay-rgb), var(--bs-text-opacity))!important
}

.text-mongoose {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-mongoose-rgb), var(--bs-text-opacity))!important
}

.text-ebony {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-ebony-rgb), var(--bs-text-opacity))!important
}

.text-alizarin {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-alizarin-rgb), var(--bs-text-opacity))!important
}

.text-chestnut {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-chestnut-rgb), var(--bs-text-opacity))!important
}

.text-portgore {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-portgore-rgb), var(--bs-text-opacity))!important
}

.text-body {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity))!important
}

.text-muted {
	--bs-text-opacity: 1;
	color: #8c98a4!important
}

.text-black-50 {
	--bs-text-opacity: 1;
	color: rgba(22, 28, 45, .5)!important
}

.text-white-50 {
	--bs-text-opacity: 1;
	color: rgba(255, 255, 255, .5)!important
}

.text-reset {
	--bs-text-opacity: 1;
	color: inherit!important
}

.text-opacity-25 {
	--bs-text-opacity: 0.25
}

.text-opacity-50 {
	--bs-text-opacity: 0.5
}

.text-opacity-75 {
	--bs-text-opacity: 0.75
}

.text-opacity-100 {
	--bs-text-opacity: 1
}

.bg-primary {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity))!important
}

.bg-secondary {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity))!important
}

.bg-primary-desat {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-primary-desat-rgb), var(--bs-bg-opacity))!important
}

.bg-black {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity))!important
}

.bg-gray-200 {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-gray-200-rgb), var(--bs-bg-opacity))!important
}

.bg-gray-800 {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-gray-800-rgb), var(--bs-bg-opacity))!important
}

.bg-white {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity))!important
}

.bg-dark {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity))!important
}

.bg-success {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity))!important
}

.bg-info {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity))!important
}

.bg-warning {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity))!important
}

.bg-danger {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity))!important
}

.bg-light {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity))!important
}

.bg-orange {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity))!important
}

.bg-blue {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-blue-rgb), var(--bs-bg-opacity))!important
}

.bg-purple {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-purple-rgb), var(--bs-bg-opacity))!important
}

.bg-teal {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity))!important
}

.bg-ice {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-ice-rgb), var(--bs-bg-opacity))!important
}

.bg-cyan {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-cyan-rgb), var(--bs-bg-opacity))!important
}

.bg-helio {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-helio-rgb), var(--bs-bg-opacity))!important
}

.bg-white-ice {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-white-ice-rgb), var(--bs-bg-opacity))!important
}

.bg-tropaz {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-tropaz-rgb), var(--bs-bg-opacity))!important
}

.bg-roman {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-roman-rgb), var(--bs-bg-opacity))!important
}

.bg-tangerine {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-tangerine-rgb), var(--bs-bg-opacity))!important
}

.bg-gigas {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-gigas-rgb), var(--bs-bg-opacity))!important
}

.bg-lavender {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-lavender-rgb), var(--bs-bg-opacity))!important
}

.bg-sienna {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-sienna-rgb), var(--bs-bg-opacity))!important
}

.bg-coral {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-coral-rgb), var(--bs-bg-opacity))!important
}

.bg-catskill {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-catskill-rgb), var(--bs-bg-opacity))!important
}

.bg-bossanova {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-bossanova-rgb), var(--bs-bg-opacity))!important
}

.bg-denim {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-denim-rgb), var(--bs-bg-opacity))!important
}

.bg-turbo {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-turbo-rgb), var(--bs-bg-opacity))!important
}

.bg-bright-sun {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-bright-sun-rgb), var(--bs-bg-opacity))!important
}

.bg-shark {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-shark-rgb), var(--bs-bg-opacity))!important
}

.bg-dodger {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-dodger-rgb), var(--bs-bg-opacity))!important
}

.bg-cloud-burst {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-cloud-burst-rgb), var(--bs-bg-opacity))!important
}

.bg-porsche {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-porsche-rgb), var(--bs-bg-opacity))!important
}

.bg-biscay {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-biscay-rgb), var(--bs-bg-opacity))!important
}

.bg-mongoose {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-mongoose-rgb), var(--bs-bg-opacity))!important
}

.bg-ebony {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-ebony-rgb), var(--bs-bg-opacity))!important
}

.bg-alizarin {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-alizarin-rgb), var(--bs-bg-opacity))!important
}

.bg-chestnut {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-chestnut-rgb), var(--bs-bg-opacity))!important
}

.bg-portgore {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-portgore-rgb), var(--bs-bg-opacity))!important
}

.bg-body {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity))!important
}

.bg-transparent {
	--bs-bg-opacity: 1;
	background-color: transparent!important
}

.bg-opacity-10 {
	--bs-bg-opacity: 0.1
}

.bg-opacity-25 {
	--bs-bg-opacity: 0.25
}

.bg-opacity-50 {
	--bs-bg-opacity: 0.5
}

.bg-opacity-75 {
	--bs-bg-opacity: 0.75
}

.bg-opacity-100 {
	--bs-bg-opacity: 1
}

.bg-gradient {
	background-image: var(--bs-gradient)!important
}

.user-select-all {
	-webkit-user-select: all!important;
	-moz-user-select: all!important;
	-ms-user-select: all!important;
	user-select: all!important
}

.user-select-auto {
	-webkit-user-select: auto!important;
	-moz-user-select: auto!important;
	-ms-user-select: auto!important;
	user-select: auto!important
}

.user-select-none {
	-webkit-user-select: none!important;
	-moz-user-select: none!important;
	-ms-user-select: none!important;
	user-select: none!important
}

.pe-none {
	pointer-events: none!important
}

.pe-auto {
	pointer-events: auto!important
}

.rounded {
	border-radius: .25rem!important
}

.rounded-0 {
	border-radius: 0!important
}

.rounded-1 {
	border-radius: .2rem!important
}

.rounded-2 {
	border-radius: .25rem!important
}

.rounded-3 {
	border-radius: .5rem!important
}

.rounded-circle {
	border-radius: 50%!important
}

.rounded-pill {
	border-radius: 50rem!important
}

.rounded-top {
	border-top-left-radius: .25rem!important;
	border-top-right-radius: .25rem!important
}

.rounded-end {
	border-top-right-radius: .25rem!important;
	border-bottom-right-radius: .25rem!important
}

.rounded-bottom {
	border-bottom-right-radius: .25rem!important;
	border-bottom-left-radius: .25rem!important
}

.rounded-start {
	border-bottom-left-radius: .25rem!important;
	border-top-left-radius: .25rem!important
}

.visible {
	visibility: visible!important
}

.invisible {
	visibility: hidden!important
}

@media (min-width:576px) {
	.float-sm-start {
		float: left!important
	}
	.float-sm-end {
		float: right!important
	}
	.float-sm-none {
		float: none!important
	}
	.d-sm-inline {
		display: inline!important
	}
	.d-sm-inline-block {
		display: inline-block!important
	}
	.d-sm-block {
		display: block!important
	}
	.d-sm-grid {
		display: grid!important
	}
	.d-sm-table {
		display: table!important
	}
	.d-sm-table-row {
		display: table-row!important
	}
	.d-sm-table-cell {
		display: table-cell!important
	}
	.d-sm-flex {
		display: -webkit-box!important;
		display: flex!important
	}
	.d-sm-inline-flex {
		display: -webkit-inline-box!important;
		display: inline-flex!important
	}
	.d-sm-none {
		display: none!important
	}
	.flex-sm-fill {
		-webkit-box-flex: 1!important;
		flex: 1 1 auto!important
	}
	.flex-sm-row {
		-webkit-box-orient: horizontal!important;
		-webkit-box-direction: normal!important;
		flex-direction: row!important
	}
	.flex-sm-column {
		-webkit-box-orient: vertical!important;
		-webkit-box-direction: normal!important;
		flex-direction: column!important
	}
	.flex-sm-row-reverse {
		-webkit-box-orient: horizontal!important;
		-webkit-box-direction: reverse!important;
		flex-direction: row-reverse!important
	}
	.flex-sm-column-reverse {
		-webkit-box-orient: vertical!important;
		-webkit-box-direction: reverse!important;
		flex-direction: column-reverse!important
	}
	.flex-sm-grow-0 {
		-webkit-box-flex: 0!important;
		flex-grow: 0!important
	}
	.flex-sm-grow-1 {
		-webkit-box-flex: 1!important;
		flex-grow: 1!important
	}
	.flex-sm-shrink-0 {
		flex-shrink: 0!important
	}
	.flex-sm-shrink-1 {
		flex-shrink: 1!important
	}
	.flex-sm-wrap {
		flex-wrap: wrap!important
	}
	.flex-sm-nowrap {
		flex-wrap: nowrap!important
	}
	.flex-sm-wrap-reverse {
		flex-wrap: wrap-reverse!important
	}
	.gap-sm-0 {
		gap: 0!important
	}
	.gap-sm-1 {
		gap: .25rem!important
	}
	.gap-sm-2 {
		gap: .5rem!important
	}
	.gap-sm-3 {
		gap: .75rem!important
	}
	.gap-sm-4 {
		gap: 1rem!important
	}
	.gap-sm-5 {
		gap: 1.5rem!important
	}
	.gap-sm-6 {
		gap: 2rem!important
	}
	.gap-sm-7 {
		gap: 2.5rem!important
	}
	.gap-sm-8 {
		gap: 3rem!important
	}
	.gap-sm-9 {
		gap: 4rem!important
	}
	.gap-sm-10 {
		gap: 5rem!important
	}
	.gap-sm-11 {
		gap: 6rem!important
	}
	.gap-sm-12 {
		gap: 8rem!important
	}
	.gap-sm-13 {
		gap: 10rem!important
	}
	.gap-sm-14 {
		gap: 12rem!important
	}
	.gap-sm-15 {
		gap: 16rem!important
	}
	.gap-sm-16 {
		gap: 25rem!important
	}
	.justify-content-sm-start {
		-webkit-box-pack: start!important;
		justify-content: flex-start!important
	}
	.justify-content-sm-end {
		-webkit-box-pack: end!important;
		justify-content: flex-end!important
	}
	.justify-content-sm-center {
		-webkit-box-pack: center!important;
		justify-content: center!important
	}
	.justify-content-sm-between {
		-webkit-box-pack: justify!important;
		justify-content: space-between!important
	}
	.justify-content-sm-around {
		justify-content: space-around!important
	}
	.justify-content-sm-evenly {
		-webkit-box-pack: space-evenly!important;
		justify-content: space-evenly!important
	}
	.align-items-sm-start {
		-webkit-box-align: start!important;
		align-items: flex-start!important
	}
	.align-items-sm-end {
		-webkit-box-align: end!important;
		align-items: flex-end!important
	}
	.align-items-sm-center {
		-webkit-box-align: center!important;
		align-items: center!important
	}
	.align-items-sm-baseline {
		-webkit-box-align: baseline!important;
		align-items: baseline!important
	}
	.align-items-sm-stretch {
		-webkit-box-align: stretch!important;
		align-items: stretch!important
	}
	.align-content-sm-start {
		align-content: flex-start!important
	}
	.align-content-sm-end {
		align-content: flex-end!important
	}
	.align-content-sm-center {
		align-content: center!important
	}
	.align-content-sm-between {
		align-content: space-between!important
	}
	.align-content-sm-around {
		align-content: space-around!important
	}
	.align-content-sm-stretch {
		align-content: stretch!important
	}
	.align-self-sm-auto {
		align-self: auto!important
	}
	.align-self-sm-start {
		align-self: flex-start!important
	}
	.align-self-sm-end {
		align-self: flex-end!important
	}
	.align-self-sm-center {
		align-self: center!important
	}
	.align-self-sm-baseline {
		align-self: baseline!important
	}
	.align-self-sm-stretch {
		align-self: stretch!important
	}
	.order-sm-first {
		-webkit-box-ordinal-group: 0!important;
		order: -1!important
	}
	.order-sm-0 {
		-webkit-box-ordinal-group: 1!important;
		order: 0!important
	}
	.order-sm-1 {
		-webkit-box-ordinal-group: 2!important;
		order: 1!important
	}
	.order-sm-2 {
		-webkit-box-ordinal-group: 3!important;
		order: 2!important
	}
	.order-sm-3 {
		-webkit-box-ordinal-group: 4!important;
		order: 3!important
	}
	.order-sm-4 {
		-webkit-box-ordinal-group: 5!important;
		order: 4!important
	}
	.order-sm-5 {
		-webkit-box-ordinal-group: 6!important;
		order: 5!important
	}
	.order-sm-last {
		-webkit-box-ordinal-group: 7!important;
		order: 6!important
	}
	.m-sm-0 {
		margin: 0!important
	}
	.m-sm-1 {
		margin: .25rem!important
	}
	.m-sm-2 {
		margin: .5rem!important
	}
	.m-sm-3 {
		margin: .75rem!important
	}
	.m-sm-4 {
		margin: 1rem!important
	}
	.m-sm-5 {
		margin: 1.5rem!important
	}
	.m-sm-6 {
		margin: 2rem!important
	}
	.m-sm-7 {
		margin: 2.5rem!important
	}
	.m-sm-8 {
		margin: 3rem!important
	}
	.m-sm-9 {
		margin: 4rem!important
	}
	.m-sm-10 {
		margin: 5rem!important
	}
	.m-sm-11 {
		margin: 6rem!important
	}
	.m-sm-12 {
		margin: 8rem!important
	}
	.m-sm-13 {
		margin: 10rem!important
	}
	.m-sm-14 {
		margin: 12rem!important
	}
	.m-sm-15 {
		margin: 16rem!important
	}
	.m-sm-16 {
		margin: 25rem!important
	}
	.m-sm-auto {
		margin: auto!important
	}
	.mx-sm-0 {
		margin-right: 0!important;
		margin-left: 0!important
	}
	.mx-sm-1 {
		margin-right: .25rem!important;
		margin-left: .25rem!important
	}
	.mx-sm-2 {
		margin-right: .5rem!important;
		margin-left: .5rem!important
	}
	.mx-sm-3 {
		margin-right: .75rem!important;
		margin-left: .75rem!important
	}
	.mx-sm-4 {
		margin-right: 1rem!important;
		margin-left: 1rem!important
	}
	.mx-sm-5 {
		margin-right: 1.5rem!important;
		margin-left: 1.5rem!important
	}
	.mx-sm-6 {
		margin-right: 2rem!important;
		margin-left: 2rem!important
	}
	.mx-sm-7 {
		margin-right: 2.5rem!important;
		margin-left: 2.5rem!important
	}
	.mx-sm-8 {
		margin-right: 3rem!important;
		margin-left: 3rem!important
	}
	.mx-sm-9 {
		margin-right: 4rem!important;
		margin-left: 4rem!important
	}
	.mx-sm-10 {
		margin-right: 5rem!important;
		margin-left: 5rem!important
	}
	.mx-sm-11 {
		margin-right: 6rem!important;
		margin-left: 6rem!important
	}
	.mx-sm-12 {
		margin-right: 8rem!important;
		margin-left: 8rem!important
	}
	.mx-sm-13 {
		margin-right: 10rem!important;
		margin-left: 10rem!important
	}
	.mx-sm-14 {
		margin-right: 12rem!important;
		margin-left: 12rem!important
	}
	.mx-sm-15 {
		margin-right: 16rem!important;
		margin-left: 16rem!important
	}
	.mx-sm-16 {
		margin-right: 25rem!important;
		margin-left: 25rem!important
	}
	.mx-sm-auto {
		margin-right: auto!important;
		margin-left: auto!important
	}
	.my-sm-0 {
		margin-top: 0!important;
		margin-bottom: 0!important
	}
	.my-sm-1 {
		margin-top: .25rem!important;
		margin-bottom: .25rem!important
	}
	.my-sm-2 {
		margin-top: .5rem!important;
		margin-bottom: .5rem!important
	}
	.my-sm-3 {
		margin-top: .75rem!important;
		margin-bottom: .75rem!important
	}
	.my-sm-4 {
		margin-top: 1rem!important;
		margin-bottom: 1rem!important
	}
	.my-sm-5 {
		margin-top: 1.5rem!important;
		margin-bottom: 1.5rem!important
	}
	.my-sm-6 {
		margin-top: 2rem!important;
		margin-bottom: 2rem!important
	}
	.my-sm-7 {
		margin-top: 2.5rem!important;
		margin-bottom: 2.5rem!important
	}
	.my-sm-8 {
		margin-top: 3rem!important;
		margin-bottom: 3rem!important
	}
	.my-sm-9 {
		margin-top: 4rem!important;
		margin-bottom: 4rem!important
	}
	.my-sm-10 {
		margin-top: 5rem!important;
		margin-bottom: 5rem!important
	}
	.my-sm-11 {
		margin-top: 6rem!important;
		margin-bottom: 6rem!important
	}
	.my-sm-12 {
		margin-top: 8rem!important;
		margin-bottom: 8rem!important
	}
	.my-sm-13 {
		margin-top: 10rem!important;
		margin-bottom: 10rem!important
	}
	.my-sm-14 {
		margin-top: 12rem!important;
		margin-bottom: 12rem!important
	}
	.my-sm-15 {
		margin-top: 16rem!important;
		margin-bottom: 16rem!important
	}
	.my-sm-16 {
		margin-top: 25rem!important;
		margin-bottom: 25rem!important
	}
	.my-sm-auto {
		margin-top: auto!important;
		margin-bottom: auto!important
	}
	.mt-sm-0 {
		margin-top: 0!important
	}
	.mt-sm-1 {
		margin-top: .25rem!important
	}
	.mt-sm-2 {
		margin-top: .5rem!important
	}
	.mt-sm-3 {
		margin-top: .75rem!important
	}
	.mt-sm-4 {
		margin-top: 1rem!important
	}
	.mt-sm-5 {
		margin-top: 1.5rem!important
	}
	.mt-sm-6 {
		margin-top: 2rem!important
	}
	.mt-sm-7 {
		margin-top: 2.5rem!important
	}
	.mt-sm-8 {
		margin-top: 3rem!important
	}
	.mt-sm-9 {
		margin-top: 4rem!important
	}
	.mt-sm-10 {
		margin-top: 5rem!important
	}
	.mt-sm-11 {
		margin-top: 6rem!important
	}
	.mt-sm-12 {
		margin-top: 8rem!important
	}
	.mt-sm-13 {
		margin-top: 10rem!important
	}
	.mt-sm-14 {
		margin-top: 12rem!important
	}
	.mt-sm-15 {
		margin-top: 16rem!important
	}
	.mt-sm-16 {
		margin-top: 25rem!important
	}
	.mt-sm-auto {
		margin-top: auto!important
	}
	.me-sm-0 {
		margin-right: 0!important
	}
	.me-sm-1 {
		margin-right: .25rem!important
	}
	.me-sm-2 {
		margin-right: .5rem!important
	}
	.me-sm-3 {
		margin-right: .75rem!important
	}
	.me-sm-4 {
		margin-right: 1rem!important
	}
	.me-sm-5 {
		margin-right: 1.5rem!important
	}
	.me-sm-6 {
		margin-right: 2rem!important
	}
	.me-sm-7 {
		margin-right: 2.5rem!important
	}
	.me-sm-8 {
		margin-right: 3rem!important
	}
	.me-sm-9 {
		margin-right: 4rem!important
	}
	.me-sm-10 {
		margin-right: 5rem!important
	}
	.me-sm-11 {
		margin-right: 6rem!important
	}
	.me-sm-12 {
		margin-right: 8rem!important
	}
	.me-sm-13 {
		margin-right: 10rem!important
	}
	.me-sm-14 {
		margin-right: 12rem!important
	}
	.me-sm-15 {
		margin-right: 16rem!important
	}
	.me-sm-16 {
		margin-right: 25rem!important
	}
	.me-sm-auto {
		margin-right: auto!important
	}
	.mb-sm-0 {
		margin-bottom: 0!important
	}
	.mb-sm-1 {
		margin-bottom: .25rem!important
	}
	.mb-sm-2 {
		margin-bottom: .5rem!important
	}
	.mb-sm-3 {
		margin-bottom: .75rem!important
	}
	.mb-sm-4 {
		margin-bottom: 1rem!important
	}
	.mb-sm-5 {
		margin-bottom: 1.5rem!important
	}
	.mb-sm-6 {
		margin-bottom: 2rem!important
	}
	.mb-sm-7 {
		margin-bottom: 2.5rem!important
	}
	.mb-sm-8 {
		margin-bottom: 3rem!important
	}
	.mb-sm-9 {
		margin-bottom: 4rem!important
	}
	.mb-sm-10 {
		margin-bottom: 5rem!important
	}
	.mb-sm-11 {
		margin-bottom: 6rem!important
	}
	.mb-sm-12 {
		margin-bottom: 8rem!important
	}
	.mb-sm-13 {
		margin-bottom: 10rem!important
	}
	.mb-sm-14 {
		margin-bottom: 12rem!important
	}
	.mb-sm-15 {
		margin-bottom: 16rem!important
	}
	.mb-sm-16 {
		margin-bottom: 25rem!important
	}
	.mb-sm-auto {
		margin-bottom: auto!important
	}
	.ms-sm-0 {
		margin-left: 0!important
	}
	.ms-sm-1 {
		margin-left: .25rem!important
	}
	.ms-sm-2 {
		margin-left: .5rem!important
	}
	.ms-sm-3 {
		margin-left: .75rem!important
	}
	.ms-sm-4 {
		margin-left: 1rem!important
	}
	.ms-sm-5 {
		margin-left: 1.5rem!important
	}
	.ms-sm-6 {
		margin-left: 2rem!important
	}
	.ms-sm-7 {
		margin-left: 2.5rem!important
	}
	.ms-sm-8 {
		margin-left: 3rem!important
	}
	.ms-sm-9 {
		margin-left: 4rem!important
	}
	.ms-sm-10 {
		margin-left: 5rem!important
	}
	.ms-sm-11 {
		margin-left: 6rem!important
	}
	.ms-sm-12 {
		margin-left: 8rem!important
	}
	.ms-sm-13 {
		margin-left: 10rem!important
	}
	.ms-sm-14 {
		margin-left: 12rem!important
	}
	.ms-sm-15 {
		margin-left: 16rem!important
	}
	.ms-sm-16 {
		margin-left: 25rem!important
	}
	.ms-sm-auto {
		margin-left: auto!important
	}
	.m-sm-n1 {
		margin: -.25rem!important
	}
	.m-sm-n2 {
		margin: -.5rem!important
	}
	.m-sm-n3 {
		margin: -.75rem!important
	}
	.m-sm-n4 {
		margin: -1rem!important
	}
	.m-sm-n5 {
		margin: -1.5rem!important
	}
	.m-sm-n6 {
		margin: -2rem!important
	}
	.m-sm-n7 {
		margin: -2.5rem!important
	}
	.m-sm-n8 {
		margin: -3rem!important
	}
	.m-sm-n9 {
		margin: -4rem!important
	}
	.m-sm-n10 {
		margin: -5rem!important
	}
	.m-sm-n11 {
		margin: -6rem!important
	}
	.m-sm-n12 {
		margin: -8rem!important
	}
	.m-sm-n13 {
		margin: -10rem!important
	}
	.m-sm-n14 {
		margin: -12rem!important
	}
	.m-sm-n15 {
		margin: -16rem!important
	}
	.m-sm-n16 {
		margin: -25rem!important
	}
	.mx-sm-n1 {
		margin-right: -.25rem!important;
		margin-left: -.25rem!important
	}
	.mx-sm-n2 {
		margin-right: -.5rem!important;
		margin-left: -.5rem!important
	}
	.mx-sm-n3 {
		margin-right: -.75rem!important;
		margin-left: -.75rem!important
	}
	.mx-sm-n4 {
		margin-right: -1rem!important;
		margin-left: -1rem!important
	}
	.mx-sm-n5 {
		margin-right: -1.5rem!important;
		margin-left: -1.5rem!important
	}
	.mx-sm-n6 {
		margin-right: -2rem!important;
		margin-left: -2rem!important
	}
	.mx-sm-n7 {
		margin-right: -2.5rem!important;
		margin-left: -2.5rem!important
	}
	.mx-sm-n8 {
		margin-right: -3rem!important;
		margin-left: -3rem!important
	}
	.mx-sm-n9 {
		margin-right: -4rem!important;
		margin-left: -4rem!important
	}
	.mx-sm-n10 {
		margin-right: -5rem!important;
		margin-left: -5rem!important
	}
	.mx-sm-n11 {
		margin-right: -6rem!important;
		margin-left: -6rem!important
	}
	.mx-sm-n12 {
		margin-right: -8rem!important;
		margin-left: -8rem!important
	}
	.mx-sm-n13 {
		margin-right: -10rem!important;
		margin-left: -10rem!important
	}
	.mx-sm-n14 {
		margin-right: -12rem!important;
		margin-left: -12rem!important
	}
	.mx-sm-n15 {
		margin-right: -16rem!important;
		margin-left: -16rem!important
	}
	.mx-sm-n16 {
		margin-right: -25rem!important;
		margin-left: -25rem!important
	}
	.my-sm-n1 {
		margin-top: -.25rem!important;
		margin-bottom: -.25rem!important
	}
	.my-sm-n2 {
		margin-top: -.5rem!important;
		margin-bottom: -.5rem!important
	}
	.my-sm-n3 {
		margin-top: -.75rem!important;
		margin-bottom: -.75rem!important
	}
	.my-sm-n4 {
		margin-top: -1rem!important;
		margin-bottom: -1rem!important
	}
	.my-sm-n5 {
		margin-top: -1.5rem!important;
		margin-bottom: -1.5rem!important
	}
	.my-sm-n6 {
		margin-top: -2rem!important;
		margin-bottom: -2rem!important
	}
	.my-sm-n7 {
		margin-top: -2.5rem!important;
		margin-bottom: -2.5rem!important
	}
	.my-sm-n8 {
		margin-top: -3rem!important;
		margin-bottom: -3rem!important
	}
	.my-sm-n9 {
		margin-top: -4rem!important;
		margin-bottom: -4rem!important
	}
	.my-sm-n10 {
		margin-top: -5rem!important;
		margin-bottom: -5rem!important
	}
	.my-sm-n11 {
		margin-top: -6rem!important;
		margin-bottom: -6rem!important
	}
	.my-sm-n12 {
		margin-top: -8rem!important;
		margin-bottom: -8rem!important
	}
	.my-sm-n13 {
		margin-top: -10rem!important;
		margin-bottom: -10rem!important
	}
	.my-sm-n14 {
		margin-top: -12rem!important;
		margin-bottom: -12rem!important
	}
	.my-sm-n15 {
		margin-top: -16rem!important;
		margin-bottom: -16rem!important
	}
	.my-sm-n16 {
		margin-top: -25rem!important;
		margin-bottom: -25rem!important
	}
	.mt-sm-n1 {
		margin-top: -.25rem!important
	}
	.mt-sm-n2 {
		margin-top: -.5rem!important
	}
	.mt-sm-n3 {
		margin-top: -.75rem!important
	}
	.mt-sm-n4 {
		margin-top: -1rem!important
	}
	.mt-sm-n5 {
		margin-top: -1.5rem!important
	}
	.mt-sm-n6 {
		margin-top: -2rem!important
	}
	.mt-sm-n7 {
		margin-top: -2.5rem!important
	}
	.mt-sm-n8 {
		margin-top: -3rem!important
	}
	.mt-sm-n9 {
		margin-top: -4rem!important
	}
	.mt-sm-n10 {
		margin-top: -5rem!important
	}
	.mt-sm-n11 {
		margin-top: -6rem!important
	}
	.mt-sm-n12 {
		margin-top: -8rem!important
	}
	.mt-sm-n13 {
		margin-top: -10rem!important
	}
	.mt-sm-n14 {
		margin-top: -12rem!important
	}
	.mt-sm-n15 {
		margin-top: -16rem!important
	}
	.mt-sm-n16 {
		margin-top: -25rem!important
	}
	.me-sm-n1 {
		margin-right: -.25rem!important
	}
	.me-sm-n2 {
		margin-right: -.5rem!important
	}
	.me-sm-n3 {
		margin-right: -.75rem!important
	}
	.me-sm-n4 {
		margin-right: -1rem!important
	}
	.me-sm-n5 {
		margin-right: -1.5rem!important
	}
	.me-sm-n6 {
		margin-right: -2rem!important
	}
	.me-sm-n7 {
		margin-right: -2.5rem!important
	}
	.me-sm-n8 {
		margin-right: -3rem!important
	}
	.me-sm-n9 {
		margin-right: -4rem!important
	}
	.me-sm-n10 {
		margin-right: -5rem!important
	}
	.me-sm-n11 {
		margin-right: -6rem!important
	}
	.me-sm-n12 {
		margin-right: -8rem!important
	}
	.me-sm-n13 {
		margin-right: -10rem!important
	}
	.me-sm-n14 {
		margin-right: -12rem!important
	}
	.me-sm-n15 {
		margin-right: -16rem!important
	}
	.me-sm-n16 {
		margin-right: -25rem!important
	}
	.mb-sm-n1 {
		margin-bottom: -.25rem!important
	}
	.mb-sm-n2 {
		margin-bottom: -.5rem!important
	}
	.mb-sm-n3 {
		margin-bottom: -.75rem!important
	}
	.mb-sm-n4 {
		margin-bottom: -1rem!important
	}
	.mb-sm-n5 {
		margin-bottom: -1.5rem!important
	}
	.mb-sm-n6 {
		margin-bottom: -2rem!important
	}
	.mb-sm-n7 {
		margin-bottom: -2.5rem!important
	}
	.mb-sm-n8 {
		margin-bottom: -3rem!important
	}
	.mb-sm-n9 {
		margin-bottom: -4rem!important
	}
	.mb-sm-n10 {
		margin-bottom: -5rem!important
	}
	.mb-sm-n11 {
		margin-bottom: -6rem!important
	}
	.mb-sm-n12 {
		margin-bottom: -8rem!important
	}
	.mb-sm-n13 {
		margin-bottom: -10rem!important
	}
	.mb-sm-n14 {
		margin-bottom: -12rem!important
	}
	.mb-sm-n15 {
		margin-bottom: -16rem!important
	}
	.mb-sm-n16 {
		margin-bottom: -25rem!important
	}
	.ms-sm-n1 {
		margin-left: -.25rem!important
	}
	.ms-sm-n2 {
		margin-left: -.5rem!important
	}
	.ms-sm-n3 {
		margin-left: -.75rem!important
	}
	.ms-sm-n4 {
		margin-left: -1rem!important
	}
	.ms-sm-n5 {
		margin-left: -1.5rem!important
	}
	.ms-sm-n6 {
		margin-left: -2rem!important
	}
	.ms-sm-n7 {
		margin-left: -2.5rem!important
	}
	.ms-sm-n8 {
		margin-left: -3rem!important
	}
	.ms-sm-n9 {
		margin-left: -4rem!important
	}
	.ms-sm-n10 {
		margin-left: -5rem!important
	}
	.ms-sm-n11 {
		margin-left: -6rem!important
	}
	.ms-sm-n12 {
		margin-left: -8rem!important
	}
	.ms-sm-n13 {
		margin-left: -10rem!important
	}
	.ms-sm-n14 {
		margin-left: -12rem!important
	}
	.ms-sm-n15 {
		margin-left: -16rem!important
	}
	.ms-sm-n16 {
		margin-left: -25rem!important
	}
	.p-sm-0 {
		padding: 0!important
	}
	.p-sm-1 {
		padding: .25rem!important
	}
	.p-sm-2 {
		padding: .5rem!important
	}
	.p-sm-3 {
		padding: .75rem!important
	}
	.p-sm-4 {
		padding: 1rem!important
	}
	.p-sm-5 {
		padding: 1.5rem!important
	}
	.p-sm-6 {
		padding: 2rem!important
	}
	.p-sm-7 {
		padding: 2.5rem!important
	}
	.p-sm-8 {
		padding: 3rem!important
	}
	.p-sm-9 {
		padding: 4rem!important
	}
	.p-sm-10 {
		padding: 5rem!important
	}
	.p-sm-11 {
		padding: 6rem!important
	}
	.p-sm-12 {
		padding: 8rem!important
	}
	.p-sm-13 {
		padding: 10rem!important
	}
	.p-sm-14 {
		padding: 12rem!important
	}
	.p-sm-15 {
		padding: 16rem!important
	}
	.p-sm-16 {
		padding: 25rem!important
	}
	.px-sm-0 {
		padding-right: 0!important;
		padding-left: 0!important
	}
	.px-sm-1 {
		padding-right: .25rem!important;
		padding-left: .25rem!important
	}
	.px-sm-2 {
		padding-right: .5rem!important;
		padding-left: .5rem!important
	}
	.px-sm-3 {
		padding-right: .75rem!important;
		padding-left: .75rem!important
	}
	.px-sm-4 {
		padding-right: 1rem!important;
		padding-left: 1rem!important
	}
	.px-sm-5 {
		padding-right: 1.5rem!important;
		padding-left: 1.5rem!important
	}
	.px-sm-6 {
		padding-right: 2rem!important;
		padding-left: 2rem!important
	}
	.px-sm-7 {
		padding-right: 2.5rem!important;
		padding-left: 2.5rem!important
	}
	.px-sm-8 {
		padding-right: 3rem!important;
		padding-left: 3rem!important
	}
	.px-sm-9 {
		padding-right: 4rem!important;
		padding-left: 4rem!important
	}
	.px-sm-10 {
		padding-right: 5rem!important;
		padding-left: 5rem!important
	}
	.px-sm-11 {
		padding-right: 6rem!important;
		padding-left: 6rem!important
	}
	.px-sm-12 {
		padding-right: 8rem!important;
		padding-left: 8rem!important
	}
	.px-sm-13 {
		padding-right: 10rem!important;
		padding-left: 10rem!important
	}
	.px-sm-14 {
		padding-right: 12rem!important;
		padding-left: 12rem!important
	}
	.px-sm-15 {
		padding-right: 16rem!important;
		padding-left: 16rem!important
	}
	.px-sm-16 {
		padding-right: 25rem!important;
		padding-left: 25rem!important
	}
	.py-sm-0 {
		padding-top: 0!important;
		padding-bottom: 0!important
	}
	.py-sm-1 {
		padding-top: .25rem!important;
		padding-bottom: .25rem!important
	}
	.py-sm-2 {
		padding-top: .5rem!important;
		padding-bottom: .5rem!important
	}
	.py-sm-3 {
		padding-top: .75rem!important;
		padding-bottom: .75rem!important
	}
	.py-sm-4 {
		padding-top: 1rem!important;
		padding-bottom: 1rem!important
	}
	.py-sm-5 {
		padding-top: 1.5rem!important;
		padding-bottom: 1.5rem!important
	}
	.py-sm-6 {
		padding-top: 2rem!important;
		padding-bottom: 2rem!important
	}
	.py-sm-7 {
		padding-top: 2.5rem!important;
		padding-bottom: 2.5rem!important
	}
	.py-sm-8 {
		padding-top: 3rem!important;
		padding-bottom: 3rem!important
	}
	.py-sm-9 {
		padding-top: 4rem!important;
		padding-bottom: 4rem!important
	}
	.py-sm-10 {
		padding-top: 5rem!important;
		padding-bottom: 5rem!important
	}
	.py-sm-11 {
		padding-top: 6rem!important;
		padding-bottom: 6rem!important
	}
	.py-sm-12 {
		padding-top: 8rem!important;
		padding-bottom: 8rem!important
	}
	.py-sm-13 {
		padding-top: 10rem!important;
		padding-bottom: 10rem!important
	}
	.py-sm-14 {
		padding-top: 12rem!important;
		padding-bottom: 12rem!important
	}
	.py-sm-15 {
		padding-top: 16rem!important;
		padding-bottom: 16rem!important
	}
	.py-sm-16 {
		padding-top: 25rem!important;
		padding-bottom: 25rem!important
	}
	.pt-sm-0 {
		padding-top: 0!important
	}
	.pt-sm-1 {
		padding-top: .25rem!important
	}
	.pt-sm-2 {
		padding-top: .5rem!important
	}
	.pt-sm-3 {
		padding-top: .75rem!important
	}
	.pt-sm-4 {
		padding-top: 1rem!important
	}
	.pt-sm-5 {
		padding-top: 1.5rem!important
	}
	.pt-sm-6 {
		padding-top: 2rem!important
	}
	.pt-sm-7 {
		padding-top: 2.5rem!important
	}
	.pt-sm-8 {
		padding-top: 3rem!important
	}
	.pt-sm-9 {
		padding-top: 4rem!important
	}
	.pt-sm-10 {
		padding-top: 5rem!important
	}
	.pt-sm-11 {
		padding-top: 6rem!important
	}
	.pt-sm-12 {
		padding-top: 8rem!important
	}
	.pt-sm-13 {
		padding-top: 10rem!important
	}
	.pt-sm-14 {
		padding-top: 12rem!important
	}
	.pt-sm-15 {
		padding-top: 16rem!important
	}
	.pt-sm-16 {
		padding-top: 25rem!important
	}
	.pe-sm-0 {
		padding-right: 0!important
	}
	.pe-sm-1 {
		padding-right: .25rem!important
	}
	.pe-sm-2 {
		padding-right: .5rem!important
	}
	.pe-sm-3 {
		padding-right: .75rem!important
	}
	.pe-sm-4 {
		padding-right: 1rem!important
	}
	.pe-sm-5 {
		padding-right: 1.5rem!important
	}
	.pe-sm-6 {
		padding-right: 2rem!important
	}
	.pe-sm-7 {
		padding-right: 2.5rem!important
	}
	.pe-sm-8 {
		padding-right: 3rem!important
	}
	.pe-sm-9 {
		padding-right: 4rem!important
	}
	.pe-sm-10 {
		padding-right: 5rem!important
	}
	.pe-sm-11 {
		padding-right: 6rem!important
	}
	.pe-sm-12 {
		padding-right: 8rem!important
	}
	.pe-sm-13 {
		padding-right: 10rem!important
	}
	.pe-sm-14 {
		padding-right: 12rem!important
	}
	.pe-sm-15 {
		padding-right: 16rem!important
	}
	.pe-sm-16 {
		padding-right: 25rem!important
	}
	.pb-sm-0 {
		padding-bottom: 0!important
	}
	.pb-sm-1 {
		padding-bottom: .25rem!important
	}
	.pb-sm-2 {
		padding-bottom: .5rem!important
	}
	.pb-sm-3 {
		padding-bottom: .75rem!important
	}
	.pb-sm-4 {
		padding-bottom: 1rem!important
	}
	.pb-sm-5 {
		padding-bottom: 1.5rem!important
	}
	.pb-sm-6 {
		padding-bottom: 2rem!important
	}
	.pb-sm-7 {
		padding-bottom: 2.5rem!important
	}
	.pb-sm-8 {
		padding-bottom: 3rem!important
	}
	.pb-sm-9 {
		padding-bottom: 4rem!important
	}
	.pb-sm-10 {
		padding-bottom: 5rem!important
	}
	.pb-sm-11 {
		padding-bottom: 6rem!important
	}
	.pb-sm-12 {
		padding-bottom: 8rem!important
	}
	.pb-sm-13 {
		padding-bottom: 10rem!important
	}
	.pb-sm-14 {
		padding-bottom: 12rem!important
	}
	.pb-sm-15 {
		padding-bottom: 16rem!important
	}
	.pb-sm-16 {
		padding-bottom: 25rem!important
	}
	.ps-sm-0 {
		padding-left: 0!important
	}
	.ps-sm-1 {
		padding-left: .25rem!important
	}
	.ps-sm-2 {
		padding-left: .5rem!important
	}
	.ps-sm-3 {
		padding-left: .75rem!important
	}
	.ps-sm-4 {
		padding-left: 1rem!important
	}
	.ps-sm-5 {
		padding-left: 1.5rem!important
	}
	.ps-sm-6 {
		padding-left: 2rem!important
	}
	.ps-sm-7 {
		padding-left: 2.5rem!important
	}
	.ps-sm-8 {
		padding-left: 3rem!important
	}
	.ps-sm-9 {
		padding-left: 4rem!important
	}
	.ps-sm-10 {
		padding-left: 5rem!important
	}
	.ps-sm-11 {
		padding-left: 6rem!important
	}
	.ps-sm-12 {
		padding-left: 8rem!important
	}
	.ps-sm-13 {
		padding-left: 10rem!important
	}
	.ps-sm-14 {
		padding-left: 12rem!important
	}
	.ps-sm-15 {
		padding-left: 16rem!important
	}
	.ps-sm-16 {
		padding-left: 25rem!important
	}
	.text-sm-start {
		text-align: left!important
	}
	.text-sm-end {
		text-align: right!important
	}
	.text-sm-center {
		text-align: center!important
	}
}

@media (min-width:768px) {
	.float-md-start {
		float: left!important
	}
	.float-md-end {
		float: right!important
	}
	.float-md-none {
		float: none!important
	}
	.d-md-inline {
		display: inline!important
	}
	.d-md-inline-block {
		display: inline-block!important
	}
	.d-md-block {
		display: block!important
	}
	.d-md-grid {
		display: grid!important
	}
	.d-md-table {
		display: table!important
	}
	.d-md-table-row {
		display: table-row!important
	}
	.d-md-table-cell {
		display: table-cell!important
	}
	.d-md-flex {
		display: -webkit-box!important;
		display: flex!important
	}
	.d-md-inline-flex {
		display: -webkit-inline-box!important;
		display: inline-flex!important
	}
	.d-md-none {
		display: none!important
	}
	.flex-md-fill {
		-webkit-box-flex: 1!important;
		flex: 1 1 auto!important
	}
	.flex-md-row {
		-webkit-box-orient: horizontal!important;
		-webkit-box-direction: normal!important;
		flex-direction: row!important
	}
	.flex-md-column {
		-webkit-box-orient: vertical!important;
		-webkit-box-direction: normal!important;
		flex-direction: column!important
	}
	.flex-md-row-reverse {
		-webkit-box-orient: horizontal!important;
		-webkit-box-direction: reverse!important;
		flex-direction: row-reverse!important
	}
	.flex-md-column-reverse {
		-webkit-box-orient: vertical!important;
		-webkit-box-direction: reverse!important;
		flex-direction: column-reverse!important
	}
	.flex-md-grow-0 {
		-webkit-box-flex: 0!important;
		flex-grow: 0!important
	}
	.flex-md-grow-1 {
		-webkit-box-flex: 1!important;
		flex-grow: 1!important
	}
	.flex-md-shrink-0 {
		flex-shrink: 0!important
	}
	.flex-md-shrink-1 {
		flex-shrink: 1!important
	}
	.flex-md-wrap {
		flex-wrap: wrap!important
	}
	.flex-md-nowrap {
		flex-wrap: nowrap!important
	}
	.flex-md-wrap-reverse {
		flex-wrap: wrap-reverse!important
	}
	.gap-md-0 {
		gap: 0!important
	}
	.gap-md-1 {
		gap: .25rem!important
	}
	.gap-md-2 {
		gap: .5rem!important
	}
	.gap-md-3 {
		gap: .75rem!important
	}
	.gap-md-4 {
		gap: 1rem!important
	}
	.gap-md-5 {
		gap: 1.5rem!important
	}
	.gap-md-6 {
		gap: 2rem!important
	}
	.gap-md-7 {
		gap: 2.5rem!important
	}
	.gap-md-8 {
		gap: 3rem!important
	}
	.gap-md-9 {
		gap: 4rem!important
	}
	.gap-md-10 {
		gap: 5rem!important
	}
	.gap-md-11 {
		gap: 6rem!important
	}
	.gap-md-12 {
		gap: 8rem!important
	}
	.gap-md-13 {
		gap: 10rem!important
	}
	.gap-md-14 {
		gap: 12rem!important
	}
	.gap-md-15 {
		gap: 16rem!important
	}
	.gap-md-16 {
		gap: 25rem!important
	}
	.justify-content-md-start {
		-webkit-box-pack: start!important;
		justify-content: flex-start!important
	}
	.justify-content-md-end {
		-webkit-box-pack: end!important;
		justify-content: flex-end!important
	}
	.justify-content-md-center {
		-webkit-box-pack: center!important;
		justify-content: center!important
	}
	.justify-content-md-between {
		-webkit-box-pack: justify!important;
		justify-content: space-between!important
	}
	.justify-content-md-around {
		justify-content: space-around!important
	}
	.justify-content-md-evenly {
		-webkit-box-pack: space-evenly!important;
		justify-content: space-evenly!important
	}
	.align-items-md-start {
		-webkit-box-align: start!important;
		align-items: flex-start!important
	}
	.align-items-md-end {
		-webkit-box-align: end!important;
		align-items: flex-end!important
	}
	.align-items-md-center {
		-webkit-box-align: center!important;
		align-items: center!important
	}
	.align-items-md-baseline {
		-webkit-box-align: baseline!important;
		align-items: baseline!important
	}
	.align-items-md-stretch {
		-webkit-box-align: stretch!important;
		align-items: stretch!important
	}
	.align-content-md-start {
		align-content: flex-start!important
	}
	.align-content-md-end {
		align-content: flex-end!important
	}
	.align-content-md-center {
		align-content: center!important
	}
	.align-content-md-between {
		align-content: space-between!important
	}
	.align-content-md-around {
		align-content: space-around!important
	}
	.align-content-md-stretch {
		align-content: stretch!important
	}
	.align-self-md-auto {
		align-self: auto!important
	}
	.align-self-md-start {
		align-self: flex-start!important
	}
	.align-self-md-end {
		align-self: flex-end!important
	}
	.align-self-md-center {
		align-self: center!important
	}
	.align-self-md-baseline {
		align-self: baseline!important
	}
	.align-self-md-stretch {
		align-self: stretch!important
	}
	.order-md-first {
		-webkit-box-ordinal-group: 0!important;
		order: -1!important
	}
	.order-md-0 {
		-webkit-box-ordinal-group: 1!important;
		order: 0!important
	}
	.order-md-1 {
		-webkit-box-ordinal-group: 2!important;
		order: 1!important
	}
	.order-md-2 {
		-webkit-box-ordinal-group: 3!important;
		order: 2!important
	}
	.order-md-3 {
		-webkit-box-ordinal-group: 4!important;
		order: 3!important
	}
	.order-md-4 {
		-webkit-box-ordinal-group: 5!important;
		order: 4!important
	}
	.order-md-5 {
		-webkit-box-ordinal-group: 6!important;
		order: 5!important
	}
	.order-md-last {
		-webkit-box-ordinal-group: 7!important;
		order: 6!important
	}
	.m-md-0 {
		margin: 0!important
	}
	.m-md-1 {
		margin: .25rem!important
	}
	.m-md-2 {
		margin: .5rem!important
	}
	.m-md-3 {
		margin: .75rem!important
	}
	.m-md-4 {
		margin: 1rem!important
	}
	.m-md-5 {
		margin: 1.5rem!important
	}
	.m-md-6 {
		margin: 2rem!important
	}
	.m-md-7 {
		margin: 2.5rem!important
	}
	.m-md-8 {
		margin: 3rem!important
	}
	.m-md-9 {
		margin: 4rem!important
	}
	.m-md-10 {
		margin: 5rem!important
	}
	.m-md-11 {
		margin: 6rem!important
	}
	.m-md-12 {
		margin: 8rem!important
	}
	.m-md-13 {
		margin: 10rem!important
	}
	.m-md-14 {
		margin: 12rem!important
	}
	.m-md-15 {
		margin: 16rem!important
	}
	.m-md-16 {
		margin: 25rem!important
	}
	.m-md-auto {
		margin: auto!important
	}
	.mx-md-0 {
		margin-right: 0!important;
		margin-left: 0!important
	}
	.mx-md-1 {
		margin-right: .25rem!important;
		margin-left: .25rem!important
	}
	.mx-md-2 {
		margin-right: .5rem!important;
		margin-left: .5rem!important
	}
	.mx-md-3 {
		margin-right: .75rem!important;
		margin-left: .75rem!important
	}
	.mx-md-4 {
		margin-right: 1rem!important;
		margin-left: 1rem!important
	}
	.mx-md-5 {
		margin-right: 1.5rem!important;
		margin-left: 1.5rem!important
	}
	.mx-md-6 {
		margin-right: 2rem!important;
		margin-left: 2rem!important
	}
	.mx-md-7 {
		margin-right: 2.5rem!important;
		margin-left: 2.5rem!important
	}
	.mx-md-8 {
		margin-right: 3rem!important;
		margin-left: 3rem!important
	}
	.mx-md-9 {
		margin-right: 4rem!important;
		margin-left: 4rem!important
	}
	.mx-md-10 {
		margin-right: 5rem!important;
		margin-left: 5rem!important
	}
	.mx-md-11 {
		margin-right: 6rem!important;
		margin-left: 6rem!important
	}
	.mx-md-12 {
		margin-right: 8rem!important;
		margin-left: 8rem!important
	}
	.mx-md-13 {
		margin-right: 10rem!important;
		margin-left: 10rem!important
	}
	.mx-md-14 {
		margin-right: 12rem!important;
		margin-left: 12rem!important
	}
	.mx-md-15 {
		margin-right: 16rem!important;
		margin-left: 16rem!important
	}
	.mx-md-16 {
		margin-right: 25rem!important;
		margin-left: 25rem!important
	}
	.mx-md-auto {
		margin-right: auto!important;
		margin-left: auto!important
	}
	.my-md-0 {
		margin-top: 0!important;
		margin-bottom: 0!important
	}
	.my-md-1 {
		margin-top: .25rem!important;
		margin-bottom: .25rem!important
	}
	.my-md-2 {
		margin-top: .5rem!important;
		margin-bottom: .5rem!important
	}
	.my-md-3 {
		margin-top: .75rem!important;
		margin-bottom: .75rem!important
	}
	.my-md-4 {
		margin-top: 1rem!important;
		margin-bottom: 1rem!important
	}
	.my-md-5 {
		margin-top: 1.5rem!important;
		margin-bottom: 1.5rem!important
	}
	.my-md-6 {
		margin-top: 2rem!important;
		margin-bottom: 2rem!important
	}
	.my-md-7 {
		margin-top: 2.5rem!important;
		margin-bottom: 2.5rem!important
	}
	.my-md-8 {
		margin-top: 3rem!important;
		margin-bottom: 3rem!important
	}
	.my-md-9 {
		margin-top: 4rem!important;
		margin-bottom: 4rem!important
	}
	.my-md-10 {
		margin-top: 5rem!important;
		margin-bottom: 5rem!important
	}
	.my-md-11 {
		margin-top: 6rem!important;
		margin-bottom: 6rem!important
	}
	.my-md-12 {
		margin-top: 8rem!important;
		margin-bottom: 8rem!important
	}
	.my-md-13 {
		margin-top: 10rem!important;
		margin-bottom: 10rem!important
	}
	.my-md-14 {
		margin-top: 12rem!important;
		margin-bottom: 12rem!important
	}
	.my-md-15 {
		margin-top: 16rem!important;
		margin-bottom: 16rem!important
	}
	.my-md-16 {
		margin-top: 25rem!important;
		margin-bottom: 25rem!important
	}
	.my-md-auto {
		margin-top: auto!important;
		margin-bottom: auto!important
	}
	.mt-md-0 {
		margin-top: 0!important
	}
	.mt-md-1 {
		margin-top: .25rem!important
	}
	.mt-md-2 {
		margin-top: .5rem!important
	}
	.mt-md-3 {
		margin-top: .75rem!important
	}
	.mt-md-4 {
		margin-top: 1rem!important
	}
	.mt-md-5 {
		margin-top: 1.5rem!important
	}
	.mt-md-6 {
		margin-top: 2rem!important
	}
	.mt-md-7 {
		margin-top: 2.5rem!important
	}
	.mt-md-8 {
		margin-top: 3rem!important
	}
	.mt-md-9 {
		margin-top: 4rem!important
	}
	.mt-md-10 {
		margin-top: 5rem!important
	}
	.mt-md-11 {
		margin-top: 6rem!important
	}
	.mt-md-12 {
		margin-top: 8rem!important
	}
	.mt-md-13 {
		margin-top: 10rem!important
	}
	.mt-md-14 {
		margin-top: 12rem!important
	}
	.mt-md-15 {
		margin-top: 16rem!important
	}
	.mt-md-16 {
		margin-top: 25rem!important
	}
	.mt-md-auto {
		margin-top: auto!important
	}
	.me-md-0 {
		margin-right: 0!important
	}
	.me-md-1 {
		margin-right: .25rem!important
	}
	.me-md-2 {
		margin-right: .5rem!important
	}
	.me-md-3 {
		margin-right: .75rem!important
	}
	.me-md-4 {
		margin-right: 1rem!important
	}
	.me-md-5 {
		margin-right: 1.5rem!important
	}
	.me-md-6 {
		margin-right: 2rem!important
	}
	.me-md-7 {
		margin-right: 2.5rem!important
	}
	.me-md-8 {
		margin-right: 3rem!important
	}
	.me-md-9 {
		margin-right: 4rem!important
	}
	.me-md-10 {
		margin-right: 5rem!important
	}
	.me-md-11 {
		margin-right: 6rem!important
	}
	.me-md-12 {
		margin-right: 8rem!important
	}
	.me-md-13 {
		margin-right: 10rem!important
	}
	.me-md-14 {
		margin-right: 12rem!important
	}
	.me-md-15 {
		margin-right: 16rem!important
	}
	.me-md-16 {
		margin-right: 25rem!important
	}
	.me-md-auto {
		margin-right: auto!important
	}
	.mb-md-0 {
		margin-bottom: 0!important
	}
	.mb-md-1 {
		margin-bottom: .25rem!important
	}
	.mb-md-2 {
		margin-bottom: .5rem!important
	}
	.mb-md-3 {
		margin-bottom: .75rem!important
	}
	.mb-md-4 {
		margin-bottom: 1rem!important
	}
	.mb-md-5 {
		margin-bottom: 1.5rem!important
	}
	.mb-md-6 {
		margin-bottom: 2rem!important
	}
	.mb-md-7 {
		margin-bottom: 2.5rem!important
	}
	.mb-md-8 {
		margin-bottom: 3rem!important
	}
	.mb-md-9 {
		margin-bottom: 4rem!important
	}
	.mb-md-10 {
		margin-bottom: 5rem!important
	}
	.mb-md-11 {
		margin-bottom: 6rem!important
	}
	.mb-md-12 {
		margin-bottom: 8rem!important
	}
	.mb-md-13 {
		margin-bottom: 10rem!important
	}
	.mb-md-14 {
		margin-bottom: 12rem!important
	}
	.mb-md-15 {
		margin-bottom: 16rem!important
	}
	.mb-md-16 {
		margin-bottom: 25rem!important
	}
	.mb-md-auto {
		margin-bottom: auto!important
	}
	.ms-md-0 {
		margin-left: 0!important
	}
	.ms-md-1 {
		margin-left: .25rem!important
	}
	.ms-md-2 {
		margin-left: .5rem!important
	}
	.ms-md-3 {
		margin-left: .75rem!important
	}
	.ms-md-4 {
		margin-left: 1rem!important
	}
	.ms-md-5 {
		margin-left: 1.5rem!important
	}
	.ms-md-6 {
		margin-left: 2rem!important
	}
	.ms-md-7 {
		margin-left: 2.5rem!important
	}
	.ms-md-8 {
		margin-left: 3rem!important
	}
	.ms-md-9 {
		margin-left: 4rem!important
	}
	.ms-md-10 {
		margin-left: 5rem!important
	}
	.ms-md-11 {
		margin-left: 6rem!important
	}
	.ms-md-12 {
		margin-left: 8rem!important
	}
	.ms-md-13 {
		margin-left: 10rem!important
	}
	.ms-md-14 {
		margin-left: 12rem!important
	}
	.ms-md-15 {
		margin-left: 16rem!important
	}
	.ms-md-16 {
		margin-left: 25rem!important
	}
	.ms-md-auto {
		margin-left: auto!important
	}
	.m-md-n1 {
		margin: -.25rem!important
	}
	.m-md-n2 {
		margin: -.5rem!important
	}
	.m-md-n3 {
		margin: -.75rem!important
	}
	.m-md-n4 {
		margin: -1rem!important
	}
	.m-md-n5 {
		margin: -1.5rem!important
	}
	.m-md-n6 {
		margin: -2rem!important
	}
	.m-md-n7 {
		margin: -2.5rem!important
	}
	.m-md-n8 {
		margin: -3rem!important
	}
	.m-md-n9 {
		margin: -4rem!important
	}
	.m-md-n10 {
		margin: -5rem!important
	}
	.m-md-n11 {
		margin: -6rem!important
	}
	.m-md-n12 {
		margin: -8rem!important
	}
	.m-md-n13 {
		margin: -10rem!important
	}
	.m-md-n14 {
		margin: -12rem!important
	}
	.m-md-n15 {
		margin: -16rem!important
	}
	.m-md-n16 {
		margin: -25rem!important
	}
	.mx-md-n1 {
		margin-right: -.25rem!important;
		margin-left: -.25rem!important
	}
	.mx-md-n2 {
		margin-right: -.5rem!important;
		margin-left: -.5rem!important
	}
	.mx-md-n3 {
		margin-right: -.75rem!important;
		margin-left: -.75rem!important
	}
	.mx-md-n4 {
		margin-right: -1rem!important;
		margin-left: -1rem!important
	}
	.mx-md-n5 {
		margin-right: -1.5rem!important;
		margin-left: -1.5rem!important
	}
	.mx-md-n6 {
		margin-right: -2rem!important;
		margin-left: -2rem!important
	}
	.mx-md-n7 {
		margin-right: -2.5rem!important;
		margin-left: -2.5rem!important
	}
	.mx-md-n8 {
		margin-right: -3rem!important;
		margin-left: -3rem!important
	}
	.mx-md-n9 {
		margin-right: -4rem!important;
		margin-left: -4rem!important
	}
	.mx-md-n10 {
		margin-right: -5rem!important;
		margin-left: -5rem!important
	}
	.mx-md-n11 {
		margin-right: -6rem!important;
		margin-left: -6rem!important
	}
	.mx-md-n12 {
		margin-right: -8rem!important;
		margin-left: -8rem!important
	}
	.mx-md-n13 {
		margin-right: -10rem!important;
		margin-left: -10rem!important
	}
	.mx-md-n14 {
		margin-right: -12rem!important;
		margin-left: -12rem!important
	}
	.mx-md-n15 {
		margin-right: -16rem!important;
		margin-left: -16rem!important
	}
	.mx-md-n16 {
		margin-right: -25rem!important;
		margin-left: -25rem!important
	}
	.my-md-n1 {
		margin-top: -.25rem!important;
		margin-bottom: -.25rem!important
	}
	.my-md-n2 {
		margin-top: -.5rem!important;
		margin-bottom: -.5rem!important
	}
	.my-md-n3 {
		margin-top: -.75rem!important;
		margin-bottom: -.75rem!important
	}
	.my-md-n4 {
		margin-top: -1rem!important;
		margin-bottom: -1rem!important
	}
	.my-md-n5 {
		margin-top: -1.5rem!important;
		margin-bottom: -1.5rem!important
	}
	.my-md-n6 {
		margin-top: -2rem!important;
		margin-bottom: -2rem!important
	}
	.my-md-n7 {
		margin-top: -2.5rem!important;
		margin-bottom: -2.5rem!important
	}
	.my-md-n8 {
		margin-top: -3rem!important;
		margin-bottom: -3rem!important
	}
	.my-md-n9 {
		margin-top: -4rem!important;
		margin-bottom: -4rem!important
	}
	.my-md-n10 {
		margin-top: -5rem!important;
		margin-bottom: -5rem!important
	}
	.my-md-n11 {
		margin-top: -6rem!important;
		margin-bottom: -6rem!important
	}
	.my-md-n12 {
		margin-top: -8rem!important;
		margin-bottom: -8rem!important
	}
	.my-md-n13 {
		margin-top: -10rem!important;
		margin-bottom: -10rem!important
	}
	.my-md-n14 {
		margin-top: -12rem!important;
		margin-bottom: -12rem!important
	}
	.my-md-n15 {
		margin-top: -16rem!important;
		margin-bottom: -16rem!important
	}
	.my-md-n16 {
		margin-top: -25rem!important;
		margin-bottom: -25rem!important
	}
	.mt-md-n1 {
		margin-top: -.25rem!important
	}
	.mt-md-n2 {
		margin-top: -.5rem!important
	}
	.mt-md-n3 {
		margin-top: -.75rem!important
	}
	.mt-md-n4 {
		margin-top: -1rem!important
	}
	.mt-md-n5 {
		margin-top: -1.5rem!important
	}
	.mt-md-n6 {
		margin-top: -2rem!important
	}
	.mt-md-n7 {
		margin-top: -2.5rem!important
	}
	.mt-md-n8 {
		margin-top: -3rem!important
	}
	.mt-md-n9 {
		margin-top: -4rem!important
	}
	.mt-md-n10 {
		margin-top: -5rem!important
	}
	.mt-md-n11 {
		margin-top: -6rem!important
	}
	.mt-md-n12 {
		margin-top: -8rem!important
	}
	.mt-md-n13 {
		margin-top: -10rem!important
	}
	.mt-md-n14 {
		margin-top: -12rem!important
	}
	.mt-md-n15 {
		margin-top: -16rem!important
	}
	.mt-md-n16 {
		margin-top: -25rem!important
	}
	.me-md-n1 {
		margin-right: -.25rem!important
	}
	.me-md-n2 {
		margin-right: -.5rem!important
	}
	.me-md-n3 {
		margin-right: -.75rem!important
	}
	.me-md-n4 {
		margin-right: -1rem!important
	}
	.me-md-n5 {
		margin-right: -1.5rem!important
	}
	.me-md-n6 {
		margin-right: -2rem!important
	}
	.me-md-n7 {
		margin-right: -2.5rem!important
	}
	.me-md-n8 {
		margin-right: -3rem!important
	}
	.me-md-n9 {
		margin-right: -4rem!important
	}
	.me-md-n10 {
		margin-right: -5rem!important
	}
	.me-md-n11 {
		margin-right: -6rem!important
	}
	.me-md-n12 {
		margin-right: -8rem!important
	}
	.me-md-n13 {
		margin-right: -10rem!important
	}
	.me-md-n14 {
		margin-right: -12rem!important
	}
	.me-md-n15 {
		margin-right: -16rem!important
	}
	.me-md-n16 {
		margin-right: -25rem!important
	}
	.mb-md-n1 {
		margin-bottom: -.25rem!important
	}
	.mb-md-n2 {
		margin-bottom: -.5rem!important
	}
	.mb-md-n3 {
		margin-bottom: -.75rem!important
	}
	.mb-md-n4 {
		margin-bottom: -1rem!important
	}
	.mb-md-n5 {
		margin-bottom: -1.5rem!important
	}
	.mb-md-n6 {
		margin-bottom: -2rem!important
	}
	.mb-md-n7 {
		margin-bottom: -2.5rem!important
	}
	.mb-md-n8 {
		margin-bottom: -3rem!important
	}
	.mb-md-n9 {
		margin-bottom: -4rem!important
	}
	.mb-md-n10 {
		margin-bottom: -5rem!important
	}
	.mb-md-n11 {
		margin-bottom: -6rem!important
	}
	.mb-md-n12 {
		margin-bottom: -8rem!important
	}
	.mb-md-n13 {
		margin-bottom: -10rem!important
	}
	.mb-md-n14 {
		margin-bottom: -12rem!important
	}
	.mb-md-n15 {
		margin-bottom: -16rem!important
	}
	.mb-md-n16 {
		margin-bottom: -25rem!important
	}
	.ms-md-n1 {
		margin-left: -.25rem!important
	}
	.ms-md-n2 {
		margin-left: -.5rem!important
	}
	.ms-md-n3 {
		margin-left: -.75rem!important
	}
	.ms-md-n4 {
		margin-left: -1rem!important
	}
	.ms-md-n5 {
		margin-left: -1.5rem!important
	}
	.ms-md-n6 {
		margin-left: -2rem!important
	}
	.ms-md-n7 {
		margin-left: -2.5rem!important
	}
	.ms-md-n8 {
		margin-left: -3rem!important
	}
	.ms-md-n9 {
		margin-left: -4rem!important
	}
	.ms-md-n10 {
		margin-left: -5rem!important
	}
	.ms-md-n11 {
		margin-left: -6rem!important
	}
	.ms-md-n12 {
		margin-left: -8rem!important
	}
	.ms-md-n13 {
		margin-left: -10rem!important
	}
	.ms-md-n14 {
		margin-left: -12rem!important
	}
	.ms-md-n15 {
		margin-left: -16rem!important
	}
	.ms-md-n16 {
		margin-left: -25rem!important
	}
	.p-md-0 {
		padding: 0!important
	}
	.p-md-1 {
		padding: .25rem!important
	}
	.p-md-2 {
		padding: .5rem!important
	}
	.p-md-3 {
		padding: .75rem!important
	}
	.p-md-4 {
		padding: 1rem!important
	}
	.p-md-5 {
		padding: 1.5rem!important
	}
	.p-md-6 {
		padding: 2rem!important
	}
	.p-md-7 {
		padding: 2.5rem!important
	}
	.p-md-8 {
		padding: 3rem!important
	}
	.p-md-9 {
		padding: 4rem!important
	}
	.p-md-10 {
		padding: 5rem!important
	}
	.p-md-11 {
		padding: 6rem!important
	}
	.p-md-12 {
		padding: 8rem!important
	}
	.p-md-13 {
		padding: 10rem!important
	}
	.p-md-14 {
		padding: 12rem!important
	}
	.p-md-15 {
		padding: 16rem!important
	}
	.p-md-16 {
		padding: 25rem!important
	}
	.px-md-0 {
		padding-right: 0!important;
		padding-left: 0!important
	}
	.px-md-1 {
		padding-right: .25rem!important;
		padding-left: .25rem!important
	}
	.px-md-2 {
		padding-right: .5rem!important;
		padding-left: .5rem!important
	}
	.px-md-3 {
		padding-right: .75rem!important;
		padding-left: .75rem!important
	}
	.px-md-4 {
		padding-right: 1rem!important;
		padding-left: 1rem!important
	}
	.px-md-5 {
		padding-right: 1.5rem!important;
		padding-left: 1.5rem!important
	}
	.px-md-6 {
		padding-right: 2rem!important;
		padding-left: 2rem!important
	}
	.px-md-7 {
		padding-right: 2.5rem!important;
		padding-left: 2.5rem!important
	}
	.px-md-8 {
		padding-right: 3rem!important;
		padding-left: 3rem!important
	}
	.px-md-9 {
		padding-right: 4rem!important;
		padding-left: 4rem!important
	}
	.px-md-10 {
		padding-right: 5rem!important;
		padding-left: 5rem!important
	}
	.px-md-11 {
		padding-right: 6rem!important;
		padding-left: 6rem!important
	}
	.px-md-12 {
		padding-right: 8rem!important;
		padding-left: 8rem!important
	}
	.px-md-13 {
		padding-right: 10rem!important;
		padding-left: 10rem!important
	}
	.px-md-14 {
		padding-right: 12rem!important;
		padding-left: 12rem!important
	}
	.px-md-15 {
		padding-right: 16rem!important;
		padding-left: 16rem!important
	}
	.px-md-16 {
		padding-right: 25rem!important;
		padding-left: 25rem!important
	}
	.py-md-0 {
		padding-top: 0!important;
		padding-bottom: 0!important
	}
	.py-md-1 {
		padding-top: .25rem!important;
		padding-bottom: .25rem!important
	}
	.py-md-2 {
		padding-top: .5rem!important;
		padding-bottom: .5rem!important
	}
	.py-md-3 {
		padding-top: .75rem!important;
		padding-bottom: .75rem!important
	}
	.py-md-4 {
		padding-top: 1rem!important;
		padding-bottom: 1rem!important
	}
	.py-md-5 {
		padding-top: 1.5rem!important;
		padding-bottom: 1.5rem!important
	}
	.py-md-6 {
		padding-top: 2rem!important;
		padding-bottom: 2rem!important
	}
	.py-md-7 {
		padding-top: 2.5rem!important;
		padding-bottom: 2.5rem!important
	}
	.py-md-8 {
		padding-top: 3rem!important;
		padding-bottom: 3rem!important
	}
	.py-md-9 {
		padding-top: 4rem!important;
		padding-bottom: 4rem!important
	}
	.py-md-10 {
		padding-top: 5rem!important;
		padding-bottom: 5rem!important
	}
	.py-md-11 {
		padding-top: 6rem!important;
		padding-bottom: 6rem!important
	}
	.py-md-12 {
		padding-top: 8rem!important;
		padding-bottom: 8rem!important
	}
	.py-md-13 {
		padding-top: 10rem!important;
		padding-bottom: 10rem!important
	}
	.py-md-14 {
		padding-top: 12rem!important;
		padding-bottom: 12rem!important
	}
	.py-md-15 {
		padding-top: 16rem!important;
		padding-bottom: 16rem!important
	}
	.py-md-16 {
		padding-top: 25rem!important;
		padding-bottom: 25rem!important
	}
	.pt-md-0 {
		padding-top: 0!important
	}
	.pt-md-1 {
		padding-top: .25rem!important
	}
	.pt-md-2 {
		padding-top: .5rem!important
	}
	.pt-md-3 {
		padding-top: .75rem!important
	}
	.pt-md-4 {
		padding-top: 1rem!important
	}
	.pt-md-5 {
		padding-top: 1.5rem!important
	}
	.pt-md-6 {
		padding-top: 2rem!important
	}
	.pt-md-7 {
		padding-top: 2.5rem!important
	}
	.pt-md-8 {
		padding-top: 3rem!important
	}
	.pt-md-9 {
		padding-top: 4rem!important
	}
	.pt-md-10 {
		padding-top: 5rem!important
	}
	.pt-md-11 {
		padding-top: 6rem!important
	}
	.pt-md-12 {
		padding-top: 8rem!important
	}
	.pt-md-13 {
		padding-top: 10rem!important
	}
	.pt-md-14 {
		padding-top: 12rem!important
	}
	.pt-md-15 {
		padding-top: 16rem!important
	}
	.pt-md-16 {
		padding-top: 25rem!important
	}
	.pe-md-0 {
		padding-right: 0!important
	}
	.pe-md-1 {
		padding-right: .25rem!important
	}
	.pe-md-2 {
		padding-right: .5rem!important
	}
	.pe-md-3 {
		padding-right: .75rem!important
	}
	.pe-md-4 {
		padding-right: 1rem!important
	}
	.pe-md-5 {
		padding-right: 1.5rem!important
	}
	.pe-md-6 {
		padding-right: 2rem!important
	}
	.pe-md-7 {
		padding-right: 2.5rem!important
	}
	.pe-md-8 {
		padding-right: 3rem!important
	}
	.pe-md-9 {
		padding-right: 4rem!important
	}
	.pe-md-10 {
		padding-right: 5rem!important
	}
	.pe-md-11 {
		padding-right: 6rem!important
	}
	.pe-md-12 {
		padding-right: 8rem!important
	}
	.pe-md-13 {
		padding-right: 10rem!important
	}
	.pe-md-14 {
		padding-right: 12rem!important
	}
	.pe-md-15 {
		padding-right: 16rem!important
	}
	.pe-md-16 {
		padding-right: 25rem!important
	}
	.pb-md-0 {
		padding-bottom: 0!important
	}
	.pb-md-1 {
		padding-bottom: .25rem!important
	}
	.pb-md-2 {
		padding-bottom: .5rem!important
	}
	.pb-md-3 {
		padding-bottom: .75rem!important
	}
	.pb-md-4 {
		padding-bottom: 1rem!important
	}
	.pb-md-5 {
		padding-bottom: 1.5rem!important
	}
	.pb-md-6 {
		padding-bottom: 2rem!important
	}
	.pb-md-7 {
		padding-bottom: 2.5rem!important
	}
	.pb-md-8 {
		padding-bottom: 3rem!important
	}
	.pb-md-9 {
		padding-bottom: 4rem!important
	}
	.pb-md-10 {
		padding-bottom: 5rem!important
	}
	.pb-md-11 {
		padding-bottom: 6rem!important
	}
	.pb-md-12 {
		padding-bottom: 8rem!important
	}
	.pb-md-13 {
		padding-bottom: 10rem!important
	}
	.pb-md-14 {
		padding-bottom: 12rem!important
	}
	.pb-md-15 {
		padding-bottom: 16rem!important
	}
	.pb-md-16 {
		padding-bottom: 25rem!important
	}
	.ps-md-0 {
		padding-left: 0!important
	}
	.ps-md-1 {
		padding-left: .25rem!important
	}
	.ps-md-2 {
		padding-left: .5rem!important
	}
	.ps-md-3 {
		padding-left: .75rem!important
	}
	.ps-md-4 {
		padding-left: 1rem!important
	}
	.ps-md-5 {
		padding-left: 1.5rem!important
	}
	.ps-md-6 {
		padding-left: 2rem!important
	}
	.ps-md-7 {
		padding-left: 2.5rem!important
	}
	.ps-md-8 {
		padding-left: 3rem!important
	}
	.ps-md-9 {
		padding-left: 4rem!important
	}
	.ps-md-10 {
		padding-left: 5rem!important
	}
	.ps-md-11 {
		padding-left: 6rem!important
	}
	.ps-md-12 {
		padding-left: 8rem!important
	}
	.ps-md-13 {
		padding-left: 10rem!important
	}
	.ps-md-14 {
		padding-left: 12rem!important
	}
	.ps-md-15 {
		padding-left: 16rem!important
	}
	.ps-md-16 {
		padding-left: 25rem!important
	}
	.text-md-start {
		text-align: left!important
	}
	.text-md-end {
		text-align: right!important
	}
	.text-md-center {
		text-align: center!important
	}
}

@media (min-width:992px) {
	.float-lg-start {
		float: left!important
	}
	.float-lg-end {
		float: right!important
	}
	.float-lg-none {
		float: none!important
	}
	.d-lg-inline {
		display: inline!important
	}
	.d-lg-inline-block {
		display: inline-block!important
	}
	.d-lg-block {
		display: block!important
	}
	.d-lg-grid {
		display: grid!important
	}
	.d-lg-table {
		display: table!important
	}
	.d-lg-table-row {
		display: table-row!important
	}
	.d-lg-table-cell {
		display: table-cell!important
	}
	.d-lg-flex {
		display: -webkit-box!important;
		display: flex!important
	}
	.d-lg-inline-flex {
		display: -webkit-inline-box!important;
		display: inline-flex!important
	}
	.d-lg-none {
		display: none!important
	}
	.flex-lg-fill {
		-webkit-box-flex: 1!important;
		flex: 1 1 auto!important
	}
	.flex-lg-row {
		-webkit-box-orient: horizontal!important;
		-webkit-box-direction: normal!important;
		flex-direction: row!important
	}
	.flex-lg-column {
		-webkit-box-orient: vertical!important;
		-webkit-box-direction: normal!important;
		flex-direction: column!important
	}
	.flex-lg-row-reverse {
		-webkit-box-orient: horizontal!important;
		-webkit-box-direction: reverse!important;
		flex-direction: row-reverse!important
	}
	.flex-lg-column-reverse {
		-webkit-box-orient: vertical!important;
		-webkit-box-direction: reverse!important;
		flex-direction: column-reverse!important
	}
	.flex-lg-grow-0 {
		-webkit-box-flex: 0!important;
		flex-grow: 0!important
	}
	.flex-lg-grow-1 {
		-webkit-box-flex: 1!important;
		flex-grow: 1!important
	}
	.flex-lg-shrink-0 {
		flex-shrink: 0!important
	}
	.flex-lg-shrink-1 {
		flex-shrink: 1!important
	}
	.flex-lg-wrap {
		flex-wrap: wrap!important
	}
	.flex-lg-nowrap {
		flex-wrap: nowrap!important
	}
	.flex-lg-wrap-reverse {
		flex-wrap: wrap-reverse!important
	}
	.gap-lg-0 {
		gap: 0!important
	}
	.gap-lg-1 {
		gap: .25rem!important
	}
	.gap-lg-2 {
		gap: .5rem!important
	}
	.gap-lg-3 {
		gap: .75rem!important
	}
	.gap-lg-4 {
		gap: 1rem!important
	}
	.gap-lg-5 {
		gap: 1.5rem!important
	}
	.gap-lg-6 {
		gap: 2rem!important
	}
	.gap-lg-7 {
		gap: 2.5rem!important
	}
	.gap-lg-8 {
		gap: 3rem!important
	}
	.gap-lg-9 {
		gap: 4rem!important
	}
	.gap-lg-10 {
		gap: 5rem!important
	}
	.gap-lg-11 {
		gap: 6rem!important
	}
	.gap-lg-12 {
		gap: 8rem!important
	}
	.gap-lg-13 {
		gap: 10rem!important
	}
	.gap-lg-14 {
		gap: 12rem!important
	}
	.gap-lg-15 {
		gap: 16rem!important
	}
	.gap-lg-16 {
		gap: 25rem!important
	}
	.justify-content-lg-start {
		-webkit-box-pack: start!important;
		justify-content: flex-start!important
	}
	.justify-content-lg-end {
		-webkit-box-pack: end!important;
		justify-content: flex-end!important
	}
	.justify-content-lg-center {
		-webkit-box-pack: center!important;
		justify-content: center!important
	}
	.justify-content-lg-between {
		-webkit-box-pack: justify!important;
		justify-content: space-between!important
	}
	.justify-content-lg-around {
		justify-content: space-around!important
	}
	.justify-content-lg-evenly {
		-webkit-box-pack: space-evenly!important;
		justify-content: space-evenly!important
	}
	.align-items-lg-start {
		-webkit-box-align: start!important;
		align-items: flex-start!important
	}
	.align-items-lg-end {
		-webkit-box-align: end!important;
		align-items: flex-end!important
	}
	.align-items-lg-center {
		-webkit-box-align: center!important;
		align-items: center!important
	}
	.align-items-lg-baseline {
		-webkit-box-align: baseline!important;
		align-items: baseline!important
	}
	.align-items-lg-stretch {
		-webkit-box-align: stretch!important;
		align-items: stretch!important
	}
	.align-content-lg-start {
		align-content: flex-start!important
	}
	.align-content-lg-end {
		align-content: flex-end!important
	}
	.align-content-lg-center {
		align-content: center!important
	}
	.align-content-lg-between {
		align-content: space-between!important
	}
	.align-content-lg-around {
		align-content: space-around!important
	}
	.align-content-lg-stretch {
		align-content: stretch!important
	}
	.align-self-lg-auto {
		align-self: auto!important
	}
	.align-self-lg-start {
		align-self: flex-start!important
	}
	.align-self-lg-end {
		align-self: flex-end!important
	}
	.align-self-lg-center {
		align-self: center!important
	}
	.align-self-lg-baseline {
		align-self: baseline!important
	}
	.align-self-lg-stretch {
		align-self: stretch!important
	}
	.order-lg-first {
		-webkit-box-ordinal-group: 0!important;
		order: -1!important
	}
	.order-lg-0 {
		-webkit-box-ordinal-group: 1!important;
		order: 0!important
	}
	.order-lg-1 {
		-webkit-box-ordinal-group: 2!important;
		order: 1!important
	}
	.order-lg-2 {
		-webkit-box-ordinal-group: 3!important;
		order: 2!important
	}
	.order-lg-3 {
		-webkit-box-ordinal-group: 4!important;
		order: 3!important
	}
	.order-lg-4 {
		-webkit-box-ordinal-group: 5!important;
		order: 4!important
	}
	.order-lg-5 {
		-webkit-box-ordinal-group: 6!important;
		order: 5!important
	}
	.order-lg-last {
		-webkit-box-ordinal-group: 7!important;
		order: 6!important
	}
	.m-lg-0 {
		margin: 0!important
	}
	.m-lg-1 {
		margin: .25rem!important
	}
	.m-lg-2 {
		margin: .5rem!important
	}
	.m-lg-3 {
		margin: .75rem!important
	}
	.m-lg-4 {
		margin: 1rem!important
	}
	.m-lg-5 {
		margin: 1.5rem!important
	}
	.m-lg-6 {
		margin: 2rem!important
	}
	.m-lg-7 {
		margin: 2.5rem!important
	}
	.m-lg-8 {
		margin: 3rem!important
	}
	.m-lg-9 {
		margin: 4rem!important
	}
	.m-lg-10 {
		margin: 5rem!important
	}
	.m-lg-11 {
		margin: 6rem!important
	}
	.m-lg-12 {
		margin: 8rem!important
	}
	.m-lg-13 {
		margin: 10rem!important
	}
	.m-lg-14 {
		margin: 12rem!important
	}
	.m-lg-15 {
		margin: 16rem!important
	}
	.m-lg-16 {
		margin: 25rem!important
	}
	.m-lg-auto {
		margin: auto!important
	}
	.mx-lg-0 {
		margin-right: 0!important;
		margin-left: 0!important
	}
	.mx-lg-1 {
		margin-right: .25rem!important;
		margin-left: .25rem!important
	}
	.mx-lg-2 {
		margin-right: .5rem!important;
		margin-left: .5rem!important
	}
	.mx-lg-3 {
		margin-right: .75rem!important;
		margin-left: .75rem!important
	}
	.mx-lg-4 {
		margin-right: 1rem!important;
		margin-left: 1rem!important
	}
	.mx-lg-5 {
		margin-right: 1.5rem!important;
		margin-left: 1.5rem!important
	}
	.mx-lg-6 {
		margin-right: 2rem!important;
		margin-left: 2rem!important
	}
	.mx-lg-7 {
		margin-right: 2.5rem!important;
		margin-left: 2.5rem!important
	}
	.mx-lg-8 {
		margin-right: 3rem!important;
		margin-left: 3rem!important
	}
	.mx-lg-9 {
		margin-right: 4rem!important;
		margin-left: 4rem!important
	}
	.mx-lg-10 {
		margin-right: 5rem!important;
		margin-left: 5rem!important
	}
	.mx-lg-11 {
		margin-right: 6rem!important;
		margin-left: 6rem!important
	}
	.mx-lg-12 {
		margin-right: 8rem!important;
		margin-left: 8rem!important
	}
	.mx-lg-13 {
		margin-right: 10rem!important;
		margin-left: 10rem!important
	}
	.mx-lg-14 {
		margin-right: 12rem!important;
		margin-left: 12rem!important
	}
	.mx-lg-15 {
		margin-right: 16rem!important;
		margin-left: 16rem!important
	}
	.mx-lg-16 {
		margin-right: 25rem!important;
		margin-left: 25rem!important
	}
	.mx-lg-auto {
		margin-right: auto!important;
		margin-left: auto!important
	}
	.my-lg-0 {
		margin-top: 0!important;
		margin-bottom: 0!important
	}
	.my-lg-1 {
		margin-top: .25rem!important;
		margin-bottom: .25rem!important
	}
	.my-lg-2 {
		margin-top: .5rem!important;
		margin-bottom: .5rem!important
	}
	.my-lg-3 {
		margin-top: .75rem!important;
		margin-bottom: .75rem!important
	}
	.my-lg-4 {
		margin-top: 1rem!important;
		margin-bottom: 1rem!important
	}
	.my-lg-5 {
		margin-top: 1.5rem!important;
		margin-bottom: 1.5rem!important
	}
	.my-lg-6 {
		margin-top: 2rem!important;
		margin-bottom: 2rem!important
	}
	.my-lg-7 {
		margin-top: 2.5rem!important;
		margin-bottom: 2.5rem!important
	}
	.my-lg-8 {
		margin-top: 3rem!important;
		margin-bottom: 3rem!important
	}
	.my-lg-9 {
		margin-top: 4rem!important;
		margin-bottom: 4rem!important
	}
	.my-lg-10 {
		margin-top: 5rem!important;
		margin-bottom: 5rem!important
	}
	.my-lg-11 {
		margin-top: 6rem!important;
		margin-bottom: 6rem!important
	}
	.my-lg-12 {
		margin-top: 8rem!important;
		margin-bottom: 8rem!important
	}
	.my-lg-13 {
		margin-top: 10rem!important;
		margin-bottom: 10rem!important
	}
	.my-lg-14 {
		margin-top: 12rem!important;
		margin-bottom: 12rem!important
	}
	.my-lg-15 {
		margin-top: 16rem!important;
		margin-bottom: 16rem!important
	}
	.my-lg-16 {
		margin-top: 25rem!important;
		margin-bottom: 25rem!important
	}
	.my-lg-auto {
		margin-top: auto!important;
		margin-bottom: auto!important
	}
	.mt-lg-0 {
		margin-top: 0!important
	}
	.mt-lg-1 {
		margin-top: .25rem!important
	}
	.mt-lg-2 {
		margin-top: .5rem!important
	}
	.mt-lg-3 {
		margin-top: .75rem!important
	}
	.mt-lg-4 {
		margin-top: 1rem!important
	}
	.mt-lg-5 {
		margin-top: 1.5rem!important
	}
	.mt-lg-6 {
		margin-top: 2rem!important
	}
	.mt-lg-7 {
		margin-top: 2.5rem!important
	}
	.mt-lg-8 {
		margin-top: 3rem!important
	}
	.mt-lg-9 {
		margin-top: 4rem!important
	}
	.mt-lg-10 {
		margin-top: 5rem!important
	}
	.mt-lg-11 {
		margin-top: 6rem!important
	}
	.mt-lg-12 {
		margin-top: 8rem!important
	}
	.mt-lg-13 {
		margin-top: 10rem!important
	}
	.mt-lg-14 {
		margin-top: 12rem!important
	}
	.mt-lg-15 {
		margin-top: 16rem!important
	}
	.mt-lg-16 {
		margin-top: 25rem!important
	}
	.mt-lg-auto {
		margin-top: auto!important
	}
	.me-lg-0 {
		margin-right: 0!important
	}
	.me-lg-1 {
		margin-right: .25rem!important
	}
	.me-lg-2 {
		margin-right: .5rem!important
	}
	.me-lg-3 {
		margin-right: .75rem!important
	}
	.me-lg-4 {
		margin-right: 1rem!important
	}
	.me-lg-5 {
		margin-right: 1.5rem!important
	}
	.me-lg-6 {
		margin-right: 2rem!important
	}
	.me-lg-7 {
		margin-right: 2.5rem!important
	}
	.me-lg-8 {
		margin-right: 3rem!important
	}
	.me-lg-9 {
		margin-right: 4rem!important
	}
	.me-lg-10 {
		margin-right: 5rem!important
	}
	.me-lg-11 {
		margin-right: 6rem!important
	}
	.me-lg-12 {
		margin-right: 8rem!important
	}
	.me-lg-13 {
		margin-right: 10rem!important
	}
	.me-lg-14 {
		margin-right: 12rem!important
	}
	.me-lg-15 {
		margin-right: 16rem!important
	}
	.me-lg-16 {
		margin-right: 25rem!important
	}
	.me-lg-auto {
		margin-right: auto!important
	}
	.mb-lg-0 {
		margin-bottom: 0!important
	}
	.mb-lg-1 {
		margin-bottom: .25rem!important
	}
	.mb-lg-2 {
		margin-bottom: .5rem!important
	}
	.mb-lg-3 {
		margin-bottom: .75rem!important
	}
	.mb-lg-4 {
		margin-bottom: 1rem!important
	}
	.mb-lg-5 {
		margin-bottom: 1.5rem!important
	}
	.mb-lg-6 {
		margin-bottom: 2rem!important
	}
	.mb-lg-7 {
		margin-bottom: 2.5rem!important
	}
	.mb-lg-8 {
		margin-bottom: 3rem!important
	}
	.mb-lg-9 {
		margin-bottom: 4rem!important
	}
	.mb-lg-10 {
		margin-bottom: 5rem!important
	}
	.mb-lg-11 {
		margin-bottom: 6rem!important
	}
	.mb-lg-12 {
		margin-bottom: 8rem!important
	}
	.mb-lg-13 {
		margin-bottom: 10rem!important
	}
	.mb-lg-14 {
		margin-bottom: 12rem!important
	}
	.mb-lg-15 {
		margin-bottom: 16rem!important
	}
	.mb-lg-16 {
		margin-bottom: 25rem!important
	}
	.mb-lg-auto {
		margin-bottom: auto!important
	}
	.ms-lg-0 {
		margin-left: 0!important
	}
	.ms-lg-1 {
		margin-left: .25rem!important
	}
	.ms-lg-2 {
		margin-left: .5rem!important
	}
	.ms-lg-3 {
		margin-left: .75rem!important
	}
	.ms-lg-4 {
		margin-left: 1rem!important
	}
	.ms-lg-5 {
		margin-left: 1.5rem!important
	}
	.ms-lg-6 {
		margin-left: 2rem!important
	}
	.ms-lg-7 {
		margin-left: 2.5rem!important
	}
	.ms-lg-8 {
		margin-left: 3rem!important
	}
	.ms-lg-9 {
		margin-left: 4rem!important
	}
	.ms-lg-10 {
		margin-left: 5rem!important
	}
	.ms-lg-11 {
		margin-left: 6rem!important
	}
	.ms-lg-12 {
		margin-left: 8rem!important
	}
	.ms-lg-13 {
		margin-left: 10rem!important
	}
	.ms-lg-14 {
		margin-left: 12rem!important
	}
	.ms-lg-15 {
		margin-left: 16rem!important
	}
	.ms-lg-16 {
		margin-left: 25rem!important
	}
	.ms-lg-auto {
		margin-left: auto!important
	}
	.m-lg-n1 {
		margin: -.25rem!important
	}
	.m-lg-n2 {
		margin: -.5rem!important
	}
	.m-lg-n3 {
		margin: -.75rem!important
	}
	.m-lg-n4 {
		margin: -1rem!important
	}
	.m-lg-n5 {
		margin: -1.5rem!important
	}
	.m-lg-n6 {
		margin: -2rem!important
	}
	.m-lg-n7 {
		margin: -2.5rem!important
	}
	.m-lg-n8 {
		margin: -3rem!important
	}
	.m-lg-n9 {
		margin: -4rem!important
	}
	.m-lg-n10 {
		margin: -5rem!important
	}
	.m-lg-n11 {
		margin: -6rem!important
	}
	.m-lg-n12 {
		margin: -8rem!important
	}
	.m-lg-n13 {
		margin: -10rem!important
	}
	.m-lg-n14 {
		margin: -12rem!important
	}
	.m-lg-n15 {
		margin: -16rem!important
	}
	.m-lg-n16 {
		margin: -25rem!important
	}
	.mx-lg-n1 {
		margin-right: -.25rem!important;
		margin-left: -.25rem!important
	}
	.mx-lg-n2 {
		margin-right: -.5rem!important;
		margin-left: -.5rem!important
	}
	.mx-lg-n3 {
		margin-right: -.75rem!important;
		margin-left: -.75rem!important
	}
	.mx-lg-n4 {
		margin-right: -1rem!important;
		margin-left: -1rem!important
	}
	.mx-lg-n5 {
		margin-right: -1.5rem!important;
		margin-left: -1.5rem!important
	}
	.mx-lg-n6 {
		margin-right: -2rem!important;
		margin-left: -2rem!important
	}
	.mx-lg-n7 {
		margin-right: -2.5rem!important;
		margin-left: -2.5rem!important
	}
	.mx-lg-n8 {
		margin-right: -3rem!important;
		margin-left: -3rem!important
	}
	.mx-lg-n9 {
		margin-right: -4rem!important;
		margin-left: -4rem!important
	}
	.mx-lg-n10 {
		margin-right: -5rem!important;
		margin-left: -5rem!important
	}
	.mx-lg-n11 {
		margin-right: -6rem!important;
		margin-left: -6rem!important
	}
	.mx-lg-n12 {
		margin-right: -8rem!important;
		margin-left: -8rem!important
	}
	.mx-lg-n13 {
		margin-right: -10rem!important;
		margin-left: -10rem!important
	}
	.mx-lg-n14 {
		margin-right: -12rem!important;
		margin-left: -12rem!important
	}
	.mx-lg-n15 {
		margin-right: -16rem!important;
		margin-left: -16rem!important
	}
	.mx-lg-n16 {
		margin-right: -25rem!important;
		margin-left: -25rem!important
	}
	.my-lg-n1 {
		margin-top: -.25rem!important;
		margin-bottom: -.25rem!important
	}
	.my-lg-n2 {
		margin-top: -.5rem!important;
		margin-bottom: -.5rem!important
	}
	.my-lg-n3 {
		margin-top: -.75rem!important;
		margin-bottom: -.75rem!important
	}
	.my-lg-n4 {
		margin-top: -1rem!important;
		margin-bottom: -1rem!important
	}
	.my-lg-n5 {
		margin-top: -1.5rem!important;
		margin-bottom: -1.5rem!important
	}
	.my-lg-n6 {
		margin-top: -2rem!important;
		margin-bottom: -2rem!important
	}
	.my-lg-n7 {
		margin-top: -2.5rem!important;
		margin-bottom: -2.5rem!important
	}
	.my-lg-n8 {
		margin-top: -3rem!important;
		margin-bottom: -3rem!important
	}
	.my-lg-n9 {
		margin-top: -4rem!important;
		margin-bottom: -4rem!important
	}
	.my-lg-n10 {
		margin-top: -5rem!important;
		margin-bottom: -5rem!important
	}
	.my-lg-n11 {
		margin-top: -6rem!important;
		margin-bottom: -6rem!important
	}
	.my-lg-n12 {
		margin-top: -8rem!important;
		margin-bottom: -8rem!important
	}
	.my-lg-n13 {
		margin-top: -10rem!important;
		margin-bottom: -10rem!important
	}
	.my-lg-n14 {
		margin-top: -12rem!important;
		margin-bottom: -12rem!important
	}
	.my-lg-n15 {
		margin-top: -16rem!important;
		margin-bottom: -16rem!important
	}
	.my-lg-n16 {
		margin-top: -25rem!important;
		margin-bottom: -25rem!important
	}
	.mt-lg-n1 {
		margin-top: -.25rem!important
	}
	.mt-lg-n2 {
		margin-top: -.5rem!important
	}
	.mt-lg-n3 {
		margin-top: -.75rem!important
	}
	.mt-lg-n4 {
		margin-top: -1rem!important
	}
	.mt-lg-n5 {
		margin-top: -1.5rem!important
	}
	.mt-lg-n6 {
		margin-top: -2rem!important
	}
	.mt-lg-n7 {
		margin-top: -2.5rem!important
	}
	.mt-lg-n8 {
		margin-top: -3rem!important
	}
	.mt-lg-n9 {
		margin-top: -4rem!important
	}
	.mt-lg-n10 {
		margin-top: -5rem!important
	}
	.mt-lg-n11 {
		margin-top: -6rem!important
	}
	.mt-lg-n12 {
		margin-top: -8rem!important
	}
	.mt-lg-n13 {
		margin-top: -10rem!important
	}
	.mt-lg-n14 {
		margin-top: -12rem!important
	}
	.mt-lg-n15 {
		margin-top: -16rem!important
	}
	.mt-lg-n16 {
		margin-top: -25rem!important
	}
	.me-lg-n1 {
		margin-right: -.25rem!important
	}
	.me-lg-n2 {
		margin-right: -.5rem!important
	}
	.me-lg-n3 {
		margin-right: -.75rem!important
	}
	.me-lg-n4 {
		margin-right: -1rem!important
	}
	.me-lg-n5 {
		margin-right: -1.5rem!important
	}
	.me-lg-n6 {
		margin-right: -2rem!important
	}
	.me-lg-n7 {
		margin-right: -2.5rem!important
	}
	.me-lg-n8 {
		margin-right: -3rem!important
	}
	.me-lg-n9 {
		margin-right: -4rem!important
	}
	.me-lg-n10 {
		margin-right: -5rem!important
	}
	.me-lg-n11 {
		margin-right: -6rem!important
	}
	.me-lg-n12 {
		margin-right: -8rem!important
	}
	.me-lg-n13 {
		margin-right: -10rem!important
	}
	.me-lg-n14 {
		margin-right: -12rem!important
	}
	.me-lg-n15 {
		margin-right: -16rem!important
	}
	.me-lg-n16 {
		margin-right: -25rem!important
	}
	.mb-lg-n1 {
		margin-bottom: -.25rem!important
	}
	.mb-lg-n2 {
		margin-bottom: -.5rem!important
	}
	.mb-lg-n3 {
		margin-bottom: -.75rem!important
	}
	.mb-lg-n4 {
		margin-bottom: -1rem!important
	}
	.mb-lg-n5 {
		margin-bottom: -1.5rem!important
	}
	.mb-lg-n6 {
		margin-bottom: -2rem!important
	}
	.mb-lg-n7 {
		margin-bottom: -2.5rem!important
	}
	.mb-lg-n8 {
		margin-bottom: -3rem!important
	}
	.mb-lg-n9 {
		margin-bottom: -4rem!important
	}
	.mb-lg-n10 {
		margin-bottom: -5rem!important
	}
	.mb-lg-n11 {
		margin-bottom: -6rem!important
	}
	.mb-lg-n12 {
		margin-bottom: -8rem!important
	}
	.mb-lg-n13 {
		margin-bottom: -10rem!important
	}
	.mb-lg-n14 {
		margin-bottom: -12rem!important
	}
	.mb-lg-n15 {
		margin-bottom: -16rem!important
	}
	.mb-lg-n16 {
		margin-bottom: -25rem!important
	}
	.ms-lg-n1 {
		margin-left: -.25rem!important
	}
	.ms-lg-n2 {
		margin-left: -.5rem!important
	}
	.ms-lg-n3 {
		margin-left: -.75rem!important
	}
	.ms-lg-n4 {
		margin-left: -1rem!important
	}
	.ms-lg-n5 {
		margin-left: -1.5rem!important
	}
	.ms-lg-n6 {
		margin-left: -2rem!important
	}
	.ms-lg-n7 {
		margin-left: -2.5rem!important
	}
	.ms-lg-n8 {
		margin-left: -3rem!important
	}
	.ms-lg-n9 {
		margin-left: -4rem!important
	}
	.ms-lg-n10 {
		margin-left: -5rem!important
	}
	.ms-lg-n11 {
		margin-left: -6rem!important
	}
	.ms-lg-n12 {
		margin-left: -8rem!important
	}
	.ms-lg-n13 {
		margin-left: -10rem!important
	}
	.ms-lg-n14 {
		margin-left: -12rem!important
	}
	.ms-lg-n15 {
		margin-left: -16rem!important
	}
	.ms-lg-n16 {
		margin-left: -25rem!important
	}
	.p-lg-0 {
		padding: 0!important
	}
	.p-lg-1 {
		padding: .25rem!important
	}
	.p-lg-2 {
		padding: .5rem!important
	}
	.p-lg-3 {
		padding: .75rem!important
	}
	.p-lg-4 {
		padding: 1rem!important
	}
	.p-lg-5 {
		padding: 1.5rem!important
	}
	.p-lg-6 {
		padding: 2rem!important
	}
	.p-lg-7 {
		padding: 2.5rem!important
	}
	.p-lg-8 {
		padding: 3rem!important
	}
	.p-lg-9 {
		padding: 4rem!important
	}
	.p-lg-10 {
		padding: 5rem!important
	}
	.p-lg-11 {
		padding: 6rem!important
	}
	.p-lg-12 {
		padding: 8rem!important
	}
	.p-lg-13 {
		padding: 10rem!important
	}
	.p-lg-14 {
		padding: 12rem!important
	}
	.p-lg-15 {
		padding: 16rem!important
	}
	.p-lg-16 {
		padding: 25rem!important
	}
	.px-lg-0 {
		padding-right: 0!important;
		padding-left: 0!important
	}
	.px-lg-1 {
		padding-right: .25rem!important;
		padding-left: .25rem!important
	}
	.px-lg-2 {
		padding-right: .5rem!important;
		padding-left: .5rem!important
	}
	.px-lg-3 {
		padding-right: .75rem!important;
		padding-left: .75rem!important
	}
	.px-lg-4 {
		padding-right: 1rem!important;
		padding-left: 1rem!important
	}
	.px-lg-5 {
		padding-right: 1.5rem!important;
		padding-left: 1.5rem!important
	}
	.px-lg-6 {
		padding-right: 2rem!important;
		padding-left: 2rem!important
	}
	.px-lg-7 {
		padding-right: 2.5rem!important;
		padding-left: 2.5rem!important
	}
	.px-lg-8 {
		padding-right: 3rem!important;
		padding-left: 3rem!important
	}
	.px-lg-9 {
		padding-right: 4rem!important;
		padding-left: 4rem!important
	}
	.px-lg-10 {
		padding-right: 5rem!important;
		padding-left: 5rem!important
	}
	.px-lg-11 {
		padding-right: 6rem!important;
		padding-left: 6rem!important
	}
	.px-lg-12 {
		padding-right: 8rem!important;
		padding-left: 8rem!important
	}
	.px-lg-13 {
		padding-right: 10rem!important;
		padding-left: 10rem!important
	}
	.px-lg-14 {
		padding-right: 12rem!important;
		padding-left: 12rem!important
	}
	.px-lg-15 {
		padding-right: 16rem!important;
		padding-left: 16rem!important
	}
	.px-lg-16 {
		padding-right: 25rem!important;
		padding-left: 25rem!important
	}
	.py-lg-0 {
		padding-top: 0!important;
		padding-bottom: 0!important
	}
	.py-lg-1 {
		padding-top: .25rem!important;
		padding-bottom: .25rem!important
	}
	.py-lg-2 {
		padding-top: .5rem!important;
		padding-bottom: .5rem!important
	}
	.py-lg-3 {
		padding-top: .75rem!important;
		padding-bottom: .75rem!important
	}
	.py-lg-4 {
		padding-top: 1rem!important;
		padding-bottom: 1rem!important
	}
	.py-lg-5 {
		padding-top: 1.5rem!important;
		padding-bottom: 1.5rem!important
	}
	.py-lg-6 {
		padding-top: 2rem!important;
		padding-bottom: 2rem!important
	}
	.py-lg-7 {
		padding-top: 2.5rem!important;
		padding-bottom: 2.5rem!important
	}
	.py-lg-8 {
		padding-top: 3rem!important;
		padding-bottom: 3rem!important
	}
	.py-lg-9 {
		padding-top: 4rem!important;
		padding-bottom: 4rem!important
	}
	.py-lg-10 {
		padding-top: 5rem!important;
		padding-bottom: 5rem!important
	}
	.py-lg-11 {
		padding-top: 6rem!important;
		padding-bottom: 6rem!important
	}
	.py-lg-12 {
		padding-top: 8rem!important;
		padding-bottom: 8rem!important
	}
	.py-lg-13 {
		padding-top: 10rem!important;
		padding-bottom: 10rem!important
	}
	.py-lg-14 {
		padding-top: 12rem!important;
		padding-bottom: 12rem!important
	}
	.py-lg-15 {
		padding-top: 16rem!important;
		padding-bottom: 16rem!important
	}
	.py-lg-16 {
		padding-top: 25rem!important;
		padding-bottom: 25rem!important
	}
	.pt-lg-0 {
		padding-top: 0!important
	}
	.pt-lg-1 {
		padding-top: .25rem!important
	}
	.pt-lg-2 {
		padding-top: .5rem!important
	}
	.pt-lg-3 {
		padding-top: .75rem!important
	}
	.pt-lg-4 {
		padding-top: 1rem!important
	}
	.pt-lg-5 {
		padding-top: 1.5rem!important
	}
	.pt-lg-6 {
		padding-top: 2rem!important
	}
	.pt-lg-7 {
		padding-top: 2.5rem!important
	}
	.pt-lg-8 {
		padding-top: 3rem!important
	}
	.pt-lg-9 {
		padding-top: 4rem!important
	}
	.pt-lg-10 {
		padding-top: 5rem!important
	}
	.pt-lg-11 {
		padding-top: 6rem!important
	}
	.pt-lg-12 {
		padding-top: 8rem!important
	}
	.pt-lg-13 {
		padding-top: 10rem!important
	}
	.pt-lg-14 {
		padding-top: 12rem!important
	}
	.pt-lg-15 {
		padding-top: 16rem!important
	}
	.pt-lg-16 {
		padding-top: 25rem!important
	}
	.pe-lg-0 {
		padding-right: 0!important
	}
	.pe-lg-1 {
		padding-right: .25rem!important
	}
	.pe-lg-2 {
		padding-right: .5rem!important
	}
	.pe-lg-3 {
		padding-right: .75rem!important
	}
	.pe-lg-4 {
		padding-right: 1rem!important
	}
	.pe-lg-5 {
		padding-right: 1.5rem!important
	}
	.pe-lg-6 {
		padding-right: 2rem!important
	}
	.pe-lg-7 {
		padding-right: 2.5rem!important
	}
	.pe-lg-8 {
		padding-right: 3rem!important
	}
	.pe-lg-9 {
		padding-right: 4rem!important
	}
	.pe-lg-10 {
		padding-right: 5rem!important
	}
	.pe-lg-11 {
		padding-right: 6rem!important
	}
	.pe-lg-12 {
		padding-right: 8rem!important
	}
	.pe-lg-13 {
		padding-right: 10rem!important
	}
	.pe-lg-14 {
		padding-right: 12rem!important
	}
	.pe-lg-15 {
		padding-right: 16rem!important
	}
	.pe-lg-16 {
		padding-right: 25rem!important
	}
	.pb-lg-0 {
		padding-bottom: 0!important
	}
	.pb-lg-1 {
		padding-bottom: .25rem!important
	}
	.pb-lg-2 {
		padding-bottom: .5rem!important
	}
	.pb-lg-3 {
		padding-bottom: .75rem!important
	}
	.pb-lg-4 {
		padding-bottom: 1rem!important
	}
	.pb-lg-5 {
		padding-bottom: 1.5rem!important
	}
	.pb-lg-6 {
		padding-bottom: 2rem!important
	}
	.pb-lg-7 {
		padding-bottom: 2.5rem!important
	}
	.pb-lg-8 {
		padding-bottom: 3rem!important
	}
	.pb-lg-9 {
		padding-bottom: 4rem!important
	}
	.pb-lg-10 {
		padding-bottom: 5rem!important
	}
	.pb-lg-11 {
		padding-bottom: 6rem!important
	}
	.pb-lg-12 {
		padding-bottom: 8rem!important
	}
	.pb-lg-13 {
		padding-bottom: 10rem!important
	}
	.pb-lg-14 {
		padding-bottom: 12rem!important
	}
	.pb-lg-15 {
		padding-bottom: 16rem!important
	}
	.pb-lg-16 {
		padding-bottom: 25rem!important
	}
	.ps-lg-0 {
		padding-left: 0!important
	}
	.ps-lg-1 {
		padding-left: .25rem!important
	}
	.ps-lg-2 {
		padding-left: .5rem!important
	}
	.ps-lg-3 {
		padding-left: .75rem!important
	}
	.ps-lg-4 {
		padding-left: 1rem!important
	}
	.ps-lg-5 {
		padding-left: 1.5rem!important
	}
	.ps-lg-6 {
		padding-left: 2rem!important
	}
	.ps-lg-7 {
		padding-left: 2.5rem!important
	}
	.ps-lg-8 {
		padding-left: 3rem!important
	}
	.ps-lg-9 {
		padding-left: 4rem!important
	}
	.ps-lg-10 {
		padding-left: 5rem!important
	}
	.ps-lg-11 {
		padding-left: 6rem!important
	}
	.ps-lg-12 {
		padding-left: 8rem!important
	}
	.ps-lg-13 {
		padding-left: 10rem!important
	}
	.ps-lg-14 {
		padding-left: 12rem!important
	}
	.ps-lg-15 {
		padding-left: 16rem!important
	}
	.ps-lg-16 {
		padding-left: 25rem!important
	}
	.text-lg-start {
		text-align: left!important
	}
	.text-lg-end {
		text-align: right!important
	}
	.text-lg-center {
		text-align: center!important
	}
}

@media (min-width:1200px) {
	.float-xl-start {
		float: left!important
	}
	.float-xl-end {
		float: right!important
	}
	.float-xl-none {
		float: none!important
	}
	.d-xl-inline {
		display: inline!important
	}
	.d-xl-inline-block {
		display: inline-block!important
	}
	.d-xl-block {
		display: block!important
	}
	.d-xl-grid {
		display: grid!important
	}
	.d-xl-table {
		display: table!important
	}
	.d-xl-table-row {
		display: table-row!important
	}
	.d-xl-table-cell {
		display: table-cell!important
	}
	.d-xl-flex {
		display: -webkit-box!important;
		display: flex!important
	}
	.d-xl-inline-flex {
		display: -webkit-inline-box!important;
		display: inline-flex!important
	}
	.d-xl-none {
		display: none!important
	}
	.flex-xl-fill {
		-webkit-box-flex: 1!important;
		flex: 1 1 auto!important
	}
	.flex-xl-row {
		-webkit-box-orient: horizontal!important;
		-webkit-box-direction: normal!important;
		flex-direction: row!important
	}
	.flex-xl-column {
		-webkit-box-orient: vertical!important;
		-webkit-box-direction: normal!important;
		flex-direction: column!important
	}
	.flex-xl-row-reverse {
		-webkit-box-orient: horizontal!important;
		-webkit-box-direction: reverse!important;
		flex-direction: row-reverse!important
	}
	.flex-xl-column-reverse {
		-webkit-box-orient: vertical!important;
		-webkit-box-direction: reverse!important;
		flex-direction: column-reverse!important
	}
	.flex-xl-grow-0 {
		-webkit-box-flex: 0!important;
		flex-grow: 0!important
	}
	.flex-xl-grow-1 {
		-webkit-box-flex: 1!important;
		flex-grow: 1!important
	}
	.flex-xl-shrink-0 {
		flex-shrink: 0!important
	}
	.flex-xl-shrink-1 {
		flex-shrink: 1!important
	}
	.flex-xl-wrap {
		flex-wrap: wrap!important
	}
	.flex-xl-nowrap {
		flex-wrap: nowrap!important
	}
	.flex-xl-wrap-reverse {
		flex-wrap: wrap-reverse!important
	}
	.gap-xl-0 {
		gap: 0!important
	}
	.gap-xl-1 {
		gap: .25rem!important
	}
	.gap-xl-2 {
		gap: .5rem!important
	}
	.gap-xl-3 {
		gap: .75rem!important
	}
	.gap-xl-4 {
		gap: 1rem!important
	}
	.gap-xl-5 {
		gap: 1.5rem!important
	}
	.gap-xl-6 {
		gap: 2rem!important
	}
	.gap-xl-7 {
		gap: 2.5rem!important
	}
	.gap-xl-8 {
		gap: 3rem!important
	}
	.gap-xl-9 {
		gap: 4rem!important
	}
	.gap-xl-10 {
		gap: 5rem!important
	}
	.gap-xl-11 {
		gap: 6rem!important
	}
	.gap-xl-12 {
		gap: 8rem!important
	}
	.gap-xl-13 {
		gap: 10rem!important
	}
	.gap-xl-14 {
		gap: 12rem!important
	}
	.gap-xl-15 {
		gap: 16rem!important
	}
	.gap-xl-16 {
		gap: 25rem!important
	}
	.justify-content-xl-start {
		-webkit-box-pack: start!important;
		justify-content: flex-start!important
	}
	.justify-content-xl-end {
		-webkit-box-pack: end!important;
		justify-content: flex-end!important
	}
	.justify-content-xl-center {
		-webkit-box-pack: center!important;
		justify-content: center!important
	}
	.justify-content-xl-between {
		-webkit-box-pack: justify!important;
		justify-content: space-between!important
	}
	.justify-content-xl-around {
		justify-content: space-around!important
	}
	.justify-content-xl-evenly {
		-webkit-box-pack: space-evenly!important;
		justify-content: space-evenly!important
	}
	.align-items-xl-start {
		-webkit-box-align: start!important;
		align-items: flex-start!important
	}
	.align-items-xl-end {
		-webkit-box-align: end!important;
		align-items: flex-end!important
	}
	.align-items-xl-center {
		-webkit-box-align: center!important;
		align-items: center!important
	}
	.align-items-xl-baseline {
		-webkit-box-align: baseline!important;
		align-items: baseline!important
	}
	.align-items-xl-stretch {
		-webkit-box-align: stretch!important;
		align-items: stretch!important
	}
	.align-content-xl-start {
		align-content: flex-start!important
	}
	.align-content-xl-end {
		align-content: flex-end!important
	}
	.align-content-xl-center {
		align-content: center!important
	}
	.align-content-xl-between {
		align-content: space-between!important
	}
	.align-content-xl-around {
		align-content: space-around!important
	}
	.align-content-xl-stretch {
		align-content: stretch!important
	}
	.align-self-xl-auto {
		align-self: auto!important
	}
	.align-self-xl-start {
		align-self: flex-start!important
	}
	.align-self-xl-end {
		align-self: flex-end!important
	}
	.align-self-xl-center {
		align-self: center!important
	}
	.align-self-xl-baseline {
		align-self: baseline!important
	}
	.align-self-xl-stretch {
		align-self: stretch!important
	}
	.order-xl-first {
		-webkit-box-ordinal-group: 0!important;
		order: -1!important
	}
	.order-xl-0 {
		-webkit-box-ordinal-group: 1!important;
		order: 0!important
	}
	.order-xl-1 {
		-webkit-box-ordinal-group: 2!important;
		order: 1!important
	}
	.order-xl-2 {
		-webkit-box-ordinal-group: 3!important;
		order: 2!important
	}
	.order-xl-3 {
		-webkit-box-ordinal-group: 4!important;
		order: 3!important
	}
	.order-xl-4 {
		-webkit-box-ordinal-group: 5!important;
		order: 4!important
	}
	.order-xl-5 {
		-webkit-box-ordinal-group: 6!important;
		order: 5!important
	}
	.order-xl-last {
		-webkit-box-ordinal-group: 7!important;
		order: 6!important
	}
	.m-xl-0 {
		margin: 0!important
	}
	.m-xl-1 {
		margin: .25rem!important
	}
	.m-xl-2 {
		margin: .5rem!important
	}
	.m-xl-3 {
		margin: .75rem!important
	}
	.m-xl-4 {
		margin: 1rem!important
	}
	.m-xl-5 {
		margin: 1.5rem!important
	}
	.m-xl-6 {
		margin: 2rem!important
	}
	.m-xl-7 {
		margin: 2.5rem!important
	}
	.m-xl-8 {
		margin: 3rem!important
	}
	.m-xl-9 {
		margin: 4rem!important
	}
	.m-xl-10 {
		margin: 5rem!important
	}
	.m-xl-11 {
		margin: 6rem!important
	}
	.m-xl-12 {
		margin: 8rem!important
	}
	.m-xl-13 {
		margin: 10rem!important
	}
	.m-xl-14 {
		margin: 12rem!important
	}
	.m-xl-15 {
		margin: 16rem!important
	}
	.m-xl-16 {
		margin: 25rem!important
	}
	.m-xl-auto {
		margin: auto!important
	}
	.mx-xl-0 {
		margin-right: 0!important;
		margin-left: 0!important
	}
	.mx-xl-1 {
		margin-right: .25rem!important;
		margin-left: .25rem!important
	}
	.mx-xl-2 {
		margin-right: .5rem!important;
		margin-left: .5rem!important
	}
	.mx-xl-3 {
		margin-right: .75rem!important;
		margin-left: .75rem!important
	}
	.mx-xl-4 {
		margin-right: 1rem!important;
		margin-left: 1rem!important
	}
	.mx-xl-5 {
		margin-right: 1.5rem!important;
		margin-left: 1.5rem!important
	}
	.mx-xl-6 {
		margin-right: 2rem!important;
		margin-left: 2rem!important
	}
	.mx-xl-7 {
		margin-right: 2.5rem!important;
		margin-left: 2.5rem!important
	}
	.mx-xl-8 {
		margin-right: 3rem!important;
		margin-left: 3rem!important
	}
	.mx-xl-9 {
		margin-right: 4rem!important;
		margin-left: 4rem!important
	}
	.mx-xl-10 {
		margin-right: 5rem!important;
		margin-left: 5rem!important
	}
	.mx-xl-11 {
		margin-right: 6rem!important;
		margin-left: 6rem!important
	}
	.mx-xl-12 {
		margin-right: 8rem!important;
		margin-left: 8rem!important
	}
	.mx-xl-13 {
		margin-right: 10rem!important;
		margin-left: 10rem!important
	}
	.mx-xl-14 {
		margin-right: 12rem!important;
		margin-left: 12rem!important
	}
	.mx-xl-15 {
		margin-right: 16rem!important;
		margin-left: 16rem!important
	}
	.mx-xl-16 {
		margin-right: 25rem!important;
		margin-left: 25rem!important
	}
	.mx-xl-auto {
		margin-right: auto!important;
		margin-left: auto!important
	}
	.my-xl-0 {
		margin-top: 0!important;
		margin-bottom: 0!important
	}
	.my-xl-1 {
		margin-top: .25rem!important;
		margin-bottom: .25rem!important
	}
	.my-xl-2 {
		margin-top: .5rem!important;
		margin-bottom: .5rem!important
	}
	.my-xl-3 {
		margin-top: .75rem!important;
		margin-bottom: .75rem!important
	}
	.my-xl-4 {
		margin-top: 1rem!important;
		margin-bottom: 1rem!important
	}
	.my-xl-5 {
		margin-top: 1.5rem!important;
		margin-bottom: 1.5rem!important
	}
	.my-xl-6 {
		margin-top: 2rem!important;
		margin-bottom: 2rem!important
	}
	.my-xl-7 {
		margin-top: 2.5rem!important;
		margin-bottom: 2.5rem!important
	}
	.my-xl-8 {
		margin-top: 3rem!important;
		margin-bottom: 3rem!important
	}
	.my-xl-9 {
		margin-top: 4rem!important;
		margin-bottom: 4rem!important
	}
	.my-xl-10 {
		margin-top: 5rem!important;
		margin-bottom: 5rem!important
	}
	.my-xl-11 {
		margin-top: 6rem!important;
		margin-bottom: 6rem!important
	}
	.my-xl-12 {
		margin-top: 8rem!important;
		margin-bottom: 8rem!important
	}
	.my-xl-13 {
		margin-top: 10rem!important;
		margin-bottom: 10rem!important
	}
	.my-xl-14 {
		margin-top: 12rem!important;
		margin-bottom: 12rem!important
	}
	.my-xl-15 {
		margin-top: 16rem!important;
		margin-bottom: 16rem!important
	}
	.my-xl-16 {
		margin-top: 25rem!important;
		margin-bottom: 25rem!important
	}
	.my-xl-auto {
		margin-top: auto!important;
		margin-bottom: auto!important
	}
	.mt-xl-0 {
		margin-top: 0!important
	}
	.mt-xl-1 {
		margin-top: .25rem!important
	}
	.mt-xl-2 {
		margin-top: .5rem!important
	}
	.mt-xl-3 {
		margin-top: .75rem!important
	}
	.mt-xl-4 {
		margin-top: 1rem!important
	}
	.mt-xl-5 {
		margin-top: 1.5rem!important
	}
	.mt-xl-6 {
		margin-top: 2rem!important
	}
	.mt-xl-7 {
		margin-top: 2.5rem!important
	}
	.mt-xl-8 {
		margin-top: 3rem!important
	}
	.mt-xl-9 {
		margin-top: 4rem!important
	}
	.mt-xl-10 {
		margin-top: 5rem!important
	}
	.mt-xl-11 {
		margin-top: 6rem!important
	}
	.mt-xl-12 {
		margin-top: 8rem!important
	}
	.mt-xl-13 {
		margin-top: 10rem!important
	}
	.mt-xl-14 {
		margin-top: 12rem!important
	}
	.mt-xl-15 {
		margin-top: 16rem!important
	}
	.mt-xl-16 {
		margin-top: 25rem!important
	}
	.mt-xl-auto {
		margin-top: auto!important
	}
	.me-xl-0 {
		margin-right: 0!important
	}
	.me-xl-1 {
		margin-right: .25rem!important
	}
	.me-xl-2 {
		margin-right: .5rem!important
	}
	.me-xl-3 {
		margin-right: .75rem!important
	}
	.me-xl-4 {
		margin-right: 1rem!important
	}
	.me-xl-5 {
		margin-right: 1.5rem!important
	}
	.me-xl-6 {
		margin-right: 2rem!important
	}
	.me-xl-7 {
		margin-right: 2.5rem!important
	}
	.me-xl-8 {
		margin-right: 3rem!important
	}
	.me-xl-9 {
		margin-right: 4rem!important
	}
	.me-xl-10 {
		margin-right: 5rem!important
	}
	.me-xl-11 {
		margin-right: 6rem!important
	}
	.me-xl-12 {
		margin-right: 8rem!important
	}
	.me-xl-13 {
		margin-right: 10rem!important
	}
	.me-xl-14 {
		margin-right: 12rem!important
	}
	.me-xl-15 {
		margin-right: 16rem!important
	}
	.me-xl-16 {
		margin-right: 25rem!important
	}
	.me-xl-auto {
		margin-right: auto!important
	}
	.mb-xl-0 {
		margin-bottom: 0!important
	}
	.mb-xl-1 {
		margin-bottom: .25rem!important
	}
	.mb-xl-2 {
		margin-bottom: .5rem!important
	}
	.mb-xl-3 {
		margin-bottom: .75rem!important
	}
	.mb-xl-4 {
		margin-bottom: 1rem!important
	}
	.mb-xl-5 {
		margin-bottom: 1.5rem!important
	}
	.mb-xl-6 {
		margin-bottom: 2rem!important
	}
	.mb-xl-7 {
		margin-bottom: 2.5rem!important
	}
	.mb-xl-8 {
		margin-bottom: 3rem!important
	}
	.mb-xl-9 {
		margin-bottom: 4rem!important
	}
	.mb-xl-10 {
		margin-bottom: 5rem!important
	}
	.mb-xl-11 {
		margin-bottom: 6rem!important
	}
	.mb-xl-12 {
		margin-bottom: 8rem!important
	}
	.mb-xl-13 {
		margin-bottom: 10rem!important
	}
	.mb-xl-14 {
		margin-bottom: 12rem!important
	}
	.mb-xl-15 {
		margin-bottom: 16rem!important
	}
	.mb-xl-16 {
		margin-bottom: 25rem!important
	}
	.mb-xl-auto {
		margin-bottom: auto!important
	}
	.ms-xl-0 {
		margin-left: 0!important
	}
	.ms-xl-1 {
		margin-left: .25rem!important
	}
	.ms-xl-2 {
		margin-left: .5rem!important
	}
	.ms-xl-3 {
		margin-left: .75rem!important
	}
	.ms-xl-4 {
		margin-left: 1rem!important
	}
	.ms-xl-5 {
		margin-left: 1.5rem!important
	}
	.ms-xl-6 {
		margin-left: 2rem!important
	}
	.ms-xl-7 {
		margin-left: 2.5rem!important
	}
	.ms-xl-8 {
		margin-left: 3rem!important
	}
	.ms-xl-9 {
		margin-left: 4rem!important
	}
	.ms-xl-10 {
		margin-left: 5rem!important
	}
	.ms-xl-11 {
		margin-left: 6rem!important
	}
	.ms-xl-12 {
		margin-left: 8rem!important
	}
	.ms-xl-13 {
		margin-left: 10rem!important
	}
	.ms-xl-14 {
		margin-left: 12rem!important
	}
	.ms-xl-15 {
		margin-left: 16rem!important
	}
	.ms-xl-16 {
		margin-left: 25rem!important
	}
	.ms-xl-auto {
		margin-left: auto!important
	}
	.m-xl-n1 {
		margin: -.25rem!important
	}
	.m-xl-n2 {
		margin: -.5rem!important
	}
	.m-xl-n3 {
		margin: -.75rem!important
	}
	.m-xl-n4 {
		margin: -1rem!important
	}
	.m-xl-n5 {
		margin: -1.5rem!important
	}
	.m-xl-n6 {
		margin: -2rem!important
	}
	.m-xl-n7 {
		margin: -2.5rem!important
	}
	.m-xl-n8 {
		margin: -3rem!important
	}
	.m-xl-n9 {
		margin: -4rem!important
	}
	.m-xl-n10 {
		margin: -5rem!important
	}
	.m-xl-n11 {
		margin: -6rem!important
	}
	.m-xl-n12 {
		margin: -8rem!important
	}
	.m-xl-n13 {
		margin: -10rem!important
	}
	.m-xl-n14 {
		margin: -12rem!important
	}
	.m-xl-n15 {
		margin: -16rem!important
	}
	.m-xl-n16 {
		margin: -25rem!important
	}
	.mx-xl-n1 {
		margin-right: -.25rem!important;
		margin-left: -.25rem!important
	}
	.mx-xl-n2 {
		margin-right: -.5rem!important;
		margin-left: -.5rem!important
	}
	.mx-xl-n3 {
		margin-right: -.75rem!important;
		margin-left: -.75rem!important
	}
	.mx-xl-n4 {
		margin-right: -1rem!important;
		margin-left: -1rem!important
	}
	.mx-xl-n5 {
		margin-right: -1.5rem!important;
		margin-left: -1.5rem!important
	}
	.mx-xl-n6 {
		margin-right: -2rem!important;
		margin-left: -2rem!important
	}
	.mx-xl-n7 {
		margin-right: -2.5rem!important;
		margin-left: -2.5rem!important
	}
	.mx-xl-n8 {
		margin-right: -3rem!important;
		margin-left: -3rem!important
	}
	.mx-xl-n9 {
		margin-right: -4rem!important;
		margin-left: -4rem!important
	}
	.mx-xl-n10 {
		margin-right: -5rem!important;
		margin-left: -5rem!important
	}
	.mx-xl-n11 {
		margin-right: -6rem!important;
		margin-left: -6rem!important
	}
	.mx-xl-n12 {
		margin-right: -8rem!important;
		margin-left: -8rem!important
	}
	.mx-xl-n13 {
		margin-right: -10rem!important;
		margin-left: -10rem!important
	}
	.mx-xl-n14 {
		margin-right: -12rem!important;
		margin-left: -12rem!important
	}
	.mx-xl-n15 {
		margin-right: -16rem!important;
		margin-left: -16rem!important
	}
	.mx-xl-n16 {
		margin-right: -25rem!important;
		margin-left: -25rem!important
	}
	.my-xl-n1 {
		margin-top: -.25rem!important;
		margin-bottom: -.25rem!important
	}
	.my-xl-n2 {
		margin-top: -.5rem!important;
		margin-bottom: -.5rem!important
	}
	.my-xl-n3 {
		margin-top: -.75rem!important;
		margin-bottom: -.75rem!important
	}
	.my-xl-n4 {
		margin-top: -1rem!important;
		margin-bottom: -1rem!important
	}
	.my-xl-n5 {
		margin-top: -1.5rem!important;
		margin-bottom: -1.5rem!important
	}
	.my-xl-n6 {
		margin-top: -2rem!important;
		margin-bottom: -2rem!important
	}
	.my-xl-n7 {
		margin-top: -2.5rem!important;
		margin-bottom: -2.5rem!important
	}
	.my-xl-n8 {
		margin-top: -3rem!important;
		margin-bottom: -3rem!important
	}
	.my-xl-n9 {
		margin-top: -4rem!important;
		margin-bottom: -4rem!important
	}
	.my-xl-n10 {
		margin-top: -5rem!important;
		margin-bottom: -5rem!important
	}
	.my-xl-n11 {
		margin-top: -6rem!important;
		margin-bottom: -6rem!important
	}
	.my-xl-n12 {
		margin-top: -8rem!important;
		margin-bottom: -8rem!important
	}
	.my-xl-n13 {
		margin-top: -10rem!important;
		margin-bottom: -10rem!important
	}
	.my-xl-n14 {
		margin-top: -12rem!important;
		margin-bottom: -12rem!important
	}
	.my-xl-n15 {
		margin-top: -16rem!important;
		margin-bottom: -16rem!important
	}
	.my-xl-n16 {
		margin-top: -25rem!important;
		margin-bottom: -25rem!important
	}
	.mt-xl-n1 {
		margin-top: -.25rem!important
	}
	.mt-xl-n2 {
		margin-top: -.5rem!important
	}
	.mt-xl-n3 {
		margin-top: -.75rem!important
	}
	.mt-xl-n4 {
		margin-top: -1rem!important
	}
	.mt-xl-n5 {
		margin-top: -1.5rem!important
	}
	.mt-xl-n6 {
		margin-top: -2rem!important
	}
	.mt-xl-n7 {
		margin-top: -2.5rem!important
	}
	.mt-xl-n8 {
		margin-top: -3rem!important
	}
	.mt-xl-n9 {
		margin-top: -4rem!important
	}
	.mt-xl-n10 {
		margin-top: -5rem!important
	}
	.mt-xl-n11 {
		margin-top: -6rem!important
	}
	.mt-xl-n12 {
		margin-top: -8rem!important
	}
	.mt-xl-n13 {
		margin-top: -10rem!important
	}
	.mt-xl-n14 {
		margin-top: -12rem!important
	}
	.mt-xl-n15 {
		margin-top: -16rem!important
	}
	.mt-xl-n16 {
		margin-top: -25rem!important
	}
	.me-xl-n1 {
		margin-right: -.25rem!important
	}
	.me-xl-n2 {
		margin-right: -.5rem!important
	}
	.me-xl-n3 {
		margin-right: -.75rem!important
	}
	.me-xl-n4 {
		margin-right: -1rem!important
	}
	.me-xl-n5 {
		margin-right: -1.5rem!important
	}
	.me-xl-n6 {
		margin-right: -2rem!important
	}
	.me-xl-n7 {
		margin-right: -2.5rem!important
	}
	.me-xl-n8 {
		margin-right: -3rem!important
	}
	.me-xl-n9 {
		margin-right: -4rem!important
	}
	.me-xl-n10 {
		margin-right: -5rem!important
	}
	.me-xl-n11 {
		margin-right: -6rem!important
	}
	.me-xl-n12 {
		margin-right: -8rem!important
	}
	.me-xl-n13 {
		margin-right: -10rem!important
	}
	.me-xl-n14 {
		margin-right: -12rem!important
	}
	.me-xl-n15 {
		margin-right: -16rem!important
	}
	.me-xl-n16 {
		margin-right: -25rem!important
	}
	.mb-xl-n1 {
		margin-bottom: -.25rem!important
	}
	.mb-xl-n2 {
		margin-bottom: -.5rem!important
	}
	.mb-xl-n3 {
		margin-bottom: -.75rem!important
	}
	.mb-xl-n4 {
		margin-bottom: -1rem!important
	}
	.mb-xl-n5 {
		margin-bottom: -1.5rem!important
	}
	.mb-xl-n6 {
		margin-bottom: -2rem!important
	}
	.mb-xl-n7 {
		margin-bottom: -2.5rem!important
	}
	.mb-xl-n8 {
		margin-bottom: -3rem!important
	}
	.mb-xl-n9 {
		margin-bottom: -4rem!important
	}
	.mb-xl-n10 {
		margin-bottom: -5rem!important
	}
	.mb-xl-n11 {
		margin-bottom: -6rem!important
	}
	.mb-xl-n12 {
		margin-bottom: -8rem!important
	}
	.mb-xl-n13 {
		margin-bottom: -10rem!important
	}
	.mb-xl-n14 {
		margin-bottom: -12rem!important
	}
	.mb-xl-n15 {
		margin-bottom: -16rem!important
	}
	.mb-xl-n16 {
		margin-bottom: -25rem!important
	}
	.ms-xl-n1 {
		margin-left: -.25rem!important
	}
	.ms-xl-n2 {
		margin-left: -.5rem!important
	}
	.ms-xl-n3 {
		margin-left: -.75rem!important
	}
	.ms-xl-n4 {
		margin-left: -1rem!important
	}
	.ms-xl-n5 {
		margin-left: -1.5rem!important
	}
	.ms-xl-n6 {
		margin-left: -2rem!important
	}
	.ms-xl-n7 {
		margin-left: -2.5rem!important
	}
	.ms-xl-n8 {
		margin-left: -3rem!important
	}
	.ms-xl-n9 {
		margin-left: -4rem!important
	}
	.ms-xl-n10 {
		margin-left: -5rem!important
	}
	.ms-xl-n11 {
		margin-left: -6rem!important
	}
	.ms-xl-n12 {
		margin-left: -8rem!important
	}
	.ms-xl-n13 {
		margin-left: -10rem!important
	}
	.ms-xl-n14 {
		margin-left: -12rem!important
	}
	.ms-xl-n15 {
		margin-left: -16rem!important
	}
	.ms-xl-n16 {
		margin-left: -25rem!important
	}
	.p-xl-0 {
		padding: 0!important
	}
	.p-xl-1 {
		padding: .25rem!important
	}
	.p-xl-2 {
		padding: .5rem!important
	}
	.p-xl-3 {
		padding: .75rem!important
	}
	.p-xl-4 {
		padding: 1rem!important
	}
	.p-xl-5 {
		padding: 1.5rem!important
	}
	.p-xl-6 {
		padding: 2rem!important
	}
	.p-xl-7 {
		padding: 2.5rem!important
	}
	.p-xl-8 {
		padding: 3rem!important
	}
	.p-xl-9 {
		padding: 4rem!important
	}
	.p-xl-10 {
		padding: 5rem!important
	}
	.p-xl-11 {
		padding: 6rem!important
	}
	.p-xl-12 {
		padding: 8rem!important
	}
	.p-xl-13 {
		padding: 10rem!important
	}
	.p-xl-14 {
		padding: 12rem!important
	}
	.p-xl-15 {
		padding: 16rem!important
	}
	.p-xl-16 {
		padding: 25rem!important
	}
	.px-xl-0 {
		padding-right: 0!important;
		padding-left: 0!important
	}
	.px-xl-1 {
		padding-right: .25rem!important;
		padding-left: .25rem!important
	}
	.px-xl-2 {
		padding-right: .5rem!important;
		padding-left: .5rem!important
	}
	.px-xl-3 {
		padding-right: .75rem!important;
		padding-left: .75rem!important
	}
	.px-xl-4 {
		padding-right: 1rem!important;
		padding-left: 1rem!important
	}
	.px-xl-5 {
		padding-right: 1.5rem!important;
		padding-left: 1.5rem!important
	}
	.px-xl-6 {
		padding-right: 2rem!important;
		padding-left: 2rem!important
	}
	.px-xl-7 {
		padding-right: 2.5rem!important;
		padding-left: 2.5rem!important
	}
	.px-xl-8 {
		padding-right: 3rem!important;
		padding-left: 3rem!important
	}
	.px-xl-9 {
		padding-right: 4rem!important;
		padding-left: 4rem!important
	}
	.px-xl-10 {
		padding-right: 5rem!important;
		padding-left: 5rem!important
	}
	.px-xl-11 {
		padding-right: 6rem!important;
		padding-left: 6rem!important
	}
	.px-xl-12 {
		padding-right: 8rem!important;
		padding-left: 8rem!important
	}
	.px-xl-13 {
		padding-right: 10rem!important;
		padding-left: 10rem!important
	}
	.px-xl-14 {
		padding-right: 12rem!important;
		padding-left: 12rem!important
	}
	.px-xl-15 {
		padding-right: 16rem!important;
		padding-left: 16rem!important
	}
	.px-xl-16 {
		padding-right: 25rem!important;
		padding-left: 25rem!important
	}
	.py-xl-0 {
		padding-top: 0!important;
		padding-bottom: 0!important
	}
	.py-xl-1 {
		padding-top: .25rem!important;
		padding-bottom: .25rem!important
	}
	.py-xl-2 {
		padding-top: .5rem!important;
		padding-bottom: .5rem!important
	}
	.py-xl-3 {
		padding-top: .75rem!important;
		padding-bottom: .75rem!important
	}
	.py-xl-4 {
		padding-top: 1rem!important;
		padding-bottom: 1rem!important
	}
	.py-xl-5 {
		padding-top: 1.5rem!important;
		padding-bottom: 1.5rem!important
	}
	.py-xl-6 {
		padding-top: 2rem!important;
		padding-bottom: 2rem!important
	}
	.py-xl-7 {
		padding-top: 2.5rem!important;
		padding-bottom: 2.5rem!important
	}
	.py-xl-8 {
		padding-top: 3rem!important;
		padding-bottom: 3rem!important
	}
	.py-xl-9 {
		padding-top: 4rem!important;
		padding-bottom: 4rem!important
	}
	.py-xl-10 {
		padding-top: 5rem!important;
		padding-bottom: 5rem!important
	}
	.py-xl-11 {
		padding-top: 6rem!important;
		padding-bottom: 6rem!important
	}
	.py-xl-12 {
		padding-top: 8rem!important;
		padding-bottom: 8rem!important
	}
	.py-xl-13 {
		padding-top: 10rem!important;
		padding-bottom: 10rem!important
	}
	.py-xl-14 {
		padding-top: 12rem!important;
		padding-bottom: 12rem!important
	}
	.py-xl-15 {
		padding-top: 16rem!important;
		padding-bottom: 16rem!important
	}
	.py-xl-16 {
		padding-top: 25rem!important;
		padding-bottom: 25rem!important
	}
	.pt-xl-0 {
		padding-top: 0!important
	}
	.pt-xl-1 {
		padding-top: .25rem!important
	}
	.pt-xl-2 {
		padding-top: .5rem!important
	}
	.pt-xl-3 {
		padding-top: .75rem!important
	}
	.pt-xl-4 {
		padding-top: 1rem!important
	}
	.pt-xl-5 {
		padding-top: 1.5rem!important
	}
	.pt-xl-6 {
		padding-top: 2rem!important
	}
	.pt-xl-7 {
		padding-top: 2.5rem!important
	}
	.pt-xl-8 {
		padding-top: 3rem!important
	}
	.pt-xl-9 {
		padding-top: 4rem!important
	}
	.pt-xl-10 {
		padding-top: 5rem!important
	}
	.pt-xl-11 {
		padding-top: 6rem!important
	}
	.pt-xl-12 {
		padding-top: 8rem!important
	}
	.pt-xl-13 {
		padding-top: 10rem!important
	}
	.pt-xl-14 {
		padding-top: 12rem!important
	}
	.pt-xl-15 {
		padding-top: 16rem!important
	}
	.pt-xl-16 {
		padding-top: 25rem!important
	}
	.pe-xl-0 {
		padding-right: 0!important
	}
	.pe-xl-1 {
		padding-right: .25rem!important
	}
	.pe-xl-2 {
		padding-right: .5rem!important
	}
	.pe-xl-3 {
		padding-right: .75rem!important
	}
	.pe-xl-4 {
		padding-right: 1rem!important
	}
	.pe-xl-5 {
		padding-right: 1.5rem!important
	}
	.pe-xl-6 {
		padding-right: 2rem!important
	}
	.pe-xl-7 {
		padding-right: 2.5rem!important
	}
	.pe-xl-8 {
		padding-right: 3rem!important
	}
	.pe-xl-9 {
		padding-right: 4rem!important
	}
	.pe-xl-10 {
		padding-right: 5rem!important
	}
	.pe-xl-11 {
		padding-right: 6rem!important
	}
	.pe-xl-12 {
		padding-right: 8rem!important
	}
	.pe-xl-13 {
		padding-right: 10rem!important
	}
	.pe-xl-14 {
		padding-right: 12rem!important
	}
	.pe-xl-15 {
		padding-right: 16rem!important
	}
	.pe-xl-16 {
		padding-right: 25rem!important
	}
	.pb-xl-0 {
		padding-bottom: 0!important
	}
	.pb-xl-1 {
		padding-bottom: .25rem!important
	}
	.pb-xl-2 {
		padding-bottom: .5rem!important
	}
	.pb-xl-3 {
		padding-bottom: .75rem!important
	}
	.pb-xl-4 {
		padding-bottom: 1rem!important
	}
	.pb-xl-5 {
		padding-bottom: 1.5rem!important
	}
	.pb-xl-6 {
		padding-bottom: 2rem!important
	}
	.pb-xl-7 {
		padding-bottom: 2.5rem!important
	}
	.pb-xl-8 {
		padding-bottom: 3rem!important
	}
	.pb-xl-9 {
		padding-bottom: 4rem!important
	}
	.pb-xl-10 {
		padding-bottom: 5rem!important
	}
	.pb-xl-11 {
		padding-bottom: 6rem!important
	}
	.pb-xl-12 {
		padding-bottom: 8rem!important
	}
	.pb-xl-13 {
		padding-bottom: 10rem!important
	}
	.pb-xl-14 {
		padding-bottom: 12rem!important
	}
	.pb-xl-15 {
		padding-bottom: 16rem!important
	}
	.pb-xl-16 {
		padding-bottom: 25rem!important
	}
	.ps-xl-0 {
		padding-left: 0!important
	}
	.ps-xl-1 {
		padding-left: .25rem!important
	}
	.ps-xl-2 {
		padding-left: .5rem!important
	}
	.ps-xl-3 {
		padding-left: .75rem!important
	}
	.ps-xl-4 {
		padding-left: 1rem!important
	}
	.ps-xl-5 {
		padding-left: 1.5rem!important
	}
	.ps-xl-6 {
		padding-left: 2rem!important
	}
	.ps-xl-7 {
		padding-left: 2.5rem!important
	}
	.ps-xl-8 {
		padding-left: 3rem!important
	}
	.ps-xl-9 {
		padding-left: 4rem!important
	}
	.ps-xl-10 {
		padding-left: 5rem!important
	}
	.ps-xl-11 {
		padding-left: 6rem!important
	}
	.ps-xl-12 {
		padding-left: 8rem!important
	}
	.ps-xl-13 {
		padding-left: 10rem!important
	}
	.ps-xl-14 {
		padding-left: 12rem!important
	}
	.ps-xl-15 {
		padding-left: 16rem!important
	}
	.ps-xl-16 {
		padding-left: 25rem!important
	}
	.text-xl-start {
		text-align: left!important
	}
	.text-xl-end {
		text-align: right!important
	}
	.text-xl-center {
		text-align: center!important
	}
}

@media (min-width:1480px) {
	.float-wd-start {
		float: left!important
	}
	.float-wd-end {
		float: right!important
	}
	.float-wd-none {
		float: none!important
	}
	.d-wd-inline {
		display: inline!important
	}
	.d-wd-inline-block {
		display: inline-block!important
	}
	.d-wd-block {
		display: block!important
	}
	.d-wd-grid {
		display: grid!important
	}
	.d-wd-table {
		display: table!important
	}
	.d-wd-table-row {
		display: table-row!important
	}
	.d-wd-table-cell {
		display: table-cell!important
	}
	.d-wd-flex {
		display: -webkit-box!important;
		display: flex!important
	}
	.d-wd-inline-flex {
		display: -webkit-inline-box!important;
		display: inline-flex!important
	}
	.d-wd-none {
		display: none!important
	}
	.flex-wd-fill {
		-webkit-box-flex: 1!important;
		flex: 1 1 auto!important
	}
	.flex-wd-row {
		-webkit-box-orient: horizontal!important;
		-webkit-box-direction: normal!important;
		flex-direction: row!important
	}
	.flex-wd-column {
		-webkit-box-orient: vertical!important;
		-webkit-box-direction: normal!important;
		flex-direction: column!important
	}
	.flex-wd-row-reverse {
		-webkit-box-orient: horizontal!important;
		-webkit-box-direction: reverse!important;
		flex-direction: row-reverse!important
	}
	.flex-wd-column-reverse {
		-webkit-box-orient: vertical!important;
		-webkit-box-direction: reverse!important;
		flex-direction: column-reverse!important
	}
	.flex-wd-grow-0 {
		-webkit-box-flex: 0!important;
		flex-grow: 0!important
	}
	.flex-wd-grow-1 {
		-webkit-box-flex: 1!important;
		flex-grow: 1!important
	}
	.flex-wd-shrink-0 {
		flex-shrink: 0!important
	}
	.flex-wd-shrink-1 {
		flex-shrink: 1!important
	}
	.flex-wd-wrap {
		flex-wrap: wrap!important
	}
	.flex-wd-nowrap {
		flex-wrap: nowrap!important
	}
	.flex-wd-wrap-reverse {
		flex-wrap: wrap-reverse!important
	}
	.gap-wd-0 {
		gap: 0!important
	}
	.gap-wd-1 {
		gap: .25rem!important
	}
	.gap-wd-2 {
		gap: .5rem!important
	}
	.gap-wd-3 {
		gap: .75rem!important
	}
	.gap-wd-4 {
		gap: 1rem!important
	}
	.gap-wd-5 {
		gap: 1.5rem!important
	}
	.gap-wd-6 {
		gap: 2rem!important
	}
	.gap-wd-7 {
		gap: 2.5rem!important
	}
	.gap-wd-8 {
		gap: 3rem!important
	}
	.gap-wd-9 {
		gap: 4rem!important
	}
	.gap-wd-10 {
		gap: 5rem!important
	}
	.gap-wd-11 {
		gap: 6rem!important
	}
	.gap-wd-12 {
		gap: 8rem!important
	}
	.gap-wd-13 {
		gap: 10rem!important
	}
	.gap-wd-14 {
		gap: 12rem!important
	}
	.gap-wd-15 {
		gap: 16rem!important
	}
	.gap-wd-16 {
		gap: 25rem!important
	}
	.justify-content-wd-start {
		-webkit-box-pack: start!important;
		justify-content: flex-start!important
	}
	.justify-content-wd-end {
		-webkit-box-pack: end!important;
		justify-content: flex-end!important
	}
	.justify-content-wd-center {
		-webkit-box-pack: center!important;
		justify-content: center!important
	}
	.justify-content-wd-between {
		-webkit-box-pack: justify!important;
		justify-content: space-between!important
	}
	.justify-content-wd-around {
		justify-content: space-around!important
	}
	.justify-content-wd-evenly {
		-webkit-box-pack: space-evenly!important;
		justify-content: space-evenly!important
	}
	.align-items-wd-start {
		-webkit-box-align: start!important;
		align-items: flex-start!important
	}
	.align-items-wd-end {
		-webkit-box-align: end!important;
		align-items: flex-end!important
	}
	.align-items-wd-center {
		-webkit-box-align: center!important;
		align-items: center!important
	}
	.align-items-wd-baseline {
		-webkit-box-align: baseline!important;
		align-items: baseline!important
	}
	.align-items-wd-stretch {
		-webkit-box-align: stretch!important;
		align-items: stretch!important
	}
	.align-content-wd-start {
		align-content: flex-start!important
	}
	.align-content-wd-end {
		align-content: flex-end!important
	}
	.align-content-wd-center {
		align-content: center!important
	}
	.align-content-wd-between {
		align-content: space-between!important
	}
	.align-content-wd-around {
		align-content: space-around!important
	}
	.align-content-wd-stretch {
		align-content: stretch!important
	}
	.align-self-wd-auto {
		align-self: auto!important
	}
	.align-self-wd-start {
		align-self: flex-start!important
	}
	.align-self-wd-end {
		align-self: flex-end!important
	}
	.align-self-wd-center {
		align-self: center!important
	}
	.align-self-wd-baseline {
		align-self: baseline!important
	}
	.align-self-wd-stretch {
		align-self: stretch!important
	}
	.order-wd-first {
		-webkit-box-ordinal-group: 0!important;
		order: -1!important
	}
	.order-wd-0 {
		-webkit-box-ordinal-group: 1!important;
		order: 0!important
	}
	.order-wd-1 {
		-webkit-box-ordinal-group: 2!important;
		order: 1!important
	}
	.order-wd-2 {
		-webkit-box-ordinal-group: 3!important;
		order: 2!important
	}
	.order-wd-3 {
		-webkit-box-ordinal-group: 4!important;
		order: 3!important
	}
	.order-wd-4 {
		-webkit-box-ordinal-group: 5!important;
		order: 4!important
	}
	.order-wd-5 {
		-webkit-box-ordinal-group: 6!important;
		order: 5!important
	}
	.order-wd-last {
		-webkit-box-ordinal-group: 7!important;
		order: 6!important
	}
	.m-wd-0 {
		margin: 0!important
	}
	.m-wd-1 {
		margin: .25rem!important
	}
	.m-wd-2 {
		margin: .5rem!important
	}
	.m-wd-3 {
		margin: .75rem!important
	}
	.m-wd-4 {
		margin: 1rem!important
	}
	.m-wd-5 {
		margin: 1.5rem!important
	}
	.m-wd-6 {
		margin: 2rem!important
	}
	.m-wd-7 {
		margin: 2.5rem!important
	}
	.m-wd-8 {
		margin: 3rem!important
	}
	.m-wd-9 {
		margin: 4rem!important
	}
	.m-wd-10 {
		margin: 5rem!important
	}
	.m-wd-11 {
		margin: 6rem!important
	}
	.m-wd-12 {
		margin: 8rem!important
	}
	.m-wd-13 {
		margin: 10rem!important
	}
	.m-wd-14 {
		margin: 12rem!important
	}
	.m-wd-15 {
		margin: 16rem!important
	}
	.m-wd-16 {
		margin: 25rem!important
	}
	.m-wd-auto {
		margin: auto!important
	}
	.mx-wd-0 {
		margin-right: 0!important;
		margin-left: 0!important
	}
	.mx-wd-1 {
		margin-right: .25rem!important;
		margin-left: .25rem!important
	}
	.mx-wd-2 {
		margin-right: .5rem!important;
		margin-left: .5rem!important
	}
	.mx-wd-3 {
		margin-right: .75rem!important;
		margin-left: .75rem!important
	}
	.mx-wd-4 {
		margin-right: 1rem!important;
		margin-left: 1rem!important
	}
	.mx-wd-5 {
		margin-right: 1.5rem!important;
		margin-left: 1.5rem!important
	}
	.mx-wd-6 {
		margin-right: 2rem!important;
		margin-left: 2rem!important
	}
	.mx-wd-7 {
		margin-right: 2.5rem!important;
		margin-left: 2.5rem!important
	}
	.mx-wd-8 {
		margin-right: 3rem!important;
		margin-left: 3rem!important
	}
	.mx-wd-9 {
		margin-right: 4rem!important;
		margin-left: 4rem!important
	}
	.mx-wd-10 {
		margin-right: 5rem!important;
		margin-left: 5rem!important
	}
	.mx-wd-11 {
		margin-right: 6rem!important;
		margin-left: 6rem!important
	}
	.mx-wd-12 {
		margin-right: 8rem!important;
		margin-left: 8rem!important
	}
	.mx-wd-13 {
		margin-right: 10rem!important;
		margin-left: 10rem!important
	}
	.mx-wd-14 {
		margin-right: 12rem!important;
		margin-left: 12rem!important
	}
	.mx-wd-15 {
		margin-right: 16rem!important;
		margin-left: 16rem!important
	}
	.mx-wd-16 {
		margin-right: 25rem!important;
		margin-left: 25rem!important
	}
	.mx-wd-auto {
		margin-right: auto!important;
		margin-left: auto!important
	}
	.my-wd-0 {
		margin-top: 0!important;
		margin-bottom: 0!important
	}
	.my-wd-1 {
		margin-top: .25rem!important;
		margin-bottom: .25rem!important
	}
	.my-wd-2 {
		margin-top: .5rem!important;
		margin-bottom: .5rem!important
	}
	.my-wd-3 {
		margin-top: .75rem!important;
		margin-bottom: .75rem!important
	}
	.my-wd-4 {
		margin-top: 1rem!important;
		margin-bottom: 1rem!important
	}
	.my-wd-5 {
		margin-top: 1.5rem!important;
		margin-bottom: 1.5rem!important
	}
	.my-wd-6 {
		margin-top: 2rem!important;
		margin-bottom: 2rem!important
	}
	.my-wd-7 {
		margin-top: 2.5rem!important;
		margin-bottom: 2.5rem!important
	}
	.my-wd-8 {
		margin-top: 3rem!important;
		margin-bottom: 3rem!important
	}
	.my-wd-9 {
		margin-top: 4rem!important;
		margin-bottom: 4rem!important
	}
	.my-wd-10 {
		margin-top: 5rem!important;
		margin-bottom: 5rem!important
	}
	.my-wd-11 {
		margin-top: 6rem!important;
		margin-bottom: 6rem!important
	}
	.my-wd-12 {
		margin-top: 8rem!important;
		margin-bottom: 8rem!important
	}
	.my-wd-13 {
		margin-top: 10rem!important;
		margin-bottom: 10rem!important
	}
	.my-wd-14 {
		margin-top: 12rem!important;
		margin-bottom: 12rem!important
	}
	.my-wd-15 {
		margin-top: 16rem!important;
		margin-bottom: 16rem!important
	}
	.my-wd-16 {
		margin-top: 25rem!important;
		margin-bottom: 25rem!important
	}
	.my-wd-auto {
		margin-top: auto!important;
		margin-bottom: auto!important
	}
	.mt-wd-0 {
		margin-top: 0!important
	}
	.mt-wd-1 {
		margin-top: .25rem!important
	}
	.mt-wd-2 {
		margin-top: .5rem!important
	}
	.mt-wd-3 {
		margin-top: .75rem!important
	}
	.mt-wd-4 {
		margin-top: 1rem!important
	}
	.mt-wd-5 {
		margin-top: 1.5rem!important
	}
	.mt-wd-6 {
		margin-top: 2rem!important
	}
	.mt-wd-7 {
		margin-top: 2.5rem!important
	}
	.mt-wd-8 {
		margin-top: 3rem!important
	}
	.mt-wd-9 {
		margin-top: 4rem!important
	}
	.mt-wd-10 {
		margin-top: 5rem!important
	}
	.mt-wd-11 {
		margin-top: 6rem!important
	}
	.mt-wd-12 {
		margin-top: 8rem!important
	}
	.mt-wd-13 {
		margin-top: 10rem!important
	}
	.mt-wd-14 {
		margin-top: 12rem!important
	}
	.mt-wd-15 {
		margin-top: 16rem!important
	}
	.mt-wd-16 {
		margin-top: 25rem!important
	}
	.mt-wd-auto {
		margin-top: auto!important
	}
	.me-wd-0 {
		margin-right: 0!important
	}
	.me-wd-1 {
		margin-right: .25rem!important
	}
	.me-wd-2 {
		margin-right: .5rem!important
	}
	.me-wd-3 {
		margin-right: .75rem!important
	}
	.me-wd-4 {
		margin-right: 1rem!important
	}
	.me-wd-5 {
		margin-right: 1.5rem!important
	}
	.me-wd-6 {
		margin-right: 2rem!important
	}
	.me-wd-7 {
		margin-right: 2.5rem!important
	}
	.me-wd-8 {
		margin-right: 3rem!important
	}
	.me-wd-9 {
		margin-right: 4rem!important
	}
	.me-wd-10 {
		margin-right: 5rem!important
	}
	.me-wd-11 {
		margin-right: 6rem!important
	}
	.me-wd-12 {
		margin-right: 8rem!important
	}
	.me-wd-13 {
		margin-right: 10rem!important
	}
	.me-wd-14 {
		margin-right: 12rem!important
	}
	.me-wd-15 {
		margin-right: 16rem!important
	}
	.me-wd-16 {
		margin-right: 25rem!important
	}
	.me-wd-auto {
		margin-right: auto!important
	}
	.mb-wd-0 {
		margin-bottom: 0!important
	}
	.mb-wd-1 {
		margin-bottom: .25rem!important
	}
	.mb-wd-2 {
		margin-bottom: .5rem!important
	}
	.mb-wd-3 {
		margin-bottom: .75rem!important
	}
	.mb-wd-4 {
		margin-bottom: 1rem!important
	}
	.mb-wd-5 {
		margin-bottom: 1.5rem!important
	}
	.mb-wd-6 {
		margin-bottom: 2rem!important
	}
	.mb-wd-7 {
		margin-bottom: 2.5rem!important
	}
	.mb-wd-8 {
		margin-bottom: 3rem!important
	}
	.mb-wd-9 {
		margin-bottom: 4rem!important
	}
	.mb-wd-10 {
		margin-bottom: 5rem!important
	}
	.mb-wd-11 {
		margin-bottom: 6rem!important
	}
	.mb-wd-12 {
		margin-bottom: 8rem!important
	}
	.mb-wd-13 {
		margin-bottom: 10rem!important
	}
	.mb-wd-14 {
		margin-bottom: 12rem!important
	}
	.mb-wd-15 {
		margin-bottom: 16rem!important
	}
	.mb-wd-16 {
		margin-bottom: 25rem!important
	}
	.mb-wd-auto {
		margin-bottom: auto!important
	}
	.ms-wd-0 {
		margin-left: 0!important
	}
	.ms-wd-1 {
		margin-left: .25rem!important
	}
	.ms-wd-2 {
		margin-left: .5rem!important
	}
	.ms-wd-3 {
		margin-left: .75rem!important
	}
	.ms-wd-4 {
		margin-left: 1rem!important
	}
	.ms-wd-5 {
		margin-left: 1.5rem!important
	}
	.ms-wd-6 {
		margin-left: 2rem!important
	}
	.ms-wd-7 {
		margin-left: 2.5rem!important
	}
	.ms-wd-8 {
		margin-left: 3rem!important
	}
	.ms-wd-9 {
		margin-left: 4rem!important
	}
	.ms-wd-10 {
		margin-left: 5rem!important
	}
	.ms-wd-11 {
		margin-left: 6rem!important
	}
	.ms-wd-12 {
		margin-left: 8rem!important
	}
	.ms-wd-13 {
		margin-left: 10rem!important
	}
	.ms-wd-14 {
		margin-left: 12rem!important
	}
	.ms-wd-15 {
		margin-left: 16rem!important
	}
	.ms-wd-16 {
		margin-left: 25rem!important
	}
	.ms-wd-auto {
		margin-left: auto!important
	}
	.m-wd-n1 {
		margin: -.25rem!important
	}
	.m-wd-n2 {
		margin: -.5rem!important
	}
	.m-wd-n3 {
		margin: -.75rem!important
	}
	.m-wd-n4 {
		margin: -1rem!important
	}
	.m-wd-n5 {
		margin: -1.5rem!important
	}
	.m-wd-n6 {
		margin: -2rem!important
	}
	.m-wd-n7 {
		margin: -2.5rem!important
	}
	.m-wd-n8 {
		margin: -3rem!important
	}
	.m-wd-n9 {
		margin: -4rem!important
	}
	.m-wd-n10 {
		margin: -5rem!important
	}
	.m-wd-n11 {
		margin: -6rem!important
	}
	.m-wd-n12 {
		margin: -8rem!important
	}
	.m-wd-n13 {
		margin: -10rem!important
	}
	.m-wd-n14 {
		margin: -12rem!important
	}
	.m-wd-n15 {
		margin: -16rem!important
	}
	.m-wd-n16 {
		margin: -25rem!important
	}
	.mx-wd-n1 {
		margin-right: -.25rem!important;
		margin-left: -.25rem!important
	}
	.mx-wd-n2 {
		margin-right: -.5rem!important;
		margin-left: -.5rem!important
	}
	.mx-wd-n3 {
		margin-right: -.75rem!important;
		margin-left: -.75rem!important
	}
	.mx-wd-n4 {
		margin-right: -1rem!important;
		margin-left: -1rem!important
	}
	.mx-wd-n5 {
		margin-right: -1.5rem!important;
		margin-left: -1.5rem!important
	}
	.mx-wd-n6 {
		margin-right: -2rem!important;
		margin-left: -2rem!important
	}
	.mx-wd-n7 {
		margin-right: -2.5rem!important;
		margin-left: -2.5rem!important
	}
	.mx-wd-n8 {
		margin-right: -3rem!important;
		margin-left: -3rem!important
	}
	.mx-wd-n9 {
		margin-right: -4rem!important;
		margin-left: -4rem!important
	}
	.mx-wd-n10 {
		margin-right: -5rem!important;
		margin-left: -5rem!important
	}
	.mx-wd-n11 {
		margin-right: -6rem!important;
		margin-left: -6rem!important
	}
	.mx-wd-n12 {
		margin-right: -8rem!important;
		margin-left: -8rem!important
	}
	.mx-wd-n13 {
		margin-right: -10rem!important;
		margin-left: -10rem!important
	}
	.mx-wd-n14 {
		margin-right: -12rem!important;
		margin-left: -12rem!important
	}
	.mx-wd-n15 {
		margin-right: -16rem!important;
		margin-left: -16rem!important
	}
	.mx-wd-n16 {
		margin-right: -25rem!important;
		margin-left: -25rem!important
	}
	.my-wd-n1 {
		margin-top: -.25rem!important;
		margin-bottom: -.25rem!important
	}
	.my-wd-n2 {
		margin-top: -.5rem!important;
		margin-bottom: -.5rem!important
	}
	.my-wd-n3 {
		margin-top: -.75rem!important;
		margin-bottom: -.75rem!important
	}
	.my-wd-n4 {
		margin-top: -1rem!important;
		margin-bottom: -1rem!important
	}
	.my-wd-n5 {
		margin-top: -1.5rem!important;
		margin-bottom: -1.5rem!important
	}
	.my-wd-n6 {
		margin-top: -2rem!important;
		margin-bottom: -2rem!important
	}
	.my-wd-n7 {
		margin-top: -2.5rem!important;
		margin-bottom: -2.5rem!important
	}
	.my-wd-n8 {
		margin-top: -3rem!important;
		margin-bottom: -3rem!important
	}
	.my-wd-n9 {
		margin-top: -4rem!important;
		margin-bottom: -4rem!important
	}
	.my-wd-n10 {
		margin-top: -5rem!important;
		margin-bottom: -5rem!important
	}
	.my-wd-n11 {
		margin-top: -6rem!important;
		margin-bottom: -6rem!important
	}
	.my-wd-n12 {
		margin-top: -8rem!important;
		margin-bottom: -8rem!important
	}
	.my-wd-n13 {
		margin-top: -10rem!important;
		margin-bottom: -10rem!important
	}
	.my-wd-n14 {
		margin-top: -12rem!important;
		margin-bottom: -12rem!important
	}
	.my-wd-n15 {
		margin-top: -16rem!important;
		margin-bottom: -16rem!important
	}
	.my-wd-n16 {
		margin-top: -25rem!important;
		margin-bottom: -25rem!important
	}
	.mt-wd-n1 {
		margin-top: -.25rem!important
	}
	.mt-wd-n2 {
		margin-top: -.5rem!important
	}
	.mt-wd-n3 {
		margin-top: -.75rem!important
	}
	.mt-wd-n4 {
		margin-top: -1rem!important
	}
	.mt-wd-n5 {
		margin-top: -1.5rem!important
	}
	.mt-wd-n6 {
		margin-top: -2rem!important
	}
	.mt-wd-n7 {
		margin-top: -2.5rem!important
	}
	.mt-wd-n8 {
		margin-top: -3rem!important
	}
	.mt-wd-n9 {
		margin-top: -4rem!important
	}
	.mt-wd-n10 {
		margin-top: -5rem!important
	}
	.mt-wd-n11 {
		margin-top: -6rem!important
	}
	.mt-wd-n12 {
		margin-top: -8rem!important
	}
	.mt-wd-n13 {
		margin-top: -10rem!important
	}
	.mt-wd-n14 {
		margin-top: -12rem!important
	}
	.mt-wd-n15 {
		margin-top: -16rem!important
	}
	.mt-wd-n16 {
		margin-top: -25rem!important
	}
	.me-wd-n1 {
		margin-right: -.25rem!important
	}
	.me-wd-n2 {
		margin-right: -.5rem!important
	}
	.me-wd-n3 {
		margin-right: -.75rem!important
	}
	.me-wd-n4 {
		margin-right: -1rem!important
	}
	.me-wd-n5 {
		margin-right: -1.5rem!important
	}
	.me-wd-n6 {
		margin-right: -2rem!important
	}
	.me-wd-n7 {
		margin-right: -2.5rem!important
	}
	.me-wd-n8 {
		margin-right: -3rem!important
	}
	.me-wd-n9 {
		margin-right: -4rem!important
	}
	.me-wd-n10 {
		margin-right: -5rem!important
	}
	.me-wd-n11 {
		margin-right: -6rem!important
	}
	.me-wd-n12 {
		margin-right: -8rem!important
	}
	.me-wd-n13 {
		margin-right: -10rem!important
	}
	.me-wd-n14 {
		margin-right: -12rem!important
	}
	.me-wd-n15 {
		margin-right: -16rem!important
	}
	.me-wd-n16 {
		margin-right: -25rem!important
	}
	.mb-wd-n1 {
		margin-bottom: -.25rem!important
	}
	.mb-wd-n2 {
		margin-bottom: -.5rem!important
	}
	.mb-wd-n3 {
		margin-bottom: -.75rem!important
	}
	.mb-wd-n4 {
		margin-bottom: -1rem!important
	}
	.mb-wd-n5 {
		margin-bottom: -1.5rem!important
	}
	.mb-wd-n6 {
		margin-bottom: -2rem!important
	}
	.mb-wd-n7 {
		margin-bottom: -2.5rem!important
	}
	.mb-wd-n8 {
		margin-bottom: -3rem!important
	}
	.mb-wd-n9 {
		margin-bottom: -4rem!important
	}
	.mb-wd-n10 {
		margin-bottom: -5rem!important
	}
	.mb-wd-n11 {
		margin-bottom: -6rem!important
	}
	.mb-wd-n12 {
		margin-bottom: -8rem!important
	}
	.mb-wd-n13 {
		margin-bottom: -10rem!important
	}
	.mb-wd-n14 {
		margin-bottom: -12rem!important
	}
	.mb-wd-n15 {
		margin-bottom: -16rem!important
	}
	.mb-wd-n16 {
		margin-bottom: -25rem!important
	}
	.ms-wd-n1 {
		margin-left: -.25rem!important
	}
	.ms-wd-n2 {
		margin-left: -.5rem!important
	}
	.ms-wd-n3 {
		margin-left: -.75rem!important
	}
	.ms-wd-n4 {
		margin-left: -1rem!important
	}
	.ms-wd-n5 {
		margin-left: -1.5rem!important
	}
	.ms-wd-n6 {
		margin-left: -2rem!important
	}
	.ms-wd-n7 {
		margin-left: -2.5rem!important
	}
	.ms-wd-n8 {
		margin-left: -3rem!important
	}
	.ms-wd-n9 {
		margin-left: -4rem!important
	}
	.ms-wd-n10 {
		margin-left: -5rem!important
	}
	.ms-wd-n11 {
		margin-left: -6rem!important
	}
	.ms-wd-n12 {
		margin-left: -8rem!important
	}
	.ms-wd-n13 {
		margin-left: -10rem!important
	}
	.ms-wd-n14 {
		margin-left: -12rem!important
	}
	.ms-wd-n15 {
		margin-left: -16rem!important
	}
	.ms-wd-n16 {
		margin-left: -25rem!important
	}
	.p-wd-0 {
		padding: 0!important
	}
	.p-wd-1 {
		padding: .25rem!important
	}
	.p-wd-2 {
		padding: .5rem!important
	}
	.p-wd-3 {
		padding: .75rem!important
	}
	.p-wd-4 {
		padding: 1rem!important
	}
	.p-wd-5 {
		padding: 1.5rem!important
	}
	.p-wd-6 {
		padding: 2rem!important
	}
	.p-wd-7 {
		padding: 2.5rem!important
	}
	.p-wd-8 {
		padding: 3rem!important
	}
	.p-wd-9 {
		padding: 4rem!important
	}
	.p-wd-10 {
		padding: 5rem!important
	}
	.p-wd-11 {
		padding: 6rem!important
	}
	.p-wd-12 {
		padding: 8rem!important
	}
	.p-wd-13 {
		padding: 10rem!important
	}
	.p-wd-14 {
		padding: 12rem!important
	}
	.p-wd-15 {
		padding: 16rem!important
	}
	.p-wd-16 {
		padding: 25rem!important
	}
	.px-wd-0 {
		padding-right: 0!important;
		padding-left: 0!important
	}
	.px-wd-1 {
		padding-right: .25rem!important;
		padding-left: .25rem!important
	}
	.px-wd-2 {
		padding-right: .5rem!important;
		padding-left: .5rem!important
	}
	.px-wd-3 {
		padding-right: .75rem!important;
		padding-left: .75rem!important
	}
	.px-wd-4 {
		padding-right: 1rem!important;
		padding-left: 1rem!important
	}
	.px-wd-5 {
		padding-right: 1.5rem!important;
		padding-left: 1.5rem!important
	}
	.px-wd-6 {
		padding-right: 2rem!important;
		padding-left: 2rem!important
	}
	.px-wd-7 {
		padding-right: 2.5rem!important;
		padding-left: 2.5rem!important
	}
	.px-wd-8 {
		padding-right: 3rem!important;
		padding-left: 3rem!important
	}
	.px-wd-9 {
		padding-right: 4rem!important;
		padding-left: 4rem!important
	}
	.px-wd-10 {
		padding-right: 5rem!important;
		padding-left: 5rem!important
	}
	.px-wd-11 {
		padding-right: 6rem!important;
		padding-left: 6rem!important
	}
	.px-wd-12 {
		padding-right: 8rem!important;
		padding-left: 8rem!important
	}
	.px-wd-13 {
		padding-right: 10rem!important;
		padding-left: 10rem!important
	}
	.px-wd-14 {
		padding-right: 12rem!important;
		padding-left: 12rem!important
	}
	.px-wd-15 {
		padding-right: 16rem!important;
		padding-left: 16rem!important
	}
	.px-wd-16 {
		padding-right: 25rem!important;
		padding-left: 25rem!important
	}
	.py-wd-0 {
		padding-top: 0!important;
		padding-bottom: 0!important
	}
	.py-wd-1 {
		padding-top: .25rem!important;
		padding-bottom: .25rem!important
	}
	.py-wd-2 {
		padding-top: .5rem!important;
		padding-bottom: .5rem!important
	}
	.py-wd-3 {
		padding-top: .75rem!important;
		padding-bottom: .75rem!important
	}
	.py-wd-4 {
		padding-top: 1rem!important;
		padding-bottom: 1rem!important
	}
	.py-wd-5 {
		padding-top: 1.5rem!important;
		padding-bottom: 1.5rem!important
	}
	.py-wd-6 {
		padding-top: 2rem!important;
		padding-bottom: 2rem!important
	}
	.py-wd-7 {
		padding-top: 2.5rem!important;
		padding-bottom: 2.5rem!important
	}
	.py-wd-8 {
		padding-top: 3rem!important;
		padding-bottom: 3rem!important
	}
	.py-wd-9 {
		padding-top: 4rem!important;
		padding-bottom: 4rem!important
	}
	.py-wd-10 {
		padding-top: 5rem!important;
		padding-bottom: 5rem!important
	}
	.py-wd-11 {
		padding-top: 6rem!important;
		padding-bottom: 6rem!important
	}
	.py-wd-12 {
		padding-top: 8rem!important;
		padding-bottom: 8rem!important
	}
	.py-wd-13 {
		padding-top: 10rem!important;
		padding-bottom: 10rem!important
	}
	.py-wd-14 {
		padding-top: 12rem!important;
		padding-bottom: 12rem!important
	}
	.py-wd-15 {
		padding-top: 16rem!important;
		padding-bottom: 16rem!important
	}
	.py-wd-16 {
		padding-top: 25rem!important;
		padding-bottom: 25rem!important
	}
	.pt-wd-0 {
		padding-top: 0!important
	}
	.pt-wd-1 {
		padding-top: .25rem!important
	}
	.pt-wd-2 {
		padding-top: .5rem!important
	}
	.pt-wd-3 {
		padding-top: .75rem!important
	}
	.pt-wd-4 {
		padding-top: 1rem!important
	}
	.pt-wd-5 {
		padding-top: 1.5rem!important
	}
	.pt-wd-6 {
		padding-top: 2rem!important
	}
	.pt-wd-7 {
		padding-top: 2.5rem!important
	}
	.pt-wd-8 {
		padding-top: 3rem!important
	}
	.pt-wd-9 {
		padding-top: 4rem!important
	}
	.pt-wd-10 {
		padding-top: 5rem!important
	}
	.pt-wd-11 {
		padding-top: 6rem!important
	}
	.pt-wd-12 {
		padding-top: 8rem!important
	}
	.pt-wd-13 {
		padding-top: 10rem!important
	}
	.pt-wd-14 {
		padding-top: 12rem!important
	}
	.pt-wd-15 {
		padding-top: 16rem!important
	}
	.pt-wd-16 {
		padding-top: 25rem!important
	}
	.pe-wd-0 {
		padding-right: 0!important
	}
	.pe-wd-1 {
		padding-right: .25rem!important
	}
	.pe-wd-2 {
		padding-right: .5rem!important
	}
	.pe-wd-3 {
		padding-right: .75rem!important
	}
	.pe-wd-4 {
		padding-right: 1rem!important
	}
	.pe-wd-5 {
		padding-right: 1.5rem!important
	}
	.pe-wd-6 {
		padding-right: 2rem!important
	}
	.pe-wd-7 {
		padding-right: 2.5rem!important
	}
	.pe-wd-8 {
		padding-right: 3rem!important
	}
	.pe-wd-9 {
		padding-right: 4rem!important
	}
	.pe-wd-10 {
		padding-right: 5rem!important
	}
	.pe-wd-11 {
		padding-right: 6rem!important
	}
	.pe-wd-12 {
		padding-right: 8rem!important
	}
	.pe-wd-13 {
		padding-right: 10rem!important
	}
	.pe-wd-14 {
		padding-right: 12rem!important
	}
	.pe-wd-15 {
		padding-right: 16rem!important
	}
	.pe-wd-16 {
		padding-right: 25rem!important
	}
	.pb-wd-0 {
		padding-bottom: 0!important
	}
	.pb-wd-1 {
		padding-bottom: .25rem!important
	}
	.pb-wd-2 {
		padding-bottom: .5rem!important
	}
	.pb-wd-3 {
		padding-bottom: .75rem!important
	}
	.pb-wd-4 {
		padding-bottom: 1rem!important
	}
	.pb-wd-5 {
		padding-bottom: 1.5rem!important
	}
	.pb-wd-6 {
		padding-bottom: 2rem!important
	}
	.pb-wd-7 {
		padding-bottom: 2.5rem!important
	}
	.pb-wd-8 {
		padding-bottom: 3rem!important
	}
	.pb-wd-9 {
		padding-bottom: 4rem!important
	}
	.pb-wd-10 {
		padding-bottom: 5rem!important
	}
	.pb-wd-11 {
		padding-bottom: 6rem!important
	}
	.pb-wd-12 {
		padding-bottom: 8rem!important
	}
	.pb-wd-13 {
		padding-bottom: 10rem!important
	}
	.pb-wd-14 {
		padding-bottom: 12rem!important
	}
	.pb-wd-15 {
		padding-bottom: 16rem!important
	}
	.pb-wd-16 {
		padding-bottom: 25rem!important
	}
	.ps-wd-0 {
		padding-left: 0!important
	}
	.ps-wd-1 {
		padding-left: .25rem!important
	}
	.ps-wd-2 {
		padding-left: .5rem!important
	}
	.ps-wd-3 {
		padding-left: .75rem!important
	}
	.ps-wd-4 {
		padding-left: 1rem!important
	}
	.ps-wd-5 {
		padding-left: 1.5rem!important
	}
	.ps-wd-6 {
		padding-left: 2rem!important
	}
	.ps-wd-7 {
		padding-left: 2.5rem!important
	}
	.ps-wd-8 {
		padding-left: 3rem!important
	}
	.ps-wd-9 {
		padding-left: 4rem!important
	}
	.ps-wd-10 {
		padding-left: 5rem!important
	}
	.ps-wd-11 {
		padding-left: 6rem!important
	}
	.ps-wd-12 {
		padding-left: 8rem!important
	}
	.ps-wd-13 {
		padding-left: 10rem!important
	}
	.ps-wd-14 {
		padding-left: 12rem!important
	}
	.ps-wd-15 {
		padding-left: 16rem!important
	}
	.ps-wd-16 {
		padding-left: 25rem!important
	}
	.text-wd-start {
		text-align: left!important
	}
	.text-wd-end {
		text-align: right!important
	}
	.text-wd-center {
		text-align: center!important
	}
}

@media (min-width:1200px) {
	.fs-1 {
		font-size: 2.187rem!important
	}
	.fs-2 {
		font-size: 1.875rem!important
	}
	.fs-3 {
		font-size: 1.375rem!important
	}
}

@media print {
	.d-print-inline {
		display: inline!important
	}
	.d-print-inline-block {
		display: inline-block!important
	}
	.d-print-block {
		display: block!important
	}
	.d-print-grid {
		display: grid!important
	}
	.d-print-table {
		display: table!important
	}
	.d-print-table-row {
		display: table-row!important
	}
	.d-print-table-cell {
		display: table-cell!important
	}
	.d-print-flex {
		display: -webkit-box!important;
		display: flex!important
	}
	.d-print-inline-flex {
		display: -webkit-inline-box!important;
		display: inline-flex!important
	}
	.d-print-none {
		display: none!important
	}
}

.alert-dismissible .close {
	position: absolute;
	top: 0;
	right: 0;
	padding: .75rem 1.25rem
}

.badge-primary-soft {
	background-color: rgba(9, 7, 97, .1);
	color: #090761
}

a.badge-primary-soft:focus,
a.badge-primary-soft:hover {
	background-color: rgba(9, 7, 97, .2);
	color: #090761
}

.badge-secondary-soft {
	background-color: rgba(148, 157, 166, .1);
	color: #949da6
}

a.badge-secondary-soft:focus,
a.badge-secondary-soft:hover {
	background-color: rgba(148, 157, 166, .2);
	color: #949da6
}

.badge-primary-desat-soft {
	background-color: rgba(108, 138, 236, .1);
	color: #6c8aec
}

a.badge-primary-desat-soft:focus,
a.badge-primary-desat-soft:hover {
	background-color: rgba(108, 138, 236, .2);
	color: #6c8aec
}

.badge-black-soft {
	background-color: rgba(22, 28, 45, .1);
	color: #161c2d
}

a.badge-black-soft:focus,
a.badge-black-soft:hover {
	background-color: rgba(22, 28, 45, .2);
	color: #161c2d
}

.badge-gray-200-soft {
	background-color: rgba(231, 231, 236, .1);
	color: #e7e7ec
}

a.badge-gray-200-soft:focus,
a.badge-gray-200-soft:hover {
	background-color: rgba(231, 231, 236, .2);
	color: #e7e7ec
}

.badge-gray-800-soft {
	background-color: rgba(119, 131, 143, .1);
	color: #77838f
}

a.badge-gray-800-soft:focus,
a.badge-gray-800-soft:hover {
	background-color: rgba(119, 131, 143, .2);
	color: #77838f
}

.badge-white-soft {
	background-color: rgba(255, 255, 255, .1);
	color: #fff
}

a.badge-white-soft:focus,
a.badge-white-soft:hover {
	background-color: rgba(255, 255, 255, .2);
	color: #fff
}

.badge-dark-soft {
	background-color: rgba(47, 45, 81, .1);
	color: #2f2d51
}

a.badge-dark-soft:focus,
a.badge-dark-soft:hover {
	background-color: rgba(47, 45, 81, .2);
	color: #2f2d51
}

.badge-success-soft {
	background-color: rgba(222, 242, 215, .1);
	color: #def2d7
}

a.badge-success-soft:focus,
a.badge-success-soft:hover {
	background-color: rgba(222, 242, 215, .2);
	color: #def2d7
}

.badge-info-soft {
	background-color: rgba(205, 233, 246, .1);
	color: #cde9f6
}

a.badge-info-soft:focus,
a.badge-info-soft:hover {
	background-color: rgba(205, 233, 246, .2);
	color: #cde9f6
}

.badge-warning-soft {
	background-color: rgba(247, 243, 215, .1);
	color: #f7f3d7
}

a.badge-warning-soft:focus,
a.badge-warning-soft:hover {
	background-color: rgba(247, 243, 215, .2);
	color: #f7f3d7
}

.badge-danger-soft {
	background-color: rgba(236, 200, 197, .1);
	color: #ecc8c5
}

a.badge-danger-soft:focus,
a.badge-danger-soft:hover {
	background-color: rgba(236, 200, 197, .2);
	color: #ecc8c5
}

.badge-light-soft {
	background-color: rgba(247, 249, 251, .1);
	color: #f7f9fb
}

a.badge-light-soft:focus,
a.badge-light-soft:hover {
	background-color: rgba(247, 249, 251, .2);
	color: #f7f9fb
}

.badge-orange-soft {
	background-color: rgba(255, 199, 139, .1);
	color: #ffc78b
}

a.badge-orange-soft:focus,
a.badge-orange-soft:hover {
	background-color: rgba(255, 199, 139, .2);
	color: #ffc78b
}

.badge-blue-soft {
	background-color: rgba(25, 110, 205, .1);
	color: #196ecd
}

a.badge-blue-soft:focus,
a.badge-blue-soft:hover {
	background-color: rgba(25, 110, 205, .2);
	color: #196ecd
}

.badge-purple-soft {
	background-color: rgba(184, 178, 253, .1);
	color: #b8b2fd
}

a.badge-purple-soft:focus,
a.badge-purple-soft:hover {
	background-color: rgba(184, 178, 253, .2);
	color: #b8b2fd
}

.badge-teal-soft {
	background-color: rgba(74, 143, 159, .1);
	color: #4a8f9f
}

a.badge-teal-soft:focus,
a.badge-teal-soft:hover {
	background-color: rgba(74, 143, 159, .2);
	color: #4a8f9f
}

.badge-ice-soft {
	background-color: rgba(156, 246, 219, .1);
	color: #9cf6db
}

a.badge-ice-soft:focus,
a.badge-ice-soft:hover {
	background-color: rgba(156, 246, 219, .2);
	color: #9cf6db
}

.badge-cyan-soft {
	background-color: rgba(158, 243, 246, .1);
	color: #9ef3f6
}

a.badge-cyan-soft:focus,
a.badge-cyan-soft:hover {
	background-color: rgba(158, 243, 246, .2);
	color: #9ef3f6
}

.badge-helio-soft {
	background-color: rgba(178, 83, 246, .1);
	color: #b253f6
}

a.badge-helio-soft:focus,
a.badge-helio-soft:hover {
	background-color: rgba(178, 83, 246, .2);
	color: #b253f6
}

.badge-white-ice-soft {
	background-color: rgba(240, 253, 248, .1);
	color: #f0fdf8
}

a.badge-white-ice-soft:focus,
a.badge-white-ice-soft:hover {
	background-color: rgba(240, 253, 248, .2);
	color: #f0fdf8
}

.badge-tropaz-soft {
	background-color: rgba(45, 79, 147, .1);
	color: #2d4f93
}

a.badge-tropaz-soft:focus,
a.badge-tropaz-soft:hover {
	background-color: rgba(45, 79, 147, .2);
	color: #2d4f93
}

.badge-roman-soft {
	background-color: rgba(224, 104, 92, .1);
	color: #e0685c
}

a.badge-roman-soft:focus,
a.badge-roman-soft:hover {
	background-color: rgba(224, 104, 92, .2);
	color: #e0685c
}

.badge-tangerine-soft {
	background-color: rgba(255, 149, 116, .1);
	color: #ff9574
}

a.badge-tangerine-soft:focus,
a.badge-tangerine-soft:hover {
	background-color: rgba(255, 149, 116, .2);
	color: #ff9574
}

.badge-gigas-soft {
	background-color: rgba(78, 53, 163, .1);
	color: #4e35a3
}

a.badge-gigas-soft:focus,
a.badge-gigas-soft:hover {
	background-color: rgba(78, 53, 163, .2);
	color: #4e35a3
}

.badge-lavender-soft {
	background-color: rgba(255, 235, 241, .1);
	color: #ffebf1
}

a.badge-lavender-soft:focus,
a.badge-lavender-soft:hover {
	background-color: rgba(255, 235, 241, .2);
	color: #ffebf1
}

.badge-sienna-soft {
	background-color: rgba(231, 100, 62, .1);
	color: #e7643e
}

a.badge-sienna-soft:focus,
a.badge-sienna-soft:hover {
	background-color: rgba(231, 100, 62, .2);
	color: #e7643e
}

.badge-coral-soft {
	background-color: rgba(254, 128, 93, .1);
	color: #fe805d
}

a.badge-coral-soft:focus,
a.badge-coral-soft:hover {
	background-color: rgba(254, 128, 93, .2);
	color: #fe805d
}

.badge-catskill-soft {
	background-color: rgba(249, 251, 252, .1);
	color: #f9fbfc
}

a.badge-catskill-soft:focus,
a.badge-catskill-soft:hover {
	background-color: rgba(249, 251, 252, .2);
	color: #f9fbfc
}

.badge-bossanova-soft {
	background-color: rgba(69, 43, 79, .1);
	color: #452b4f
}

a.badge-bossanova-soft:focus,
a.badge-bossanova-soft:hover {
	background-color: rgba(69, 43, 79, .2);
	color: #452b4f
}

.badge-denim-soft {
	background-color: rgba(20, 110, 207, .1);
	color: #146ecf
}

a.badge-denim-soft:focus,
a.badge-denim-soft:hover {
	background-color: rgba(20, 110, 207, .2);
	color: #146ecf
}

.badge-turbo-soft {
	background-color: rgba(254, 184, 0, .1);
	color: #feb800
}

a.badge-turbo-soft:focus,
a.badge-turbo-soft:hover {
	background-color: rgba(254, 184, 0, .2);
	color: #feb800
}

.badge-bright-sun-soft {
	background-color: rgba(255, 212, 56, .1);
	color: #ffd438
}

a.badge-bright-sun-soft:focus,
a.badge-bright-sun-soft:hover {
	background-color: rgba(255, 212, 56, .2);
	color: #ffd438
}

.badge-shark-soft {
	background-color: rgba(40, 42, 46, .1);
	color: #282a2e
}

a.badge-shark-soft:focus,
a.badge-shark-soft:hover {
	background-color: rgba(40, 42, 46, .2);
	color: #282a2e
}

.badge-dodger-soft {
	background-color: rgba(55, 125, 255, .1);
	color: #377dff
}

a.badge-dodger-soft:focus,
a.badge-dodger-soft:hover {
	background-color: rgba(55, 125, 255, .2);
	color: #377dff
}

.badge-cloud-burst-soft {
	background-color: rgba(33, 50, 91, .1);
	color: #21325b
}

a.badge-cloud-burst-soft:focus,
a.badge-cloud-burst-soft:hover {
	background-color: rgba(33, 50, 91, .2);
	color: #21325b
}

.badge-porsche-soft {
	background-color: rgba(240, 178, 107, .1);
	color: #f0b26b
}

a.badge-porsche-soft:focus,
a.badge-porsche-soft:hover {
	background-color: rgba(240, 178, 107, .2);
	color: #f0b26b
}

.badge-biscay-soft {
	background-color: rgba(24, 63, 99, .1);
	color: #183f63
}

a.badge-biscay-soft:focus,
a.badge-biscay-soft:hover {
	background-color: rgba(24, 63, 99, .2);
	color: #183f63
}

.badge-mongoose-soft {
	background-color: rgba(189, 165, 136, .1);
	color: #bda588
}

a.badge-mongoose-soft:focus,
a.badge-mongoose-soft:hover {
	background-color: rgba(189, 165, 136, .2);
	color: #bda588
}

.badge-ebony-soft {
	background-color: rgba(37, 40, 55, .1);
	color: #252837
}

a.badge-ebony-soft:focus,
a.badge-ebony-soft:hover {
	background-color: rgba(37, 40, 55, .2);
	color: #252837
}

.badge-alizarin-soft {
	background-color: rgba(235, 28, 36, .1);
	color: #eb1c24
}

a.badge-alizarin-soft:focus,
a.badge-alizarin-soft:hover {
	background-color: rgba(235, 28, 36, .2);
	color: #eb1c24
}

.badge-chestnut-soft {
	background-color: rgba(207, 87, 75, .1);
	color: #cf574b
}

a.badge-chestnut-soft:focus,
a.badge-chestnut-soft:hover {
	background-color: rgba(207, 87, 75, .2);
	color: #cf574b
}

.badge-portgore-soft {
	background-color: rgba(33, 32, 65, .1);
	color: #212041
}

a.badge-portgore-soft:focus,
a.badge-portgore-soft:hover {
	background-color: rgba(33, 32, 65, .2);
	color: #212041
}

.badge-primary {
	color: color-yiq(#090761);
	background-color: #090761
}

a.badge-primary:focus,
a.badge-primary:hover {
	color: color-yiq(#090761);
	background-color: #050431
}

a.badge-primary.focus,
a.badge-primary:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(9, 7, 97, .5)
}

.badge-secondary {
	color: color-yiq(#949da6);
	background-color: #949da6
}

a.badge-secondary:focus,
a.badge-secondary:hover {
	color: color-yiq(#949da6);
	background-color: #78848f
}

a.badge-secondary.focus,
a.badge-secondary:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(148, 157, 166, .5)
}

.badge-primary-desat {
	color: color-yiq(#6c8aec);
	background-color: #6c8aec
}

a.badge-primary-desat:focus,
a.badge-primary-desat:hover {
	color: color-yiq(#6c8aec);
	background-color: #3f66e6
}

a.badge-primary-desat.focus,
a.badge-primary-desat:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(108, 138, 236, .5)
}

.badge-black {
	color: color-yiq(#161c2d);
	background-color: #161c2d
}

a.badge-black:focus,
a.badge-black:hover {
	color: color-yiq(#161c2d);
	background-color: #05070b
}

a.badge-black.focus,
a.badge-black:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(22, 28, 45, .5)
}

.badge-gray-200 {
	color: color-yiq(#e7e7ec);
	background-color: #e7e7ec
}

a.badge-gray-200:focus,
a.badge-gray-200:hover {
	color: color-yiq(#e7e7ec);
	background-color: #cbcbd5
}

a.badge-gray-200.focus,
a.badge-gray-200:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(231, 231, 236, .5)
}

.badge-gray-800 {
	color: color-yiq(#77838f);
	background-color: #77838f
}

a.badge-gray-800:focus,
a.badge-gray-800:hover {
	color: color-yiq(#77838f);
	background-color: #5f6a74
}

a.badge-gray-800.focus,
a.badge-gray-800:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(119, 131, 143, .5)
}

.badge-white {
	color: color-yiq(#fff);
	background-color: #fff
}

a.badge-white:focus,
a.badge-white:hover {
	color: color-yiq(#fff);
	background-color: #e6e6e6
}

a.badge-white.focus,
a.badge-white:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5)
}

.badge-dark {
	color: color-yiq(#2f2d51);
	background-color: #2f2d51
}

a.badge-dark:focus,
a.badge-dark:hover {
	color: color-yiq(#2f2d51);
	background-color: #1c1b30
}

a.badge-dark.focus,
a.badge-dark:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(47, 45, 81, .5)
}

.badge-success {
	color: color-yiq(#def2d7);
	background-color: #def2d7
}

a.badge-success:focus,
a.badge-success:hover {
	color: color-yiq(#def2d7);
	background-color: #bee5b1
}

a.badge-success.focus,
a.badge-success:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(222, 242, 215, .5)
}

.badge-info {
	color: color-yiq(#cde9f6);
	background-color: #cde9f6
}

a.badge-info:focus,
a.badge-info:hover {
	color: color-yiq(#cde9f6);
	background-color: #a2d6ee
}

a.badge-info.focus,
a.badge-info:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(205, 233, 246, .5)
}

.badge-warning {
	color: color-yiq(#f7f3d7);
	background-color: #f7f3d7
}

a.badge-warning:focus,
a.badge-warning:hover {
	color: color-yiq(#f7f3d7);
	background-color: #efe6ad
}

a.badge-warning.focus,
a.badge-warning:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(247, 243, 215, .5)
}

.badge-danger {
	color: color-yiq(#ecc8c5);
	background-color: #ecc8c5
}

a.badge-danger:focus,
a.badge-danger:hover {
	color: color-yiq(#ecc8c5);
	background-color: #dfa49f
}

a.badge-danger.focus,
a.badge-danger:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(236, 200, 197, .5)
}

.badge-light {
	color: color-yiq(#f7f9fb);
	background-color: #f7f9fb
}

a.badge-light:focus,
a.badge-light:hover {
	color: color-yiq(#f7f9fb);
	background-color: #d5e0ea
}

a.badge-light.focus,
a.badge-light:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(247, 249, 251, .5)
}

.badge-orange {
	color: color-yiq(#ffc78b);
	background-color: #ffc78b
}

a.badge-orange:focus,
a.badge-orange:hover {
	color: color-yiq(#ffc78b);
	background-color: #ffae58
}

a.badge-orange.focus,
a.badge-orange:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(255, 199, 139, .5)
}

.badge-blue {
	color: color-yiq(#196ecd);
	background-color: #196ecd
}

a.badge-blue:focus,
a.badge-blue:hover {
	color: color-yiq(#196ecd);
	background-color: #1356a0
}

a.badge-blue.focus,
a.badge-blue:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(25, 110, 205, .5)
}

.badge-purple {
	color: color-yiq(#b8b2fd);
	background-color: #b8b2fd
}

a.badge-purple:focus,
a.badge-purple:hover {
	color: color-yiq(#b8b2fd);
	background-color: #8a80fc
}

a.badge-purple.focus,
a.badge-purple:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(184, 178, 253, .5)
}

.badge-teal {
	color: color-yiq(#4a8f9f);
	background-color: #4a8f9f
}

a.badge-teal:focus,
a.badge-teal:hover {
	color: color-yiq(#4a8f9f);
	background-color: #3a707c
}

a.badge-teal.focus,
a.badge-teal:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(74, 143, 159, .5)
}

.badge-ice {
	color: color-yiq(#9cf6db);
	background-color: #9cf6db
}

a.badge-ice:focus,
a.badge-ice:hover {
	color: color-yiq(#9cf6db);
	background-color: #6df2ca
}

a.badge-ice.focus,
a.badge-ice:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(156, 246, 219, .5)
}

.badge-cyan {
	color: color-yiq(#9ef3f6);
	background-color: #9ef3f6
}

a.badge-cyan:focus,
a.badge-cyan:hover {
	color: color-yiq(#9ef3f6);
	background-color: #6fedf2
}

a.badge-cyan.focus,
a.badge-cyan:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(158, 243, 246, .5)
}

.badge-helio {
	color: color-yiq(#b253f6);
	background-color: #b253f6
}

a.badge-helio:focus,
a.badge-helio:hover {
	color: color-yiq(#b253f6);
	background-color: #9c23f3
}

a.badge-helio.focus,
a.badge-helio:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(178, 83, 246, .5)
}

.badge-white-ice {
	color: color-yiq(#f0fdf8);
	background-color: #f0fdf8
}

a.badge-white-ice:focus,
a.badge-white-ice:hover {
	color: color-yiq(#f0fdf8);
	background-color: #c3f7e3
}

a.badge-white-ice.focus,
a.badge-white-ice:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(240, 253, 248, .5)
}

.badge-tropaz {
	color: color-yiq(#2d4f93);
	background-color: #2d4f93
}

a.badge-tropaz:focus,
a.badge-tropaz:hover {
	color: color-yiq(#2d4f93);
	background-color: #213a6c
}

a.badge-tropaz.focus,
a.badge-tropaz:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(45, 79, 147, .5)
}

.badge-roman {
	color: color-yiq(#e0685c);
	background-color: #e0685c
}

a.badge-roman:focus,
a.badge-roman:hover {
	color: color-yiq(#e0685c);
	background-color: #d84031
}

a.badge-roman.focus,
a.badge-roman:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(224, 104, 92, .5)
}

.badge-tangerine {
	color: color-yiq(#ff9574);
	background-color: #ff9574
}

a.badge-tangerine:focus,
a.badge-tangerine:hover {
	color: color-yiq(#ff9574);
	background-color: #ff6e41
}

a.badge-tangerine.focus,
a.badge-tangerine:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(255, 149, 116, .5)
}

.badge-gigas {
	color: color-yiq(#4e35a3);
	background-color: #4e35a3
}

a.badge-gigas:focus,
a.badge-gigas:hover {
	color: color-yiq(#4e35a3);
	background-color: #3c287d
}

a.badge-gigas.focus,
a.badge-gigas:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(78, 53, 163, .5)
}

.badge-lavender {
	color: color-yiq(#ffebf1);
	background-color: #ffebf1
}

a.badge-lavender:focus,
a.badge-lavender:hover {
	color: color-yiq(#ffebf1);
	background-color: #ffb8cd
}

a.badge-lavender.focus,
a.badge-lavender:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(255, 235, 241, .5)
}

.badge-sienna {
	color: color-yiq(#e7643e);
	background-color: #e7643e
}

a.badge-sienna:focus,
a.badge-sienna:hover {
	color: color-yiq(#e7643e);
	background-color: #d7451b
}

a.badge-sienna.focus,
a.badge-sienna:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(231, 100, 62, .5)
}

.badge-coral {
	color: color-yiq(#fe805d);
	background-color: #fe805d
}

a.badge-coral:focus,
a.badge-coral:hover {
	color: color-yiq(#fe805d);
	background-color: #fe582a
}

a.badge-coral.focus,
a.badge-coral:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(254, 128, 93, .5)
}

.badge-catskill {
	color: color-yiq(#f9fbfc);
	background-color: #f9fbfc
}

a.badge-catskill:focus,
a.badge-catskill:hover {
	color: color-yiq(#f9fbfc);
	background-color: #d7e4eb
}

a.badge-catskill.focus,
a.badge-catskill:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(249, 251, 252, .5)
}

.badge-bossanova {
	color: color-yiq(#452b4f);
	background-color: #452b4f
}

a.badge-bossanova:focus,
a.badge-bossanova:hover {
	color: color-yiq(#452b4f);
	background-color: #28192e
}

a.badge-bossanova.focus,
a.badge-bossanova:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(69, 43, 79, .5)
}

.badge-denim {
	color: color-yiq(#146ecf);
	background-color: #146ecf
}

a.badge-denim:focus,
a.badge-denim:hover {
	color: color-yiq(#146ecf);
	background-color: #1055a0
}

a.badge-denim.focus,
a.badge-denim:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(20, 110, 207, .5)
}

.badge-turbo {
	color: color-yiq(#feb800);
	background-color: #feb800
}

a.badge-turbo:focus,
a.badge-turbo:hover {
	color: color-yiq(#feb800);
	background-color: #cb9300
}

a.badge-turbo.focus,
a.badge-turbo:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(254, 184, 0, .5)
}

.badge-bright-sun {
	color: color-yiq(#ffd438);
	background-color: #ffd438
}

a.badge-bright-sun:focus,
a.badge-bright-sun:hover {
	color: color-yiq(#ffd438);
	background-color: #ffc905
}

a.badge-bright-sun.focus,
a.badge-bright-sun:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(255, 212, 56, .5)
}

.badge-shark {
	color: color-yiq(#282a2e);
	background-color: #282a2e
}

a.badge-shark:focus,
a.badge-shark:hover {
	color: color-yiq(#282a2e);
	background-color: #101113
}

a.badge-shark.focus,
a.badge-shark:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(40, 42, 46, .5)
}

.badge-dodger {
	color: color-yiq(#377dff);
	background-color: #377dff
}

a.badge-dodger:focus,
a.badge-dodger:hover {
	color: color-yiq(#377dff);
	background-color: #045cff
}

a.badge-dodger.focus,
a.badge-dodger:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(55, 125, 255, .5)
}

.badge-cloud-burst {
	color: color-yiq(#21325b);
	background-color: #21325b
}

a.badge-cloud-burst:focus,
a.badge-cloud-burst:hover {
	color: color-yiq(#21325b);
	background-color: #131d36
}

a.badge-cloud-burst.focus,
a.badge-cloud-burst:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(33, 50, 91, .5)
}

.badge-porsche {
	color: color-yiq(#f0b26b);
	background-color: #f0b26b
}

a.badge-porsche:focus,
a.badge-porsche:hover {
	color: color-yiq(#f0b26b);
	background-color: #eb9a3d
}

a.badge-porsche.focus,
a.badge-porsche:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(240, 178, 107, .5)
}

.badge-biscay {
	color: color-yiq(#183f63);
	background-color: #183f63
}

a.badge-biscay:focus,
a.badge-biscay:hover {
	color: color-yiq(#183f63);
	background-color: #0e253a
}

a.badge-biscay.focus,
a.badge-biscay:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(24, 63, 99, .5)
}

.badge-mongoose {
	color: color-yiq(#bda588);
	background-color: #bda588
}

a.badge-mongoose:focus,
a.badge-mongoose:hover {
	color: color-yiq(#bda588);
	background-color: #ab8c67
}

a.badge-mongoose.focus,
a.badge-mongoose:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(189, 165, 136, .5)
}

.badge-ebony {
	color: color-yiq(#252837);
	background-color: #252837
}

a.badge-ebony:focus,
a.badge-ebony:hover {
	color: color-yiq(#252837);
	background-color: #101219
}

a.badge-ebony.focus,
a.badge-ebony:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(37, 40, 55, .5)
}

.badge-alizarin {
	color: color-yiq(#eb1c24);
	background-color: #eb1c24
}

a.badge-alizarin:focus,
a.badge-alizarin:hover {
	color: color-yiq(#eb1c24);
	background-color: #c31118
}

a.badge-alizarin.focus,
a.badge-alizarin:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(235, 28, 36, .5)
}

.badge-chestnut {
	color: color-yiq(#cf574b);
	background-color: #cf574b
}

a.badge-chestnut:focus,
a.badge-chestnut:hover {
	color: color-yiq(#cf574b);
	background-color: #b63d31
}

a.badge-chestnut.focus,
a.badge-chestnut:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(207, 87, 75, .5)
}

.badge-portgore {
	color: color-yiq(#212041);
	background-color: #212041
}

a.badge-portgore:focus,
a.badge-portgore:hover {
	color: color-yiq(#212041);
	background-color: #100f1f
}

a.badge-portgore.focus,
a.badge-portgore:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(33, 32, 65, .5)
}

.badge-gray-700-soft {
	background-color: rgba(148, 157, 166, .1);
	color: #949da6
}

a.badge-gray-700-soft:focus,
a.badge-gray-700-soft:hover {
	background-color: rgba(148, 157, 166, .2);
	color: #949da6
}

.badge-gray-600 {
	background-color: #8c98a4;
	color: #fff
}

.badge-lg {
	padding: .7em 1.15em
}

.badge-pill {
	padding-right: .75rem;
	padding-left: .75rem;
	border-radius: 10rem
}

.badge-rounded-circle {
	height: calc(1em + .5rem);
	padding-left: .25rem;
	padding-right: .25rem;
	border-radius: 10rem
}

.badge-rounded-circle>* {
	display: block;
	width: 1em
}

.badge-rounded-circle.badge-lg {
	height: calc(1em + 1.4em);
	padding-left: .7em;
	padding-right: .7em
}

.badge-float,
.card-float {
	position: absolute;
	z-index: 100
}

.badge-float-outside {
	top: -.5rem;
	right: -.5rem
}

.badge-float-inside {
	top: 1rem;
	right: 1rem
}

.badge-white-soft.active {
	background-color: #fff;
	color: #090761
}

.badge-white-soft.active:focus,
.badge-white-soft.active:hover {
	background-color: #f2f2f2;
	color: #090761
}

.badge-secondary-soft.active {
	background-color: #090761;
	color: color-yiq(#090761)
}

.badge-secondary-soft.active:focus,
.badge-secondary-soft.active:hover {
	background-color: #070549;
	color: color-yiq(#070549)
}

.breadcrumb-item {
	font-size: .875rem
}

.breadcrumb-item+.breadcrumb-item::before {
	content: "/"
}

.breadcrumb-scroll {
	display: -webkit-box;
	display: flex;
	flex-wrap: nowrap;
	overflow-y: auto
}

.breadcrumb-scroll .breadcrumb-item {
	white-space: nowrap
}

.breadcrumb-scroll::-webkit-scrollbar {
	display: none
}

.btn>img,
.cart-collaterals .checkout-button>img,
.shop_table.cart input.button>img,
form.woocommerce-checkout .place-order .button>img {
	max-height: 1em;
	width: auto
}

.btn:not([class*=btn-outline]),
.cart-collaterals .checkout-button:not([class*=btn-outline]),
.shop_table.cart input.button:not([class*=btn-outline]),
form.woocommerce-checkout .place-order .button:not([class*=btn-outline]) {
	border-color: transparent!important
}

.btn-xs {
	padding: .25rem .625rem;
	font-size: .875rem
}

.btn-wide,
.shop_table.cart .coupon input.button {
	min-width: 12.5rem
}

.btn-sm-wide {
	min-width: 11.25rem
}

.btn-x-wide {
	min-width: 11.25rem
}

@media (min-width:768px) {
	.btn-x-wide {
		min-width: 15.375rem
	}
}

.btn-white {
	background-color: #fff;
	color: #090761
}

.btn-white:focus,
.btn-white:hover {
	background-color: #fff;
	color: #070549
}

.btn-white.active {
	background-color: #f2f2f2;
	color: #070549
}

.btn-group .btn-white {
	border-color: #d9e2ef!important;
	color: #8c98a4
}

.btn-group .btn-white.active {
	background-color: #f2f2f2;
	color: #7e8b99;
	box-shadow: none
}

.btn-gray-300-20 {
	background-color: rgba(217, 226, 239, .2);
	color: #fff
}

.btn-gray-300-20:focus,
.btn-gray-300-20:hover {
	background-color: rgba(217, 226, 239, .25);
	color: #fff
}

.btn-gray-300-20.active {
	background-color: rgba(217, 226, 239, .25);
	color: #fff
}

.btn-gray-400 {
	background-color: #c6d3e6;
	color: #fff
}

.btn-gray-400:focus,
.btn-gray-400:hover {
	background-color: #b4c5de;
	color: #f2f2f2
}

.btn-gray-400.active {
	background-color: #b4c5de;
	color: #f2f2f2
}

.btn-gray-400-10 {
	background-color: rgba(198, 211, 230, .1);
	color: #fff
}

.btn-gray-400-10:focus,
.btn-gray-400-10:hover {
	background-color: rgba(198, 211, 230, .15);
	color: #fff
}

.btn-gray-400-10.active {
	background-color: rgba(198, 211, 230, .15);
	color: #fff
}

.btn-outline-white {
	border-color: #d9e2ef;
	color: #77838f
}

.btn-outline-white:focus,
.btn-outline-white:hover {
	background-color: #f2f2f2;
	border-color: #c6d3e6;
	color: #77838f
}

.btn-outline-white.active {
	background-color: #f2f2f2;
	border-color: #c6d3e6;
	color: #77838f
}

.btn-outline-gray-300 {
	border-color: #d9e2ef;
	color: #090761
}

.btn-outline-gray-300:focus,
.btn-outline-gray-300:hover {
	background-color: #090761;
	border-color: #090761;
	color: #fff
}

.btn-outline-gray-300.active {
	background-color: #090761;
	border-color: #090761;
	color: #fff
}

.btn-primary-soft {
	background-color: rgba(9, 7, 97, .1);
	color: #090761
}

.btn-primary-soft:focus,
.btn-primary-soft:hover {
	background-color: rgba(9, 7, 97, .15);
	color: #090761
}

.btn-primary-soft.active {
	background-color: rgba(9, 7, 97, .15);
	color: #090761
}

.btn-secondary-soft {
	background-color: rgba(148, 157, 166, .1);
	color: #949da6
}

.btn-secondary-soft:focus,
.btn-secondary-soft:hover {
	background-color: rgba(148, 157, 166, .15);
	color: #949da6
}

.btn-secondary-soft.active {
	background-color: rgba(148, 157, 166, .15);
	color: #949da6
}

.btn-primary-desat-soft {
	background-color: rgba(108, 138, 236, .1);
	color: #6c8aec
}

.btn-primary-desat-soft:focus,
.btn-primary-desat-soft:hover {
	background-color: rgba(108, 138, 236, .15);
	color: #6c8aec
}

.btn-primary-desat-soft.active {
	background-color: rgba(108, 138, 236, .15);
	color: #6c8aec
}

.btn-black-soft {
	background-color: rgba(22, 28, 45, .1);
	color: #161c2d
}

.btn-black-soft:focus,
.btn-black-soft:hover {
	background-color: rgba(22, 28, 45, .15);
	color: #161c2d
}

.btn-black-soft.active {
	background-color: rgba(22, 28, 45, .15);
	color: #161c2d
}

.btn-gray-200-soft {
	background-color: rgba(231, 231, 236, .1);
	color: #e7e7ec
}

.btn-gray-200-soft:focus,
.btn-gray-200-soft:hover {
	background-color: rgba(231, 231, 236, .15);
	color: #e7e7ec
}

.btn-gray-200-soft.active {
	background-color: rgba(231, 231, 236, .15);
	color: #e7e7ec
}

.btn-gray-800-soft {
	background-color: rgba(119, 131, 143, .1);
	color: #77838f
}

.btn-gray-800-soft:focus,
.btn-gray-800-soft:hover {
	background-color: rgba(119, 131, 143, .15);
	color: #77838f
}

.btn-gray-800-soft.active {
	background-color: rgba(119, 131, 143, .15);
	color: #77838f
}

.btn-white-soft {
	background-color: rgba(255, 255, 255, .1);
	color: #fff
}

.btn-white-soft:focus,
.btn-white-soft:hover {
	background-color: rgba(255, 255, 255, .15);
	color: #fff
}

.btn-white-soft.active {
	background-color: rgba(255, 255, 255, .15);
	color: #fff
}

.btn-dark-soft {
	background-color: rgba(47, 45, 81, .1);
	color: #2f2d51
}

.btn-dark-soft:focus,
.btn-dark-soft:hover {
	background-color: rgba(47, 45, 81, .15);
	color: #2f2d51
}

.btn-dark-soft.active {
	background-color: rgba(47, 45, 81, .15);
	color: #2f2d51
}

.btn-success-soft {
	background-color: rgba(222, 242, 215, .1);
	color: #def2d7
}

.btn-success-soft:focus,
.btn-success-soft:hover {
	background-color: rgba(222, 242, 215, .15);
	color: #def2d7
}

.btn-success-soft.active {
	background-color: rgba(222, 242, 215, .15);
	color: #def2d7
}

.btn-info-soft {
	background-color: rgba(205, 233, 246, .1);
	color: #cde9f6
}

.btn-info-soft:focus,
.btn-info-soft:hover {
	background-color: rgba(205, 233, 246, .15);
	color: #cde9f6
}

.btn-info-soft.active {
	background-color: rgba(205, 233, 246, .15);
	color: #cde9f6
}

.btn-warning-soft {
	background-color: rgba(247, 243, 215, .1);
	color: #f7f3d7
}

.btn-warning-soft:focus,
.btn-warning-soft:hover {
	background-color: rgba(247, 243, 215, .15);
	color: #f7f3d7
}

.btn-warning-soft.active {
	background-color: rgba(247, 243, 215, .15);
	color: #f7f3d7
}

.btn-danger-soft {
	background-color: rgba(236, 200, 197, .1);
	color: #ecc8c5
}

.btn-danger-soft:focus,
.btn-danger-soft:hover {
	background-color: rgba(236, 200, 197, .15);
	color: #ecc8c5
}

.btn-danger-soft.active {
	background-color: rgba(236, 200, 197, .15);
	color: #ecc8c5
}

.btn-light-soft {
	background-color: rgba(247, 249, 251, .1);
	color: #f7f9fb
}

.btn-light-soft:focus,
.btn-light-soft:hover {
	background-color: rgba(247, 249, 251, .15);
	color: #f7f9fb
}

.btn-light-soft.active {
	background-color: rgba(247, 249, 251, .15);
	color: #f7f9fb
}

.btn-orange-soft {
	background-color: rgba(255, 199, 139, .1);
	color: #ffc78b
}

.btn-orange-soft:focus,
.btn-orange-soft:hover {
	background-color: rgba(255, 199, 139, .15);
	color: #ffc78b
}

.btn-orange-soft.active {
	background-color: rgba(255, 199, 139, .15);
	color: #ffc78b
}

.btn-blue-soft {
	background-color: rgba(25, 110, 205, .1);
	color: #196ecd
}

.btn-blue-soft:focus,
.btn-blue-soft:hover {
	background-color: rgba(25, 110, 205, .15);
	color: #196ecd
}

.btn-blue-soft.active {
	background-color: rgba(25, 110, 205, .15);
	color: #196ecd
}

.btn-purple-soft {
	background-color: rgba(184, 178, 253, .1);
	color: #b8b2fd
}

.btn-purple-soft:focus,
.btn-purple-soft:hover {
	background-color: rgba(184, 178, 253, .15);
	color: #b8b2fd
}

.btn-purple-soft.active {
	background-color: rgba(184, 178, 253, .15);
	color: #b8b2fd
}

.btn-teal-soft {
	background-color: rgba(74, 143, 159, .1);
	color: #4a8f9f
}

.btn-teal-soft:focus,
.btn-teal-soft:hover {
	background-color: rgba(74, 143, 159, .15);
	color: #4a8f9f
}

.btn-teal-soft.active {
	background-color: rgba(74, 143, 159, .15);
	color: #4a8f9f
}

.btn-ice-soft {
	background-color: rgba(156, 246, 219, .1);
	color: #9cf6db
}

.btn-ice-soft:focus,
.btn-ice-soft:hover {
	background-color: rgba(156, 246, 219, .15);
	color: #9cf6db
}

.btn-ice-soft.active {
	background-color: rgba(156, 246, 219, .15);
	color: #9cf6db
}

.btn-cyan-soft {
	background-color: rgba(158, 243, 246, .1);
	color: #9ef3f6
}

.btn-cyan-soft:focus,
.btn-cyan-soft:hover {
	background-color: rgba(158, 243, 246, .15);
	color: #9ef3f6
}

.btn-cyan-soft.active {
	background-color: rgba(158, 243, 246, .15);
	color: #9ef3f6
}

.btn-helio-soft {
	background-color: rgba(178, 83, 246, .1);
	color: #b253f6
}

.btn-helio-soft:focus,
.btn-helio-soft:hover {
	background-color: rgba(178, 83, 246, .15);
	color: #b253f6
}

.btn-helio-soft.active {
	background-color: rgba(178, 83, 246, .15);
	color: #b253f6
}

.btn-white-ice-soft {
	background-color: rgba(240, 253, 248, .1);
	color: #f0fdf8
}

.btn-white-ice-soft:focus,
.btn-white-ice-soft:hover {
	background-color: rgba(240, 253, 248, .15);
	color: #f0fdf8
}

.btn-white-ice-soft.active {
	background-color: rgba(240, 253, 248, .15);
	color: #f0fdf8
}

.btn-tropaz-soft {
	background-color: rgba(45, 79, 147, .1);
	color: #2d4f93
}

.btn-tropaz-soft:focus,
.btn-tropaz-soft:hover {
	background-color: rgba(45, 79, 147, .15);
	color: #2d4f93
}

.btn-tropaz-soft.active {
	background-color: rgba(45, 79, 147, .15);
	color: #2d4f93
}

.btn-roman-soft {
	background-color: rgba(224, 104, 92, .1);
	color: #e0685c
}

.btn-roman-soft:focus,
.btn-roman-soft:hover {
	background-color: rgba(224, 104, 92, .15);
	color: #e0685c
}

.btn-roman-soft.active {
	background-color: rgba(224, 104, 92, .15);
	color: #e0685c
}

.btn-tangerine-soft {
	background-color: rgba(255, 149, 116, .1);
	color: #ff9574
}

.btn-tangerine-soft:focus,
.btn-tangerine-soft:hover {
	background-color: rgba(255, 149, 116, .15);
	color: #ff9574
}

.btn-tangerine-soft.active {
	background-color: rgba(255, 149, 116, .15);
	color: #ff9574
}

.btn-gigas-soft {
	background-color: rgba(78, 53, 163, .1);
	color: #4e35a3
}

.btn-gigas-soft:focus,
.btn-gigas-soft:hover {
	background-color: rgba(78, 53, 163, .15);
	color: #4e35a3
}

.btn-gigas-soft.active {
	background-color: rgba(78, 53, 163, .15);
	color: #4e35a3
}

.btn-lavender-soft {
	background-color: rgba(255, 235, 241, .1);
	color: #ffebf1
}

.btn-lavender-soft:focus,
.btn-lavender-soft:hover {
	background-color: rgba(255, 235, 241, .15);
	color: #ffebf1
}

.btn-lavender-soft.active {
	background-color: rgba(255, 235, 241, .15);
	color: #ffebf1
}

.btn-sienna-soft {
	background-color: rgba(231, 100, 62, .1);
	color: #e7643e
}

.btn-sienna-soft:focus,
.btn-sienna-soft:hover {
	background-color: rgba(231, 100, 62, .15);
	color: #e7643e
}

.btn-sienna-soft.active {
	background-color: rgba(231, 100, 62, .15);
	color: #e7643e
}

.btn-coral-soft {
	background-color: rgba(254, 128, 93, .1);
	color: #fe805d
}

.btn-coral-soft:focus,
.btn-coral-soft:hover {
	background-color: rgba(254, 128, 93, .15);
	color: #fe805d
}

.btn-coral-soft.active {
	background-color: rgba(254, 128, 93, .15);
	color: #fe805d
}

.btn-catskill-soft {
	background-color: rgba(249, 251, 252, .1);
	color: #f9fbfc
}

.btn-catskill-soft:focus,
.btn-catskill-soft:hover {
	background-color: rgba(249, 251, 252, .15);
	color: #f9fbfc
}

.btn-catskill-soft.active {
	background-color: rgba(249, 251, 252, .15);
	color: #f9fbfc
}

.btn-bossanova-soft {
	background-color: rgba(69, 43, 79, .1);
	color: #452b4f
}

.btn-bossanova-soft:focus,
.btn-bossanova-soft:hover {
	background-color: rgba(69, 43, 79, .15);
	color: #452b4f
}

.btn-bossanova-soft.active {
	background-color: rgba(69, 43, 79, .15);
	color: #452b4f
}

.btn-denim-soft {
	background-color: rgba(20, 110, 207, .1);
	color: #146ecf
}

.btn-denim-soft:focus,
.btn-denim-soft:hover {
	background-color: rgba(20, 110, 207, .15);
	color: #146ecf
}

.btn-denim-soft.active {
	background-color: rgba(20, 110, 207, .15);
	color: #146ecf
}

.btn-turbo-soft {
	background-color: rgba(254, 184, 0, .1);
	color: #feb800
}

.btn-turbo-soft:focus,
.btn-turbo-soft:hover {
	background-color: rgba(254, 184, 0, .15);
	color: #feb800
}

.btn-turbo-soft.active {
	background-color: rgba(254, 184, 0, .15);
	color: #feb800
}

.btn-bright-sun-soft {
	background-color: rgba(255, 212, 56, .1);
	color: #ffd438
}

.btn-bright-sun-soft:focus,
.btn-bright-sun-soft:hover {
	background-color: rgba(255, 212, 56, .15);
	color: #ffd438
}

.btn-bright-sun-soft.active {
	background-color: rgba(255, 212, 56, .15);
	color: #ffd438
}

.btn-shark-soft {
	background-color: rgba(40, 42, 46, .1);
	color: #282a2e
}

.btn-shark-soft:focus,
.btn-shark-soft:hover {
	background-color: rgba(40, 42, 46, .15);
	color: #282a2e
}

.btn-shark-soft.active {
	background-color: rgba(40, 42, 46, .15);
	color: #282a2e
}

.btn-dodger-soft {
	background-color: rgba(55, 125, 255, .1);
	color: #377dff
}

.btn-dodger-soft:focus,
.btn-dodger-soft:hover {
	background-color: rgba(55, 125, 255, .15);
	color: #377dff
}

.btn-dodger-soft.active {
	background-color: rgba(55, 125, 255, .15);
	color: #377dff
}

.btn-cloud-burst-soft {
	background-color: rgba(33, 50, 91, .1);
	color: #21325b
}

.btn-cloud-burst-soft:focus,
.btn-cloud-burst-soft:hover {
	background-color: rgba(33, 50, 91, .15);
	color: #21325b
}

.btn-cloud-burst-soft.active {
	background-color: rgba(33, 50, 91, .15);
	color: #21325b
}

.btn-porsche-soft {
	background-color: rgba(240, 178, 107, .1);
	color: #f0b26b
}

.btn-porsche-soft:focus,
.btn-porsche-soft:hover {
	background-color: rgba(240, 178, 107, .15);
	color: #f0b26b
}

.btn-porsche-soft.active {
	background-color: rgba(240, 178, 107, .15);
	color: #f0b26b
}

.btn-biscay-soft {
	background-color: rgba(24, 63, 99, .1);
	color: #183f63
}

.btn-biscay-soft:focus,
.btn-biscay-soft:hover {
	background-color: rgba(24, 63, 99, .15);
	color: #183f63
}

.btn-biscay-soft.active {
	background-color: rgba(24, 63, 99, .15);
	color: #183f63
}

.btn-mongoose-soft {
	background-color: rgba(189, 165, 136, .1);
	color: #bda588
}

.btn-mongoose-soft:focus,
.btn-mongoose-soft:hover {
	background-color: rgba(189, 165, 136, .15);
	color: #bda588
}

.btn-mongoose-soft.active {
	background-color: rgba(189, 165, 136, .15);
	color: #bda588
}

.btn-ebony-soft {
	background-color: rgba(37, 40, 55, .1);
	color: #252837
}

.btn-ebony-soft:focus,
.btn-ebony-soft:hover {
	background-color: rgba(37, 40, 55, .15);
	color: #252837
}

.btn-ebony-soft.active {
	background-color: rgba(37, 40, 55, .15);
	color: #252837
}

.btn-alizarin-soft {
	background-color: rgba(235, 28, 36, .1);
	color: #eb1c24
}

.btn-alizarin-soft:focus,
.btn-alizarin-soft:hover {
	background-color: rgba(235, 28, 36, .15);
	color: #eb1c24
}

.btn-alizarin-soft.active {
	background-color: rgba(235, 28, 36, .15);
	color: #eb1c24
}

.btn-chestnut-soft {
	background-color: rgba(207, 87, 75, .1);
	color: #cf574b
}

.btn-chestnut-soft:focus,
.btn-chestnut-soft:hover {
	background-color: rgba(207, 87, 75, .15);
	color: #cf574b
}

.btn-chestnut-soft.active {
	background-color: rgba(207, 87, 75, .15);
	color: #cf574b
}

.btn-portgore-soft {
	background-color: rgba(33, 32, 65, .1);
	color: #212041
}

.btn-portgore-soft:focus,
.btn-portgore-soft:hover {
	background-color: rgba(33, 32, 65, .15);
	color: #212041
}

.btn-portgore-soft.active {
	background-color: rgba(33, 32, 65, .15);
	color: #212041
}

.btn-block {
	width: 100%
}

.btn-pill {
	border-radius: 50rem
}

.btn-rounded-circle {
	padding-left: 1rem;
	padding-right: 1rem;
	border-radius: 50rem
}

.btn-rounded-circle>* {
	display: block;
	line-height: 1;
	width: 1em
}

.btn-group-lg>.btn-rounded-circle.btn,
.btn-rounded-circle.btn-lg,
.cart-collaterals .btn-group-lg>.btn-rounded-circle.checkout-button,
.shop_table.cart .btn-group-lg>input.btn-rounded-circle.button,
form.woocommerce-checkout .place-order .btn-group-lg>.btn-rounded-circle.button {
	padding-left: 1.122rem;
	padding-right: 1.122rem
}

.btn-group-sm>.btn-rounded-circle.btn,
.btn-rounded-circle.btn-sm,
.cart-collaterals .btn-group-sm>.btn-rounded-circle.checkout-button,
.shop_table.cart input.btn-rounded-circle.button,
form.woocommerce-checkout .place-order .btn-group-sm>.btn-rounded-circle.button {
	padding-left: .5625rem;
	padding-right: .5625rem
}

.btn-rounded-circle.btn-xs {
	padding-left: .25rem;
	padding-right: .25rem
}

.btn-gradient-1 {
	background: -webkit-gradient(linear, left top, right top, color-stop(.26%, #ffa35a), color-stop(100.08%, #ff2574))!important;
	background: linear-gradient(90deg, #ffa35a .26%, #ff2574 100.08%)!important
}

.btn-gradient-1:focus,
.btn-gradient-1:hover {
	background: -webkit-gradient(linear, left top, right top, color-stop(.26%, #ff9541), color-stop(100.08%, #ff0c64))!important;
	background: linear-gradient(90deg, #ff9541 .26%, #ff0c64 100.08%)!important
}

.btn.slide-primary:not([class*=btn-outline]),
.cart-collaterals .slide-primary.checkout-button:not([class*=btn-outline]),
.shop_table.cart input.slide-primary.button:not([class*=btn-outline]),
form.woocommerce-checkout .place-order .slide-primary.button:not([class*=btn-outline]) {
	border-color: #090761!important
}

.btn.slide-white:not([class*=btn-outline]),
.cart-collaterals .slide-white.checkout-button:not([class*=btn-outline]),
.shop_table.cart input.slide-white.button:not([class*=btn-outline]),
form.woocommerce-checkout .place-order .slide-white.button:not([class*=btn-outline]) {
	border-color: #fff!important
}

.slide-primary {
	background: -webkit-gradient(linear, right top, left top, color-stop(50%, transparent), color-stop(50%, #090761));
	background: linear-gradient(to left, transparent 50%, #090761 50%);
	border: 1px solid #090761;
	border-left: 6px solid #090761;
	color: #090761
}

.slide-primary:focus,
.slide-primary:hover {
	color: #fff
}

.slide-white {
	background: -webkit-gradient(linear, right top, left top, color-stop(50%, transparent), color-stop(50%, #fff));
	background: linear-gradient(to left, transparent 50%, #fff 50%);
	border: 1px solid #fff;
	border-left: 6px solid #fff;
	color: #fff
}

.slide-white:focus,
.slide-white:hover {
	color: #77838f
}

.btn-slide {
	background-size: 200% 100%;
	background-position: right bottom;
	-webkit-transition: all .5s ease;
	transition: all .5s ease;
	-webkit-transition-property: inherit!important;
	transition-property: inherit!important
}

.btn-slide:focus,
.btn-slide:hover {
	background-position: left bottom
}

.card {
	position: relative;
	width: 100%;
	-webkit-transition: all .3s ease;
	transition: all .3s ease
}

.card-header {
	background-color: transparent;
	border-bottom: 1px solid #e7e7ec
}

.card-body {
	display: block;
	-webkit-box-flex: 0;
	flex-grow: 0
}

.card-footer {
	background-color: unset
}

.card-body,
.card-footer,
.card-img,
.card-img-left,
.card-img-right,
.card-img-top,
.card-meta {
	position: relative;
	min-height: 1px
}

a.card,
a.card-body,
a.card-footer,
a.card-meta {
	color: inherit
}

a.card-body:hover,
a.card-footer:hover,
a.card-meta:hover,
a.card:hover {
	text-decoration: none
}

.card-overlay-image::before {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	content: " ";
	border-radius: inherit;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}

.card-overlay-image::before {
	background-color: inherit
}

.card-overlay-image:focus::before,
.card-overlay-image:hover::before {
	opacity: .8
}

.card-hover-image .hover-image {
	margin-bottom: .6rem;
	height: 0;
	opacity: 0;
	visibility: hidden;
	position: relative
}

.card-hover-image::before {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(254, 128, 93, .8);
	content: " ";
	opacity: 0;
	border-radius: inherit
}

.card-hover-image:focus .hover-image,
.card-hover-image:hover .hover-image {
	height: auto;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}

.card-hover-image:focus::before,
.card-hover-image:hover::before {
	opacity: 1
}

.card-hover {
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}

.card-hover-overlay::before {
	content: " ";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #090761;
	opacity: 0;
	visibility: hidden;
	z-index: 1
}

.card-hover-overlay.coral::before {
	background: #fe805d
}

.card-hover-overlay.alizarin::before {
	background: #eb1c24
}

.card-hover-overlay.ebony::before {
	background: #252837;
	opacity: .9;
	visibility: visible;
	border-radius: inherit
}

.card-hover-overlay.ebony:hover::before {
	background: #eb1c24
}

.card-hover-overlay-gradient-1::before {
	background: -webkit-gradient(linear, left top, right top, color-stop(.26%, #ffa35a), color-stop(100.08%, #ff2574));
	background: linear-gradient(90deg, #ffa35a .26%, #ff2574 100.08%)
}

.card:focus .card-hover,
.card:hover .card-hover {
	visibility: visible;
	opacity: 1
}

.card:focus .card-hover-overlay::before,
.card:hover .card-hover-overlay::before {
	opacity: .8;
	visibility: visible
}

.card .card-hover-up {
	margin-bottom: 5rem!important
}

.card-border-hover {
	border-color: transparent!important
}

.card-border-hover:focus,
.card-border-hover:hover {
	border-color: #e7e7ec!important
}

.card-flush {
	background-color: unset
}

.card-flush>:not(.card-btn) {
	padding-left: 0;
	padding-right: 0
}

.card-border {
	position: relative
}

.card-border-action {
	border: 1px solid #e7e7ec
}

.card-border-action:focus,
.card-border-action:hover {
	border-color: #090761
}

.card-border::after {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	border-top-width: 2px;
	border-bottom-width: calc(.25rem - 2px);
	border-top-style: solid;
	border-bottom-style: solid;
	border-top-color: inherit;
	border-bottom-color: transparent;
	border-top-left-radius: .25rem;
	border-top-right-radius: .25rem
}

.card-border-lg::after {
	border-top-width: 3px;
	border-bottom-width: calc(.25rem - 3px)
}

.card-border-xl::after {
	border-top-width: 4px;
	border-bottom-width: calc(.25rem - 3px)
}

@media (min-width:768px) {
	.card-row .card-body {
		padding: 4rem 2.5rem
	}
}

@media (min-width:768px) {
	.card-row .card-meta {
		padding-left: 2.5rem;
		padding-right: 2.5rem
	}
}

.card-img-right {
	border-radius: .25rem .25rem 0 0
}

@media (min-width:768px) {
	.card-img-right {
		border-radius: 0 .25rem .25rem 0
	}
}

.card-img-left {
	border-radius: .25rem .25rem 0 0
}

@media (min-width:768px) {
	.card-img-left {
		border-radius: .25rem 0 0 .25rem
	}
}

@media (min-width:768px) {
	.card-img-slider {
		height: 100%;
		width: 100%
	}
	.card-img-slider * {
		height: inherit!important;
		width: inherit!important
	}
}

.card-text-overlay {
	opacity: 0;
	visibility: hidden
}

.card:hover .card-text-overlay {
	opacity: 1;
	visibility: visible
}

.card-img-overlay {
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	padding: 2rem
}

.card-img-overlay .card-body {
	margin: -2rem;
	margin-top: auto;
	border-bottom-right-radius: calc(.25rem - 0px);
	border-bottom-left-radius: calc(.25rem - 0px)
}

.card-img-overlay-hover {
	overflow: hidden
}

.card-img-overlay-hover .card-body {
	-webkit-transform: translateY(110%);
	transform: translateY(110%);
	-webkit-transition: all .3s ease;
	transition: all .3s ease
}

.card-img-overlay-hover:hover .card-body {
	-webkit-transform: translateY(0);
	transform: translateY(0)
}

@media (min-width:576px) and (max-width:767.98px) {
	.card-group {
		-webkit-box-orient: vertical!important;
		-webkit-box-direction: normal!important;
		flex-direction: column!important
	}
	.card-group>.card {
		-webkit-box-flex: 1;
		flex: auto
	}
}

.card-meta {
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	-webkit-box-align: center;
	align-items: center;
	padding: 0 2rem 1.5rem
}

.card-meta-divider {
	width: 100%;
	margin: 0 0 1.5rem
}

.card-btn:last-child {
	border-top-right-radius: 0;
	border-top-left-radius: 0
}

.card-zoom {
	overflow: hidden!important;
	border-radius: inherit
}

.card-zoom>[class*=card-img] {
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
	-webkit-transform-origin: center center;
	transform-origin: center center
}

.card:hover>.card-zoom>[class*=card-img] {
	-webkit-transform: scale(1.1);
	transform: scale(1.1)
}

.card-list .list-link {
	position: relative
}

.card-list .list-link::before {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: -2rem;
	border-right: 2px solid #090761;
	display: none
}

.card-list .list-link:hover {
	color: #77838f!important
}

.card-list .active .list-link {
	color: #77838f!important
}

.card-list .active .list-link::before {
	display: block
}

@media (max-width:575.98px) {
	.card-bleed {
		width: auto;
		min-width: 100%;
		margin-right: -15px;
		margin-left: -15px;
		border-radius: 0
	}
	.card-bleed .card-body,
	.card-bleed .card-footer,
	.card-bleed .card-header {
		padding-right: 15px;
		padding-left: 15px;
		border-radius: 0
	}
	.card-bleed .card-list .list-link::before {
		left: -15px
	}
}

@media (min-width:1200px) {
	.product-item:not(.no-hover):focus .product-wrapper,
	.product-item:not(.no-hover):hover .product-wrapper {
		-webkit-transform: translateY(-54px);
		transform: translateY(-54px)
	}
	.product-item:not(.no-hover) .product-wrapper {
		-webkit-transition: -webkit-transform .3s ease;
		transition: -webkit-transform .3s ease;
		transition: transform .3s ease;
		transition: transform .3s ease, -webkit-transform .3s ease
	}
	.product-item:not(.no-hover) .add-btn,
	.product-item:not(.no-hover) .card-footer,
	.product-item:not(.no-hover) .product-info {
		height: 54px;
		overflow: hidden
	}
}

.close:focus,
.close:hover {
	outline: 0
}

button.close {
	padding: 0;
	background-color: transparent;
	border: 0
}

.custom-control {
	position: relative;
	display: block;
	min-height: 1.6rem;
	padding-left: 1.5rem
}

.custom-control-input {
	position: absolute;
	left: 0;
	z-index: -1;
	width: 1rem;
	height: 1.3rem;
	opacity: 0
}

.custom-control-label {
	color: #77838f;
	font-size: .875rem;
	padding-left: 6px;
	position: relative;
	margin-bottom: 0;
	vertical-align: top
}

.custom-control-label::after {
	top: .55rem;
	left: -1.24rem;
	width: .5rem;
	height: .5rem;
	content: "";
	background: 0 0;
	border-radius: 3px;
	position: absolute
}

.custom-control-label::before {
	position: absolute;
	top: .3rem;
	left: -1.5rem;
	display: block;
	width: 1rem;
	height: 1rem;
	pointer-events: none;
	content: "";
	background-color: transparent;
	border: #e7e7ec solid 1px;
	border-radius: 3px
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
	background-color: transparent;
	border-color: #090761
}

.custom-control-input:checked~.custom-control-label::before {
	border-color: #090761
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
	background-color: #090761;
	border-radius: 3px
}

.custom-radio .custom-control-label::before {
	border-radius: 50%
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
	border-radius: 50%;
	background-color: #090761
}

.custom-switch {
	min-height: 1.875rem;
	padding-left: 4.25rem
}

.custom-switch .custom-control-label::before {
	top: -.1375rem;
	height: 1.875rem;
	border-radius: .9375rem;
	left: -4.25rem;
	width: 3.75rem;
	pointer-events: all
}

.custom-switch .custom-control-label::after {
	top: .175rem;
	left: -3.9375rem;
	background-color: #949da6;
	width: 1.25rem;
	height: 1.25rem;
	border-radius: .625rem
}

.custom-switch .custom-control-input:checked~.custom-control-label::before {
	background-color: #196ecd;
	border-color: #e7e7ec
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
	background-color: #fff;
	-webkit-transform: translateX(1.875rem);
	transform: translateX(1.875rem)
}

.custom-switch-dark .custom-control-label::before {
	background-color: rgba(255, 255, 255, .2)
}

.custom-switch-dark .custom-control-label::after {
	background-color: #fff
}

.custom-switch-dark .custom-control-input:checked~.custom-control-label::before {
	background-color: #fff
}

.custom-switch-dark .custom-control-input:checked~.custom-control-label::after {
	background-color: #090761
}

.custom-select-sm {
	background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='22' height='36' viewBox='0 0 24 24'%3e%3cpolyline fill='none' stroke='%232F2D51' stroke-width='2.8' stroke-linecap='round' points='6 9 12 15 18 9'/%3e%3c/svg%3e") no-repeat right .5rem center/auto 50%;
	line-height: 2;
	padding-left: 1.5rem
}

.custom-select-xs {
	height: calc(.5rem + .875rem * 1.6 + 2px);
	padding: .25rem 1.875rem .25rem .625rem;
	background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='22' height='36' viewBox='0 0 24 24'%3e%3cpolyline fill='none' stroke='%232F2D51' stroke-width='2.8' stroke-linecap='round' points='6 9 12 15 18 9'/%3e%3c/svg%3e") no-repeat right .625rem center/auto 50%;
	line-height: 1.6;
	font-size: .875rem
}

.custom-select-white {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='22' height='36' viewBox='0 0 24 24'%3e%3cpolyline fill='none' stroke='%23FFFFFF' stroke-width='2.8' stroke-linecap='round' points='6 9 12 15 18 9'/%3e%3c/svg%3e")
}

.custom-select-white-off {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='22' height='36' viewBox='0 0 24 24'%3e%3cpolyline fill='none' stroke='rgba(255, 255, 255, 0.6)' stroke-width='2.8' stroke-linecap='round' points='6 9 12 15 18 9'/%3e%3c/svg%3e")
}

.dropdown-menu {
	padding-left: 1.75rem;
	padding-right: 1.75rem
}

.dropdown,
.dropleft,
.dropright,
.dropup {
	position: relative
}

.dropdown-menu-end+.dropdown-menu {
	left: auto;
	right: 0
}

.dropdown-menu-start+.dropdown-menu {
	left: 0;
	right: auto
}

.dropdown-item {
	width: auto;
	min-width: 100%;
	margin-left: -1.75rem;
	margin-right: -1.75rem
}

.dropdown-item:focus,
.dropdown-item:hover {
	outline: 0
}

.dropdown-item+.dropdown-item {
	margin-top: .45rem
}

.dropdown-link {
	color: inherit
}

.dropdown-link:hover {
	text-decoration: inherit
}

.dropdown-link:focus,
.dropdown-link:hover {
	outline: 0
}

.dropdown-header {
	margin-left: -1.75rem;
	margin-right: -1.75rem;
	padding-top: 0;
	padding-bottom: .9rem;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.55;
	text-transform: capitalize
}

@media (min-width:1200px) {
	.dropdown-header {
		font-size: 1.125rem
	}
}

.dropdown-toggle::after {
	vertical-align: middle;
	border: none!important;
	content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6'><path fill='%2377838F' d='M9.84016 0.540385L9.5126 0.210545C9.40951 0.107536 9.27235 0.0507876 9.12577 0.0507876C8.97926 0.0507876 8.84194 0.107536 8.73885 0.210545L5.00224 3.94732L1.26131 0.206399C1.15838 0.10339 1.02107 0.0467224 0.87456 0.0467224C0.728055 0.0467224 0.590655 0.10339 0.487646 0.206399L0.160001 0.534206C-0.0533338 0.747379 -0.0533338 1.09462 0.160001 1.30779L4.61402 5.77783C4.71695 5.88075 4.8541 5.95327 5.00191 5.95327H5.00362C5.1502 5.95327 5.28736 5.88067 5.39029 5.77783L9.84016 1.3199C9.94325 1.21698 9.99984 1.07567 10 0.929169C10 0.782582 9.94325 0.643231 9.84016 0.540385Z'/></svg>");
	margin-top: -6px
}

.navbar-dark .dropdown-toggle::after {
	content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6'><path fill='%23ffffff' d='M9.84016 0.540385L9.5126 0.210545C9.40951 0.107536 9.27235 0.0507876 9.12577 0.0507876C8.97926 0.0507876 8.84194 0.107536 8.73885 0.210545L5.00224 3.94732L1.26131 0.206399C1.15838 0.10339 1.02107 0.0467224 0.87456 0.0467224C0.728055 0.0467224 0.590655 0.10339 0.487646 0.206399L0.160001 0.534206C-0.0533338 0.747379 -0.0533338 1.09462 0.160001 1.30779L4.61402 5.77783C4.71695 5.88075 4.8541 5.95327 5.00191 5.95327H5.00362C5.1502 5.95327 5.28736 5.88067 5.39029 5.77783L9.84016 1.3199C9.94325 1.21698 9.99984 1.07567 10 0.929169C10 0.782582 9.94325 0.643231 9.84016 0.540385Z'/></svg>")
}

.dropright>.dropdown-toggle::after {
	content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='5' height='7' viewBox='0 0 6 10'><path fill='%2377838F' d='M0.540415 0.15984L0.210575 0.4874C0.107566 0.59049 0.0508177 0.72765 0.0508177 0.87423C0.0508177 1.02074 0.107566 1.15806 0.210575 1.26115L3.94735 4.99776L0.206429 8.73869C0.10342 8.84162 0.0467529 8.97893 0.0467529 9.12544C0.0467529 9.27194 0.10342 9.40934 0.20643 9.51235L0.534236 9.84C0.747409 10.0533 1.09465 10.0533 1.30782 9.84L5.77786 5.38598C5.88078 5.28305 5.9533 5.1459 5.9533 4.99809L5.9533 4.99638C5.9533 4.8498 5.8807 4.71264 5.77786 4.60971L1.31993 0.15984C1.21701 0.0567496 1.0757 0.000159955 0.929199 -3.8573e-08C0.782612 -3.21654e-08 0.643261 0.0567496 0.540415 0.15984Z'/></svg>");
	margin-top: 0
}

.dropdown-full-width {
	position: static
}

.dropdown-full-width>.dropdown-menu {
	min-width: 100%
}

.dropdown-menu-xs {
	min-width: 0;
	padding: 1rem 1.25rem
}

.dropdown-menu-md {
	min-width: 20rem
}

.dropdown-menu-lg {
	min-width: 39.5rem
}

.dropdown-menu-xl {
	min-width: 46rem
}

.dropdown-menu-lg,
.dropdown-menu-xl {
	padding: 2rem 2rem
}

.dropdown-menu-lg .dropdown-item,
.dropdown-menu-xl .dropdown-header {
	margin-left: -2rem;
	margin-right: -2rem;
	padding-left: 2rem;
	padding-right: 2rem
}

.dropright>.dropdown-menu {
	top: -1.625rem;
	right: auto;
	left: 100%;
	margin-top: 0;
	margin-left: 0
}

.dropleft .dropdown-menu {
	top: 0;
	right: 100%;
	left: auto;
	margin-top: 0;
	margin-right: 0
}

.form-control-xs {
	height: calc(.5rem + .875rem * 1.6 + 2px);
	padding: .25rem .625rem;
	font-size: .875rem
}

.form-control-flush {
	padding-left: 0;
	padding-right: 0;
	border-top-width: 0;
	border-right-width: 0;
	border-left-width: 0;
	border-radius: 0
}

.custom-file-label::after {
	font-weight: 700
}

label {
	color: #2f2d51;
	margin-bottom: .5rem
}

.quantity.form-control[type=number],
.shop_table.cart tbody td.actions .quantity.input-text[type=number],
form.woocommerce-checkout .form-row .quantity.input-text[type=number] {
	text-align: center
}

.quantity.form-control[type=number]::-webkit-inner-spin-button,
.quantity.form-control[type=number]::-webkit-outer-spin-button,
.shop_table.cart tbody td.actions .quantity.input-text[type=number]::-webkit-inner-spin-button,
.shop_table.cart tbody td.actions .quantity.input-text[type=number]::-webkit-outer-spin-button,
form.woocommerce-checkout .form-row .quantity.input-text[type=number]::-webkit-inner-spin-button,
form.woocommerce-checkout .form-row .quantity.input-text[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0
}

.form-label-group {
	position: relative;
	margin-bottom: 1rem
}

.form-label-group>label {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(1.64em + 2rem + 2px);
	padding: 1rem 1.25rem;
	margin-bottom: 0;
	line-height: 1.64;
	font-size: 1rem;
	color: #8c98a4;
	pointer-events: none;
	cursor: text;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}

.form-label-group>.form-control-flush~label {
	padding-left: 0;
	padding-right: 0
}

.form-label-group>.form-control::-webkit-input-placeholder,
.shop_table.cart tbody td.actions .form-label-group>.input-text::-webkit-input-placeholder,
form.woocommerce-checkout .form-row .form-label-group>.input-text::-webkit-input-placeholder {
	color: transparent
}

.form-label-group>.form-control::-moz-placeholder,
.shop_table.cart tbody td.actions .form-label-group>.input-text::-moz-placeholder,
form.woocommerce-checkout .form-row .form-label-group>.input-text::-moz-placeholder {
	color: transparent
}

.form-label-group>.form-control:-ms-input-placeholder,
.shop_table.cart tbody td.actions .form-label-group>.input-text:-ms-input-placeholder,
form.woocommerce-checkout .form-row .form-label-group>.input-text:-ms-input-placeholder {
	color: transparent
}

.form-label-group>.form-control::-ms-input-placeholder,
.shop_table.cart tbody td.actions .form-label-group>.input-text::-ms-input-placeholder,
form.woocommerce-checkout .form-row .form-label-group>.input-text::-ms-input-placeholder {
	color: transparent
}

.form-label-group>.form-control::placeholder,
.shop_table.cart tbody td.actions .form-label-group>.input-text::placeholder,
form.woocommerce-checkout .form-row .form-label-group>.input-text::placeholder {
	color: transparent
}

.form-label-group>.form-control:not(:placeholder-shown),
.shop_table.cart tbody td.actions .form-label-group>.input-text:not(:placeholder-shown),
form.woocommerce-checkout .form-row .form-label-group>.input-text:not(:placeholder-shown) {
	padding-top: 1.5rem;
	padding-bottom: .5rem
}

.form-label-group>.form-control:not(:placeholder-shown)~label,
.shop_table.cart tbody td.actions .form-label-group>.input-text:not(:placeholder-shown)~label,
form.woocommerce-checkout .form-row .form-label-group>.input-text:not(:placeholder-shown)~label {
	padding-top: .135rem;
	padding-bottom: .135rem;
	font-size: .75rem
}

@supports (-ms-ime-align:auto) {
	.form-label-group>label {
		display: none
	}
	.form-label-group .form-control::-webkit-input-placeholder,
	.form-label-group .shop_table.cart tbody td.actions .input-text::-webkit-input-placeholder,
	.form-label-group form.woocommerce-checkout .form-row .input-text::-webkit-input-placeholder,
	.shop_table.cart tbody td.actions .form-label-group .input-text::-webkit-input-placeholder,
	form.woocommerce-checkout .form-row .form-label-group .input-text::-webkit-input-placeholder {
		color: #8c98a4
	}
	.form-label-group .form-control::-moz-placeholder,
	.form-label-group .shop_table.cart tbody td.actions .input-text::-moz-placeholder,
	.form-label-group form.woocommerce-checkout .form-row .input-text::-moz-placeholder,
	.shop_table.cart tbody td.actions .form-label-group .input-text::-moz-placeholder,
	form.woocommerce-checkout .form-row .form-label-group .input-text::-moz-placeholder {
		color: #8c98a4
	}
	.form-label-group .form-control:-ms-input-placeholder,
	.form-label-group .shop_table.cart tbody td.actions .input-text:-ms-input-placeholder,
	.form-label-group form.woocommerce-checkout .form-row .input-text:-ms-input-placeholder,
	.shop_table.cart tbody td.actions .form-label-group .input-text:-ms-input-placeholder,
	form.woocommerce-checkout .form-row .form-label-group .input-text:-ms-input-placeholder {
		color: #8c98a4
	}
	.form-label-group .form-control::-ms-input-placeholder,
	.form-label-group .shop_table.cart tbody td.actions .input-text::-ms-input-placeholder,
	.form-label-group form.woocommerce-checkout .form-row .input-text::-ms-input-placeholder,
	.shop_table.cart tbody td.actions .form-label-group .input-text::-ms-input-placeholder,
	form.woocommerce-checkout .form-row .form-label-group .input-text::-ms-input-placeholder {
		color: #8c98a4
	}
	.form-label-group .form-control::placeholder,
	.form-label-group .shop_table.cart tbody td.actions .input-text::placeholder,
	.form-label-group form.woocommerce-checkout .form-row .input-text::placeholder,
	.shop_table.cart tbody td.actions .form-label-group .input-text::placeholder,
	form.woocommerce-checkout .form-row .form-label-group .input-text::placeholder {
		color: #8c98a4
	}
}

@media all and (-ms-high-contrast:none),
(-ms-high-contrast:active) {
	.form-label-group>label {
		display: none
	}
	.form-label-group .form-control::-webkit-input-placeholder,
	.form-label-group .shop_table.cart tbody td.actions .input-text::-webkit-input-placeholder,
	.form-label-group form.woocommerce-checkout .form-row .input-text::-webkit-input-placeholder,
	.shop_table.cart tbody td.actions .form-label-group .input-text::-webkit-input-placeholder,
	form.woocommerce-checkout .form-row .form-label-group .input-text::-webkit-input-placeholder {
		color: #8c98a4
	}
	.form-label-group .form-control::-moz-placeholder,
	.form-label-group .shop_table.cart tbody td.actions .input-text::-moz-placeholder,
	.form-label-group form.woocommerce-checkout .form-row .input-text::-moz-placeholder,
	.shop_table.cart tbody td.actions .form-label-group .input-text::-moz-placeholder,
	form.woocommerce-checkout .form-row .form-label-group .input-text::-moz-placeholder {
		color: #8c98a4
	}
	.form-label-group .form-control:-ms-input-placeholder,
	.form-label-group .shop_table.cart tbody td.actions .input-text:-ms-input-placeholder,
	.form-label-group form.woocommerce-checkout .form-row .input-text:-ms-input-placeholder,
	.shop_table.cart tbody td.actions .form-label-group .input-text:-ms-input-placeholder,
	form.woocommerce-checkout .form-row .form-label-group .input-text:-ms-input-placeholder {
		color: #8c98a4
	}
	.form-label-group .form-control::-ms-input-placeholder,
	.form-label-group .shop_table.cart tbody td.actions .input-text::-ms-input-placeholder,
	.form-label-group form.woocommerce-checkout .form-row .input-text::-ms-input-placeholder,
	.shop_table.cart tbody td.actions .form-label-group .input-text::-ms-input-placeholder,
	form.woocommerce-checkout .form-row .form-label-group .input-text::-ms-input-placeholder {
		color: #8c98a4
	}
	.form-label-group .form-control::placeholder,
	.form-label-group .shop_table.cart tbody td.actions .input-text::placeholder,
	.form-label-group form.woocommerce-checkout .form-row .input-text::placeholder,
	.shop_table.cart tbody td.actions .form-label-group .input-text::placeholder,
	form.woocommerce-checkout .form-row .form-label-group .input-text::placeholder {
		color: #8c98a4
	}
}

.form-select,
form.woocommerce-checkout #billing_country,
form.woocommerce-checkout #shipping_country {
	box-shadow: none
}

.placeholder-1::-webkit-input-placeholder {
	font-size: .875rem
}

.placeholder-1::-moz-placeholder {
	font-size: .875rem
}

.placeholder-1:-ms-input-placeholder {
	font-size: .875rem
}

.placeholder-1::-ms-input-placeholder {
	font-size: .875rem
}

.placeholder-1::placeholder {
	font-size: .875rem
}

.placeholder-white::-webkit-input-placeholder {
	color: #fff
}

.placeholder-white::-moz-placeholder {
	color: #fff
}

.placeholder-white:-ms-input-placeholder {
	color: #fff
}

.placeholder-white::-ms-input-placeholder {
	color: #fff
}

.placeholder-white::placeholder {
	color: #fff
}

.placeholder-dark::-webkit-input-placeholder {
	color: #2f2d51
}

.placeholder-dark::-moz-placeholder {
	color: #2f2d51
}

.placeholder-dark:-ms-input-placeholder {
	color: #2f2d51
}

.placeholder-dark::-ms-input-placeholder {
	color: #2f2d51
}

.placeholder-dark::placeholder {
	color: #2f2d51
}

.form-control,
.shop_table.cart tbody td.actions .input-text,
form.woocommerce-checkout .form-row .input-text {
	box-shadow: none
}

[class^=container] {
	position: relative;
	z-index: 1
}

@media (min-width:1480px) {
	.container-wd {
		max-width: 1445px
	}
}

.img-cover {
	width: 100vw;
	height: auto;
	margin-left: calc(50% - 50vw)
}

.img-fluid>img,
.img-fluid>svg {
	max-width: inherit;
	height: inherit
}

.figure {
	display: block
}

.figure-img {
	margin-bottom: 1rem
}

.o-f-c {
	-o-object-fit: cover;
	object-fit: cover
}

.bg-darken .footer-brand {
	-webkit-filter: brightness(0) invert(1);
	filter: brightness(0) invert(1)
}

.bg-darken .list-social:not(.text-white-70) i,
.bg-darken .list-social:not(.text-white-70) img {
	-webkit-filter: brightness(0) invert(.6);
	filter: brightness(0) invert(.6)
}

.bg-darken .list-social.text-white-70 a {
	color: rgba(255, 255, 255, .7)
}

.bg-darken .list-social.text-white-70 a:focus,
.bg-darken .list-social.text-white-70 a:hover {
	color: #090761
}

.bg-darken.bg-porsche .list-social.text-white-70 a {
	color: #fff
}

.bg-darken.bg-porsche .list-social.text-white-70 a:focus,
.bg-darken.bg-porsche .list-social.text-white-70 a:hover {
	color: #f0b26b
}

.bg-darken.bg-biscay .list-social.text-white-70 a {
	color: #fff
}

.bg-darken.bg-biscay .list-social.text-white-70 a:focus,
.bg-darken.bg-biscay .list-social.text-white-70 a:hover {
	color: #183f63
}

.bg-darken.bg-alizarin .list-social.text-white-70 a {
	color: #fff
}

.bg-darken.bg-alizarin .list-social.text-white-70 a:focus,
.bg-darken.bg-alizarin .list-social.text-white-70 a:hover {
	color: #090761
}

.bg-darken.bg-dark .list-social.text-white-70 a {
	color: #fff
}

.bg-darken.bg-dark .list-social.text-white-70 a:focus,
.bg-darken.bg-dark .list-social.text-white-70 a:hover {
	color: #2f2d51
}

.dark-70 .list-social img {
	-webkit-filter: brightness(0) invert(.7);
	filter: brightness(0) invert(.7)
}

.dark-70 .list-social-item a:focus img,
.dark-70 .list-social-item a:hover img {
	-webkit-filter: brightness(100) invert(.8);
	filter: brightness(100) invert(.8)
}

.white-img-100 .list-social img {
	-webkit-filter: brightness(0) invert(1);
	filter: brightness(0) invert(1)
}

.white-img-100 .list-social-item a:focus img,
.white-img-100 .list-social-item a:hover img {
	-webkit-filter: brightness(100) invert(.8);
	filter: brightness(100) invert(.8)
}

.sk-thumbnail {
	position: relative;
	overflow: hidden;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding-bottom: calc(.62 * 100%)
}

.sk-thumbnail img {
	height: auto;
	position: absolute;
	top: calc(50% + 1px);
	left: calc(50% + 1px);
	-webkit-transform: scale(1.01) translate(-50%, -50%);
	transform: scale(1.01) translate(-50%, -50%);
	display: block;
	width: 100%;
	max-height: none;
	max-width: none;
	-webkit-transition: -webkit-filter .3s;
	transition: -webkit-filter .3s;
	transition: filter .3s;
	transition: filter .3s, -webkit-filter .3s
}

.img-ratio-one {
	padding-bottom: calc(1 * 100%)
}

.img-ratio-1 {
	padding-bottom: calc(.789 * 100%)
}

.img-ratio-2 {
	padding-bottom: calc(.769 * 100%)
}

.img-ratio-3 {
	padding-bottom: calc(.715 * 100%)
}

.img-ratio-4 {
	padding-bottom: calc(.96 * 100%)
}

.img-ratio-5 {
	padding-bottom: calc(.523 * 100%)
}

.img-ratio-6 {
	padding-bottom: calc(.815 * 100%)
}

.img-ratio-7 {
	padding-bottom: calc(.31 * 100%)
}

.img-ratio-8 {
	padding-bottom: calc(.828 * 100%)
}

.img-ratio-9 {
	padding-bottom: calc(.694 * 100%)
}

.img-ratio-10 {
	padding-bottom: calc(.74 * 100%)
}

.input-group-xs .form-control,
.input-group-xs .shop_table.cart tbody td.actions .input-text,
.input-group-xs form.woocommerce-checkout .form-row .input-text,
.shop_table.cart tbody td.actions .input-group-xs .input-text,
form.woocommerce-checkout .form-row .input-group-xs .input-text {
	height: calc(.5rem + .875rem * 1.6 + 2px);
	padding: .25rem .625rem;
	font-size: .875rem
}

.input-group-xs .input-group-text {
	padding: .25rem .625rem;
	font-size: .875rem
}

.cart-collaterals .input-group .form-control:focus+.input-group-append .checkout-button,
.cart-collaterals .input-group .shop_table.cart tbody td.actions .input-text:focus+.input-group-append .checkout-button,
.cart-collaterals .input-group form.woocommerce-checkout .form-row .input-text:focus+.input-group-append .checkout-button,
.cart-collaterals .shop_table.cart tbody td.actions .input-group .input-text:focus+.input-group-append .checkout-button,
.cart-collaterals form.woocommerce-checkout .form-row .input-group .input-text:focus+.input-group-append .checkout-button,
.input-group .form-control:focus+.input-group-append .btn,
.input-group .form-control:focus+.input-group-append .cart-collaterals .checkout-button,
.input-group .form-control:focus+.input-group-append .shop_table.cart input.button,
.input-group .form-control:focus+.input-group-append form.woocommerce-checkout .place-order .button,
.input-group .shop_table.cart tbody td.actions .input-text:focus+.input-group-append .btn,
.input-group .shop_table.cart tbody td.actions .input-text:focus+.input-group-append .cart-collaterals .checkout-button,
.input-group .shop_table.cart tbody td.actions .input-text:focus+.input-group-append form.woocommerce-checkout .place-order .button,
.input-group .shop_table.cart tbody td.actions .input-text:focus+.input-group-append input.button,
.input-group form.woocommerce-checkout .form-row .input-text:focus+.input-group-append .btn,
.input-group form.woocommerce-checkout .form-row .input-text:focus+.input-group-append .cart-collaterals .checkout-button,
.input-group form.woocommerce-checkout .form-row .input-text:focus+.input-group-append .place-order .button,
.input-group form.woocommerce-checkout .form-row .input-text:focus+.input-group-append .shop_table.cart input.button,
.input-group form.woocommerce-checkout .place-order .form-row .input-text:focus+.input-group-append .button,
.shop_table.cart .input-group .form-control:focus+.input-group-append input.button,
.shop_table.cart .input-group form.woocommerce-checkout .form-row .input-text:focus+.input-group-append input.button,
.shop_table.cart form.woocommerce-checkout .form-row .input-group .input-text:focus+.input-group-append input.button,
.shop_table.cart tbody td.actions .input-group .input-text:focus+.input-group-append .btn,
.shop_table.cart tbody td.actions .input-group .input-text:focus+.input-group-append .cart-collaterals .checkout-button,
.shop_table.cart tbody td.actions .input-group .input-text:focus+.input-group-append form.woocommerce-checkout .place-order .button,
.shop_table.cart tbody td.actions .input-group .input-text:focus+.input-group-append input.button,
form.woocommerce-checkout .form-row .input-group .input-text:focus+.input-group-append .btn,
form.woocommerce-checkout .form-row .input-group .input-text:focus+.input-group-append .cart-collaterals .checkout-button,
form.woocommerce-checkout .form-row .input-group .input-text:focus+.input-group-append .place-order .button,
form.woocommerce-checkout .form-row .input-group .input-text:focus+.input-group-append .shop_table.cart input.button,
form.woocommerce-checkout .place-order .form-row .input-group .input-text:focus+.input-group-append .button,
form.woocommerce-checkout .place-order .input-group .form-control:focus+.input-group-append .button,
form.woocommerce-checkout .place-order .input-group .shop_table.cart tbody td.actions .input-text:focus+.input-group-append .button,
form.woocommerce-checkout .place-order .shop_table.cart tbody td.actions .input-group .input-text:focus+.input-group-append .button {
	border-color: #090761
}

.input-group-filter .btn-outline-white {
	border-color: #e7e7ec
}

.input-group-append,
.input-group-prepend {
	display: -webkit-box;
	display: flex
}

.cart-collaterals .input-group>.input-group-append:last-child>.checkout-button:not(:last-child):not(.dropdown-toggle),
.cart-collaterals .input-group>.input-group-append:not(:last-child)>.checkout-button,
.cart-collaterals .input-group>.input-group-prepend>.checkout-button,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text,
.shop_table.cart .input-group>.input-group-append:last-child>input.button:not(:last-child):not(.dropdown-toggle),
.shop_table.cart .input-group>.input-group-append:not(:last-child)>input.button,
.shop_table.cart .input-group>.input-group-prepend>input.button,
form.woocommerce-checkout .place-order .input-group>.input-group-append:last-child>.button:not(:last-child):not(.dropdown-toggle),
form.woocommerce-checkout .place-order .input-group>.input-group-append:not(:last-child)>.button,
form.woocommerce-checkout .place-order .input-group>.input-group-prepend>.button {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.cart-collaterals .input-group>.input-group-append>.checkout-button,
.cart-collaterals .input-group>.input-group-prepend:first-child>.checkout-button:not(:first-child),
.cart-collaterals .input-group>.input-group-prepend:not(:first-child)>.checkout-button,
.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.shop_table.cart .input-group>.input-group-append>input.button,
.shop_table.cart .input-group>.input-group-prepend:first-child>input.button:not(:first-child),
.shop_table.cart .input-group>.input-group-prepend:not(:first-child)>input.button,
form.woocommerce-checkout .place-order .input-group>.input-group-append>.button,
form.woocommerce-checkout .place-order .input-group>.input-group-prepend:first-child>.button:not(:first-child),
form.woocommerce-checkout .place-order .input-group>.input-group-prepend:not(:first-child)>.button {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}

.list-group-flush .list-group-item {
	padding-left: 0;
	padding-right: 0
}

.list-group-flush .list-group-item:first-child {
	padding-top: 0!important;
	border-top: 0
}

.list-group-flush .list-group-item:last-child {
	padding-bottom: 0!important;
	border-bottom: 0
}

.modal-open .navbar.fixed-top {
	padding-right: inherit
}

.modal-close {
	position: absolute;
	top: 1.5rem;
	right: 1.5rem;
	z-index: 1000
}

.modal-header .close {
	padding: 1rem 1rem;
	margin: -1rem -1rem -1rem auto
}

.modal-sidebar .modal-dialog {
	max-width: 300px;
	margin-top: 0;
	margin-bottom: 0;
	display: -webkit-box;
	display: flex;
	height: 100vh
}

@media (min-width:768px) {
	.modal-sidebar .modal-dialog {
		max-width: 460px
	}
}

.modal-sidebar .modal-header {
	border-width: 0;
	margin-bottom: 2.5rem
}

.modal-sidebar .modal-body,
.modal-sidebar .modal-header {
	padding: 0
}

.modal-sidebar .close {
	opacity: 1
}

.modal-sidebar .modal-content {
	box-shadow: none;
	border-radius: 0;
	border-width: 0;
	padding: 1.75rem 1.5rem
}

@media (min-width:768px) {
	.modal-sidebar .modal-content {
		padding: 2.75rem 2.5rem
	}
}

.modal-sidebar.fade-left:not(.show) .modal-dialog {
	-webkit-transform: translate(50px, 0);
	transform: translate(50px, 0)
}

.modal-sidebar.fade-right:not(.show) .modal-dialog {
	-webkit-transform: translate(-50px, 0);
	transform: translate(-50px, 0)
}

.modal-sidebar.left .modal-dialog {
	margin-left: auto;
	margin-right: 0
}

.modal-sidebar.right .modal-dialog {
	margin-right: auto;
	margin-left: 0
}

.navbar {
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	-webkit-transition-property: background-color, color;
	transition-property: background-color, color;
	z-index: 1030
}

@media (max-width:991.98px) {
	.navbar-dark .navbar-nav .nav-item .nav-link {
		color: #77838f
	}
	.navbar-dark .navbar-nav .nav-item .nav-link:focus,
	.navbar-dark .navbar-nav .nav-item .nav-link:hover {
		color: #090761
	}
	.navbar-dark .navbar-nav .nav-item .nav-link.active,
	.navbar-dark .navbar-nav .nav-item.active .nav-link {
		color: #090761
	}
	.navbar-dark .navbar-collapse .navbar-toggler {
		color: #77838f
	}
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl {
	padding-left: 15px!important;
	padding-right: 15px!important
}

@media (min-width:1200px) {
	.navbar>.container-fluid,
	.navbar>.container-lg,
	.navbar>.container-md,
	.navbar>.container-sm,
	.navbar>.container-xl {
		padding-left: 60px!important;
		padding-right: 60px!important
	}
}

.navbar-brand {
	font-weight: 700
}

.navbar-brand-img {
	max-height: 3.8rem;
	width: auto
}

@media (min-width:1200px) {
	.navbar-brand-img {
		max-height: 4.5rem
	}
}

.navbar-dark .navbar-brand {
	-webkit-filter: brightness(0) invert(1);
	filter: brightness(0) invert(1)
}

@media (max-width:991.98px) {
	.navbar-btn {
		width: 100%;
		padding: 1rem 1.25rem;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		font-size: 1rem
	}
}

.navbar-nav .nav-link {
	font-weight: 500
}

.navbar-nav .nav-link:focus,
.navbar-nav .nav-link:hover {
	outline: 0
}

.navbar-nav:not(.navbar-vertical) .dropdown-item {
	font-size: 1rem;
	line-height: 1.8
}

@media (min-width:1200px) {
	.navbar-nav:not(.navbar-vertical) .dropdown-item .dropdown-menu {
		-webkit-transform: translate(-20px, 10px);
		transform: translate(-20px, 10px)
	}
	.navbar-nav:not(.navbar-vertical) .dropdown-item .dropdown-menu.show {
		top: 0;
		-webkit-transform: translate(-20px, 0);
		transform: translate(-20px, 0)
	}
}

@media (min-width:1200px) {
	.navbar-nav:not(.navbar-vertical) .dropright>.dropdown-toggle::after {
		margin-right: 6px
	}
}

.navbar-dark.fixed-top>.container {
	position: relative
}

.navbar-dark.fixed-top>.container::after {
	content: "";
	position: absolute;
	right: 0;
	bottom: -1.25rem;
	left: 0;
	border-top: 1px solid rgba(255, 255, 255, .2)
}

@media (max-width:991.98px) {
	.navbar-collapse {
		position: fixed;
		top: 1rem;
		left: 1rem;
		height: auto;
		max-height: calc(100% - 2rem)!important;
		width: calc(100% - 2rem);
		background-color: #fff;
		border-radius: .25rem;
		box-shadow: 0 1.5rem 4rem rgba(140, 152, 164, .15);
		overflow-x: hidden;
		overflow-y: scroll
	}
	.navbar-collapse.collapsing,
	.navbar-collapse.show {
		-webkit-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
		transition-property: opacity, transform, -webkit-transform;
		-webkit-transform-origin: top right;
		transform-origin: top right
	}
	.navbar-collapse.show {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1)
	}
	.navbar-collapse.collapsing {
		opacity: 0;
		-webkit-transform: scale(.9);
		transform: scale(.9)
	}
}

.navbar .navbar-toggler {
	margin-left: auto
}

.navbar-collapse .navbar-toggler {
	position: absolute;
	top: 1rem;
	right: 1rem;
	z-index: 1
}

@media (min-width:1200px) {
	.navbar-collapse .navbar-toggler {
		display: none
	}
}

@media (max-width:991.98px) {
	.navbar-nav .nav-item {
		padding: 1.5rem
	}
	.navbar-nav .nav-item>.dropdown-toggle::after {
		opacity: 0
	}
	.navbar-nav .nav-item+.nav-item {
		border-top: 1px solid #e7e7ec
	}
}

.navbar:not(.navbar-dark) .navbar-nav .nav-item:focus>.nav-link,
.navbar:not(.navbar-dark) .navbar-nav .nav-item:hover>.nav-link {
	color: #090761
}

@media (max-width:991.98px) {
	.navbar-nav .nav-link {
		padding-top: 0;
		padding-bottom: 0
	}
}

.navbar-nav .dropdown-menu {
	box-shadow: none
}

@media (max-width:991.98px) {
	.navbar-nav .dropdown-menu {
		min-width: 0;
		padding: 0
	}
}

@media (min-width:1200px) {
	.navbar-nav .dropdown-menu {
		box-shadow: 0 1.5rem 4rem rgba(140, 152, 164, .15)
	}
}

@media (max-width:991.98px) {
	.navbar-nav .dropdown>.dropdown-menu {
		display: block!important
	}
}

@media (max-width:991.98px) {
	.navbar-nav .dropright>.dropdown-menu {
		padding-top: 1rem;
		padding-bottom: 1rem;
		padding-left: .5rem
	}
}

@media (max-width:991.98px) {
	.navbar-nav .dropdown-header,
	.navbar-nav .dropdown-item {
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0
	}
}

@media (max-width:991.98px) {
	.navbar-nav .dropdown>.dropdown-toggle {
		margin-bottom: 1.5rem;
		pointer-events: none
	}
}

.navbar-nav .dropright>.dropdown-toggle {
	display: -webkit-box;
	display: flex
}

.navbar-nav .dropright>.dropdown-toggle::after {
	margin-left: auto
}

@media (max-width:991.98px) {
	.navbar-nav .dropright>.dropdown-toggle[aria-expanded=true]::after {
		-webkit-transform-origin: center center;
		transform-origin: center center;
		-webkit-transform: rotate(90deg);
		transform: rotate(90deg)
	}
}

.navbar-nav .dropdown-img-left {
	height: 100%;
	display: none;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	padding: 1.625rem 1.75rem;
	background: no-repeat center center/cover;
	border-top-left-radius: .25rem;
	border-bottom-left-radius: .25rem
}

@media (min-width:1200px) {
	.navbar-nav .dropdown-img-left {
		display: -webkit-box;
		display: flex
	}
}

.navbar-nav .dropdown-img-left::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(9, 7, 97, .8);
	border-radius: inherit
}

.navbar-nav .dropdown-img-left * {
	position: relative
}

@media (min-width:1200px) {
	.navbar-nav .dropdown-menu-lg .dropdown-img-left,
	.navbar-nav .dropdown-menu-xl .dropdown-img-left {
		padding: 2rem 2rem
	}
}

@media (min-width:1200px) {
	.navbar-nav .dropdown-body {
		padding: 1.625rem 1.75rem
	}
}

@media (min-width:1200px) {
	.navbar-nav .dropdown-menu-lg .dropdown-body,
	.navbar-nav .dropdown-menu-xl .dropdown-body {
		padding: 2rem 2rem
	}
}

.navbar-nav .dropdown-menu .list-group-item {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	padding-top: 1rem;
	padding-bottom: 1rem;
	color: inherit
}

.navbar-nav .dropdown-menu .list-group-item:hover {
	text-decoration: none
}

@media (min-width:1200px) {
	.navbar-nav .dropdown-menu {
		display: none;
		opacity: 0;
		-webkit-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
		transition-property: opacity, transform, -webkit-transform
	}
	.navbar-nav .dropright>.dropdown-menu {
		-webkit-transform: translateY(10px);
		transform: translateY(10px)
	}
	.navbar-nav .dropdown>.dropdown-menu {
		left: 50%;
		-webkit-transform: translate(-50%, 10px);
		transform: translate(-50%, 10px)
	}
	.navbar-nav .dropdown-menu.showing {
		display: block
	}
	.navbar-nav .dropdown-menu.show {
		display: block;
		opacity: 1
	}
	.navbar-nav .dropright>.dropdown-menu.show {
		-webkit-transform: translateY(0);
		transform: translateY(0)
	}
	.navbar-nav .dropdown>.dropdown-menu.show {
		-webkit-transform: translate(-50%, 0);
		transform: translate(-50%, 0)
	}
}

.tab-nav .active {
	color: #fff!important;
	background-color: #2d4f93
}

.tab-nav .active:focus,
.tab-nav .active:hover {
	color: #fff!important
}

.tab-nav-1 .active {
	color: #fff!important;
	background-color: #2f2d51
}

.tab-nav-1 .active:focus,
.tab-nav-1 .active:hover {
	color: #fff!important
}

.navbar-dark .navbar-vertical .dropright>.dropdown-toggle::after {
	content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='5' height='7' viewBox='0 0 6 10'><path fill='%2377838F' d='M0.540415 0.15984L0.210575 0.4874C0.107566 0.59049 0.0508177 0.72765 0.0508177 0.87423C0.0508177 1.02074 0.107566 1.15806 0.210575 1.26115L3.94735 4.99776L0.206429 8.73869C0.10342 8.84162 0.0467529 8.97893 0.0467529 9.12544C0.0467529 9.27194 0.10342 9.40934 0.20643 9.51235L0.534236 9.84C0.747409 10.0533 1.09465 10.0533 1.30782 9.84L5.77786 5.38598C5.88078 5.28305 5.9533 5.1459 5.9533 4.99809L5.9533 4.99638C5.9533 4.8498 5.8807 4.71264 5.77786 4.60971L1.31993 0.15984C1.21701 0.0567496 1.0757 0.000159955 0.929199 -3.8573e-08C0.782612 -3.21654e-08 0.643261 0.0567496 0.540415 0.15984Z'/></svg>")
}

.navbar-vertical .dropright>.dropdown-toggle {
	-webkit-box-align: center;
	align-items: center
}

.navbar-vertical .dropright>.dropdown-toggle::after {
	content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='5' height='7' viewBox='0 0 6 10'><path fill='rgba(255, 255, 255, 0.6)' d='M0.540415 0.15984L0.210575 0.4874C0.107566 0.59049 0.0508177 0.72765 0.0508177 0.87423C0.0508177 1.02074 0.107566 1.15806 0.210575 1.26115L3.94735 4.99776L0.206429 8.73869C0.10342 8.84162 0.0467529 8.97893 0.0467529 9.12544C0.0467529 9.27194 0.10342 9.40934 0.20643 9.51235L0.534236 9.84C0.747409 10.0533 1.09465 10.0533 1.30782 9.84L5.77786 5.38598C5.88078 5.28305 5.9533 5.1459 5.9533 4.99809L5.9533 4.99638C5.9533 4.8498 5.8807 4.71264 5.77786 4.60971L1.31993 0.15984C1.21701 0.0567496 1.0757 0.000159955 0.929199 -3.8573e-08C0.782612 -3.21654e-08 0.643261 0.0567496 0.540415 0.15984Z'/></svg>")
}

.navbar-vertical .dropdown-menu {
	font-size: 1rem
}

.navbar-vertical .dropdown-menu a {
	color: rgba(255, 255, 255, .6);
	font-weight: 500
}

.navbar-vertical .dropdown-menu a svg path {
	fill-opacity: .6
}

.navbar-vertical .dropdown-menu a:focus,
.navbar-vertical .dropdown-menu a:hover {
	color: #fff
}

.navbar-vertical .dropdown-menu a:focus svg path,
.navbar-vertical .dropdown-menu a:hover svg path {
	fill-opacity: 1
}

.navbar-vertical .dropdown-menu-inner {
	padding: 1rem 1.75rem .6rem
}

.navbar-vertical .dropdown>.dropdown-menu,
.navbar-vertical .dropdown>.dropdown-menu.show {
	left: 0
}

.navbar-vertical .dropdown>.dropdown-menu {
	-webkit-transform: translate(0, 10px);
	transform: translate(0, 10px)
}

.navbar-vertical .dropdown>.dropdown-menu.show {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0)
}

.navbar-vertical .dropdown-item {
	border-left: 5px solid transparent;
	padding: 12.5px 1.75rem;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}

.navbar-vertical .dropdown-item+.dropdown-item {
	margin-top: 0
}

.navbar-vertical .dropdown-item:focus,
.navbar-vertical .dropdown-item:hover {
	border-left-color: #9cf6db;
	background-color: #232374
}

.navbar-vertical .dropdown-item:focus .dropdown-link,
.navbar-vertical .dropdown-item:hover .dropdown-link {
	color: #fff
}

.navbar-vertical .dropdown-item:focus .dropdown-link svg path,
.navbar-vertical .dropdown-item:hover .dropdown-link svg path {
	fill-opacity: 1
}

.navbar-vertical .dropdown-item .dropdown-item:focus,
.navbar-vertical .dropdown-item .dropdown-item:hover {
	background-color: transparent
}

.navbar-vertical .bg-dark .dropdown-item:focus,
.navbar-vertical .bg-dark .dropdown-item:hover {
	background-color: #252441
}

.navbar-dark .navbar-vertical .dropdown-menu {
	font-size: 1rem
}

.navbar-dark .navbar-vertical .dropdown-menu a {
	color: rgba(9, 7, 97, .8)
}

.navbar-dark .navbar-vertical .dropdown-menu a:focus,
.navbar-dark .navbar-vertical .dropdown-menu a:hover {
	color: #090761
}

.navbar-dark .navbar-vertical .dropdown-item:focus,
.navbar-dark .navbar-vertical .dropdown-item:hover {
	border-left-color: #090761;
	background-color: #e7e7ec
}

.navbar-dark .navbar-vertical .dropdown-item:focus .dropdown-link,
.navbar-dark .navbar-vertical .dropdown-item:hover .dropdown-link {
	color: #090761
}

.navbar-custom-2 .navbar-nav .nav-link {
	font-family: Nunito, sans-serif!important;
	font-weight: 700
}

.navbar-custom .navbar-nav .nav-link {
	color: #263053;
	font-family: Montserrat, sans-serif!important
}

@media (min-width:1200px) and (max-width:1400px) {
	.navbar-nav .nav-item:first-child.dropdown>.dropdown-menu:not(.dropdown-full) {
		-webkit-transform: translate(-40%, 10px);
		transform: translate(-40%, 10px)
	}
	.navbar-nav .nav-item:first-child.dropdown>.dropdown-menu.show:not(.dropdown-full) {
		-webkit-transform: translate(-40%, 0);
		transform: translate(-40%, 0)
	}
}

.nav-vertical .nav-link:not(.active) {
	color: #77838f
}

.nav-vertical .nav-link {
	padding: .8rem 0
}

.nav-vertical .nav-link.active {
	color: #196ecd;
	text-decoration: underline;
	padding-left: 1rem
}

.pagination-sm .page-link {
	line-height: 1.5;
	font-size: 1rem
}

.pagination-sm .page-item:first-child .page-link {
	border-top-left-radius: .25rem;
	border-bottom-left-radius: .25rem
}

.pagination-sm .page-item:last-child .page-link {
	border-top-right-radius: .25rem;
	border-bottom-right-radius: .25rem
}

.pagination-lg .page-link {
	line-height: 1.5;
	font-size: 1rem
}

.pagination-lg .page-item:first-child .page-link {
	border-top-left-radius: .25rem;
	border-bottom-left-radius: .25rem
}

.pagination-lg .page-item:last-child .page-link {
	border-top-right-radius: .25rem;
	border-bottom-right-radius: .25rem
}

.pagination .page-link {
	height: 44px;
	width: 44px;
	padding: 0;
	text-align: center;
	color: #77838f;
	font-size: .875rem;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	border-radius: 50%!important
}

.pagination i {
	font-size: 12px
}

.pagination .page-item {
	margin: 0 10px
}

.pagination .page-item.active .page-link {
	background-color: #4a8f9f;
	border-color: #4a8f9f
}

.pagination.pagination-sm .page-item {
	margin: 0 6px
}

.pagination.pagination-sm i {
	font-size: 10px
}

.pagination.pagination-sm .page-link {
	height: 34px;
	width: 34px
}

.pagination.pagination-lg .page-item {
	margin: 0 12px
}

.pagination.pagination-lg i {
	font-size: 14px
}

.pagination.pagination-lg .page-link {
	height: 54px;
	width: 54px
}

.popover-header {
	padding-bottom: 0;
	margin-bottom: .9rem;
	font-size: 1rem;
	color: #090761
}

.popover-header:not(:empty)~.popover-body {
	padding-top: 0
}

body,
html {
	overflow-x: hidden
}

body {
	-webkit-font-smoothing: antialiased
}

.table thead>tr>th {
	border-top-width: 0;
	border-bottom-width: 1px
}

.table-align-middle tbody>tr>td,
.table-align-middle thead>tr>th {
	vertical-align: middle
}

.table-dark.table>:not(caption)>*>* {
	box-shadow: none
}

.h1,
h1 {
	line-height: 1.3
}

@media (max-width:767.98px) {
	.h1,
	h1 {
		font-size: 1.625rem
	}
}

.h2,
h2 {
	line-height: 1.35
}

@media (max-width:767.98px) {
	.h2,
	h2 {
		font-size: 1.5rem
	}
}

.cart-collaterals .h2,
.cart-collaterals h2,
.h3,
h3 {
	line-height: 1.4
}

@media (max-width:767.98px) {
	.cart-collaterals .h2,
	.cart-collaterals h2,
	.h3,
	h3 {
		font-size: 1.125rem
	}
}

.h4,
h4 {
	line-height: 1.45
}

@media (max-width:767.98px) {
	.h4,
	h4 {
		font-size: 1rem
	}
}

.h5,
h5 {
	line-height: 1.5
}

@media (max-width:767.98px) {
	.h5,
	h5 {
		font-size: 1rem
	}
}

.h6,
h6 {
	line-height: 1.55
}

.h6.text-uppercase,
.text-uppercase.h6,
h6.text-uppercase {
	letter-spacing: .08em
}

blockquote {
	padding: 34px;
	background-color: #f7f9fb;
	border-radius: .25rem;
	margin-bottom: 3.4rem
}

@media (min-width:768px) {
	blockquote {
		padding: 40px 52px 35px
	}
}

blockquote p {
	font-size: 1.125rem;
	color: #2f2d51;
	font-style: italic
}

blockquote p cite {
	margin-top: 14px
}

blockquote p:only-child {
	margin-bottom: 0
}

blockquote cite {
	color: #77838f;
	font-style: normal;
	display: block;
	font-size: 1rem
}

.display-1 {
	line-height: 1.2
}

@media (max-width:767.98px) {
	.display-1 {
		font-size: 3.125rem
	}
}

.display-2 {
	line-height: 1.2
}

@media (max-width:767.98px) {
	.display-2 {
		font-size: 2.5rem
	}
}

.display-3 {
	line-height: 1.2
}

@media (max-width:767.98px) {
	.display-3 {
		font-size: 2.25rem
	}
}

.display-4 {
	line-height: 1.2
}

@media (max-width:767.98px) {
	.display-4 {
		font-size: 2rem
	}
}

.display-5 {
	line-height: 1.2;
	font-size: 3.437rem
}

@media (max-width:767.98px) {
	.display-5 {
		font-size: 2rem
	}
}

.display-6 {
	line-height: 1.2;
	font-size: 3.125rem
}

@media (max-width:767.98px) {
	.display-6 {
		font-size: 2rem
	}
}

.lead {
	line-height: 1.7
}

@media (max-width:767.98px) {
	.lead {
		font-size: 1rem
	}
}

.blockquote-img {
	max-height: 2.5rem;
	max-width: 100%;
	width: auto
}

.blockquote-footer::before {
	display: none
}

.list-social-icon {
	max-width: 1rem;
	height: auto
}

.hr-sm {
	width: 60px;
	border-top-width: 2px
}

.hr-md {
	width: 100px;
	border-top-width: 2px
}

code {
	line-height: 1.35
}

ins {
	text-decoration: none
}

.line-height-one {
	line-height: 1
}

.line-height-sm {
	line-height: 1.7
}

.line-height-md {
	line-height: 1.9
}

.line-height-lg {
	line-height: 2
}

.bg-cover {
	background: no-repeat center center/cover
}

.bg-cover-left {
	background: no-repeat left center/cover
}

@media (max-width:767.98px) {
	.bg-between {
		background-image: none!important
	}
}

@media (min-width:768px) {
	.bg-between {
		background-repeat: no-repeat;
		background-position: left center, right center;
		background-size: auto 70%
	}
}

.bg-gradient-light {
	background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f9fb), to(#fff));
	background-image: linear-gradient(to bottom, #f7f9fb 0, #fff 100%)
}

.bg-gradient-1 {
	background: -webkit-gradient(linear, left top, right top, color-stop(.26%, #ffa35a), color-stop(100.08%, #ff2574))!important;
	background: linear-gradient(90deg, #ffa35a .26%, #ff2574 100.08%)!important
}

.bg-gradient-2 {
	background: linear-gradient(284.28deg, #311343 26.18%, #2c146c 100%)
}

.bg-gray-100 {
	background-color: #f7f9fb!important
}

.bg-gray-300 {
	background-color: #d9e2ef!important
}

.bg-gray-800 {
	background-color: #77838f!important
}

.bg-gray-900 {
	background-color: #2f2d51!important
}

.bg-black {
	background-color: #161c2d!important
}

.bg-white-10 {
	background-color: rgba(255, 255, 255, .1)
}

.bg-orange-40 {
	background-color: rgba(255, 199, 139, .4)
}

.bg-white-hover-10:focus,
.bg-white-hover-10:hover {
	background-color: rgba(255, 255, 255, .1)
}

.bg-white-all {
	background-color: #fff
}

.bg-white-all:focus,
.bg-white-all:hover {
	background-color: #fff
}

.bg-white-hover:focus,
.bg-white-hover:hover {
	background-color: #fff
}

@media (min-width:576px) {
	.border-sm {
		border: 1px solid #e7e7ec!important;
		color: rose
	}
	.border-top-sm {
		border-top: 1px solid #e7e7ec!important
	}
	.border-right-sm {
		border-right: 1px solid #e7e7ec!important
	}
	.border-bottom-sm {
		border-bottom: 1px solid #e7e7ec!important
	}
	.border-left-sm {
		border-left: 1px solid #e7e7ec!important
	}
	.border-sm-0 {
		border: 0!important
	}
	.border-top-sm-0 {
		border-top: 0!important
	}
	.border-right-sm-0 {
		border-right: 0!important
	}
	.border-bottom-sm-0 {
		border-bottom: 0!important
	}
	.border-left-sm-0 {
		border-left: 0!important
	}
}

@media (min-width:768px) {
	.border-md {
		border: 1px solid #e7e7ec!important;
		color: rose
	}
	.border-top-md {
		border-top: 1px solid #e7e7ec!important
	}
	.border-right-md {
		border-right: 1px solid #e7e7ec!important
	}
	.border-bottom-md {
		border-bottom: 1px solid #e7e7ec!important
	}
	.border-left-md {
		border-left: 1px solid #e7e7ec!important
	}
	.border-md-0 {
		border: 0!important
	}
	.border-top-md-0 {
		border-top: 0!important
	}
	.border-right-md-0 {
		border-right: 0!important
	}
	.border-bottom-md-0 {
		border-bottom: 0!important
	}
	.border-left-md-0 {
		border-left: 0!important
	}
}

@media (min-width:992px) {
	.border-lg {
		border: 1px solid #e7e7ec!important;
		color: rose
	}
	.border-top-lg {
		border-top: 1px solid #e7e7ec!important
	}
	.border-right-lg {
		border-right: 1px solid #e7e7ec!important
	}
	.border-bottom-lg {
		border-bottom: 1px solid #e7e7ec!important
	}
	.border-left-lg {
		border-left: 1px solid #e7e7ec!important
	}
	.border-lg-0 {
		border: 0!important
	}
	.border-top-lg-0 {
		border-top: 0!important
	}
	.border-right-lg-0 {
		border-right: 0!important
	}
	.border-bottom-lg-0 {
		border-bottom: 0!important
	}
	.border-left-lg-0 {
		border-left: 0!important
	}
}

@media (min-width:1200px) {
	.border-xl {
		border: 1px solid #e7e7ec!important;
		color: rose
	}
	.border-top-xl {
		border-top: 1px solid #e7e7ec!important
	}
	.border-right-xl {
		border-right: 1px solid #e7e7ec!important
	}
	.border-bottom-xl {
		border-bottom: 1px solid #e7e7ec!important
	}
	.border-left-xl {
		border-left: 1px solid #e7e7ec!important
	}
	.border-xl-0 {
		border: 0!important
	}
	.border-top-xl-0 {
		border-top: 0!important
	}
	.border-right-xl-0 {
		border-right: 0!important
	}
	.border-bottom-xl-0 {
		border-bottom: 0!important
	}
	.border-left-xl-0 {
		border-left: 0!important
	}
}

.rounded-top-lg {
	border-top-left-radius: .5rem!important;
	border-top-right-radius: .5rem!important
}

.rounded-right-lg {
	border-top-right-radius: .5rem!important;
	border-bottom-right-radius: .5rem!important
}

.rounded-bottom-lg {
	border-bottom-right-radius: .5rem!important;
	border-bottom-left-radius: .5rem!important
}

.rounded-left-lg {
	border-top-left-radius: .5rem!important;
	border-bottom-left-radius: .5rem!important
}

.rounded-xl {
	border-radius: .937rem!important
}

.rounded-top-xl {
	border-top-left-radius: .937rem!important;
	border-top-right-radius: .937rem!important
}

.rounded-right-xl {
	border-top-right-radius: .937rem!important;
	border-bottom-right-radius: .937rem!important
}

.rounded-bottom-xl {
	border-bottom-right-radius: .937rem!important;
	border-bottom-left-radius: .937rem!important
}

.rounded-left-xl {
	border-top-left-radius: .937rem!important;
	border-bottom-left-radius: .937rem!important
}

.rounded-bottom-0 {
	border-bottom-right-radius: 0!important;
	border-bottom-left-radius: 0!important
}

.rounded-lg {
	border-radius: .5rem!important
}

.border-white-10 {
	border-color: rgba(255, 255, 255, .1)!important
}

.border-white-20 {
	border-color: rgba(255, 255, 255, .2)!important
}

.border-white-90 {
	border-color: rgba(255, 255, 255, .9)!important
}

.border-color-20 {
	border-color: rgba(231, 231, 236, .2)!important
}

.border-gray-300 {
	border-color: #d9e2ef!important
}

.border-gray-800 {
	border-color: #77838f!important
}

.border-gray-800-50 {
	border-color: rgba(119, 131, 143, .5)!important
}

.border-gray-900-50 {
	border-color: rgba(47, 45, 81, .5)!important
}

.border-w-md {
	border-width: 2px!important
}

.border-w-lg {
	border-width: 3px!important
}

.border-w-xl {
	border-width: 5px!important
}

.border-style-dashed {
	border-style: dashed!important
}

.border-hover:focus,
.border-hover:hover {
	border: 1px solid #e7e7ec
}

.border-gradient {
	background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, .7) 0, rgba(255, 255, 255, .7) 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to right, rgba(255, 255, 255, .7) 0, rgba(255, 255, 255, .7) 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to bottom, rgba(255, 255, 255, .7) 0, rgba(255, 255, 255, .7) 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to bottom, rgba(255, 255, 255, .7) 0, rgba(255, 255, 255, .7) 50%, transparent 50%, transparent 100%);
	background-position: left top, left bottom, left top, right top;
	background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
	background-size: 20px 2px, 20px 2px, 2px 20px, 2px 20px;
	border-radius: 12px
}

.border-dashed-2 {
	border: 2px dashed #fff
}

.outline-0 {
	outline: 0!important
}

.outline-0:focus,
.outline-0:hover {
	outline: 0!important
}

.img-skewed {
	-webkit-perspective: 1500px;
	perspective: 1500px;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}

.img-skewed-left {
	-webkit-perspective-origin: left center;
	perspective-origin: left center
}

.img-skewed-left .img-skewed-item {
	-webkit-transform: rotateY(-35deg) rotateX(15deg);
	transform: rotateY(-35deg) rotateX(15deg);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden
}

.img-skewed-right {
	-webkit-perspective-origin: right center;
	perspective-origin: right center
}

.img-skewed-right .img-skewed-item {
	-webkit-transform: rotateY(35deg) rotateX(15deg);
	transform: rotateY(35deg) rotateX(15deg);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden
}

.overlay {
	position: relative
}

.overlay::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: inherit
}

.overlay-primary::before {
	background-color: #090761
}

.overlay-gradient-primary-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#090761));
	background-image: linear-gradient(to bottom, transparent, #090761)
}

@media (min-width:768px) {
	.overlay-gradient-primary-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #090761), to(#090761));
		background-image: linear-gradient(to right, transparent, #090761 50%, #090761)
	}
}

.overlay-gradient-primary-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#090761));
	background-image: linear-gradient(to bottom, transparent, #090761)
}

.overlay-gradient-primary-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#090761));
	background-image: linear-gradient(to bottom, transparent, #090761)
}

@media (min-width:768px) {
	.overlay-gradient-primary-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #090761), to(#090761));
		background-image: linear-gradient(to left, transparent, #090761 50%, #090761)
	}
}

.overlay-secondary::before {
	background-color: #949da6
}

.overlay-gradient-secondary-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#949da6));
	background-image: linear-gradient(to bottom, transparent, #949da6)
}

@media (min-width:768px) {
	.overlay-gradient-secondary-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #949da6), to(#949da6));
		background-image: linear-gradient(to right, transparent, #949da6 50%, #949da6)
	}
}

.overlay-gradient-secondary-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#949da6));
	background-image: linear-gradient(to bottom, transparent, #949da6)
}

.overlay-gradient-secondary-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#949da6));
	background-image: linear-gradient(to bottom, transparent, #949da6)
}

@media (min-width:768px) {
	.overlay-gradient-secondary-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #949da6), to(#949da6));
		background-image: linear-gradient(to left, transparent, #949da6 50%, #949da6)
	}
}

.overlay-primary-desat::before {
	background-color: #6c8aec
}

.overlay-gradient-primary-desat-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#6c8aec));
	background-image: linear-gradient(to bottom, transparent, #6c8aec)
}

@media (min-width:768px) {
	.overlay-gradient-primary-desat-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #6c8aec), to(#6c8aec));
		background-image: linear-gradient(to right, transparent, #6c8aec 50%, #6c8aec)
	}
}

.overlay-gradient-primary-desat-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#6c8aec));
	background-image: linear-gradient(to bottom, transparent, #6c8aec)
}

.overlay-gradient-primary-desat-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#6c8aec));
	background-image: linear-gradient(to bottom, transparent, #6c8aec)
}

@media (min-width:768px) {
	.overlay-gradient-primary-desat-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #6c8aec), to(#6c8aec));
		background-image: linear-gradient(to left, transparent, #6c8aec 50%, #6c8aec)
	}
}

.overlay-black::before {
	background-color: #161c2d
}

.overlay-gradient-black-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#161c2d));
	background-image: linear-gradient(to bottom, transparent, #161c2d)
}

@media (min-width:768px) {
	.overlay-gradient-black-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #161c2d), to(#161c2d));
		background-image: linear-gradient(to right, transparent, #161c2d 50%, #161c2d)
	}
}

.overlay-gradient-black-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#161c2d));
	background-image: linear-gradient(to bottom, transparent, #161c2d)
}

.overlay-gradient-black-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#161c2d));
	background-image: linear-gradient(to bottom, transparent, #161c2d)
}

@media (min-width:768px) {
	.overlay-gradient-black-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #161c2d), to(#161c2d));
		background-image: linear-gradient(to left, transparent, #161c2d 50%, #161c2d)
	}
}

.overlay-gray-200::before {
	background-color: #e7e7ec
}

.overlay-gradient-gray-200-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#e7e7ec));
	background-image: linear-gradient(to bottom, transparent, #e7e7ec)
}

@media (min-width:768px) {
	.overlay-gradient-gray-200-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #e7e7ec), to(#e7e7ec));
		background-image: linear-gradient(to right, transparent, #e7e7ec 50%, #e7e7ec)
	}
}

.overlay-gradient-gray-200-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#e7e7ec));
	background-image: linear-gradient(to bottom, transparent, #e7e7ec)
}

.overlay-gradient-gray-200-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#e7e7ec));
	background-image: linear-gradient(to bottom, transparent, #e7e7ec)
}

@media (min-width:768px) {
	.overlay-gradient-gray-200-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #e7e7ec), to(#e7e7ec));
		background-image: linear-gradient(to left, transparent, #e7e7ec 50%, #e7e7ec)
	}
}

.overlay-gray-800::before {
	background-color: #77838f
}

.overlay-gradient-gray-800-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#77838f));
	background-image: linear-gradient(to bottom, transparent, #77838f)
}

@media (min-width:768px) {
	.overlay-gradient-gray-800-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #77838f), to(#77838f));
		background-image: linear-gradient(to right, transparent, #77838f 50%, #77838f)
	}
}

.overlay-gradient-gray-800-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#77838f));
	background-image: linear-gradient(to bottom, transparent, #77838f)
}

.overlay-gradient-gray-800-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#77838f));
	background-image: linear-gradient(to bottom, transparent, #77838f)
}

@media (min-width:768px) {
	.overlay-gradient-gray-800-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #77838f), to(#77838f));
		background-image: linear-gradient(to left, transparent, #77838f 50%, #77838f)
	}
}

.overlay-white::before {
	background-color: #fff
}

.overlay-gradient-white-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#fff));
	background-image: linear-gradient(to bottom, transparent, #fff)
}

@media (min-width:768px) {
	.overlay-gradient-white-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #fff), to(#fff));
		background-image: linear-gradient(to right, transparent, #fff 50%, #fff)
	}
}

.overlay-gradient-white-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#fff));
	background-image: linear-gradient(to bottom, transparent, #fff)
}

.overlay-gradient-white-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#fff));
	background-image: linear-gradient(to bottom, transparent, #fff)
}

@media (min-width:768px) {
	.overlay-gradient-white-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #fff), to(#fff));
		background-image: linear-gradient(to left, transparent, #fff 50%, #fff)
	}
}

.overlay-dark::before {
	background-color: #2f2d51
}

.overlay-gradient-dark-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#2f2d51));
	background-image: linear-gradient(to bottom, transparent, #2f2d51)
}

@media (min-width:768px) {
	.overlay-gradient-dark-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #2f2d51), to(#2f2d51));
		background-image: linear-gradient(to right, transparent, #2f2d51 50%, #2f2d51)
	}
}

.overlay-gradient-dark-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#2f2d51));
	background-image: linear-gradient(to bottom, transparent, #2f2d51)
}

.overlay-gradient-dark-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#2f2d51));
	background-image: linear-gradient(to bottom, transparent, #2f2d51)
}

@media (min-width:768px) {
	.overlay-gradient-dark-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #2f2d51), to(#2f2d51));
		background-image: linear-gradient(to left, transparent, #2f2d51 50%, #2f2d51)
	}
}

.overlay-success::before {
	background-color: #def2d7
}

.overlay-gradient-success-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#def2d7));
	background-image: linear-gradient(to bottom, transparent, #def2d7)
}

@media (min-width:768px) {
	.overlay-gradient-success-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #def2d7), to(#def2d7));
		background-image: linear-gradient(to right, transparent, #def2d7 50%, #def2d7)
	}
}

.overlay-gradient-success-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#def2d7));
	background-image: linear-gradient(to bottom, transparent, #def2d7)
}

.overlay-gradient-success-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#def2d7));
	background-image: linear-gradient(to bottom, transparent, #def2d7)
}

@media (min-width:768px) {
	.overlay-gradient-success-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #def2d7), to(#def2d7));
		background-image: linear-gradient(to left, transparent, #def2d7 50%, #def2d7)
	}
}

.overlay-info::before {
	background-color: #cde9f6
}

.overlay-gradient-info-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#cde9f6));
	background-image: linear-gradient(to bottom, transparent, #cde9f6)
}

@media (min-width:768px) {
	.overlay-gradient-info-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #cde9f6), to(#cde9f6));
		background-image: linear-gradient(to right, transparent, #cde9f6 50%, #cde9f6)
	}
}

.overlay-gradient-info-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#cde9f6));
	background-image: linear-gradient(to bottom, transparent, #cde9f6)
}

.overlay-gradient-info-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#cde9f6));
	background-image: linear-gradient(to bottom, transparent, #cde9f6)
}

@media (min-width:768px) {
	.overlay-gradient-info-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #cde9f6), to(#cde9f6));
		background-image: linear-gradient(to left, transparent, #cde9f6 50%, #cde9f6)
	}
}

.overlay-warning::before {
	background-color: #f7f3d7
}

.overlay-gradient-warning-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#f7f3d7));
	background-image: linear-gradient(to bottom, transparent, #f7f3d7)
}

@media (min-width:768px) {
	.overlay-gradient-warning-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #f7f3d7), to(#f7f3d7));
		background-image: linear-gradient(to right, transparent, #f7f3d7 50%, #f7f3d7)
	}
}

.overlay-gradient-warning-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#f7f3d7));
	background-image: linear-gradient(to bottom, transparent, #f7f3d7)
}

.overlay-gradient-warning-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#f7f3d7));
	background-image: linear-gradient(to bottom, transparent, #f7f3d7)
}

@media (min-width:768px) {
	.overlay-gradient-warning-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #f7f3d7), to(#f7f3d7));
		background-image: linear-gradient(to left, transparent, #f7f3d7 50%, #f7f3d7)
	}
}

.overlay-danger::before {
	background-color: #ecc8c5
}

.overlay-gradient-danger-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#ecc8c5));
	background-image: linear-gradient(to bottom, transparent, #ecc8c5)
}

@media (min-width:768px) {
	.overlay-gradient-danger-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #ecc8c5), to(#ecc8c5));
		background-image: linear-gradient(to right, transparent, #ecc8c5 50%, #ecc8c5)
	}
}

.overlay-gradient-danger-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#ecc8c5));
	background-image: linear-gradient(to bottom, transparent, #ecc8c5)
}

.overlay-gradient-danger-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#ecc8c5));
	background-image: linear-gradient(to bottom, transparent, #ecc8c5)
}

@media (min-width:768px) {
	.overlay-gradient-danger-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #ecc8c5), to(#ecc8c5));
		background-image: linear-gradient(to left, transparent, #ecc8c5 50%, #ecc8c5)
	}
}

.overlay-light::before {
	background-color: #f7f9fb
}

.overlay-gradient-light-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#f7f9fb));
	background-image: linear-gradient(to bottom, transparent, #f7f9fb)
}

@media (min-width:768px) {
	.overlay-gradient-light-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #f7f9fb), to(#f7f9fb));
		background-image: linear-gradient(to right, transparent, #f7f9fb 50%, #f7f9fb)
	}
}

.overlay-gradient-light-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#f7f9fb));
	background-image: linear-gradient(to bottom, transparent, #f7f9fb)
}

.overlay-gradient-light-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#f7f9fb));
	background-image: linear-gradient(to bottom, transparent, #f7f9fb)
}

@media (min-width:768px) {
	.overlay-gradient-light-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #f7f9fb), to(#f7f9fb));
		background-image: linear-gradient(to left, transparent, #f7f9fb 50%, #f7f9fb)
	}
}

.overlay-orange::before {
	background-color: #ffc78b
}

.overlay-gradient-orange-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#ffc78b));
	background-image: linear-gradient(to bottom, transparent, #ffc78b)
}

@media (min-width:768px) {
	.overlay-gradient-orange-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #ffc78b), to(#ffc78b));
		background-image: linear-gradient(to right, transparent, #ffc78b 50%, #ffc78b)
	}
}

.overlay-gradient-orange-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#ffc78b));
	background-image: linear-gradient(to bottom, transparent, #ffc78b)
}

.overlay-gradient-orange-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#ffc78b));
	background-image: linear-gradient(to bottom, transparent, #ffc78b)
}

@media (min-width:768px) {
	.overlay-gradient-orange-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #ffc78b), to(#ffc78b));
		background-image: linear-gradient(to left, transparent, #ffc78b 50%, #ffc78b)
	}
}

.overlay-blue::before {
	background-color: #196ecd
}

.overlay-gradient-blue-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#196ecd));
	background-image: linear-gradient(to bottom, transparent, #196ecd)
}

@media (min-width:768px) {
	.overlay-gradient-blue-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #196ecd), to(#196ecd));
		background-image: linear-gradient(to right, transparent, #196ecd 50%, #196ecd)
	}
}

.overlay-gradient-blue-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#196ecd));
	background-image: linear-gradient(to bottom, transparent, #196ecd)
}

.overlay-gradient-blue-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#196ecd));
	background-image: linear-gradient(to bottom, transparent, #196ecd)
}

@media (min-width:768px) {
	.overlay-gradient-blue-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #196ecd), to(#196ecd));
		background-image: linear-gradient(to left, transparent, #196ecd 50%, #196ecd)
	}
}

.overlay-purple::before {
	background-color: #b8b2fd
}

.overlay-gradient-purple-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#b8b2fd));
	background-image: linear-gradient(to bottom, transparent, #b8b2fd)
}

@media (min-width:768px) {
	.overlay-gradient-purple-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #b8b2fd), to(#b8b2fd));
		background-image: linear-gradient(to right, transparent, #b8b2fd 50%, #b8b2fd)
	}
}

.overlay-gradient-purple-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#b8b2fd));
	background-image: linear-gradient(to bottom, transparent, #b8b2fd)
}

.overlay-gradient-purple-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#b8b2fd));
	background-image: linear-gradient(to bottom, transparent, #b8b2fd)
}

@media (min-width:768px) {
	.overlay-gradient-purple-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #b8b2fd), to(#b8b2fd));
		background-image: linear-gradient(to left, transparent, #b8b2fd 50%, #b8b2fd)
	}
}

.overlay-teal::before {
	background-color: #4a8f9f
}

.overlay-gradient-teal-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#4a8f9f));
	background-image: linear-gradient(to bottom, transparent, #4a8f9f)
}

@media (min-width:768px) {
	.overlay-gradient-teal-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #4a8f9f), to(#4a8f9f));
		background-image: linear-gradient(to right, transparent, #4a8f9f 50%, #4a8f9f)
	}
}

.overlay-gradient-teal-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#4a8f9f));
	background-image: linear-gradient(to bottom, transparent, #4a8f9f)
}

.overlay-gradient-teal-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#4a8f9f));
	background-image: linear-gradient(to bottom, transparent, #4a8f9f)
}

@media (min-width:768px) {
	.overlay-gradient-teal-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #4a8f9f), to(#4a8f9f));
		background-image: linear-gradient(to left, transparent, #4a8f9f 50%, #4a8f9f)
	}
}

.overlay-ice::before {
	background-color: #9cf6db
}

.overlay-gradient-ice-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#9cf6db));
	background-image: linear-gradient(to bottom, transparent, #9cf6db)
}

@media (min-width:768px) {
	.overlay-gradient-ice-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #9cf6db), to(#9cf6db));
		background-image: linear-gradient(to right, transparent, #9cf6db 50%, #9cf6db)
	}
}

.overlay-gradient-ice-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#9cf6db));
	background-image: linear-gradient(to bottom, transparent, #9cf6db)
}

.overlay-gradient-ice-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#9cf6db));
	background-image: linear-gradient(to bottom, transparent, #9cf6db)
}

@media (min-width:768px) {
	.overlay-gradient-ice-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #9cf6db), to(#9cf6db));
		background-image: linear-gradient(to left, transparent, #9cf6db 50%, #9cf6db)
	}
}

.overlay-cyan::before {
	background-color: #9ef3f6
}

.overlay-gradient-cyan-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#9ef3f6));
	background-image: linear-gradient(to bottom, transparent, #9ef3f6)
}

@media (min-width:768px) {
	.overlay-gradient-cyan-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #9ef3f6), to(#9ef3f6));
		background-image: linear-gradient(to right, transparent, #9ef3f6 50%, #9ef3f6)
	}
}

.overlay-gradient-cyan-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#9ef3f6));
	background-image: linear-gradient(to bottom, transparent, #9ef3f6)
}

.overlay-gradient-cyan-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#9ef3f6));
	background-image: linear-gradient(to bottom, transparent, #9ef3f6)
}

@media (min-width:768px) {
	.overlay-gradient-cyan-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #9ef3f6), to(#9ef3f6));
		background-image: linear-gradient(to left, transparent, #9ef3f6 50%, #9ef3f6)
	}
}

.overlay-helio::before {
	background-color: #b253f6
}

.overlay-gradient-helio-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#b253f6));
	background-image: linear-gradient(to bottom, transparent, #b253f6)
}

@media (min-width:768px) {
	.overlay-gradient-helio-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #b253f6), to(#b253f6));
		background-image: linear-gradient(to right, transparent, #b253f6 50%, #b253f6)
	}
}

.overlay-gradient-helio-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#b253f6));
	background-image: linear-gradient(to bottom, transparent, #b253f6)
}

.overlay-gradient-helio-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#b253f6));
	background-image: linear-gradient(to bottom, transparent, #b253f6)
}

@media (min-width:768px) {
	.overlay-gradient-helio-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #b253f6), to(#b253f6));
		background-image: linear-gradient(to left, transparent, #b253f6 50%, #b253f6)
	}
}

.overlay-white-ice::before {
	background-color: #f0fdf8
}

.overlay-gradient-white-ice-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#f0fdf8));
	background-image: linear-gradient(to bottom, transparent, #f0fdf8)
}

@media (min-width:768px) {
	.overlay-gradient-white-ice-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #f0fdf8), to(#f0fdf8));
		background-image: linear-gradient(to right, transparent, #f0fdf8 50%, #f0fdf8)
	}
}

.overlay-gradient-white-ice-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#f0fdf8));
	background-image: linear-gradient(to bottom, transparent, #f0fdf8)
}

.overlay-gradient-white-ice-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#f0fdf8));
	background-image: linear-gradient(to bottom, transparent, #f0fdf8)
}

@media (min-width:768px) {
	.overlay-gradient-white-ice-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #f0fdf8), to(#f0fdf8));
		background-image: linear-gradient(to left, transparent, #f0fdf8 50%, #f0fdf8)
	}
}

.overlay-tropaz::before {
	background-color: #2d4f93
}

.overlay-gradient-tropaz-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#2d4f93));
	background-image: linear-gradient(to bottom, transparent, #2d4f93)
}

@media (min-width:768px) {
	.overlay-gradient-tropaz-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #2d4f93), to(#2d4f93));
		background-image: linear-gradient(to right, transparent, #2d4f93 50%, #2d4f93)
	}
}

.overlay-gradient-tropaz-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#2d4f93));
	background-image: linear-gradient(to bottom, transparent, #2d4f93)
}

.overlay-gradient-tropaz-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#2d4f93));
	background-image: linear-gradient(to bottom, transparent, #2d4f93)
}

@media (min-width:768px) {
	.overlay-gradient-tropaz-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #2d4f93), to(#2d4f93));
		background-image: linear-gradient(to left, transparent, #2d4f93 50%, #2d4f93)
	}
}

.overlay-roman::before {
	background-color: #e0685c
}

.overlay-gradient-roman-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#e0685c));
	background-image: linear-gradient(to bottom, transparent, #e0685c)
}

@media (min-width:768px) {
	.overlay-gradient-roman-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #e0685c), to(#e0685c));
		background-image: linear-gradient(to right, transparent, #e0685c 50%, #e0685c)
	}
}

.overlay-gradient-roman-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#e0685c));
	background-image: linear-gradient(to bottom, transparent, #e0685c)
}

.overlay-gradient-roman-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#e0685c));
	background-image: linear-gradient(to bottom, transparent, #e0685c)
}

@media (min-width:768px) {
	.overlay-gradient-roman-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #e0685c), to(#e0685c));
		background-image: linear-gradient(to left, transparent, #e0685c 50%, #e0685c)
	}
}

.overlay-tangerine::before {
	background-color: #ff9574
}

.overlay-gradient-tangerine-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#ff9574));
	background-image: linear-gradient(to bottom, transparent, #ff9574)
}

@media (min-width:768px) {
	.overlay-gradient-tangerine-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #ff9574), to(#ff9574));
		background-image: linear-gradient(to right, transparent, #ff9574 50%, #ff9574)
	}
}

.overlay-gradient-tangerine-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#ff9574));
	background-image: linear-gradient(to bottom, transparent, #ff9574)
}

.overlay-gradient-tangerine-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#ff9574));
	background-image: linear-gradient(to bottom, transparent, #ff9574)
}

@media (min-width:768px) {
	.overlay-gradient-tangerine-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #ff9574), to(#ff9574));
		background-image: linear-gradient(to left, transparent, #ff9574 50%, #ff9574)
	}
}

.overlay-gigas::before {
	background-color: #4e35a3
}

.overlay-gradient-gigas-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#4e35a3));
	background-image: linear-gradient(to bottom, transparent, #4e35a3)
}

@media (min-width:768px) {
	.overlay-gradient-gigas-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #4e35a3), to(#4e35a3));
		background-image: linear-gradient(to right, transparent, #4e35a3 50%, #4e35a3)
	}
}

.overlay-gradient-gigas-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#4e35a3));
	background-image: linear-gradient(to bottom, transparent, #4e35a3)
}

.overlay-gradient-gigas-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#4e35a3));
	background-image: linear-gradient(to bottom, transparent, #4e35a3)
}

@media (min-width:768px) {
	.overlay-gradient-gigas-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #4e35a3), to(#4e35a3));
		background-image: linear-gradient(to left, transparent, #4e35a3 50%, #4e35a3)
	}
}

.overlay-lavender::before {
	background-color: #ffebf1
}

.overlay-gradient-lavender-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#ffebf1));
	background-image: linear-gradient(to bottom, transparent, #ffebf1)
}

@media (min-width:768px) {
	.overlay-gradient-lavender-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #ffebf1), to(#ffebf1));
		background-image: linear-gradient(to right, transparent, #ffebf1 50%, #ffebf1)
	}
}

.overlay-gradient-lavender-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#ffebf1));
	background-image: linear-gradient(to bottom, transparent, #ffebf1)
}

.overlay-gradient-lavender-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#ffebf1));
	background-image: linear-gradient(to bottom, transparent, #ffebf1)
}

@media (min-width:768px) {
	.overlay-gradient-lavender-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #ffebf1), to(#ffebf1));
		background-image: linear-gradient(to left, transparent, #ffebf1 50%, #ffebf1)
	}
}

.overlay-sienna::before {
	background-color: #e7643e
}

.overlay-gradient-sienna-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#e7643e));
	background-image: linear-gradient(to bottom, transparent, #e7643e)
}

@media (min-width:768px) {
	.overlay-gradient-sienna-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #e7643e), to(#e7643e));
		background-image: linear-gradient(to right, transparent, #e7643e 50%, #e7643e)
	}
}

.overlay-gradient-sienna-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#e7643e));
	background-image: linear-gradient(to bottom, transparent, #e7643e)
}

.overlay-gradient-sienna-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#e7643e));
	background-image: linear-gradient(to bottom, transparent, #e7643e)
}

@media (min-width:768px) {
	.overlay-gradient-sienna-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #e7643e), to(#e7643e));
		background-image: linear-gradient(to left, transparent, #e7643e 50%, #e7643e)
	}
}

.overlay-coral::before {
	background-color: #fe805d
}

.overlay-gradient-coral-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#fe805d));
	background-image: linear-gradient(to bottom, transparent, #fe805d)
}

@media (min-width:768px) {
	.overlay-gradient-coral-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #fe805d), to(#fe805d));
		background-image: linear-gradient(to right, transparent, #fe805d 50%, #fe805d)
	}
}

.overlay-gradient-coral-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#fe805d));
	background-image: linear-gradient(to bottom, transparent, #fe805d)
}

.overlay-gradient-coral-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#fe805d));
	background-image: linear-gradient(to bottom, transparent, #fe805d)
}

@media (min-width:768px) {
	.overlay-gradient-coral-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #fe805d), to(#fe805d));
		background-image: linear-gradient(to left, transparent, #fe805d 50%, #fe805d)
	}
}

.overlay-catskill::before {
	background-color: #f9fbfc
}

.overlay-gradient-catskill-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#f9fbfc));
	background-image: linear-gradient(to bottom, transparent, #f9fbfc)
}

@media (min-width:768px) {
	.overlay-gradient-catskill-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #f9fbfc), to(#f9fbfc));
		background-image: linear-gradient(to right, transparent, #f9fbfc 50%, #f9fbfc)
	}
}

.overlay-gradient-catskill-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#f9fbfc));
	background-image: linear-gradient(to bottom, transparent, #f9fbfc)
}

.overlay-gradient-catskill-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#f9fbfc));
	background-image: linear-gradient(to bottom, transparent, #f9fbfc)
}

@media (min-width:768px) {
	.overlay-gradient-catskill-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #f9fbfc), to(#f9fbfc));
		background-image: linear-gradient(to left, transparent, #f9fbfc 50%, #f9fbfc)
	}
}

.overlay-bossanova::before {
	background-color: #452b4f
}

.overlay-gradient-bossanova-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#452b4f));
	background-image: linear-gradient(to bottom, transparent, #452b4f)
}

@media (min-width:768px) {
	.overlay-gradient-bossanova-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #452b4f), to(#452b4f));
		background-image: linear-gradient(to right, transparent, #452b4f 50%, #452b4f)
	}
}

.overlay-gradient-bossanova-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#452b4f));
	background-image: linear-gradient(to bottom, transparent, #452b4f)
}

.overlay-gradient-bossanova-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#452b4f));
	background-image: linear-gradient(to bottom, transparent, #452b4f)
}

@media (min-width:768px) {
	.overlay-gradient-bossanova-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #452b4f), to(#452b4f));
		background-image: linear-gradient(to left, transparent, #452b4f 50%, #452b4f)
	}
}

.overlay-denim::before {
	background-color: #146ecf
}

.overlay-gradient-denim-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#146ecf));
	background-image: linear-gradient(to bottom, transparent, #146ecf)
}

@media (min-width:768px) {
	.overlay-gradient-denim-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #146ecf), to(#146ecf));
		background-image: linear-gradient(to right, transparent, #146ecf 50%, #146ecf)
	}
}

.overlay-gradient-denim-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#146ecf));
	background-image: linear-gradient(to bottom, transparent, #146ecf)
}

.overlay-gradient-denim-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#146ecf));
	background-image: linear-gradient(to bottom, transparent, #146ecf)
}

@media (min-width:768px) {
	.overlay-gradient-denim-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #146ecf), to(#146ecf));
		background-image: linear-gradient(to left, transparent, #146ecf 50%, #146ecf)
	}
}

.overlay-turbo::before {
	background-color: #feb800
}

.overlay-gradient-turbo-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#feb800));
	background-image: linear-gradient(to bottom, transparent, #feb800)
}

@media (min-width:768px) {
	.overlay-gradient-turbo-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #feb800), to(#feb800));
		background-image: linear-gradient(to right, transparent, #feb800 50%, #feb800)
	}
}

.overlay-gradient-turbo-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#feb800));
	background-image: linear-gradient(to bottom, transparent, #feb800)
}

.overlay-gradient-turbo-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#feb800));
	background-image: linear-gradient(to bottom, transparent, #feb800)
}

@media (min-width:768px) {
	.overlay-gradient-turbo-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #feb800), to(#feb800));
		background-image: linear-gradient(to left, transparent, #feb800 50%, #feb800)
	}
}

.overlay-bright-sun::before {
	background-color: #ffd438
}

.overlay-gradient-bright-sun-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#ffd438));
	background-image: linear-gradient(to bottom, transparent, #ffd438)
}

@media (min-width:768px) {
	.overlay-gradient-bright-sun-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #ffd438), to(#ffd438));
		background-image: linear-gradient(to right, transparent, #ffd438 50%, #ffd438)
	}
}

.overlay-gradient-bright-sun-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#ffd438));
	background-image: linear-gradient(to bottom, transparent, #ffd438)
}

.overlay-gradient-bright-sun-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#ffd438));
	background-image: linear-gradient(to bottom, transparent, #ffd438)
}

@media (min-width:768px) {
	.overlay-gradient-bright-sun-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #ffd438), to(#ffd438));
		background-image: linear-gradient(to left, transparent, #ffd438 50%, #ffd438)
	}
}

.overlay-shark::before {
	background-color: #282a2e
}

.overlay-gradient-shark-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#282a2e));
	background-image: linear-gradient(to bottom, transparent, #282a2e)
}

@media (min-width:768px) {
	.overlay-gradient-shark-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #282a2e), to(#282a2e));
		background-image: linear-gradient(to right, transparent, #282a2e 50%, #282a2e)
	}
}

.overlay-gradient-shark-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#282a2e));
	background-image: linear-gradient(to bottom, transparent, #282a2e)
}

.overlay-gradient-shark-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#282a2e));
	background-image: linear-gradient(to bottom, transparent, #282a2e)
}

@media (min-width:768px) {
	.overlay-gradient-shark-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #282a2e), to(#282a2e));
		background-image: linear-gradient(to left, transparent, #282a2e 50%, #282a2e)
	}
}

.overlay-dodger::before {
	background-color: #377dff
}

.overlay-gradient-dodger-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#377dff));
	background-image: linear-gradient(to bottom, transparent, #377dff)
}

@media (min-width:768px) {
	.overlay-gradient-dodger-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #377dff), to(#377dff));
		background-image: linear-gradient(to right, transparent, #377dff 50%, #377dff)
	}
}

.overlay-gradient-dodger-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#377dff));
	background-image: linear-gradient(to bottom, transparent, #377dff)
}

.overlay-gradient-dodger-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#377dff));
	background-image: linear-gradient(to bottom, transparent, #377dff)
}

@media (min-width:768px) {
	.overlay-gradient-dodger-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #377dff), to(#377dff));
		background-image: linear-gradient(to left, transparent, #377dff 50%, #377dff)
	}
}

.overlay-cloud-burst::before {
	background-color: #21325b
}

.overlay-gradient-cloud-burst-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#21325b));
	background-image: linear-gradient(to bottom, transparent, #21325b)
}

@media (min-width:768px) {
	.overlay-gradient-cloud-burst-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #21325b), to(#21325b));
		background-image: linear-gradient(to right, transparent, #21325b 50%, #21325b)
	}
}

.overlay-gradient-cloud-burst-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#21325b));
	background-image: linear-gradient(to bottom, transparent, #21325b)
}

.overlay-gradient-cloud-burst-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#21325b));
	background-image: linear-gradient(to bottom, transparent, #21325b)
}

@media (min-width:768px) {
	.overlay-gradient-cloud-burst-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #21325b), to(#21325b));
		background-image: linear-gradient(to left, transparent, #21325b 50%, #21325b)
	}
}

.overlay-porsche::before {
	background-color: #f0b26b
}

.overlay-gradient-porsche-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#f0b26b));
	background-image: linear-gradient(to bottom, transparent, #f0b26b)
}

@media (min-width:768px) {
	.overlay-gradient-porsche-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #f0b26b), to(#f0b26b));
		background-image: linear-gradient(to right, transparent, #f0b26b 50%, #f0b26b)
	}
}

.overlay-gradient-porsche-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#f0b26b));
	background-image: linear-gradient(to bottom, transparent, #f0b26b)
}

.overlay-gradient-porsche-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#f0b26b));
	background-image: linear-gradient(to bottom, transparent, #f0b26b)
}

@media (min-width:768px) {
	.overlay-gradient-porsche-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #f0b26b), to(#f0b26b));
		background-image: linear-gradient(to left, transparent, #f0b26b 50%, #f0b26b)
	}
}

.overlay-biscay::before {
	background-color: #183f63
}

.overlay-gradient-biscay-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#183f63));
	background-image: linear-gradient(to bottom, transparent, #183f63)
}

@media (min-width:768px) {
	.overlay-gradient-biscay-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #183f63), to(#183f63));
		background-image: linear-gradient(to right, transparent, #183f63 50%, #183f63)
	}
}

.overlay-gradient-biscay-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#183f63));
	background-image: linear-gradient(to bottom, transparent, #183f63)
}

.overlay-gradient-biscay-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#183f63));
	background-image: linear-gradient(to bottom, transparent, #183f63)
}

@media (min-width:768px) {
	.overlay-gradient-biscay-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #183f63), to(#183f63));
		background-image: linear-gradient(to left, transparent, #183f63 50%, #183f63)
	}
}

.overlay-mongoose::before {
	background-color: #bda588
}

.overlay-gradient-mongoose-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#bda588));
	background-image: linear-gradient(to bottom, transparent, #bda588)
}

@media (min-width:768px) {
	.overlay-gradient-mongoose-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #bda588), to(#bda588));
		background-image: linear-gradient(to right, transparent, #bda588 50%, #bda588)
	}
}

.overlay-gradient-mongoose-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#bda588));
	background-image: linear-gradient(to bottom, transparent, #bda588)
}

.overlay-gradient-mongoose-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#bda588));
	background-image: linear-gradient(to bottom, transparent, #bda588)
}

@media (min-width:768px) {
	.overlay-gradient-mongoose-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #bda588), to(#bda588));
		background-image: linear-gradient(to left, transparent, #bda588 50%, #bda588)
	}
}

.overlay-ebony::before {
	background-color: #252837
}

.overlay-gradient-ebony-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#252837));
	background-image: linear-gradient(to bottom, transparent, #252837)
}

@media (min-width:768px) {
	.overlay-gradient-ebony-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #252837), to(#252837));
		background-image: linear-gradient(to right, transparent, #252837 50%, #252837)
	}
}

.overlay-gradient-ebony-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#252837));
	background-image: linear-gradient(to bottom, transparent, #252837)
}

.overlay-gradient-ebony-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#252837));
	background-image: linear-gradient(to bottom, transparent, #252837)
}

@media (min-width:768px) {
	.overlay-gradient-ebony-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #252837), to(#252837));
		background-image: linear-gradient(to left, transparent, #252837 50%, #252837)
	}
}

.overlay-alizarin::before {
	background-color: #eb1c24
}

.overlay-gradient-alizarin-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#eb1c24));
	background-image: linear-gradient(to bottom, transparent, #eb1c24)
}

@media (min-width:768px) {
	.overlay-gradient-alizarin-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #eb1c24), to(#eb1c24));
		background-image: linear-gradient(to right, transparent, #eb1c24 50%, #eb1c24)
	}
}

.overlay-gradient-alizarin-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#eb1c24));
	background-image: linear-gradient(to bottom, transparent, #eb1c24)
}

.overlay-gradient-alizarin-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#eb1c24));
	background-image: linear-gradient(to bottom, transparent, #eb1c24)
}

@media (min-width:768px) {
	.overlay-gradient-alizarin-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #eb1c24), to(#eb1c24));
		background-image: linear-gradient(to left, transparent, #eb1c24 50%, #eb1c24)
	}
}

.overlay-chestnut::before {
	background-color: #cf574b
}

.overlay-gradient-chestnut-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#cf574b));
	background-image: linear-gradient(to bottom, transparent, #cf574b)
}

@media (min-width:768px) {
	.overlay-gradient-chestnut-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #cf574b), to(#cf574b));
		background-image: linear-gradient(to right, transparent, #cf574b 50%, #cf574b)
	}
}

.overlay-gradient-chestnut-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#cf574b));
	background-image: linear-gradient(to bottom, transparent, #cf574b)
}

.overlay-gradient-chestnut-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#cf574b));
	background-image: linear-gradient(to bottom, transparent, #cf574b)
}

@media (min-width:768px) {
	.overlay-gradient-chestnut-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #cf574b), to(#cf574b));
		background-image: linear-gradient(to left, transparent, #cf574b 50%, #cf574b)
	}
}

.overlay-portgore::before {
	background-color: #212041
}

.overlay-gradient-portgore-right::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#212041));
	background-image: linear-gradient(to bottom, transparent, #212041)
}

@media (min-width:768px) {
	.overlay-gradient-portgore-right::before {
		background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(50%, #212041), to(#212041));
		background-image: linear-gradient(to right, transparent, #212041 50%, #212041)
	}
}

.overlay-gradient-portgore-down::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#212041));
	background-image: linear-gradient(to bottom, transparent, #212041)
}

.overlay-gradient-portgore-left::before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#212041));
	background-image: linear-gradient(to bottom, transparent, #212041)
}

@media (min-width:768px) {
	.overlay-gradient-portgore-left::before {
		background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #212041), to(#212041));
		background-image: linear-gradient(to left, transparent, #212041 50%, #212041)
	}
}

.overlay-10::before {
	opacity: .1
}

.overlay-20::before {
	opacity: .2
}

.overlay-30::before {
	opacity: .3
}

.overlay-40::before {
	opacity: .4
}

.overlay-50::before {
	opacity: .5
}

.overlay-60::before {
	opacity: .6
}

.overlay-70::before {
	opacity: .7
}

.overlay-80::before {
	opacity: .8
}

.overlay-90::before {
	opacity: .9
}

.grayscal-hover {
	-webkit-filter: grayscale(1);
	filter: grayscale(1)
}

.grayscal-hover:focus,
.grayscal-hover:hover {
	-webkit-filter: grayscale(0);
	filter: grayscale(0)
}

@media (min-width:992px) {
	.overlay-lg-none::before {
		display: none
	}
}

.overlay-custom::before {
	background: -webkit-gradient(linear, left bottom, left top, from(rgba(69, 43, 79, .6)), to(rgba(69, 43, 79, .6))), -webkit-gradient(linear, left bottom, left top, color-stop(7.65%, #452b4f), color-stop(78.19%, rgba(255, 255, 255, 0)));
	background: linear-gradient(0deg, rgba(69, 43, 79, .6), rgba(69, 43, 79, .6)), linear-gradient(0deg, #452b4f 7.65%, rgba(255, 255, 255, 0) 78.19%)
}

.overlay-custom-left::before {
	background: -webkit-gradient(linear, left bottom, left top, from(rgba(9, 7, 97, .6)), to(rgba(9, 7, 97, .6))), -webkit-gradient(linear, right top, left top, color-stop(17.76%, #090761), to(rgba(255, 255, 255, 0)));
	background: linear-gradient(0deg, rgba(9, 7, 97, .6), rgba(9, 7, 97, .6)), linear-gradient(270deg, #090761 17.76%, rgba(255, 255, 255, 0) 100%)
}

.overlay-custom-blue::before {
	background: linear-gradient(0deg, rgba(23, 62, 98, .3), rgba(23, 62, 98, .3)), linear-gradient(252.15deg, rgba(23, 62, 98, .3) 14.62%, #173e62 70.59%)
}

.overlay-custom-gray::before {
	background: -webkit-gradient(linear, left bottom, left top, from(rgba(37, 40, 55, .6)), to(rgba(37, 40, 55, .6)));
	background: linear-gradient(0deg, rgba(37, 40, 55, .6), rgba(37, 40, 55, .6))
}

.overlay-custom-collage::before {
	background: linear-gradient(288.36deg, rgba(254, 190, 68, .9) 22.61%, rgba(255, 142, 113, .9) 43.82%, rgba(15, 138, 194, .9) 79.04%)
}

.overlay-custom-dark::before {
	background: -webkit-gradient(linear, left bottom, left top, from(rgba(40, 42, 46, .7)), to(rgba(40, 42, 46, .7)));
	background: linear-gradient(0deg, rgba(40, 42, 46, .7), rgba(40, 42, 46, .7))
}

.lift {
	-webkit-transition: box-shadow .25s ease, -webkit-transform .25s ease;
	transition: box-shadow .25s ease, -webkit-transform .25s ease;
	transition: box-shadow .25s ease, transform .25s ease;
	transition: box-shadow .25s ease, transform .25s ease, -webkit-transform .25s ease
}

.lift:focus,
.lift:hover {
	box-shadow: 0 1rem 2.5rem rgba(140, 152, 164, .1), 0 .5rem 1rem -.75rem rgba(140, 152, 164, .1)!important;
	-webkit-transform: translate3d(0, -3px, 0);
	transform: translate3d(0, -3px, 0)
}

.lift-lg:focus,
.lift-lg:hover {
	box-shadow: 0 2rem 5rem rgba(140, 152, 164, .1), 0 .5rem 1rem -.75rem rgba(140, 152, 164, .05)!important;
	-webkit-transform: translate3d(0, -5px, 0);
	transform: translate3d(0, -5px, 0)
}

.top-0 {
	top: 0!important
}

.right-0 {
	right: 0!important
}

.bottom-0 {
	bottom: 0!important
}

.left-0 {
	left: 0!important
}

.center {
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%)
}

@media (min-width:1200px) {
	.center-xl {
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%)
	}
}

.place-center {
	place-items: center
}

.place-content-center {
	place-content: center
}

.place-flex-center {
	place-items: center;
	place-content: center
}

.center-y {
	-webkit-transform: translate(0, 50%);
	transform: translate(0, 50%)
}

.position-center-y {
	top: 50%;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%)
}

@media (min-width:992px) {
	.center-n-y {
		-webkit-transform: translate(0, -50%);
		transform: translate(0, -50%)
	}
}

@media (min-width:768px) {
	.center-md-y {
		-webkit-transform: translate(0, 50%);
		transform: translate(0, 50%)
	}
}

a .hover-visible {
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all .3s ease;
	transition: all .3s ease
}

a:hover .hover-visible {
	opacity: 1;
	visibility: visible
}

.z-index-0 {
	z-index: 0
}

.z-index-1 {
	z-index: 1
}

.z-index-2 {
	z-index: 2
}

.z-index-md {
	z-index: 9
}

.z-index-lg {
	z-index: 999
}

.z-index-xl {
	z-index: 9999
}

.mb-n-1 {
	margin-bottom: -1px
}

.mt-n-1 {
	margin-top: -1px
}

@media (min-width:576px) {
	.position-sm-static {
		position: static!important
	}
	.position-sm-relative {
		position: relative!important
	}
	.position-sm-absolute {
		position: absolute!important
	}
	.position-sm-fixed {
		position: fixed!important
	}
	.position-sm-sticky {
		position: -webkit-sticky!important;
		position: sticky!important
	}
}

@media (min-width:768px) {
	.position-md-static {
		position: static!important
	}
	.position-md-relative {
		position: relative!important
	}
	.position-md-absolute {
		position: absolute!important
	}
	.position-md-fixed {
		position: fixed!important
	}
	.position-md-sticky {
		position: -webkit-sticky!important;
		position: sticky!important
	}
}

@media (min-width:992px) {
	.position-lg-static {
		position: static!important
	}
	.position-lg-relative {
		position: relative!important
	}
	.position-lg-absolute {
		position: absolute!important
	}
	.position-lg-fixed {
		position: fixed!important
	}
	.position-lg-sticky {
		position: -webkit-sticky!important;
		position: sticky!important
	}
}

@media (min-width:1200px) {
	.position-xl-static {
		position: static!important
	}
	.position-xl-relative {
		position: relative!important
	}
	.position-xl-absolute {
		position: absolute!important
	}
	.position-xl-fixed {
		position: fixed!important
	}
	.position-xl-sticky {
		position: -webkit-sticky!important;
		position: sticky!important
	}
}

@media (min-width:1480px) {
	.position-wd-static {
		position: static!important
	}
	.position-wd-relative {
		position: relative!important
	}
	.position-wd-absolute {
		position: absolute!important
	}
	.position-wd-fixed {
		position: fixed!important
	}
	.position-wd-sticky {
		position: -webkit-sticky!important;
		position: sticky!important
	}
}

.flex-shrink-all * {
	flex-shrink: 0
}

.cs-parallax {
	position: relative
}

.cs-parallax-layer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%
}

.cs-parallax-layer>img {
	display: block;
	width: 100%
}

.header-fixed {
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 2
}

@media (min-width:768px) {
	.header-fixed {
		height: 110px;
		display: -webkit-box;
		display: flex;
		-webkit-box-align: center;
		align-items: center
	}
}

.list-checkbox-limit,
.vertical-scroll {
	overflow-x: hidden;
	overflow-y: auto
}

.shadow-light {
	box-shadow: 0 .5rem 1.5rem rgba(140, 152, 164, .05)!important
}

.shadow-light-lg {
	box-shadow: 0 1.5rem 4rem rgba(140, 152, 164, .05)!important
}

.shadow-dark {
	box-shadow: 0 .5rem 1.5rem rgba(140, 152, 164, .15)!important
}

.shadow-dark-lg {
	box-shadow: 0 1.5rem 4rem rgba(140, 152, 164, .15)!important
}

.shadow-lift {
	box-shadow: 0 1rem 2.5rem rgba(140, 152, 164, .1), 0 .5rem 1rem -.75rem rgba(140, 152, 164, .1)!important
}

.shadow-dark-hover:focus,
.shadow-dark-hover:hover {
	box-shadow: 0 .5rem 1.5rem rgba(140, 152, 164, .15)!important
}

.shadow-custom {
	box-shadow: 0 0 10px rgba(140, 152, 164, .4)!important
}

.mw-14 {
	max-width: 14%!important
}

.vw-14 {
	width: 14vw!important
}

.mw-16 {
	max-width: 16px!important
}

.vw-16 {
	width: 16vw!important
}

.mw-20 {
	max-width: 20%!important
}

.vw-20 {
	width: 20vw!important
}

.mw-25 {
	max-width: 25%!important
}

.vw-25 {
	width: 25vw!important
}

.mw-26p {
	max-width: 26px!important
}

.vw-26p {
	width: 26pvw!important
}

.mw-28 {
	max-width: 28%!important
}

.vw-28 {
	width: 28vw!important
}

.mw-36 {
	max-width: 36px!important
}

.vw-36 {
	width: 36vw!important
}

.mw-40p {
	max-width: 40px!important
}

.vw-40p {
	width: 40pvw!important
}

.mw-48 {
	max-width: 48px!important
}

.vw-48 {
	width: 48vw!important
}

.mw-50 {
	max-width: 50%!important
}

.vw-50 {
	width: 50vw!important
}

.mw-50p {
	max-width: 50px!important
}

.vw-50p {
	width: 50pvw!important
}

.mw-52 {
	max-width: 52px!important
}

.vw-52 {
	width: 52vw!important
}

.mw-58 {
	max-width: 58px!important
}

.vw-58 {
	width: 58vw!important
}

.mw-60p {
	max-width: 60px!important
}

.vw-60p {
	width: 60pvw!important
}

.mw-65 {
	max-width: 65%!important
}

.vw-65 {
	width: 65vw!important
}

.mw-70p {
	max-width: 70px!important
}

.vw-70p {
	width: 70pvw!important
}

.mw-75 {
	max-width: 75%!important
}

.vw-75 {
	width: 75vw!important
}

.mw-80 {
	max-width: 80%!important
}

.vw-80 {
	width: 80vw!important
}

.mw-90p {
	max-width: 90px!important
}

.vw-90p {
	width: 90pvw!important
}

.mw-96 {
	max-width: 96%!important
}

.vw-96 {
	width: 96vw!important
}

.mw-100p {
	max-width: 100px!important
}

.vw-100p {
	width: 100pvw!important
}

.mw-100 {
	max-width: 100%!important
}

.vw-100 {
	width: 100vw!important
}

.mw-110 {
	max-width: 110%!important
}

.vw-110 {
	width: 110vw!important
}

.mw-120 {
	max-width: 120%!important
}

.vw-120 {
	width: 120vw!important
}

.mw-120p {
	max-width: 120px!important
}

.vw-120p {
	width: 120pvw!important
}

.mw-130 {
	max-width: 130%!important
}

.vw-130 {
	width: 130vw!important
}

.mw-130p {
	max-width: 130px!important
}

.vw-130p {
	width: 130pvw!important
}

.mw-140 {
	max-width: 140%!important
}

.vw-140 {
	width: 140vw!important
}

.mw-140p {
	max-width: 140px!important
}

.vw-140p {
	width: 140pvw!important
}

.mw-150 {
	max-width: 150%!important
}

.vw-150 {
	width: 150vw!important
}

.mw-152 {
	max-width: 152px!important
}

.vw-152 {
	width: 152vw!important
}

.mw-174 {
	max-width: 174%!important
}

.vw-174 {
	width: 174vw!important
}

.mw-180p {
	max-width: 180px!important
}

.vw-180p {
	width: 180pvw!important
}

.mw-200p {
	max-width: 200px!important
}

.vw-200p {
	width: 200pvw!important
}

.mw-210p {
	max-width: 210px!important
}

.vw-210p {
	width: 210pvw!important
}

.mw-220p {
	max-width: 220px!important
}

.vw-220p {
	width: 220pvw!important
}

.mw-260p {
	max-width: 260px!important
}

.vw-260p {
	width: 260pvw!important
}

.mw-270p {
	max-width: 270px!important
}

.vw-270p {
	width: 270pvw!important
}

.mw-280p {
	max-width: 280px!important
}

.vw-280p {
	width: 280pvw!important
}

.mw-300p {
	max-width: 300px!important
}

.vw-300p {
	width: 300pvw!important
}

.mw-350p {
	max-width: 350px!important
}

.vw-350p {
	width: 350pvw!important
}

.mw-450p {
	max-width: 450px!important
}

.vw-450p {
	width: 450pvw!important
}

.mw-auto {
	max-width: auto!important
}

.vw-auto {
	width: autovw!important
}

.w-14 {
	width: 14%!important
}

.w-16 {
	width: 16px!important
}

.w-20 {
	width: 20%!important
}

.w-25 {
	width: 25%!important
}

.w-26p {
	width: 26px!important
}

.w-28 {
	width: 28%!important
}

.w-36 {
	width: 36px!important
}

.w-40p {
	width: 40px!important
}

.w-48 {
	width: 48px!important
}

.w-50 {
	width: 50%!important
}

.w-50p {
	width: 50px!important
}

.w-52 {
	width: 52px!important
}

.w-58 {
	width: 58px!important
}

.w-60p {
	width: 60px!important
}

.w-65 {
	width: 65%!important
}

.w-70p {
	width: 70px!important
}

.w-75 {
	width: 75%!important
}

.w-80 {
	width: 80%!important
}

.w-90p {
	width: 90px!important
}

.w-96 {
	width: 96%!important
}

.w-100p {
	width: 100px!important
}

.w-100 {
	width: 100%!important
}

.w-110 {
	width: 110%!important
}

.w-120 {
	width: 120%!important
}

.w-120p {
	width: 120px!important
}

.w-130 {
	width: 130%!important
}

.w-130p {
	width: 130px!important
}

.w-140 {
	width: 140%!important
}

.w-140p {
	width: 140px!important
}

.w-150 {
	width: 150%!important
}

.w-152 {
	width: 152px!important
}

.w-174 {
	width: 174%!important
}

.w-180p {
	width: 180px!important
}

.w-200p {
	width: 200px!important
}

.w-210p {
	width: 210px!important
}

.w-220p {
	width: 220px!important
}

.w-260p {
	width: 260px!important
}

.w-270p {
	width: 270px!important
}

.w-280p {
	width: 280px!important
}

.w-300p {
	width: 300px!important
}

.w-350p {
	width: 350px!important
}

.w-450p {
	width: 450px!important
}

.w-auto {
	width: auto!important
}

.h-14 {
	height: 14%!important
}

.h-16 {
	height: 16px!important
}

.h-20 {
	height: 20%!important
}

.h-25 {
	height: 25%!important
}

.h-26p {
	height: 26px!important
}

.h-28 {
	height: 28%!important
}

.h-36 {
	height: 36px!important
}

.h-40p {
	height: 40px!important
}

.h-48 {
	height: 48px!important
}

.h-50 {
	height: 50%!important
}

.h-50p {
	height: 50px!important
}

.h-52 {
	height: 52px!important
}

.h-58 {
	height: 58px!important
}

.h-60p {
	height: 60px!important
}

.h-65 {
	height: 65%!important
}

.h-70p {
	height: 70px!important
}

.h-75 {
	height: 75%!important
}

.h-80 {
	height: 80%!important
}

.h-90p {
	height: 90px!important
}

.h-96 {
	height: 96%!important
}

.h-100p {
	height: 100px!important
}

.h-100 {
	height: 100%!important
}

.h-110 {
	height: 110%!important
}

.h-120 {
	height: 120%!important
}

.h-120p {
	height: 120px!important
}

.h-130 {
	height: 130%!important
}

.h-130p {
	height: 130px!important
}

.h-140 {
	height: 140%!important
}

.h-140p {
	height: 140px!important
}

.h-150 {
	height: 150%!important
}

.h-152,
.list-checkbox-limit {
	height: 152px!important
}

.h-174 {
	height: 174%!important
}

.h-180p {
	height: 180px!important
}

.h-200p {
	height: 200px!important
}

.h-210p {
	height: 210px!important
}

.h-220p {
	height: 220px!important
}

.h-260p {
	height: 260px!important
}

.h-270p {
	height: 270px!important
}

.h-280p {
	height: 280px!important
}

.h-300p {
	height: 300px!important
}

.h-350p {
	height: 350px!important
}

.h-450p {
	height: 450px!important
}

.h-auto {
	height: auto!important
}

@media (min-width:576px) {
	.h-sm-14 {
		height: 14%!important
	}
	.w-sm-14 {
		width: 14%!important
	}
	.mw-sm-14 {
		max-width: 14%!important
	}
	.vh-sm-14 {
		height: 14vh
	}
	.vw-sm-14 {
		width: 14vw
	}
	.h-sm-16 {
		height: 16px!important
	}
	.w-sm-16 {
		width: 16px!important
	}
	.mw-sm-16 {
		max-width: 16px!important
	}
	.vh-sm-16 {
		height: 16vh
	}
	.vw-sm-16 {
		width: 16vw
	}
	.h-sm-20 {
		height: 20%!important
	}
	.w-sm-20 {
		width: 20%!important
	}
	.mw-sm-20 {
		max-width: 20%!important
	}
	.vh-sm-20 {
		height: 20vh
	}
	.vw-sm-20 {
		width: 20vw
	}
	.h-sm-25 {
		height: 25%!important
	}
	.w-sm-25 {
		width: 25%!important
	}
	.mw-sm-25 {
		max-width: 25%!important
	}
	.vh-sm-25 {
		height: 25vh
	}
	.vw-sm-25 {
		width: 25vw
	}
	.h-sm-26p {
		height: 26px!important
	}
	.w-sm-26p {
		width: 26px!important
	}
	.mw-sm-26p {
		max-width: 26px!important
	}
	.vh-sm-26p {
		height: 26pvh
	}
	.vw-sm-26p {
		width: 26pvw
	}
	.h-sm-28 {
		height: 28%!important
	}
	.w-sm-28 {
		width: 28%!important
	}
	.mw-sm-28 {
		max-width: 28%!important
	}
	.vh-sm-28 {
		height: 28vh
	}
	.vw-sm-28 {
		width: 28vw
	}
	.h-sm-36 {
		height: 36px!important
	}
	.w-sm-36 {
		width: 36px!important
	}
	.mw-sm-36 {
		max-width: 36px!important
	}
	.vh-sm-36 {
		height: 36vh
	}
	.vw-sm-36 {
		width: 36vw
	}
	.h-sm-40p {
		height: 40px!important
	}
	.w-sm-40p {
		width: 40px!important
	}
	.mw-sm-40p {
		max-width: 40px!important
	}
	.vh-sm-40p {
		height: 40pvh
	}
	.vw-sm-40p {
		width: 40pvw
	}
	.h-sm-48 {
		height: 48px!important
	}
	.w-sm-48 {
		width: 48px!important
	}
	.mw-sm-48 {
		max-width: 48px!important
	}
	.vh-sm-48 {
		height: 48vh
	}
	.vw-sm-48 {
		width: 48vw
	}
	.h-sm-50 {
		height: 50%!important
	}
	.w-sm-50 {
		width: 50%!important
	}
	.mw-sm-50 {
		max-width: 50%!important
	}
	.vh-sm-50 {
		height: 50vh
	}
	.vw-sm-50 {
		width: 50vw
	}
	.h-sm-50p {
		height: 50px!important
	}
	.w-sm-50p {
		width: 50px!important
	}
	.mw-sm-50p {
		max-width: 50px!important
	}
	.vh-sm-50p {
		height: 50pvh
	}
	.vw-sm-50p {
		width: 50pvw
	}
	.h-sm-52 {
		height: 52px!important
	}
	.w-sm-52 {
		width: 52px!important
	}
	.mw-sm-52 {
		max-width: 52px!important
	}
	.vh-sm-52 {
		height: 52vh
	}
	.vw-sm-52 {
		width: 52vw
	}
	.h-sm-58 {
		height: 58px!important
	}
	.w-sm-58 {
		width: 58px!important
	}
	.mw-sm-58 {
		max-width: 58px!important
	}
	.vh-sm-58 {
		height: 58vh
	}
	.vw-sm-58 {
		width: 58vw
	}
	.h-sm-60p {
		height: 60px!important
	}
	.w-sm-60p {
		width: 60px!important
	}
	.mw-sm-60p {
		max-width: 60px!important
	}
	.vh-sm-60p {
		height: 60pvh
	}
	.vw-sm-60p {
		width: 60pvw
	}
	.h-sm-65 {
		height: 65%!important
	}
	.w-sm-65 {
		width: 65%!important
	}
	.mw-sm-65 {
		max-width: 65%!important
	}
	.vh-sm-65 {
		height: 65vh
	}
	.vw-sm-65 {
		width: 65vw
	}
	.h-sm-70p {
		height: 70px!important
	}
	.w-sm-70p {
		width: 70px!important
	}
	.mw-sm-70p {
		max-width: 70px!important
	}
	.vh-sm-70p {
		height: 70pvh
	}
	.vw-sm-70p {
		width: 70pvw
	}
	.h-sm-75 {
		height: 75%!important
	}
	.w-sm-75 {
		width: 75%!important
	}
	.mw-sm-75 {
		max-width: 75%!important
	}
	.vh-sm-75 {
		height: 75vh
	}
	.vw-sm-75 {
		width: 75vw
	}
	.h-sm-80 {
		height: 80%!important
	}
	.w-sm-80 {
		width: 80%!important
	}
	.mw-sm-80 {
		max-width: 80%!important
	}
	.vh-sm-80 {
		height: 80vh
	}
	.vw-sm-80 {
		width: 80vw
	}
	.h-sm-90p {
		height: 90px!important
	}
	.w-sm-90p {
		width: 90px!important
	}
	.mw-sm-90p {
		max-width: 90px!important
	}
	.vh-sm-90p {
		height: 90pvh
	}
	.vw-sm-90p {
		width: 90pvw
	}
	.h-sm-96 {
		height: 96%!important
	}
	.w-sm-96 {
		width: 96%!important
	}
	.mw-sm-96 {
		max-width: 96%!important
	}
	.vh-sm-96 {
		height: 96vh
	}
	.vw-sm-96 {
		width: 96vw
	}
	.h-sm-100p {
		height: 100px!important
	}
	.w-sm-100p {
		width: 100px!important
	}
	.mw-sm-100p {
		max-width: 100px!important
	}
	.vh-sm-100p {
		height: 100pvh
	}
	.vw-sm-100p {
		width: 100pvw
	}
	.h-sm-100 {
		height: 100%!important
	}
	.w-sm-100 {
		width: 100%!important
	}
	.mw-sm-100 {
		max-width: 100%!important
	}
	.vh-sm-100 {
		height: 100vh
	}
	.vw-sm-100 {
		width: 100vw
	}
	.h-sm-110 {
		height: 110%!important
	}
	.w-sm-110 {
		width: 110%!important
	}
	.mw-sm-110 {
		max-width: 110%!important
	}
	.vh-sm-110 {
		height: 110vh
	}
	.vw-sm-110 {
		width: 110vw
	}
	.h-sm-120 {
		height: 120%!important
	}
	.w-sm-120 {
		width: 120%!important
	}
	.mw-sm-120 {
		max-width: 120%!important
	}
	.vh-sm-120 {
		height: 120vh
	}
	.vw-sm-120 {
		width: 120vw
	}
	.h-sm-120p {
		height: 120px!important
	}
	.w-sm-120p {
		width: 120px!important
	}
	.mw-sm-120p {
		max-width: 120px!important
	}
	.vh-sm-120p {
		height: 120pvh
	}
	.vw-sm-120p {
		width: 120pvw
	}
	.h-sm-130 {
		height: 130%!important
	}
	.w-sm-130 {
		width: 130%!important
	}
	.mw-sm-130 {
		max-width: 130%!important
	}
	.vh-sm-130 {
		height: 130vh
	}
	.vw-sm-130 {
		width: 130vw
	}
	.h-sm-130p {
		height: 130px!important
	}
	.w-sm-130p {
		width: 130px!important
	}
	.mw-sm-130p {
		max-width: 130px!important
	}
	.vh-sm-130p {
		height: 130pvh
	}
	.vw-sm-130p {
		width: 130pvw
	}
	.h-sm-140 {
		height: 140%!important
	}
	.w-sm-140 {
		width: 140%!important
	}
	.mw-sm-140 {
		max-width: 140%!important
	}
	.vh-sm-140 {
		height: 140vh
	}
	.vw-sm-140 {
		width: 140vw
	}
	.h-sm-140p {
		height: 140px!important
	}
	.w-sm-140p {
		width: 140px!important
	}
	.mw-sm-140p {
		max-width: 140px!important
	}
	.vh-sm-140p {
		height: 140pvh
	}
	.vw-sm-140p {
		width: 140pvw
	}
	.h-sm-150 {
		height: 150%!important
	}
	.w-sm-150 {
		width: 150%!important
	}
	.mw-sm-150 {
		max-width: 150%!important
	}
	.vh-sm-150 {
		height: 150vh
	}
	.vw-sm-150 {
		width: 150vw
	}
	.h-sm-152 {
		height: 152px!important
	}
	.w-sm-152 {
		width: 152px!important
	}
	.mw-sm-152 {
		max-width: 152px!important
	}
	.vh-sm-152 {
		height: 152vh
	}
	.vw-sm-152 {
		width: 152vw
	}
	.h-sm-174 {
		height: 174%!important
	}
	.w-sm-174 {
		width: 174%!important
	}
	.mw-sm-174 {
		max-width: 174%!important
	}
	.vh-sm-174 {
		height: 174vh
	}
	.vw-sm-174 {
		width: 174vw
	}
	.h-sm-180p {
		height: 180px!important
	}
	.w-sm-180p {
		width: 180px!important
	}
	.mw-sm-180p {
		max-width: 180px!important
	}
	.vh-sm-180p {
		height: 180pvh
	}
	.vw-sm-180p {
		width: 180pvw
	}
	.h-sm-200p {
		height: 200px!important
	}
	.w-sm-200p {
		width: 200px!important
	}
	.mw-sm-200p {
		max-width: 200px!important
	}
	.vh-sm-200p {
		height: 200pvh
	}
	.vw-sm-200p {
		width: 200pvw
	}
	.h-sm-210p {
		height: 210px!important
	}
	.w-sm-210p {
		width: 210px!important
	}
	.mw-sm-210p {
		max-width: 210px!important
	}
	.vh-sm-210p {
		height: 210pvh
	}
	.vw-sm-210p {
		width: 210pvw
	}
	.h-sm-220p {
		height: 220px!important
	}
	.w-sm-220p {
		width: 220px!important
	}
	.mw-sm-220p {
		max-width: 220px!important
	}
	.vh-sm-220p {
		height: 220pvh
	}
	.vw-sm-220p {
		width: 220pvw
	}
	.h-sm-260p {
		height: 260px!important
	}
	.w-sm-260p {
		width: 260px!important
	}
	.mw-sm-260p {
		max-width: 260px!important
	}
	.vh-sm-260p {
		height: 260pvh
	}
	.vw-sm-260p {
		width: 260pvw
	}
	.h-sm-270p {
		height: 270px!important
	}
	.w-sm-270p {
		width: 270px!important
	}
	.mw-sm-270p {
		max-width: 270px!important
	}
	.vh-sm-270p {
		height: 270pvh
	}
	.vw-sm-270p {
		width: 270pvw
	}
	.h-sm-280p {
		height: 280px!important
	}
	.w-sm-280p {
		width: 280px!important
	}
	.mw-sm-280p {
		max-width: 280px!important
	}
	.vh-sm-280p {
		height: 280pvh
	}
	.vw-sm-280p {
		width: 280pvw
	}
	.h-sm-300p {
		height: 300px!important
	}
	.w-sm-300p {
		width: 300px!important
	}
	.mw-sm-300p {
		max-width: 300px!important
	}
	.vh-sm-300p {
		height: 300pvh
	}
	.vw-sm-300p {
		width: 300pvw
	}
	.h-sm-350p {
		height: 350px!important
	}
	.w-sm-350p {
		width: 350px!important
	}
	.mw-sm-350p {
		max-width: 350px!important
	}
	.vh-sm-350p {
		height: 350pvh
	}
	.vw-sm-350p {
		width: 350pvw
	}
	.h-sm-450p {
		height: 450px!important
	}
	.w-sm-450p {
		width: 450px!important
	}
	.mw-sm-450p {
		max-width: 450px!important
	}
	.vh-sm-450p {
		height: 450pvh
	}
	.vw-sm-450p {
		width: 450pvw
	}
	.h-sm-auto {
		height: auto!important
	}
	.w-sm-auto {
		width: auto!important
	}
	.mw-sm-auto {
		max-width: auto!important
	}
	.vh-sm-auto {
		height: autovh
	}
	.vw-sm-auto {
		width: autovw
	}
}

@media (min-width:768px) {
	.h-md-14 {
		height: 14%!important
	}
	.w-md-14 {
		width: 14%!important
	}
	.mw-md-14 {
		max-width: 14%!important
	}
	.vh-md-14 {
		height: 14vh
	}
	.vw-md-14 {
		width: 14vw
	}
	.h-md-16 {
		height: 16px!important
	}
	.w-md-16 {
		width: 16px!important
	}
	.mw-md-16 {
		max-width: 16px!important
	}
	.vh-md-16 {
		height: 16vh
	}
	.vw-md-16 {
		width: 16vw
	}
	.h-md-20 {
		height: 20%!important
	}
	.w-md-20 {
		width: 20%!important
	}
	.mw-md-20 {
		max-width: 20%!important
	}
	.vh-md-20 {
		height: 20vh
	}
	.vw-md-20 {
		width: 20vw
	}
	.h-md-25 {
		height: 25%!important
	}
	.w-md-25 {
		width: 25%!important
	}
	.mw-md-25 {
		max-width: 25%!important
	}
	.vh-md-25 {
		height: 25vh
	}
	.vw-md-25 {
		width: 25vw
	}
	.h-md-26p {
		height: 26px!important
	}
	.w-md-26p {
		width: 26px!important
	}
	.mw-md-26p {
		max-width: 26px!important
	}
	.vh-md-26p {
		height: 26pvh
	}
	.vw-md-26p {
		width: 26pvw
	}
	.h-md-28 {
		height: 28%!important
	}
	.w-md-28 {
		width: 28%!important
	}
	.mw-md-28 {
		max-width: 28%!important
	}
	.vh-md-28 {
		height: 28vh
	}
	.vw-md-28 {
		width: 28vw
	}
	.h-md-36 {
		height: 36px!important
	}
	.w-md-36 {
		width: 36px!important
	}
	.mw-md-36 {
		max-width: 36px!important
	}
	.vh-md-36 {
		height: 36vh
	}
	.vw-md-36 {
		width: 36vw
	}
	.h-md-40p {
		height: 40px!important
	}
	.w-md-40p {
		width: 40px!important
	}
	.mw-md-40p {
		max-width: 40px!important
	}
	.vh-md-40p {
		height: 40pvh
	}
	.vw-md-40p {
		width: 40pvw
	}
	.h-md-48 {
		height: 48px!important
	}
	.w-md-48 {
		width: 48px!important
	}
	.mw-md-48 {
		max-width: 48px!important
	}
	.vh-md-48 {
		height: 48vh
	}
	.vw-md-48 {
		width: 48vw
	}
	.h-md-50 {
		height: 50%!important
	}
	.w-md-50 {
		width: 50%!important
	}
	.mw-md-50 {
		max-width: 50%!important
	}
	.vh-md-50 {
		height: 50vh
	}
	.vw-md-50 {
		width: 50vw
	}
	.h-md-50p {
		height: 50px!important
	}
	.w-md-50p {
		width: 50px!important
	}
	.mw-md-50p {
		max-width: 50px!important
	}
	.vh-md-50p {
		height: 50pvh
	}
	.vw-md-50p {
		width: 50pvw
	}
	.h-md-52 {
		height: 52px!important
	}
	.w-md-52 {
		width: 52px!important
	}
	.mw-md-52 {
		max-width: 52px!important
	}
	.vh-md-52 {
		height: 52vh
	}
	.vw-md-52 {
		width: 52vw
	}
	.h-md-58 {
		height: 58px!important
	}
	.w-md-58 {
		width: 58px!important
	}
	.mw-md-58 {
		max-width: 58px!important
	}
	.vh-md-58 {
		height: 58vh
	}
	.vw-md-58 {
		width: 58vw
	}
	.h-md-60p {
		height: 60px!important
	}
	.w-md-60p {
		width: 60px!important
	}
	.mw-md-60p {
		max-width: 60px!important
	}
	.vh-md-60p {
		height: 60pvh
	}
	.vw-md-60p {
		width: 60pvw
	}
	.h-md-65 {
		height: 65%!important
	}
	.w-md-65 {
		width: 65%!important
	}
	.mw-md-65 {
		max-width: 65%!important
	}
	.vh-md-65 {
		height: 65vh
	}
	.vw-md-65 {
		width: 65vw
	}
	.h-md-70p {
		height: 70px!important
	}
	.w-md-70p {
		width: 70px!important
	}
	.mw-md-70p {
		max-width: 70px!important
	}
	.vh-md-70p {
		height: 70pvh
	}
	.vw-md-70p {
		width: 70pvw
	}
	.h-md-75 {
		height: 75%!important
	}
	.w-md-75 {
		width: 75%!important
	}
	.mw-md-75 {
		max-width: 75%!important
	}
	.vh-md-75 {
		height: 75vh
	}
	.vw-md-75 {
		width: 75vw
	}
	.h-md-80 {
		height: 80%!important
	}
	.w-md-80 {
		width: 80%!important
	}
	.mw-md-80 {
		max-width: 80%!important
	}
	.vh-md-80 {
		height: 80vh
	}
	.vw-md-80 {
		width: 80vw
	}
	.h-md-90p {
		height: 90px!important
	}
	.w-md-90p {
		width: 90px!important
	}
	.mw-md-90p {
		max-width: 90px!important
	}
	.vh-md-90p {
		height: 90pvh
	}
	.vw-md-90p {
		width: 90pvw
	}
	.h-md-96 {
		height: 96%!important
	}
	.w-md-96 {
		width: 96%!important
	}
	.mw-md-96 {
		max-width: 96%!important
	}
	.vh-md-96 {
		height: 96vh
	}
	.vw-md-96 {
		width: 96vw
	}
	.h-md-100p {
		height: 100px!important
	}
	.w-md-100p {
		width: 100px!important
	}
	.mw-md-100p {
		max-width: 100px!important
	}
	.vh-md-100p {
		height: 100pvh
	}
	.vw-md-100p {
		width: 100pvw
	}
	.h-md-100 {
		height: 100%!important
	}
	.w-md-100 {
		width: 100%!important
	}
	.mw-md-100 {
		max-width: 100%!important
	}
	.vh-md-100 {
		height: 100vh
	}
	.vw-md-100 {
		width: 100vw
	}
	.h-md-110 {
		height: 110%!important
	}
	.w-md-110 {
		width: 110%!important
	}
	.mw-md-110 {
		max-width: 110%!important
	}
	.vh-md-110 {
		height: 110vh
	}
	.vw-md-110 {
		width: 110vw
	}
	.h-md-120 {
		height: 120%!important
	}
	.w-md-120 {
		width: 120%!important
	}
	.mw-md-120 {
		max-width: 120%!important
	}
	.vh-md-120 {
		height: 120vh
	}
	.vw-md-120 {
		width: 120vw
	}
	.h-md-120p {
		height: 120px!important
	}
	.w-md-120p {
		width: 120px!important
	}
	.mw-md-120p {
		max-width: 120px!important
	}
	.vh-md-120p {
		height: 120pvh
	}
	.vw-md-120p {
		width: 120pvw
	}
	.h-md-130 {
		height: 130%!important
	}
	.w-md-130 {
		width: 130%!important
	}
	.mw-md-130 {
		max-width: 130%!important
	}
	.vh-md-130 {
		height: 130vh
	}
	.vw-md-130 {
		width: 130vw
	}
	.h-md-130p {
		height: 130px!important
	}
	.w-md-130p {
		width: 130px!important
	}
	.mw-md-130p {
		max-width: 130px!important
	}
	.vh-md-130p {
		height: 130pvh
	}
	.vw-md-130p {
		width: 130pvw
	}
	.h-md-140 {
		height: 140%!important
	}
	.w-md-140 {
		width: 140%!important
	}
	.mw-md-140 {
		max-width: 140%!important
	}
	.vh-md-140 {
		height: 140vh
	}
	.vw-md-140 {
		width: 140vw
	}
	.h-md-140p {
		height: 140px!important
	}
	.w-md-140p {
		width: 140px!important
	}
	.mw-md-140p {
		max-width: 140px!important
	}
	.vh-md-140p {
		height: 140pvh
	}
	.vw-md-140p {
		width: 140pvw
	}
	.h-md-150 {
		height: 150%!important
	}
	.w-md-150 {
		width: 150%!important
	}
	.mw-md-150 {
		max-width: 150%!important
	}
	.vh-md-150 {
		height: 150vh
	}
	.vw-md-150 {
		width: 150vw
	}
	.h-md-152 {
		height: 152px!important
	}
	.w-md-152 {
		width: 152px!important
	}
	.mw-md-152 {
		max-width: 152px!important
	}
	.vh-md-152 {
		height: 152vh
	}
	.vw-md-152 {
		width: 152vw
	}
	.h-md-174 {
		height: 174%!important
	}
	.w-md-174 {
		width: 174%!important
	}
	.mw-md-174 {
		max-width: 174%!important
	}
	.vh-md-174 {
		height: 174vh
	}
	.vw-md-174 {
		width: 174vw
	}
	.h-md-180p {
		height: 180px!important
	}
	.w-md-180p {
		width: 180px!important
	}
	.mw-md-180p {
		max-width: 180px!important
	}
	.vh-md-180p {
		height: 180pvh
	}
	.vw-md-180p {
		width: 180pvw
	}
	.h-md-200p {
		height: 200px!important
	}
	.w-md-200p {
		width: 200px!important
	}
	.mw-md-200p {
		max-width: 200px!important
	}
	.vh-md-200p {
		height: 200pvh
	}
	.vw-md-200p {
		width: 200pvw
	}
	.h-md-210p {
		height: 210px!important
	}
	.w-md-210p {
		width: 210px!important
	}
	.mw-md-210p {
		max-width: 210px!important
	}
	.vh-md-210p {
		height: 210pvh
	}
	.vw-md-210p {
		width: 210pvw
	}
	.h-md-220p {
		height: 220px!important
	}
	.w-md-220p {
		width: 220px!important
	}
	.mw-md-220p {
		max-width: 220px!important
	}
	.vh-md-220p {
		height: 220pvh
	}
	.vw-md-220p {
		width: 220pvw
	}
	.h-md-260p {
		height: 260px!important
	}
	.w-md-260p {
		width: 260px!important
	}
	.mw-md-260p {
		max-width: 260px!important
	}
	.vh-md-260p {
		height: 260pvh
	}
	.vw-md-260p {
		width: 260pvw
	}
	.h-md-270p {
		height: 270px!important
	}
	.w-md-270p {
		width: 270px!important
	}
	.mw-md-270p {
		max-width: 270px!important
	}
	.vh-md-270p {
		height: 270pvh
	}
	.vw-md-270p {
		width: 270pvw
	}
	.h-md-280p {
		height: 280px!important
	}
	.w-md-280p {
		width: 280px!important
	}
	.mw-md-280p {
		max-width: 280px!important
	}
	.vh-md-280p {
		height: 280pvh
	}
	.vw-md-280p {
		width: 280pvw
	}
	.h-md-300p {
		height: 300px!important
	}
	.w-md-300p {
		width: 300px!important
	}
	.mw-md-300p {
		max-width: 300px!important
	}
	.vh-md-300p {
		height: 300pvh
	}
	.vw-md-300p {
		width: 300pvw
	}
	.h-md-350p {
		height: 350px!important
	}
	.w-md-350p {
		width: 350px!important
	}
	.mw-md-350p {
		max-width: 350px!important
	}
	.vh-md-350p {
		height: 350pvh
	}
	.vw-md-350p {
		width: 350pvw
	}
	.h-md-450p {
		height: 450px!important
	}
	.w-md-450p {
		width: 450px!important
	}
	.mw-md-450p {
		max-width: 450px!important
	}
	.vh-md-450p {
		height: 450pvh
	}
	.vw-md-450p {
		width: 450pvw
	}
	.h-md-auto {
		height: auto!important
	}
	.w-md-auto {
		width: auto!important
	}
	.mw-md-auto {
		max-width: auto!important
	}
	.vh-md-auto {
		height: autovh
	}
	.vw-md-auto {
		width: autovw
	}
}

@media (min-width:992px) {
	.h-lg-14 {
		height: 14%!important
	}
	.w-lg-14 {
		width: 14%!important
	}
	.mw-lg-14 {
		max-width: 14%!important
	}
	.vh-lg-14 {
		height: 14vh
	}
	.vw-lg-14 {
		width: 14vw
	}
	.h-lg-16 {
		height: 16px!important
	}
	.w-lg-16 {
		width: 16px!important
	}
	.mw-lg-16 {
		max-width: 16px!important
	}
	.vh-lg-16 {
		height: 16vh
	}
	.vw-lg-16 {
		width: 16vw
	}
	.h-lg-20 {
		height: 20%!important
	}
	.w-lg-20 {
		width: 20%!important
	}
	.mw-lg-20 {
		max-width: 20%!important
	}
	.vh-lg-20 {
		height: 20vh
	}
	.vw-lg-20 {
		width: 20vw
	}
	.h-lg-25 {
		height: 25%!important
	}
	.w-lg-25 {
		width: 25%!important
	}
	.mw-lg-25 {
		max-width: 25%!important
	}
	.vh-lg-25 {
		height: 25vh
	}
	.vw-lg-25 {
		width: 25vw
	}
	.h-lg-26p {
		height: 26px!important
	}
	.w-lg-26p {
		width: 26px!important
	}
	.mw-lg-26p {
		max-width: 26px!important
	}
	.vh-lg-26p {
		height: 26pvh
	}
	.vw-lg-26p {
		width: 26pvw
	}
	.h-lg-28 {
		height: 28%!important
	}
	.w-lg-28 {
		width: 28%!important
	}
	.mw-lg-28 {
		max-width: 28%!important
	}
	.vh-lg-28 {
		height: 28vh
	}
	.vw-lg-28 {
		width: 28vw
	}
	.h-lg-36 {
		height: 36px!important
	}
	.w-lg-36 {
		width: 36px!important
	}
	.mw-lg-36 {
		max-width: 36px!important
	}
	.vh-lg-36 {
		height: 36vh
	}
	.vw-lg-36 {
		width: 36vw
	}
	.h-lg-40p {
		height: 40px!important
	}
	.w-lg-40p {
		width: 40px!important
	}
	.mw-lg-40p {
		max-width: 40px!important
	}
	.vh-lg-40p {
		height: 40pvh
	}
	.vw-lg-40p {
		width: 40pvw
	}
	.h-lg-48 {
		height: 48px!important
	}
	.w-lg-48 {
		width: 48px!important
	}
	.mw-lg-48 {
		max-width: 48px!important
	}
	.vh-lg-48 {
		height: 48vh
	}
	.vw-lg-48 {
		width: 48vw
	}
	.h-lg-50 {
		height: 50%!important
	}
	.w-lg-50 {
		width: 50%!important
	}
	.mw-lg-50 {
		max-width: 50%!important
	}
	.vh-lg-50 {
		height: 50vh
	}
	.vw-lg-50 {
		width: 50vw
	}
	.h-lg-50p {
		height: 50px!important
	}
	.w-lg-50p {
		width: 50px!important
	}
	.mw-lg-50p {
		max-width: 50px!important
	}
	.vh-lg-50p {
		height: 50pvh
	}
	.vw-lg-50p {
		width: 50pvw
	}
	.h-lg-52 {
		height: 52px!important
	}
	.w-lg-52 {
		width: 52px!important
	}
	.mw-lg-52 {
		max-width: 52px!important
	}
	.vh-lg-52 {
		height: 52vh
	}
	.vw-lg-52 {
		width: 52vw
	}
	.h-lg-58 {
		height: 58px!important
	}
	.w-lg-58 {
		width: 58px!important
	}
	.mw-lg-58 {
		max-width: 58px!important
	}
	.vh-lg-58 {
		height: 58vh
	}
	.vw-lg-58 {
		width: 58vw
	}
	.h-lg-60p {
		height: 60px!important
	}
	.w-lg-60p {
		width: 60px!important
	}
	.mw-lg-60p {
		max-width: 60px!important
	}
	.vh-lg-60p {
		height: 60pvh
	}
	.vw-lg-60p {
		width: 60pvw
	}
	.h-lg-65 {
		height: 65%!important
	}
	.w-lg-65 {
		width: 65%!important
	}
	.mw-lg-65 {
		max-width: 65%!important
	}
	.vh-lg-65 {
		height: 65vh
	}
	.vw-lg-65 {
		width: 65vw
	}
	.h-lg-70p {
		height: 70px!important
	}
	.w-lg-70p {
		width: 70px!important
	}
	.mw-lg-70p {
		max-width: 70px!important
	}
	.vh-lg-70p {
		height: 70pvh
	}
	.vw-lg-70p {
		width: 70pvw
	}
	.h-lg-75 {
		height: 75%!important
	}
	.w-lg-75 {
		width: 75%!important
	}
	.mw-lg-75 {
		max-width: 75%!important
	}
	.vh-lg-75 {
		height: 75vh
	}
	.vw-lg-75 {
		width: 75vw
	}
	.h-lg-80 {
		height: 80%!important
	}
	.w-lg-80 {
		width: 80%!important
	}
	.mw-lg-80 {
		max-width: 80%!important
	}
	.vh-lg-80 {
		height: 80vh
	}
	.vw-lg-80 {
		width: 80vw
	}
	.h-lg-90p {
		height: 90px!important
	}
	.w-lg-90p {
		width: 90px!important
	}
	.mw-lg-90p {
		max-width: 90px!important
	}
	.vh-lg-90p {
		height: 90pvh
	}
	.vw-lg-90p {
		width: 90pvw
	}
	.h-lg-96 {
		height: 96%!important
	}
	.w-lg-96 {
		width: 96%!important
	}
	.mw-lg-96 {
		max-width: 96%!important
	}
	.vh-lg-96 {
		height: 96vh
	}
	.vw-lg-96 {
		width: 96vw
	}
	.h-lg-100p {
		height: 100px!important
	}
	.w-lg-100p {
		width: 100px!important
	}
	.mw-lg-100p {
		max-width: 100px!important
	}
	.vh-lg-100p {
		height: 100pvh
	}
	.vw-lg-100p {
		width: 100pvw
	}
	.h-lg-100 {
		height: 100%!important
	}
	.w-lg-100 {
		width: 100%!important
	}
	.mw-lg-100 {
		max-width: 100%!important
	}
	.vh-lg-100 {
		height: 100vh
	}
	.vw-lg-100 {
		width: 100vw
	}
	.h-lg-110 {
		height: 110%!important
	}
	.w-lg-110 {
		width: 110%!important
	}
	.mw-lg-110 {
		max-width: 110%!important
	}
	.vh-lg-110 {
		height: 110vh
	}
	.vw-lg-110 {
		width: 110vw
	}
	.h-lg-120 {
		height: 120%!important
	}
	.w-lg-120 {
		width: 120%!important
	}
	.mw-lg-120 {
		max-width: 120%!important
	}
	.vh-lg-120 {
		height: 120vh
	}
	.vw-lg-120 {
		width: 120vw
	}
	.h-lg-120p {
		height: 120px!important
	}
	.w-lg-120p {
		width: 120px!important
	}
	.mw-lg-120p {
		max-width: 120px!important
	}
	.vh-lg-120p {
		height: 120pvh
	}
	.vw-lg-120p {
		width: 120pvw
	}
	.h-lg-130 {
		height: 130%!important
	}
	.w-lg-130 {
		width: 130%!important
	}
	.mw-lg-130 {
		max-width: 130%!important
	}
	.vh-lg-130 {
		height: 130vh
	}
	.vw-lg-130 {
		width: 130vw
	}
	.h-lg-130p {
		height: 130px!important
	}
	.w-lg-130p {
		width: 130px!important
	}
	.mw-lg-130p {
		max-width: 130px!important
	}
	.vh-lg-130p {
		height: 130pvh
	}
	.vw-lg-130p {
		width: 130pvw
	}
	.h-lg-140 {
		height: 140%!important
	}
	.w-lg-140 {
		width: 140%!important
	}
	.mw-lg-140 {
		max-width: 140%!important
	}
	.vh-lg-140 {
		height: 140vh
	}
	.vw-lg-140 {
		width: 140vw
	}
	.h-lg-140p {
		height: 140px!important
	}
	.w-lg-140p {
		width: 140px!important
	}
	.mw-lg-140p {
		max-width: 140px!important
	}
	.vh-lg-140p {
		height: 140pvh
	}
	.vw-lg-140p {
		width: 140pvw
	}
	.h-lg-150 {
		height: 150%!important
	}
	.w-lg-150 {
		width: 150%!important
	}
	.mw-lg-150 {
		max-width: 150%!important
	}
	.vh-lg-150 {
		height: 150vh
	}
	.vw-lg-150 {
		width: 150vw
	}
	.h-lg-152 {
		height: 152px!important
	}
	.w-lg-152 {
		width: 152px!important
	}
	.mw-lg-152 {
		max-width: 152px!important
	}
	.vh-lg-152 {
		height: 152vh
	}
	.vw-lg-152 {
		width: 152vw
	}
	.h-lg-174 {
		height: 174%!important
	}
	.w-lg-174 {
		width: 174%!important
	}
	.mw-lg-174 {
		max-width: 174%!important
	}
	.vh-lg-174 {
		height: 174vh
	}
	.vw-lg-174 {
		width: 174vw
	}
	.h-lg-180p {
		height: 180px!important
	}
	.w-lg-180p {
		width: 180px!important
	}
	.mw-lg-180p {
		max-width: 180px!important
	}
	.vh-lg-180p {
		height: 180pvh
	}
	.vw-lg-180p {
		width: 180pvw
	}
	.h-lg-200p {
		height: 200px!important
	}
	.w-lg-200p {
		width: 200px!important
	}
	.mw-lg-200p {
		max-width: 200px!important
	}
	.vh-lg-200p {
		height: 200pvh
	}
	.vw-lg-200p {
		width: 200pvw
	}
	.h-lg-210p {
		height: 210px!important
	}
	.w-lg-210p {
		width: 210px!important
	}
	.mw-lg-210p {
		max-width: 210px!important
	}
	.vh-lg-210p {
		height: 210pvh
	}
	.vw-lg-210p {
		width: 210pvw
	}
	.h-lg-220p {
		height: 220px!important
	}
	.w-lg-220p {
		width: 220px!important
	}
	.mw-lg-220p {
		max-width: 220px!important
	}
	.vh-lg-220p {
		height: 220pvh
	}
	.vw-lg-220p {
		width: 220pvw
	}
	.h-lg-260p {
		height: 260px!important
	}
	.w-lg-260p {
		width: 260px!important
	}
	.mw-lg-260p {
		max-width: 260px!important
	}
	.vh-lg-260p {
		height: 260pvh
	}
	.vw-lg-260p {
		width: 260pvw
	}
	.h-lg-270p {
		height: 270px!important
	}
	.w-lg-270p {
		width: 270px!important
	}
	.mw-lg-270p {
		max-width: 270px!important
	}
	.vh-lg-270p {
		height: 270pvh
	}
	.vw-lg-270p {
		width: 270pvw
	}
	.h-lg-280p {
		height: 280px!important
	}
	.w-lg-280p {
		width: 280px!important
	}
	.mw-lg-280p {
		max-width: 280px!important
	}
	.vh-lg-280p {
		height: 280pvh
	}
	.vw-lg-280p {
		width: 280pvw
	}
	.h-lg-300p {
		height: 300px!important
	}
	.w-lg-300p {
		width: 300px!important
	}
	.mw-lg-300p {
		max-width: 300px!important
	}
	.vh-lg-300p {
		height: 300pvh
	}
	.vw-lg-300p {
		width: 300pvw
	}
	.h-lg-350p {
		height: 350px!important
	}
	.w-lg-350p {
		width: 350px!important
	}
	.mw-lg-350p {
		max-width: 350px!important
	}
	.vh-lg-350p {
		height: 350pvh
	}
	.vw-lg-350p {
		width: 350pvw
	}
	.h-lg-450p {
		height: 450px!important
	}
	.w-lg-450p {
		width: 450px!important
	}
	.mw-lg-450p {
		max-width: 450px!important
	}
	.vh-lg-450p {
		height: 450pvh
	}
	.vw-lg-450p {
		width: 450pvw
	}
	.h-lg-auto {
		height: auto!important
	}
	.w-lg-auto {
		width: auto!important
	}
	.mw-lg-auto {
		max-width: auto!important
	}
	.vh-lg-auto {
		height: autovh
	}
	.vw-lg-auto {
		width: autovw
	}
}

@media (min-width:1200px) {
	.h-xl-14 {
		height: 14%!important
	}
	.w-xl-14 {
		width: 14%!important
	}
	.mw-xl-14 {
		max-width: 14%!important
	}
	.vh-xl-14 {
		height: 14vh
	}
	.vw-xl-14 {
		width: 14vw
	}
	.h-xl-16 {
		height: 16px!important
	}
	.w-xl-16 {
		width: 16px!important
	}
	.mw-xl-16 {
		max-width: 16px!important
	}
	.vh-xl-16 {
		height: 16vh
	}
	.vw-xl-16 {
		width: 16vw
	}
	.h-xl-20 {
		height: 20%!important
	}
	.w-xl-20 {
		width: 20%!important
	}
	.mw-xl-20 {
		max-width: 20%!important
	}
	.vh-xl-20 {
		height: 20vh
	}
	.vw-xl-20 {
		width: 20vw
	}
	.h-xl-25 {
		height: 25%!important
	}
	.w-xl-25 {
		width: 25%!important
	}
	.mw-xl-25 {
		max-width: 25%!important
	}
	.vh-xl-25 {
		height: 25vh
	}
	.vw-xl-25 {
		width: 25vw
	}
	.h-xl-26p {
		height: 26px!important
	}
	.w-xl-26p {
		width: 26px!important
	}
	.mw-xl-26p {
		max-width: 26px!important
	}
	.vh-xl-26p {
		height: 26pvh
	}
	.vw-xl-26p {
		width: 26pvw
	}
	.h-xl-28 {
		height: 28%!important
	}
	.w-xl-28 {
		width: 28%!important
	}
	.mw-xl-28 {
		max-width: 28%!important
	}
	.vh-xl-28 {
		height: 28vh
	}
	.vw-xl-28 {
		width: 28vw
	}
	.h-xl-36 {
		height: 36px!important
	}
	.w-xl-36 {
		width: 36px!important
	}
	.mw-xl-36 {
		max-width: 36px!important
	}
	.vh-xl-36 {
		height: 36vh
	}
	.vw-xl-36 {
		width: 36vw
	}
	.h-xl-40p {
		height: 40px!important
	}
	.w-xl-40p {
		width: 40px!important
	}
	.mw-xl-40p {
		max-width: 40px!important
	}
	.vh-xl-40p {
		height: 40pvh
	}
	.vw-xl-40p {
		width: 40pvw
	}
	.h-xl-48 {
		height: 48px!important
	}
	.w-xl-48 {
		width: 48px!important
	}
	.mw-xl-48 {
		max-width: 48px!important
	}
	.vh-xl-48 {
		height: 48vh
	}
	.vw-xl-48 {
		width: 48vw
	}
	.h-xl-50 {
		height: 50%!important
	}
	.w-xl-50 {
		width: 50%!important
	}
	.mw-xl-50 {
		max-width: 50%!important
	}
	.vh-xl-50 {
		height: 50vh
	}
	.vw-xl-50 {
		width: 50vw
	}
	.h-xl-50p {
		height: 50px!important
	}
	.w-xl-50p {
		width: 50px!important
	}
	.mw-xl-50p {
		max-width: 50px!important
	}
	.vh-xl-50p {
		height: 50pvh
	}
	.vw-xl-50p {
		width: 50pvw
	}
	.h-xl-52 {
		height: 52px!important
	}
	.w-xl-52 {
		width: 52px!important
	}
	.mw-xl-52 {
		max-width: 52px!important
	}
	.vh-xl-52 {
		height: 52vh
	}
	.vw-xl-52 {
		width: 52vw
	}
	.h-xl-58 {
		height: 58px!important
	}
	.w-xl-58 {
		width: 58px!important
	}
	.mw-xl-58 {
		max-width: 58px!important
	}
	.vh-xl-58 {
		height: 58vh
	}
	.vw-xl-58 {
		width: 58vw
	}
	.h-xl-60p {
		height: 60px!important
	}
	.w-xl-60p {
		width: 60px!important
	}
	.mw-xl-60p {
		max-width: 60px!important
	}
	.vh-xl-60p {
		height: 60pvh
	}
	.vw-xl-60p {
		width: 60pvw
	}
	.h-xl-65 {
		height: 65%!important
	}
	.w-xl-65 {
		width: 65%!important
	}
	.mw-xl-65 {
		max-width: 65%!important
	}
	.vh-xl-65 {
		height: 65vh
	}
	.vw-xl-65 {
		width: 65vw
	}
	.h-xl-70p {
		height: 70px!important
	}
	.w-xl-70p {
		width: 70px!important
	}
	.mw-xl-70p {
		max-width: 70px!important
	}
	.vh-xl-70p {
		height: 70pvh
	}
	.vw-xl-70p {
		width: 70pvw
	}
	.h-xl-75 {
		height: 75%!important
	}
	.w-xl-75 {
		width: 75%!important
	}
	.mw-xl-75 {
		max-width: 75%!important
	}
	.vh-xl-75 {
		height: 75vh
	}
	.vw-xl-75 {
		width: 75vw
	}
	.h-xl-80 {
		height: 80%!important
	}
	.w-xl-80 {
		width: 80%!important
	}
	.mw-xl-80 {
		max-width: 80%!important
	}
	.vh-xl-80 {
		height: 80vh
	}
	.vw-xl-80 {
		width: 80vw
	}
	.h-xl-90p {
		height: 90px!important
	}
	.w-xl-90p {
		width: 90px!important
	}
	.mw-xl-90p {
		max-width: 90px!important
	}
	.vh-xl-90p {
		height: 90pvh
	}
	.vw-xl-90p {
		width: 90pvw
	}
	.h-xl-96 {
		height: 96%!important
	}
	.w-xl-96 {
		width: 96%!important
	}
	.mw-xl-96 {
		max-width: 96%!important
	}
	.vh-xl-96 {
		height: 96vh
	}
	.vw-xl-96 {
		width: 96vw
	}
	.h-xl-100p {
		height: 100px!important
	}
	.w-xl-100p {
		width: 100px!important
	}
	.mw-xl-100p {
		max-width: 100px!important
	}
	.vh-xl-100p {
		height: 100pvh
	}
	.vw-xl-100p {
		width: 100pvw
	}
	.h-xl-100 {
		height: 100%!important
	}
	.w-xl-100 {
		width: 100%!important
	}
	.mw-xl-100 {
		max-width: 100%!important
	}
	.vh-xl-100 {
		height: 100vh
	}
	.vw-xl-100 {
		width: 100vw
	}
	.h-xl-110 {
		height: 110%!important
	}
	.w-xl-110 {
		width: 110%!important
	}
	.mw-xl-110 {
		max-width: 110%!important
	}
	.vh-xl-110 {
		height: 110vh
	}
	.vw-xl-110 {
		width: 110vw
	}
	.h-xl-120 {
		height: 120%!important
	}
	.w-xl-120 {
		width: 120%!important
	}
	.mw-xl-120 {
		max-width: 120%!important
	}
	.vh-xl-120 {
		height: 120vh
	}
	.vw-xl-120 {
		width: 120vw
	}
	.h-xl-120p {
		height: 120px!important
	}
	.w-xl-120p {
		width: 120px!important
	}
	.mw-xl-120p {
		max-width: 120px!important
	}
	.vh-xl-120p {
		height: 120pvh
	}
	.vw-xl-120p {
		width: 120pvw
	}
	.h-xl-130 {
		height: 130%!important
	}
	.w-xl-130 {
		width: 130%!important
	}
	.mw-xl-130 {
		max-width: 130%!important
	}
	.vh-xl-130 {
		height: 130vh
	}
	.vw-xl-130 {
		width: 130vw
	}
	.h-xl-130p {
		height: 130px!important
	}
	.w-xl-130p {
		width: 130px!important
	}
	.mw-xl-130p {
		max-width: 130px!important
	}
	.vh-xl-130p {
		height: 130pvh
	}
	.vw-xl-130p {
		width: 130pvw
	}
	.h-xl-140 {
		height: 140%!important
	}
	.w-xl-140 {
		width: 140%!important
	}
	.mw-xl-140 {
		max-width: 140%!important
	}
	.vh-xl-140 {
		height: 140vh
	}
	.vw-xl-140 {
		width: 140vw
	}
	.h-xl-140p {
		height: 140px!important
	}
	.w-xl-140p {
		width: 140px!important
	}
	.mw-xl-140p {
		max-width: 140px!important
	}
	.vh-xl-140p {
		height: 140pvh
	}
	.vw-xl-140p {
		width: 140pvw
	}
	.h-xl-150 {
		height: 150%!important
	}
	.w-xl-150 {
		width: 150%!important
	}
	.mw-xl-150 {
		max-width: 150%!important
	}
	.vh-xl-150 {
		height: 150vh
	}
	.vw-xl-150 {
		width: 150vw
	}
	.h-xl-152 {
		height: 152px!important
	}
	.w-xl-152 {
		width: 152px!important
	}
	.mw-xl-152 {
		max-width: 152px!important
	}
	.vh-xl-152 {
		height: 152vh
	}
	.vw-xl-152 {
		width: 152vw
	}
	.h-xl-174 {
		height: 174%!important
	}
	.w-xl-174 {
		width: 174%!important
	}
	.mw-xl-174 {
		max-width: 174%!important
	}
	.vh-xl-174 {
		height: 174vh
	}
	.vw-xl-174 {
		width: 174vw
	}
	.h-xl-180p {
		height: 180px!important
	}
	.w-xl-180p {
		width: 180px!important
	}
	.mw-xl-180p {
		max-width: 180px!important
	}
	.vh-xl-180p {
		height: 180pvh
	}
	.vw-xl-180p {
		width: 180pvw
	}
	.h-xl-200p {
		height: 200px!important
	}
	.w-xl-200p {
		width: 200px!important
	}
	.mw-xl-200p {
		max-width: 200px!important
	}
	.vh-xl-200p {
		height: 200pvh
	}
	.vw-xl-200p {
		width: 200pvw
	}
	.h-xl-210p {
		height: 210px!important
	}
	.w-xl-210p {
		width: 210px!important
	}
	.mw-xl-210p {
		max-width: 210px!important
	}
	.vh-xl-210p {
		height: 210pvh
	}
	.vw-xl-210p {
		width: 210pvw
	}
	.h-xl-220p {
		height: 220px!important
	}
	.w-xl-220p {
		width: 220px!important
	}
	.mw-xl-220p {
		max-width: 220px!important
	}
	.vh-xl-220p {
		height: 220pvh
	}
	.vw-xl-220p {
		width: 220pvw
	}
	.h-xl-260p {
		height: 260px!important
	}
	.w-xl-260p {
		width: 260px!important
	}
	.mw-xl-260p {
		max-width: 260px!important
	}
	.vh-xl-260p {
		height: 260pvh
	}
	.vw-xl-260p {
		width: 260pvw
	}
	.h-xl-270p {
		height: 270px!important
	}
	.w-xl-270p {
		width: 270px!important
	}
	.mw-xl-270p {
		max-width: 270px!important
	}
	.vh-xl-270p {
		height: 270pvh
	}
	.vw-xl-270p {
		width: 270pvw
	}
	.h-xl-280p {
		height: 280px!important
	}
	.w-xl-280p {
		width: 280px!important
	}
	.mw-xl-280p {
		max-width: 280px!important
	}
	.vh-xl-280p {
		height: 280pvh
	}
	.vw-xl-280p {
		width: 280pvw
	}
	.h-xl-300p {
		height: 300px!important
	}
	.w-xl-300p {
		width: 300px!important
	}
	.mw-xl-300p {
		max-width: 300px!important
	}
	.vh-xl-300p {
		height: 300pvh
	}
	.vw-xl-300p {
		width: 300pvw
	}
	.h-xl-350p {
		height: 350px!important
	}
	.w-xl-350p {
		width: 350px!important
	}
	.mw-xl-350p {
		max-width: 350px!important
	}
	.vh-xl-350p {
		height: 350pvh
	}
	.vw-xl-350p {
		width: 350pvw
	}
	.h-xl-450p {
		height: 450px!important
	}
	.w-xl-450p {
		width: 450px!important
	}
	.mw-xl-450p {
		max-width: 450px!important
	}
	.vh-xl-450p {
		height: 450pvh
	}
	.vw-xl-450p {
		width: 450pvw
	}
	.h-xl-auto {
		height: auto!important
	}
	.w-xl-auto {
		width: auto!important
	}
	.mw-xl-auto {
		max-width: auto!important
	}
	.vh-xl-auto {
		height: autovh
	}
	.vw-xl-auto {
		width: autovw
	}
}

@media (min-width:576px) {
	.w-cover {
		width: calc(100% + (100vw - 540px)/ 2 + 30px)
	}
}

@media (min-width:768px) {
	.w-cover {
		width: calc(100% + (100vw - 720px)/ 2 + 30px)
	}
}

@media (min-width:992px) {
	.w-cover {
		width: calc(100% + (100vw - 960px)/ 2 + 30px)
	}
}

@media (min-width:1200px) {
	.w-cover {
		width: calc(100% + (100vw - 1200px)/ 2 + 30px)
	}
}

.min-width-140 {
	min-width: 140px
}

.mw-100-all * {
	max-width: 100%
}

.mh-50 {
	min-height: 50px
}

.min-height-70 {
	min-height: 70px
}

.min-height-80 {
	min-height: 80px
}

.size-20-all * {
	width: 20px;
	height: 20px
}

.size-30-all * {
	width: 30px;
	height: 30px
}

@media (min-width:1200px) {
	.mw-xl-20p {
		-webkit-box-flex: 0;
		flex: 0 0 20%;
		max-width: 20%
	}
}

.font-size-10 {
	font-size: .625rem!important
}

.font-size-15 {
	font-size: .937rem!important
}

.font-size-xs {
	font-size: .75rem!important
}

.font-size-base {
	font-size: 1rem
}

.font-size-sm {
	font-size: .875rem!important
}

@media (max-width:767.98px) {
	.font-size-sm {
		font-size: .75rem!important
	}
}

.font-size-sm-alone {
	font-size: .875rem!important
}

@media (max-width:767.98px) {
	.font-size-d-sm {
		font-size: .875rem!important
	}
}

.font-size-lg {
	font-size: 1.125rem!important
}

@media (max-width:767.98px) {
	.font-size-lg {
		font-size: 1rem!important
	}
}

.font-size-xl {
	font-size: 1.5rem!important
}

@media (max-width:767.98px) {
	.font-size-xl {
		font-size: 1.25rem!important
	}
}

.font-size-xxl {
	font-size: 1.625rem!important
}

@media (max-width:767.98px) {
	.font-size-xxl {
		font-size: 1.5rem!important
	}
}

.font-weight-semi-bold,
.fw-semi-bold {
	font-weight: 600
}

.font-weight-medium,
.fw-medium {
	font-weight: 500!important
}

.font-primary {
	font-family: Jost, sans-serif!important
}

.font-lora {
	font-family: Lora, serif!important
}

.font-montserrat {
	font-family: Montserrat, sans-serif!important
}

.font-fredoka {
	font-family: 'Fredoka One', cursive!important
}

.font-nunito {
	font-family: Nunito, sans-serif!important
}

.font-awesome {
	font-family: 'Font Awesome 5 Pro'
}

.text-w {
	color: #fff!important
}

.text-gray-100 {
	color: #f7f9fb!important
}

.text-gray-200 {
	color: #e7e7ec!important
}

.text-gray-300 {
	color: #d9e2ef!important
}

.text-gray-400 {
	color: #c6d3e6!important
}

.text-gray-500 {
	color: #c8c8d6!important
}

.text-gray-600 {
	color: #8c98a4!important
}

.text-gray-700 {
	color: #949da6!important
}

.text-gray-800 {
	color: #77838f!important
}

.text-gray-900 {
	color: #2f2d51!important
}

.text-underline {
	text-decoration: underline
}

.text-underline:focus,
.text-underline:hover {
	text-decoration: underline
}

.current-color path {
	fill: currentColor!important
}

.text-dark-10 {
	color: rgba(47, 45, 81, .1)!important
}

.text-dark-70 {
	color: rgba(47, 45, 81, .7)!important
}

.text-white-20 {
	color: rgba(255, 255, 255, .2)!important
}

.text-white-60 {
	color: rgba(255, 255, 255, .6)!important
}

.text-white-70 {
	color: rgba(255, 255, 255, .7)!important
}

.text-white-75 {
	color: rgba(255, 255, 255, .75)!important
}

.text-white-80 {
	color: rgba(255, 255, 255, .8)!important
}

.text-muted-80 {
	color: rgba(140, 152, 164, .8)!important
}

.text-white-alone {
	color: #fff
}

.bg-darken .text-dark {
	color: #fff!important
}

.bg-darken .text-gray-800 {
	color: rgba(255, 255, 255, .6)!important
}

.bg-darken .text-gray-800 a:focus,
.bg-darken .text-gray-800 a:hover {
	color: rgba(255, 255, 255, .8)!important
}

.bg-darken a.text-gray-800:focus,
.bg-darken a.text-gray-800:hover {
	color: rgba(255, 255, 255, .8)!important
}

.footer-font-lora .text-dark {
	font-family: Lora, serif!important;
	font-weight: 700!important
}

.footer-font-lora .font-size-sm-alone {
	font-size: 13px!important
}

.footer-font-nunito .text-dark {
	font-family: 'Fredoka One', cursive!important
}

.dark-70 .text-gray-800 {
	color: rgba(255, 255, 255, .7)!important
}

.dark-70 .text-gray-800 a:focus,
.dark-70 .text-gray-800 a:hover {
	color: rgba(255, 255, 255, .88)!important
}

.dark-70 a.text-gray-800:focus,
.dark-70 a.text-gray-800:hover {
	color: rgba(255, 255, 255, .88)!important
}

.white-100 .text-gray-800 {
	color: #fff!important
}

.white-100 .text-gray-800 a:focus,
.white-100 .text-gray-800 a:hover {
	color: #fff!important
}

.white-100 a.text-gray-800:focus,
.white-100 a.text-gray-800:hover {
	color: #fff!important
}

.text-white-all {
	color: #fff!important
}

.text-white-all:focus,
.text-white-all:hover {
	color: #fff!important
}

.text-hover-white:focus,
.text-hover-white:hover {
	color: #fff!important
}

.letter-spacing-lg {
	letter-spacing: .08em!important
}

.line-clamp-1 {
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical
}

.line-clamp-2 {
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical
}

.line-clamp-3 {
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical
}

.text-gradient-1 {
	background: -webkit-gradient(linear, left top, right top, color-stop(.78%, #ff9574), color-stop(71.02%, #4e35a3));
	background: linear-gradient(90deg, #ff9574 .78%, #4e35a3 71.02%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent
}

:focus {
	outline: transparent
}

.opacity-0 {
	opacity: 0!important
}

.opacity-1 {
	opacity: 1!important
}

.opacity-dot-7 {
	opacity: .7
}

.opacity-hover {
	opacity: .4;
	color: #2f2d51;
	-webkit-transition: all .3s ease;
	transition: all .3s ease
}

.opacity-hover:focus,
.opacity-hover:hover {
	opacity: 1;
	color: #090761
}

.opacity-hover:focus .font-size-lg,
.opacity-hover:hover .font-size-lg {
	text-decoration: underline
}

.overflow-scroll {
	overflow: scroll
}

@media (min-width:768px) {
	.overflow-md-visible {
		overflow: visible!important
	}
}

@media (min-width:370px) and (max-width:1199px) {
	.d-370-block {
		display: block!important
	}
}

.search-mobile[aria-expanded=false] svg:not(:first-child),
.sidebar-collapse>a[aria-expanded=false] svg:not(:first-child) {
	display: none
}

.search-mobile[aria-expanded=true] svg:first-child,
.sidebar-collapse>a[aria-expanded=true] svg:first-child {
	display: none
}

.filter-none {
	-webkit-filter: none!important;
	filter: none!important
}

[data-aos=img-skewed-item-left],
[data-aos=img-skewed-item-right] {
	opacity: 0;
	transition-property: opacity, transform, -webkit-transform;
	will-change: opacity, transform, -webkit-transform;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden
}

[data-aos=img-skewed-item-left].aos-animate,
[data-aos=img-skewed-item-right].aos-animate {
	opacity: 1
}

[data-aos=img-skewed-item-left] {
	-webkit-transform: rotateY(-20deg) rotateX(5deg) translate3d(0, 100px, 0);
	transform: rotateY(-20deg) rotateX(5deg) translate3d(0, 100px, 0)
}

[data-aos=img-skewed-item-left].aos-animate {
	-webkit-transform: rotateY(-35deg) rotateX(15deg) translate3d(0, 0, 0);
	transform: rotateY(-35deg) rotateX(15deg) translate3d(0, 0, 0)
}

[data-aos=img-skewed-item-right] {
	-webkit-transform: rotateY(20deg) rotateX(5deg) translate3d(0, 100px, 0);
	transform: rotateY(20deg) rotateX(5deg) translate3d(0, 100px, 0)
}

[data-aos=img-skewed-item-right].aos-animate {
	-webkit-transform: rotateY(35deg) rotateX(15deg) translate3d(0, 0, 0);
	transform: rotateY(35deg) rotateX(15deg) translate3d(0, 0, 0)
}

html:not(.no-js) [data-aos=fade-up] {
	-webkit-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0)
}

.sk-fade-bottom,
.sk-fade-left,
.sk-fade-right,
.sk-fade-top {
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all .3s ease;
	transition: all .3s ease
}

.sk-fade-top {
	-webkit-transform: translateY(-25px) translateZ(0);
	transform: translateY(-25px) translateZ(0)
}

.sk-fade-bottom {
	-webkit-transform: translateY(25px) translateZ(0);
	transform: translateY(25px) translateZ(0)
}

.sk-fade-left {
	-webkit-transform: translateX(-25px) translateZ(0);
	transform: translateX(-25px) translateZ(0)
}

.sk-fade-right {
	-webkit-transform: translateX(25px) translateZ(0);
	transform: translateX(25px) translateZ(0)
}

.sk-fade:focus .sk-fade-bottom,
.sk-fade:focus .sk-fade-left,
.sk-fade:focus .sk-fade-right,
.sk-fade:focus .sk-fade-top,
.sk-fade:hover .sk-fade-bottom,
.sk-fade:hover .sk-fade-left,
.sk-fade:hover .sk-fade-right,
.sk-fade:hover .sk-fade-top {
	opacity: 1;
	visibility: visible
}

.sk-fade:focus .sk-fade-bottom,
.sk-fade:focus .sk-fade-top,
.sk-fade:hover .sk-fade-bottom,
.sk-fade:hover .sk-fade-top {
	-webkit-transform: translateY(0) translateZ(0);
	transform: translateY(0) translateZ(0)
}

.sk-fade:focus .sk-fade-left,
.sk-fade:focus .sk-fade-right,
.sk-fade:hover .sk-fade-left,
.sk-fade:hover .sk-fade-right {
	-webkit-transform: translateX(0) translateZ(0);
	transform: translateX(0) translateZ(0)
}

.sk-fade:focus .sk-push,
.sk-fade:hover .sk-push {
	-webkit-animation-name: sk-push;
	animation-name: sk-push;
	-webkit-animation-duration: .3s;
	animation-duration: .3s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1
}

.sk-push {
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0)
}

@-webkit-keyframes sk-push {
	50% {
		-webkit-transform: scale(.8);
		transform: scale(.8)
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1)
	}
}

@keyframes sk-push {
	50% {
		-webkit-transform: scale(.8);
		transform: scale(.8)
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1)
	}
}

.avatar {
	position: relative;
	display: inline-block;
	width: 1.875rem;
	height: 1.875rem;
	font-size: .625rem
}

.avatar:after {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	background-image: url(../../public/assets/img/masks/avatar-status.svg), url(../../public/assets/img/masks/avatar-group.svg), url(../../public/assets/img/masks/avatar-group-hover.svg), url(../../public/assets/img/masks/avatar-group-hover-last.svg)
}

.avatar-img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover
}

.avatar-title {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: #c8c8d6;
	color: #fff
}

.avatar-offline::before,
.avatar-online::before {
	content: '';
	position: absolute;
	bottom: 5%;
	right: 5%;
	width: 20%;
	height: 20%;
	border-radius: 50%
}

.avatar-offline .avatar-img,
.avatar-online .avatar-img {
	-webkit-mask-image: url(../../public/assets/img/masks/avatar-status.svg);
	mask-image: url(../../public/assets/img/masks/avatar-status.svg);
	-webkit-mask-size: 100% 100%;
	mask-size: 100% 100%
}

.avatar-online::before {
	background-color: #def2d7
}

.avatar-offline::before {
	background-color: #c8c8d6
}

.avatar-xs {
	width: 1rem;
	height: 1rem;
	font-size: .33333rem
}

.avatar-sm {
	width: 1.5rem;
	height: 1.5rem;
	font-size: .5rem
}

.avatar-lg {
	width: 3.125rem;
	height: 3.125rem;
	font-size: 1.04167rem
}

.avatar-xl {
	width: 4.125rem;
	height: 4.125rem;
	font-size: 1.375rem
}

.avatar-xxl {
	width: 4.125rem;
	height: 4.125rem;
	font-size: 1.375rem
}

@media (min-width:768px) {
	.avatar-xxl {
		width: 5rem;
		height: 5rem;
		font-size: 1.66667rem
	}
}

.avatar-custom {
	width: 4.375rem;
	height: 4.375rem
}

.avatar-size-110 {
	width: 6.875rem;
	height: 6.875rem
}

.avatar-size-120 {
	width: 7.5rem;
	height: 7.5rem
}

.avatar.avatar-4by3 {
	width: 2.5rem
}

.avatar-xs.avatar-4by3 {
	width: 1.33333rem
}

.avatar-sm.avatar-4by3 {
	width: 2rem
}

.avatar-lg.avatar-4by3 {
	width: 4.16667rem
}

.avatar-xl.avatar-4by3 {
	width: 5.5rem
}

.avatar-xxl.avatar-4by3 {
	width: 6.66667rem
}

.avatar-group {
	display: -webkit-inline-box;
	display: inline-flex
}

.avatar-group .avatar+.avatar {
	margin-left: -.46875rem
}

.avatar-group .avatar-xs+.avatar-xs {
	margin-left: -.25rem
}

.avatar-group .avatar-sm+.avatar-sm {
	margin-left: -.375rem
}

.avatar-group .avatar-lg+.avatar-lg {
	margin-left: -.78125rem
}

.avatar-group .avatar-xl+.avatar-xl {
	margin-left: -1.03125rem
}

.avatar-group .avatar-xxl+.avatar-xxl {
	margin-left: -1.25rem
}

.avatar-group .avatar:not(:last-child) {
	-webkit-mask-image: url(../../public/assets/img/masks/avatar-group.svg);
	mask-image: url(../../public/assets/img/masks/avatar-group.svg);
	-webkit-mask-size: 100% 100%;
	mask-size: 100% 100%
}

.avatar-group .avatar:hover {
	-webkit-mask-image: none;
	mask-image: none;
	z-index: 1
}

.avatar-group .avatar:hover+.avatar {
	-webkit-mask-image: url(../../public/assets/img/masks/avatar-group-hover.svg);
	mask-image: url(../../public/assets/img/masks/avatar-group-hover.svg);
	-webkit-mask-size: 100% 100%;
	mask-size: 100% 100%
}

.avatar-group .avatar:hover+.avatar:last-child {
	-webkit-mask-image: url(../../public/assets/img/masks/avatar-group-hover-last.svg);
	mask-image: url(../../public/assets/img/masks/avatar-group-hover-last.svg)
}

.choices::after {
	display: none
}

.choices .dropdown-menu {
	width: 100%
}

.choices .custom-select-xs~.dropdown-menu,
.choices .form-control-xs~.dropdown-menu {
	width: auto;
	min-width: 100%;
	padding: 1rem 1.25rem
}

.is-flipped .dropdown-menu {
	top: auto;
	bottom: 100%;
	margin-top: 0;
	margin-bottom: -1px;
	border-radius: .25rem .25rem 0 0
}

.choices-label {
	position: relative
}

.choices-label .choices {
	position: static;
	-webkit-box-flex: 1;
	flex-grow: 1
}

.choices-label .choices .show.dropdown-menu {
	margin-top: 1px
}

.course-tab-v1 .nav-link {
	color: #77838f;
	padding: 1rem 1.5rem
}

.course-tab-v1 .nav-item:not(:last-child) {
	margin-right: 24px
}

.course-tab-v1 .nav-link.active {
	color: #2f2d51;
	position: relative
}

.course-tab-v1 .nav-link.active::after {
	content: "";
	height: 3px;
	background-color: #2f2d51;
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0
}

@media (max-width:1199.98px) {
	.course-single-white .text-white {
		color: #2f2d51!important
	}
}

.course-tab-v2 .nav-link {
	padding: .625rem 1.39rem;
	line-height: 1;
	color: #77838f
}

.course-tab-v2 .nav-item:not(:last-child) {
	margin-right: 20px
}

.course-tab-v2 .nav-link.active {
	background-color: #4a8f9f
}

.left-sidebar #primary,
.left-sidebar #secondary,
.right-sidebar #primary,
.right-sidebar #secondary {
	flex-shrink: 0;
	width: 100%;
	max-width: 100%;
	padding-right: calc(var(--bs-gutter-x) * .5);
	padding-left: calc(var(--bs-gutter-x) * .5);
	margin-top: var(--bs-gutter-y)
}

@media (min-width:768px) {
	.left-sidebar #primary,
	.right-sidebar #primary {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 58.33333%
	}
}

@media (min-width:992px) {
	.left-sidebar #primary,
	.right-sidebar #primary {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 66.66667%
	}
}

@media (min-width:768px) {
	.left-sidebar #secondary,
	.right-sidebar #secondary {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 41.66667%
	}
}

@media (min-width:992px) {
	.left-sidebar #secondary,
	.right-sidebar #secondary {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 33.33333%
	}
}

form.woocommerce-checkout {
	--bs-gutter-x: 30px;
	--bs-gutter-y: 0;
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(-1 * var(--bs-gutter-y));
	margin-right: calc(-.5 * var(--bs-gutter-x));
	margin-left: calc(-.5 * var(--bs-gutter-x))
}

form.woocommerce-checkout #customer_details,
form.woocommerce-checkout #order_review {
	flex-shrink: 0;
	width: 100%;
	max-width: 100%;
	padding-right: calc(var(--bs-gutter-x) * .5);
	padding-left: calc(var(--bs-gutter-x) * .5);
	margin-top: var(--bs-gutter-y)
}

@media (min-width:768px) {
	form.woocommerce-checkout #customer_details {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 58.33333%
	}
}

@media (min-width:992px) {
	form.woocommerce-checkout #customer_details {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 66.66667%
	}
}

form.woocommerce-checkout #customer_details .col-1,
form.woocommerce-checkout #customer_details .col-2 {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: 100%;
	padding: 0
}

@media (min-width:768px) {
	form.woocommerce-checkout #order_review {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 41.66667%
	}
}

@media (min-width:992px) {
	form.woocommerce-checkout #order_review {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 33.33333%
	}
}

form.woocommerce-checkout>#order_review_heading {
	display: none
}

form.woocommerce-checkout .woocommerce-additional-fields__field-wrapper,
form.woocommerce-checkout .woocommerce-billing-fields__field-wrapper,
form.woocommerce-checkout .woocommerce-shipping-fields__field-wrapper {
	--bs-gutter-x: 30px;
	--bs-gutter-y: 0;
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(-1 * var(--bs-gutter-y));
	margin-right: calc(-.5 * var(--bs-gutter-x));
	margin-left: calc(-.5 * var(--bs-gutter-x))
}

form.woocommerce-checkout .form-row {
	flex-shrink: 0;
	width: 100%;
	max-width: 100%;
	padding-right: calc(var(--bs-gutter-x) * .5);
	padding-left: calc(var(--bs-gutter-x) * .5);
	margin-top: var(--bs-gutter-y);
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	margin: 0 0 24px
}

@media (min-width:1200px) {
	form.woocommerce-checkout .form-row {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 50%
	}
}

.cart-collaterals form.woocommerce-checkout .h2,
.cart-collaterals form.woocommerce-checkout h2,
form.woocommerce-checkout .cart-collaterals .h2,
form.woocommerce-checkout .cart-collaterals h2,
form.woocommerce-checkout .h3,
form.woocommerce-checkout h3 {
	margin-bottom: 1.2rem
}

.cart-collaterals form.woocommerce-checkout .woocommerce-additional-fields .h2,
.cart-collaterals form.woocommerce-checkout .woocommerce-additional-fields h2,
form.woocommerce-checkout .woocommerce-additional-fields .cart-collaterals .h2,
form.woocommerce-checkout .woocommerce-additional-fields .cart-collaterals h2,
form.woocommerce-checkout .woocommerce-additional-fields .h3,
form.woocommerce-checkout .woocommerce-additional-fields h3 {
	margin-top: 6px;
	margin-bottom: 1.3rem
}

form.woocommerce-checkout #billing_address_1_field,
form.woocommerce-checkout #shipping_address_1_field {
	margin-bottom: 10px
}

@media (min-width:768px) {
	form.woocommerce-checkout #billing_address_1_field,
	form.woocommerce-checkout #billing_address_2_field,
	form.woocommerce-checkout #billing_company_field,
	form.woocommerce-checkout #billing_country_field,
	form.woocommerce-checkout #billing_email_field,
	form.woocommerce-checkout #order_comments_field,
	form.woocommerce-checkout #shipping_address_1_field,
	form.woocommerce-checkout #shipping_address_2_field,
	form.woocommerce-checkout #shipping_company_field,
	form.woocommerce-checkout #shipping_country_field,
	form.woocommerce-checkout #shipping_email_field {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 100%
	}
}

form.woocommerce-checkout .woocommerce-checkout-payment .payment_methods,
form.woocommerce-checkout .woocommerce-checkout-review-order-inner {
	border: 1px solid #e7e7ec;
	border-radius: .25rem;
	box-shadow: 0 .5rem .937rem rgba(140, 152, 164, .1);
	padding: 30px;
	margin-bottom: 30px
}

form.woocommerce-checkout .woocommerce-checkout-review-order-inner {
	padding: 25px 30px 12px
}

form.woocommerce-checkout .place-order {
	padding: 0
}

@media (min-width:768px) {
	form.woocommerce-checkout .place-order {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: 100%
	}
}

form.woocommerce-checkout .place-order .button {
	width: 100%;
	text-transform: uppercase
}

.shop_table.cart {
	width: 100%
}

.shop_table.cart .product-subtotal .woocommerce-Price-amount {
	color: #2f2d51
}

.shop_table.cart tbody td {
	padding: 1.9rem 2rem
}

.shop_table.cart thead {
	background-color: #4a8f9f
}

.shop_table.cart thead th {
	font-weight: 500;
	font-size: 1.125rem;
	color: #fff;
	padding: 1.32rem 2rem
}

.shop_table.cart thead th:first-child {
	border-radius: .25rem 0 0 .25rem
}

.shop_table.cart thead th:last-child {
	border-radius: 0 .25rem .25rem 0
}

.shop_table.cart tbody td {
	border-bottom: 1px solid #e7e7ec
}

.shop_table.cart tbody td.actions {
	padding: 1.5rem 0;
	border-width: 0
}

.shop_table.cart tbody td.actions label {
	display: none
}

.shop_table.cart tbody td.actions .input-text {
	margin-right: 30px
}

.shop_table.cart tbody td.actions>input.button {
	text-decoration: underline;
	text-transform: uppercase;
	font-weight: 500;
	color: #196ecd;
	float: right;
	padding-right: 0
}

.shop_table.cart tbody .product-name img {
	border: 1px solid #e7e7ec;
	border-radius: .25rem;
	box-shadow: 0 .5rem .937rem rgba(140, 152, 164, .1);
	max-width: 100px
}

.shop_table.cart tbody .product-name a {
	color: #2f2d51;
	font-weight: 500;
	font-size: 1.125rem
}

.shop_table.cart .coupon {
	display: -webkit-inline-box;
	display: inline-flex
}

.shop_table.cart .coupon input.button {
	font-weight: 400
}

.cart-collaterals {
	border: 1px solid #e7e7ec;
	border-radius: .25rem;
	box-shadow: 0 .5rem .937rem rgba(140, 152, 164, .1);
	padding: 2rem
}

.cart-collaterals .h2,
.cart-collaterals h2 {
	margin-bottom: 1rem
}

.cart-collaterals .checkout-button {
	width: 100%;
	text-transform: uppercase;
	text-align: center;
	margin-top: .75rem
}

.cart-collaterals .woocommerce-checkout-review-order #shipping_method {
	list-style: none;
	padding: 0;
	font-size: 14px
}

.cart-collaterals .woocommerce-checkout-review-order #shipping_method li {
	margin-bottom: 14px
}

.cart-collaterals .woocommerce-checkout-review-order #shipping_method li label {
	margin-bottom: 0;
	margin-left: 10px
}

.cart-collaterals .shop_table,
.woocommerce-checkout-review-order .shop_table,
.woocommerce-order-received .shop_table {
	width: 100%
}

.cart-collaterals .shop_table th,
.woocommerce-checkout-review-order .shop_table th,
.woocommerce-order-received .shop_table th {
	font-weight: 500;
	color: #2f2d51
}

.cart-collaterals .shop_table td,
.cart-collaterals .shop_table th,
.woocommerce-checkout-review-order .shop_table td,
.woocommerce-checkout-review-order .shop_table th,
.woocommerce-order-received .shop_table td,
.woocommerce-order-received .shop_table th {
	padding: .86rem 0
}

.cart-collaterals .shop_table td,
.woocommerce-checkout-review-order .shop_table td,
.woocommerce-order-received .shop_table td {
	float: right
}

.cart-collaterals .shop_table tr+tr,
.woocommerce-checkout-review-order .shop_table tr+tr,
.woocommerce-order-received .shop_table tr+tr {
	border-top: 1px solid #e7e7ec
}

.cart-collaterals .shop_table strong,
.woocommerce-checkout-review-order .shop_table strong,
.woocommerce-order-received .shop_table strong {
	font-weight: 500
}

.cart-collaterals .shop_table.woocommerce-checkout-review-order-table td,
.cart-collaterals .shop_table.woocommerce-checkout-review-order-table th,
.cart-collaterals .shop_table.woocommerce-table--order-details td,
.cart-collaterals .shop_table.woocommerce-table--order-details th,
.woocommerce-checkout-review-order .shop_table.woocommerce-checkout-review-order-table td,
.woocommerce-checkout-review-order .shop_table.woocommerce-checkout-review-order-table th,
.woocommerce-checkout-review-order .shop_table.woocommerce-table--order-details td,
.woocommerce-checkout-review-order .shop_table.woocommerce-table--order-details th,
.woocommerce-order-received .shop_table.woocommerce-checkout-review-order-table td,
.woocommerce-order-received .shop_table.woocommerce-checkout-review-order-table th,
.woocommerce-order-received .shop_table.woocommerce-table--order-details td,
.woocommerce-order-received .shop_table.woocommerce-table--order-details th {
	float: none
}

.cart-collaterals .shop_table.woocommerce-checkout-review-order-table td+td,
.cart-collaterals .shop_table.woocommerce-checkout-review-order-table td+th,
.cart-collaterals .shop_table.woocommerce-checkout-review-order-table th+td,
.cart-collaterals .shop_table.woocommerce-checkout-review-order-table th+th,
.cart-collaterals .shop_table.woocommerce-table--order-details td+td,
.cart-collaterals .shop_table.woocommerce-table--order-details td+th,
.cart-collaterals .shop_table.woocommerce-table--order-details th+td,
.cart-collaterals .shop_table.woocommerce-table--order-details th+th,
.woocommerce-checkout-review-order .shop_table.woocommerce-checkout-review-order-table td+td,
.woocommerce-checkout-review-order .shop_table.woocommerce-checkout-review-order-table td+th,
.woocommerce-checkout-review-order .shop_table.woocommerce-checkout-review-order-table th+td,
.woocommerce-checkout-review-order .shop_table.woocommerce-checkout-review-order-table th+th,
.woocommerce-checkout-review-order .shop_table.woocommerce-table--order-details td+td,
.woocommerce-checkout-review-order .shop_table.woocommerce-table--order-details td+th,
.woocommerce-checkout-review-order .shop_table.woocommerce-table--order-details th+td,
.woocommerce-checkout-review-order .shop_table.woocommerce-table--order-details th+th,
.woocommerce-order-received .shop_table.woocommerce-checkout-review-order-table td+td,
.woocommerce-order-received .shop_table.woocommerce-checkout-review-order-table td+th,
.woocommerce-order-received .shop_table.woocommerce-checkout-review-order-table th+td,
.woocommerce-order-received .shop_table.woocommerce-checkout-review-order-table th+th,
.woocommerce-order-received .shop_table.woocommerce-table--order-details td+td,
.woocommerce-order-received .shop_table.woocommerce-table--order-details td+th,
.woocommerce-order-received .shop_table.woocommerce-table--order-details th+td,
.woocommerce-order-received .shop_table.woocommerce-table--order-details th+th {
	text-align: right
}

.woocommerce-checkout-review-order-table thead tr,
.woocommerce-table--order-details thead tr {
	border-bottom: 1px solid #e7e7ec
}

.woocommerce-checkout-review-order-table tbody .product-name,
.woocommerce-table--order-details tbody .product-name {
	padding-right: 15px
}

.woocommerce-checkout-review-order-table tbody .product-quantity,
.woocommerce-table--order-details tbody .product-quantity {
	font-weight: 400
}

.woocommerce-checkout-review-order-table tbody .amount,
.woocommerce-table--order-details tbody .amount {
	display: -webkit-inline-box;
	display: inline-flex
}

.woocommerce-checkout-review-order-table tbody tr:first-child td,
.woocommerce-table--order-details tbody tr:first-child td {
	padding-top: .86rem!important
}

.woocommerce-checkout-review-order-table tbody tr:last-child td,
.woocommerce-table--order-details tbody tr:last-child td {
	padding-bottom: .86rem!important
}

.woocommerce-checkout-review-order-table tbody td,
.woocommerce-table--order-details tbody td {
	padding-top: .5rem!important;
	padding-bottom: .5rem!important
}

.woocommerce-checkout-review-order-table tbody tr,
.woocommerce-table--order-details tbody tr {
	border-top-width: 0!important
}

.woocommerce-checkout-review-order-table .cart-subtotal .amount,
.woocommerce-checkout-review-order-table .order-total .amount,
.woocommerce-table--order-details .cart-subtotal .amount,
.woocommerce-table--order-details .order-total .amount {
	color: #2f2d51;
	font-weight: 500
}

.woocommerce-table--order-details .product-name a {
	color: #77838f
}

.woocommerce-table--order-details tfoot td {
	color: #2f2d51;
	font-weight: 500
}

.woocommerce-checkout-payment .methods {
	list-style: none
}

.woocommerce-checkout-payment .methods li:not(:last-child) {
	margin-bottom: 22px
}

.woocommerce-checkout-payment .methods li {
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	-webkit-box-align: center;
	align-items: center
}

.woocommerce-checkout-payment .methods label {
	margin-left: 16px;
	margin-bottom: 0;
	color: #77838f
}

.woocommerce-checkout-payment .payment_method_paypal a,
.woocommerce-checkout-payment .payment_method_paypal img {
	display: none
}

.woocommerce-checkout-payment .payment_box {
	margin-top: 10px;
	margin-left: 30px
}

.woocommerce-checkout-payment .payment_box p {
	color: #77838f;
	font-size: 14px;
	margin-bottom: 0
}

.woocommerce-order-received .entry-header {
	position: relative;
	padding-top: 100px
}

.woocommerce-order-received .entry-header::after,
.woocommerce-order-received .entry-header::before {
	position: absolute;
	left: 50%;
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	top: 0
}

.woocommerce-order-received .entry-header::before {
	background-color: #4a8f9f;
	width: 80px;
	height: 80px;
	content: " ";
	border-radius: 50%
}

.woocommerce-order-received .entry-header::after {
	content: "\f00c";
	font-family: 'Font Awesome 5 Pro';
	font-weight: 900;
	font-size: 28px;
	color: #fff;
	margin-top: 19px
}

.woocommerce-order-received .woocommerce-notice {
	text-align: center;
	margin-bottom: 24px
}

.woocommerce-order-received .woocommerce-customer-details,
.woocommerce-order-received .woocommerce-order-details {
	padding: 30px;
	border: 1px solid #e7e7ec;
	border-radius: .25rem;
	box-shadow: 0 .5rem .937rem rgba(140, 152, 164, .1);
	margin-bottom: 30px
}

@media (min-width:768px) {
	.woocommerce-order-received .woocommerce-customer-details,
	.woocommerce-order-received .woocommerce-order-details {
		padding: 54px 60px
	}
}

.woocommerce-order-received .woocommerce-column__title,
.woocommerce-order-received .woocommerce-order-details__title {
	font-size: 1.375rem;
	margin-bottom: 20px
}

.woocommerce-order-received .woocommerce-order-overview {
	list-style: none;
	padding: 0;
	padding: 36px;
	background-image: repeating-linear-gradient(to right, #4a8f9f 0, #4a8f9f 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to right, #4a8f9f 0, #4a8f9f 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to bottom, #4a8f9f 0, #4a8f9f 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to bottom, #4a8f9f 0, #4a8f9f 50%, transparent 50%, transparent 100%);
	background-position: left top, left bottom, left top, right top;
	background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
	background-size: 16px 1px, 16px 1px, 1px 16px, 1px 16px;
	border-radius: 4px;
	margin-bottom: 60px
}

@media (min-width:768px) {
	.woocommerce-order-received .woocommerce-order-overview {
		display: -webkit-box;
		display: flex;
		-webkit-box-pack: justify;
		justify-content: space-between;
		padding: 36px 60px
	}
}

.woocommerce-order-received .woocommerce-order-overview li {
	margin-bottom: 20px
}

@media (min-width:768px) {
	.woocommerce-order-received .woocommerce-order-overview li {
		margin-bottom: 0
	}
}

.woocommerce-order-received .woocommerce-order-overview li strong {
	font-weight: 500;
	display: block;
	color: #2f2d51;
	margin-top: 10px
}

.woocommerce-order-received .entry-title {
	text-align: center;
	text-transform: capitalize
}

.collapse-chevron {
	display: inline-block;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}

[aria-expanded=true] .collapse-chevron {
	-webkit-transform: rotate(-180deg);
	transform: rotate(-180deg)
}

@media (min-width:768px) {
	.footer-accordion .collapse,
	.footer-accordion .collapsing {
		display: block!important;
		height: auto!important
	}
}

.collapse-accordion-toggle {
	border-width: 0;
	background-color: transparent;
	width: 100%;
	padding: 0;
	outline: 0!important
}

/* .collapse-accordion-toggle[aria-expanded=true] div svg:not(:first-child),
.collapse-accordion-toggle[aria-expanded=true] span svg:not(:first-child) {
	display: none
} */

.collapse-accordion-toggle[aria-expanded=false] div svg:not(:last-child),
.collapse-accordion-toggle[aria-expanded=false] span svg:not(:last-child) {
	display: none
}

.footer-accordion-toggle {
	border-width: 0;
	background-color: transparent;
	width: 100%;
	padding: 0;
	outline: 0!important
}

.footer-accordion-toggle[aria-expanded=true] div svg:not(:first-child),
.footer-accordion-toggle[aria-expanded=true] span svg:not(:first-child) {
	display: none
}

.footer-accordion-toggle[aria-expanded=false] div svg:not(:last-child),
.footer-accordion-toggle[aria-expanded=false] span svg:not(:last-child) {
	display: none
}

@media (min-width:768px) {
	.footer-accordion-toggle {
		cursor: inherit!important
	}
}

@media (min-width:768px) {
	.footer-accordion-toggle div,
	.footer-accordion-toggle span {
		display: none
	}
}

.read-more[aria-expanded=true] .more {
	display: none!important
}

.read-more[aria-expanded=false] .less {
	display: none!important
}

.sidebar-collapse {
	z-index: 2;
	position: fixed;
	top: 238px;
	bottom: 0
}

@media (min-width:768px) {
	.sidebar-collapse {
		top: 110px
	}
}

.sidebar-collapse>a {
	display: -webkit-inline-box;
	display: inline-flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	left: 100%;
	margin-top: 30px;
	position: absolute;
	-webkit-transition: -webkit-transform .3s ease-out;
	transition: -webkit-transform .3s ease-out;
	transition: transform .3s ease-out;
	transition: transform .3s ease-out, -webkit-transform .3s ease-out
}

.sidebar-collapse>a[aria-expanded=false] {
	-webkit-transform: translate(-310px, 0);
	transform: translate(-310px, 0)
}

@media (min-width:576px) {
	.sidebar-collapse>a[aria-expanded=false] {
		-webkit-transform: translate(-390px, 0);
		transform: translate(-390px, 0)
	}
}

.sidebar-collapse>a[aria-expanded=true] {
	-webkit-transform: translate(-310px, 0);
	transform: translate(-310px, 0);
	z-index: 2
}

@media (min-width:370px) {
	.sidebar-collapse>a[aria-expanded=true] {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0)
	}
}

.sidebar-collapse>.collapse,
.sidebar-collapse>.collapsing {
	border-right: 1px solid #e7e7ec;
	width: 310px;
	height: auto;
	display: block;
	-webkit-transition: -webkit-transform .1s ease-out;
	transition: -webkit-transform .1s ease-out;
	transition: transform .1s ease-out;
	transition: transform .1s ease-out, -webkit-transform .1s ease-out;
	-webkit-transform: translate(-310px, 0);
	transform: translate(-310px, 0);
	background-color: #fff
}

.sidebar-collapse>.collapse.show,
.sidebar-collapse>.collapsing.show {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0)
}

@media (min-width:576px) {
	.sidebar-collapse>.collapse,
	.sidebar-collapse>.collapsing {
		width: 390px;
		-webkit-transform: translate(-390px, 0);
		transform: translate(-390px, 0)
	}
}

.sidebar-collapse-scroll {
	height: 100vh;
	overflow-x: hidden;
	overflow-y: auto
}

.device {
	position: relative
}

.device>.img-fluid {
	position: relative
}

.device::before {
	content: "";
	background-color: #f7f9fb
}

.device-screen,
.device::before {
	position: absolute;
	-o-object-fit: cover;
	object-fit: cover
}

.device-iphonex::before,
.device-iphonex>.device-screen {
	top: 7.784431138%;
	left: 16.4021164%;
	width: 66.137566137%;
	height: 80.838323353%
}

.device-macbook::before,
.device-macbook>.device-screen {
	top: 11.53846154%;
	left: 13.38709677%;
	width: 73.548387096%;
	height: 73.076923076%
}

.device-combo {
	position: relative
}

.device-combo>.device {
	position: absolute
}

.device-combo-iphonex-iphonex {
	padding-bottom: 130.250482%
}

.device-combo-iphonex-iphonex>.device-iphonex:first-child {
	bottom: 0;
	left: 0;
	width: 65.5260116%;
	z-index: 1
}

.device-combo-iphonex-iphonex>.device-iphonex:last-child {
	top: 0;
	right: 0;
	width: 72.8323699%
}

.device-combo-iphonex-macbook,
.device-combo-macbook-iphonex {
	padding-bottom: 62.4260355%
}

.device-combo-iphonex-macbook>.device-macbook,
.device-combo-macbook-iphonex>.device-macbook {
	width: 91.7159763%
}

.device-combo-iphonex-macbook>.device-iphonex,
.device-combo-macbook-iphonex>.device-iphonex {
	width: 27.9585799%;
	z-index: 1
}

.device-combo-macbook-iphonex>.device-macbook {
	top: 0;
	left: 0
}

.device-combo-iphonex-macbook>.device-macbook {
	top: 0;
	right: 0
}

.device-combo-macbook-iphonex>.device-iphonex {
	bottom: 0;
	right: 0
}

.device-combo-iphonex-macbook>.device-iphonex {
	bottom: 0;
	left: 0
}

.dropzone {
	position: relative;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column
}

.dz-message {
	padding: 5rem 1rem;
	background-color: #fff;
	border: 1px dashed #c6d3e6;
	border-radius: .25rem;
	text-align: center;
	color: #8c98a4;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	-webkit-box-ordinal-group: 0;
	order: -1;
	cursor: pointer;
	z-index: 999
}

.dz-message:hover {
	border-color: #8c98a4;
	color: #77838f
}

.dz-button {
	background: 0 0;
	border: 0;
	font-size: inherit;
	color: inherit
}

.dz-drag-hover .dz-message {
	border-color: #090761;
	color: #090761
}

.dropzone-multiple .dz-message {
	padding-top: 2rem;
	padding-bottom: 2rem
}

.dropzone-single.dz-max-files-reached .dz-message {
	background-color: rgba(22, 28, 45, .9);
	color: #fff;
	opacity: 0
}

.dropzone-single.dz-max-files-reached .dz-message:hover {
	opacity: 1
}

.dz-preview-single {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: .25rem
}

.dz-preview-cover {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: .25rem
}

.dz-preview-img {
	-o-object-fit: cover;
	object-fit: cover;
	width: 100%;
	height: 100%;
	border-radius: .25rem
}

.dz-preview-multiple .list-group-item:last-child {
	padding-bottom: 0;
	border-bottom: 0
}

[data-dz-size] strong {
	font-weight: 400
}

body.compensate-for-scrollbar {
	margin-right: 0!important
}

.fancybox-container .fancybox-bg {
	background-color: rgba(255, 255, 255, .97)
}

.fe-lg {
	font-size: 1.375rem
}

@media (min-width:768px) {
	.fe-lg {
		font-size: 1.125rem
	}
}

.bg-is-selected .card {
	background-color: transparent!important;
	opacity: .4
}

.bg-is-selected .card .card-float {
	opacity: 0
}

.bg-is-selected .is-selected .card {
	background-color: #f7f9fb!important;
	opacity: 1
}

.bg-is-selected .is-selected .card-float {
	opacity: 1
}

.flickity-button {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	width: 45px;
	height: 45px;
	background-color: #fff;
	box-shadow: 0 .5rem 1.5rem rgba(140, 152, 164, .05);
	border: 1px solid #e7e7ec;
	color: #fff
}

.flickity-button:focus,
.flickity-button:hover {
	background-color: #090761;
	box-shadow: 0 .5rem .937rem rgba(140, 152, 164, .1);
	border-color: #090761
}

.flickity-button.previous {
	left: 2px
}

@media (min-width:768px) {
	.flickity-button.previous {
		-webkit-transform: translate(calc(-50% + 15px), -50%);
		transform: translate(calc(-50% + 15px), -50%)
	}
}

.flickity-button.previous::before {
	content: url(../../public/assets/img/icons/icon-previous-gray.svg)
}

.flickity-button.previous:focus::before,
.flickity-button.previous:hover::before {
	content: url(../../public/assets/img/icons/icon-previous-white.svg)
}

.flickity-button.next {
	right: 2px
}

@media (min-width:768px) {
	.flickity-button.next {
		-webkit-transform: translate(calc(50% - 15px), -50%);
		transform: translate(calc(50% - 15px), -50%)
	}
}

.flickity-button.next::before {
	content: url(../../public/assets/img/icons/icon-next-gray.svg)
}

.flickity-button.next:focus::before,
.flickity-button.next:hover::before {
	content: url(../../public/assets/img/icons/icon-next-white.svg)
}

.flickity-button-outline .flickity-button.next,
.flickity-button-outline .flickity-button.previous {
	background-color: transparent;
	border-width: 2px;
	width: 60px;
	height: 60px
}

.flickity-button-outline .flickity-button.next:focus,
.flickity-button-outline .flickity-button.next:hover,
.flickity-button-outline .flickity-button.previous:focus,
.flickity-button-outline .flickity-button.previous:hover {
	background-color: #fff;
	border-color: #fff
}

.flickity-button-outline .flickity-button.previous::before {
	content: url(../../public/assets/img/icons/icon-previous-white.svg)
}

.flickity-button-outline .flickity-button.previous:focus::before,
.flickity-button-outline .flickity-button.previous:hover::before {
	content: url(../../public/assets/img/icons/icon-previous-gray.svg)
}

.flickity-button-outline .flickity-button.next::before {
	content: url(../../public/assets/img/icons/icon-next-white.svg)
}

.flickity-button-outline .flickity-button.next:focus::before,
.flickity-button-outline .flickity-button.next:hover::before {
	content: url(../../public/assets/img/icons/icon-next-gray.svg)
}

.button-same-position .flickity-button.next,
.button-same-position .flickity-button.previous {
	display: none;
	top: 50%;
	right: calc(50% - 580px)
}

@media (min-width:1200px) {
	.button-same-position .flickity-button.next,
	.button-same-position .flickity-button.previous {
		display: -webkit-box;
		display: flex
	}
}

.button-same-position .flickity-button.previous {
	-webkit-transform: translate(0, 100%);
	transform: translate(0, 100%);
	left: auto
}

.button-same-position .flickity-button.next {
	-webkit-transform: translate(0, -65%);
	transform: translate(0, -65%)
}

.flickity-button-icon {
	display: none
}

.flickity-button-white .flickity-button {
	background-color: #fff;
	color: #090761
}

.flickity-button-bottom .flickity-button {
	top: auto;
	bottom: 1.5rem;
	-webkit-transform: none;
	transform: none
}

.flickity-button-bottom .flickity-button.previous {
	left: auto;
	right: 6.375rem
}

.flickity-button-bottom .flickity-button.next {
	left: auto;
	right: 2.5rem
}

@media (min-width:1200px) {
	.flickity-button-outset .flickity-button.previous {
		-webkit-transform: translate(calc(-50% - 39px), -50%)!important;
		transform: translate(calc(-50% - 39px), -50%)!important
	}
}

@media (min-width:1200px) {
	.flickity-button-outset .flickity-button.next {
		-webkit-transform: translate(calc(50% + 39px), -50%)!important;
		transform: translate(calc(50% + 39px), -50%)!important
	}
}

.flickity-button-outset-long .flickity-button.previous {
	display: none
}

@media (min-width:768px) {
	.flickity-button-outset-long .flickity-button.previous {
		display: -webkit-box;
		display: flex
	}
}

@media (min-width:1480px) {
	.flickity-button-outset-long .flickity-button.previous {
		-webkit-transform: translate(calc(-50% - -70px), -50%)!important;
		transform: translate(calc(-50% - -70px), -50%)!important
	}
}

.flickity-button-outset-long .flickity-button.next {
	display: none
}

@media (min-width:768px) {
	.flickity-button-outset-long .flickity-button.next {
		display: -webkit-box;
		display: flex
	}
}

@media (min-width:1480px) {
	.flickity-button-outset-long .flickity-button.next {
		-webkit-transform: translate(calc(50% + -70px), -50%)!important;
		transform: translate(calc(50% + -70px), -50%)!important
	}
}

@media (min-width:768px) {
	.flickity-page-dots-mobile .flickity-page-dots {
		display: none!important
	}
}

@media (max-width:767.98px) {
	.flickity-page-dots-md .flickity-page-dots {
		display: none!important
	}
}

.flickity-button-inset .flickity-button {
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%)
}

.flickity-button-inset .flickity-button.previous {
	left: 1.5rem
}

.flickity-button-inset .flickity-button.next {
	right: 1.5rem
}

.flickity-viewport-visible .flickity-viewport {
	overflow: visible
}

[data-flickity*='"adaptiveHeight": true'] .flickity-viewport {
	-webkit-transition: all .5s ease;
	transition: all .5s ease
}

.flickity-page-dots .dot {
	width: 8px;
	margin: 0 5px;
	height: 8px;
	background-color: #c8c8d6;
	opacity: 1
}

.flickity-page-dots .dot.is-selected {
	width: 20px;
	background-color: #090761;
	border-radius: 20px
}

.flickity-dot-yellow .dot.is-selected {
	background-color: #feb800
}

.flickity-dot-light .dot {
	background-color: #fff;
	opacity: .6
}

.flickity-dot-light .dot.is-selected {
	background-color: #fff;
	opacity: 1
}

.flickity-dot-left .flickity-page-dots {
	text-align: left
}

.flickity-page-dots-43 .flickity-page-dots {
	bottom: 43px
}

.page-dots-custom .flickity-page-dots .dot {
	background-color: #8b4c22
}

.page-dots-custom .flickity-page-dots .dot.is-selected {
	background-color: #fff
}

.page-dots-custom-1 .flickity-page-dots .dot {
	background-color: rgba(171, 200, 189, .6)
}

.page-dots-custom-1 .flickity-page-dots .dot.is-selected {
	background-color: #abc8bd
}

.flickity-page-dots-white .flickity-page-dots .dot {
	width: 6px;
	height: 6px;
	margin: 0 14px;
	background-color: #fff;
	opacity: 1
}

.flickity-page-dots-white .flickity-page-dots .dot.is-selected {
	box-shadow: 0 0 0 10px rgba(255, 255, 255, .3);
	background-color: #fff
}

.flickity-page-dots-vertical .flickity-page-dots {
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	width: auto;
	top: 50%;
	bottom: auto
}

@media (min-width:1480px) {
	.flickity-page-dots-vertical .flickity-page-dots {
		left: 60px
	}
}

.flickity-page-dots-vertical .flickity-page-dots .dot {
	margin: 28px 0;
	display: block
}

.flickity-tab .tab-pane:not(.active) {
	display: block!important;
	height: 0!important;
	overflow: hidden;
	padding: 0!important
}

.flickity-tab .tab-pane.active {
	height: auto!important
}

@media (max-width:575.98px) {
	.accordion-flickity .collapse {
		display: block!important;
		height: 0!important;
		overflow: hidden;
		padding: 0!important
	}
	.accordion-flickity .collapse.show {
		height: auto!important;
		overflow: visible
	}
}

.flickity-hover-opacity .card {
	opacity: .4
}

.flickity-hover-opacity .is-selected .card {
	opacity: 1
}

@media (min-width:1200px) {
	.flickity-hover-opacity .is-selected+div .card {
		opacity: 1
	}
}

.bg-dark .footer-brand {
	-webkit-filter: brightness(0) invert(1);
	filter: brightness(0) invert(1)
}

.hljs {
	background-color: transparent
}

.icon>svg {
	width: 3rem;
	height: 3rem
}

.icon[class*=text-]>svg [fill]:not([fill=none]) {
	fill: currentColor!important
}

.icon-uxs>svg {
	width: 1rem;
	height: 1rem
}

.icon-xs>svg {
	width: 1.25rem;
	height: 1.25rem
}

.icon-sm>svg {
	width: 2.25rem;
	height: 2.25rem
}

.icon-md>svg {
	width: 3.75rem;
	height: 3.75rem
}

.icon-lg>svg {
	width: 4rem;
	height: 4rem
}

.icon-xl>svg {
	width: 5rem;
	height: 5rem
}

.icon-45>svg {
	width: 2.812rem;
	height: 2.812rem
}

.icon-circle {
	display: -webkit-inline-box;
	display: inline-flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	width: 4.1875rem;
	height: 4.1875rem;
	border-radius: 50%
}

.icon-circle>.fe {
	font-size: 1.875rem
}

.icon-circle-lg {
	width: 6.125rem;
	height: 6.125rem
}

.icon-category .icon-h-p i,
.icon-category .icon-h-p path {
	-webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.icon-category .icon-h-p i {
	color: #77838f
}

.icon-category .icon-h-p.secondary i {
	color: #949da6
}

.icon-category:focus .icon-h-p path,
.icon-category:hover .icon-h-p path {
	fill: #090761!important
}

.icon-category:focus .icon-h-p i,
.icon-category:hover .icon-h-p i {
	color: #090761
}

.icon-category i {
	font-size: 46px
}

.icon-category-sm i {
	font-size: 40px
}

.hover-gradient-1:focus,
.hover-gradient-1:hover {
	background: -webkit-gradient(linear, left top, right top, color-stop(.26%, #ffa35a), color-stop(100.08%, #ff2574))!important;
	background: linear-gradient(90deg, #ffa35a .26%, #ff2574 100.08%)!important
}

.hover-gradient-1:focus .text-gigas,
.hover-gradient-1:hover .text-gigas {
	color: #fff!important
}

.list {
	margin-bottom: 0;
	padding-left: 0;
	list-style-type: none
}

.list-item+.list-item {
	margin-top: .35rem
}

.list-link {
	display: -webkit-box;
	display: flex;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	align-items: center;
	font-size: .875rem;
	color: #949da6
}

.list-link:focus,
.list-link:hover {
	text-decoration: none;
	color: #090761
}

.list-checkbox li+li {
	margin-top: 15px
}

.columns-2 {
	-webkit-columns: 2;
	-moz-columns: 2;
	columns: 2
}

.list-style-v1 li,
.list-style-v2 li {
	margin-bottom: 20px;
	display: -webkit-box;
	display: flex;
	position: relative
}

.list-style-v1 li {
	-webkit-box-align: center;
	align-items: center
}

.list-style-v1 li::after {
	content: " ";
	border-radius: 50%;
	background-color: #4a8f9f;
	height: 18px;
	width: 18px;
	-webkit-box-ordinal-group: 0;
	order: -1;
	margin-right: -12px
}

.list-style-v1 li::before {
	content: "\f00c";
	font-weight: 900;
	font-size: 8px;
	font-family: 'Font Awesome 5 Pro';
	color: #fff;
	margin-right: 16px
}

.list-style-v2 li::before {
	content: " ";
	border-radius: 50%;
	background-color: #4a8f9f;
	height: 6px;
	width: 6px;
	margin-right: 12px;
	flex-shrink: 0;
	margin-top: 8px
}

.ql-container {
	font-family: Jost, sans-serif
}

.ql-toolbar {
	position: relative;
	padding: 1rem 1.25rem;
	background-color: #fff;
	border: 1px solid #e7e7ec;
	border-radius: .25rem .25rem 0 0;
	color: #77838f
}

.ql-toolbar+.ql-container {
	margin-top: -1px
}

.ql-toolbar+.ql-container .ql-editor {
	border-top-left-radius: 0;
	border-top-right-radius: 0
}

.ql-editor {
	min-height: 6.4rem;
	display: block;
	width: 100%;
	padding: 1rem 1.25rem;
	font-size: 1rem;
	line-height: 1.64;
	color: #77838f;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #e7e7ec;
	border-radius: .25rem;
	box-shadow: inset 0 1px 2px rgba(22, 28, 45, .075);
	-webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.ql-editor {
		-webkit-transition: none;
		transition: none
	}
}

.ql-editor::-ms-expand {
	background-color: transparent;
	border: 0
}

.ql-hidden {
	position: absolute;
	-webkit-transform: scale(0);
	transform: scale(0)
}

.ql-editor.ql-blank::before {
	top: 1rem;
	left: 1.25rem;
	font-style: normal;
	color: #8c98a4
}

.ql-editor:focus::before {
	display: none
}

.ql-formats {
	padding-left: .5rem;
	padding-right: .5rem
}

.ql-formats:first-child {
	padding-left: 0
}

.ql-formats:last-child {
	padding-right: 0
}

.ql-toolbar button {
	padding: 0 .25rem;
	background: 0 0;
	border: none;
	color: #77838f;
	cursor: pointer;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}

.ql-toolbar button:hover {
	color: #090761
}

.ql-toolbar button:first-child {
	margin-left: -.25rem
}

.ql-toolbar .ql-active {
	color: #090761
}

.ql-toolbar button svg {
	height: 1.125rem;
	width: 1.125rem
}

.ql-toolbar .ql-stroke {
	stroke: currentColor;
	stroke-width: 2;
	stroke-linecap: round;
	stroke-linejoin: round;
	fill: none
}

.ql-toolbar .ql-thin {
	stroke-width: 1
}

.ql-toolbar .ql-fill {
	fill: currentColor
}

.ql-toolbar input.ql-image {
	position: absolute;
	-webkit-transform: scale(0);
	transform: scale(0)
}

.ql-tooltip {
	position: absolute;
	z-index: 1080;
	display: -webkit-box;
	display: flex;
	flex-wrap: nowrap;
	width: 18.5rem;
	background-color: #fff;
	border: 1px solid transparent;
	border-radius: .25rem;
	padding: 1rem 1.25rem;
	margin-top: .6rem;
	box-shadow: 0 1.5rem 4rem rgba(140, 152, 164, .1)
}

.ql-tooltip:after,
.ql-tooltip:before {
	content: '';
	position: absolute;
	left: 50%;
	bottom: 100%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%)
}

.ql-tooltip:before {
	border-bottom: .6rem solid transparent;
	border-left: .6rem solid transparent;
	border-right: .6rem solid transparent
}

.ql-tooltip:after {
	border-bottom: .5rem solid #fff;
	border-left: .5rem solid transparent;
	border-right: .5rem solid transparent
}

.ql-container .ql-tooltip:hover {
	display: -webkit-box!important;
	display: flex!important
}

.ql-tooltip .ql-preview {
	width: 100%;
	-webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.ql-tooltip .ql-preview {
		-webkit-transition: none;
		transition: none
	}
}

.ql-tooltip.ql-editing .ql-preview {
	display: none
}

.ql-tooltip input {
	display: none;
	width: 100%;
	padding-top: calc(.5625rem + 1px);
	padding-bottom: calc(.5625rem + 1px);
	background-color: transparent;
	font-size: .875rem;
	line-height: 1.6;
	border: none;
	color: #77838f
}

.ql-tooltip input:focus {
	outline: 0
}

.ql-tooltip.ql-editing input {
	display: block
}

.ql-tooltip .ql-action,
.ql-tooltip .ql-remove {
	margin-left: .25rem
}

.ql-tooltip .ql-action::before,
.ql-tooltip .ql-remove::before {
	display: inline-block;
	font-weight: 500;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 1px solid transparent;
	cursor: pointer;
	-webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
	.ql-tooltip .ql-action::before,
	.ql-tooltip .ql-remove::before {
		-webkit-transition: none;
		transition: none
	}
}

.ql-tooltip .ql-action::before:focus,
.ql-tooltip .ql-action::before:hover,
.ql-tooltip .ql-remove::before:focus,
.ql-tooltip .ql-remove::before:hover {
	text-decoration: none
}

.ql-tooltip .ql-action::before.focus,
.ql-tooltip .ql-action::before:focus,
.ql-tooltip .ql-remove::before.focus,
.ql-tooltip .ql-remove::before:focus {
	outline: 0;
	box-shadow: 0 0 0 0 transparent
}

.ql-tooltip .ql-action::before,
.ql-tooltip.ql-editing .ql-action::before {
	color: #fff;
	background-color: #090761;
	border-color: #090761;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075)
}

.ql-tooltip .ql-action::before:hover,
.ql-tooltip.ql-editing .ql-action::before:hover {
	color: #fff;
	background-color: #080652;
	border-color: #07064e
}

.btn-check:focus+.ql-tooltip .ql-action::before,
.btn-check:focus+.ql-tooltip.ql-editing .ql-action::before,
.ql-tooltip .ql-action::before:focus,
.ql-tooltip.ql-editing .ql-action::before:focus {
	color: #fff;
	background-color: #080652;
	border-color: #07064e;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(46, 44, 121, .5)
}

.btn-check:active+.ql-tooltip .ql-action::before,
.btn-check:active+.ql-tooltip.ql-editing .ql-action::before,
.btn-check:checked+.ql-tooltip .ql-action::before,
.btn-check:checked+.ql-tooltip.ql-editing .ql-action::before,
.ql-tooltip .ql-action::before.active,
.ql-tooltip .ql-action::before:active,
.ql-tooltip.ql-editing .ql-action::before.active,
.ql-tooltip.ql-editing .ql-action::before:active,
.show>.ql-tooltip .ql-action::before.dropdown-toggle,
.show>.ql-tooltip.ql-editing .ql-action::before.dropdown-toggle {
	color: #fff;
	background-color: #07064e;
	border-color: #070549
}

.btn-check:active+.ql-tooltip .ql-action::before:focus,
.btn-check:active+.ql-tooltip.ql-editing .ql-action::before:focus,
.btn-check:checked+.ql-tooltip .ql-action::before:focus,
.btn-check:checked+.ql-tooltip.ql-editing .ql-action::before:focus,
.ql-tooltip .ql-action::before.active:focus,
.ql-tooltip .ql-action::before:active:focus,
.ql-tooltip.ql-editing .ql-action::before.active:focus,
.ql-tooltip.ql-editing .ql-action::before:active:focus,
.show>.ql-tooltip .ql-action::before.dropdown-toggle:focus,
.show>.ql-tooltip.ql-editing .ql-action::before.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(46, 44, 121, .5)
}

.ql-tooltip .ql-action::before.disabled,
.ql-tooltip .ql-action::before:disabled,
.ql-tooltip.ql-editing .ql-action::before.disabled,
.ql-tooltip.ql-editing .ql-action::before:disabled {
	color: #fff;
	background-color: #090761;
	border-color: #090761
}

.ql-tooltip .ql-action::before {
	content: 'Edit'
}

.ql-tooltip.ql-editing .ql-action::before {
	content: 'Save'
}

.ql-tooltip .ql-remove::before {
	color: #161c2d;
	background-color: #fff;
	border-color: #fff;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075);
	content: 'Remove';
	border-color: #d9e2ef
}

.ql-tooltip .ql-remove::before:hover {
	color: #161c2d;
	background-color: #fff;
	border-color: #fff
}

.btn-check:focus+.ql-tooltip .ql-remove::before,
.ql-tooltip .ql-remove::before:focus {
	color: #161c2d;
	background-color: #fff;
	border-color: #fff;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(22, 28, 45, .075), 0 0 0 0 rgba(220, 221, 224, .5)
}

.btn-check:active+.ql-tooltip .ql-remove::before,
.btn-check:checked+.ql-tooltip .ql-remove::before,
.ql-tooltip .ql-remove::before.active,
.ql-tooltip .ql-remove::before:active,
.show>.ql-tooltip .ql-remove::before.dropdown-toggle {
	color: #161c2d;
	background-color: #fff;
	border-color: #fff
}

.btn-check:active+.ql-tooltip .ql-remove::before:focus,
.btn-check:checked+.ql-tooltip .ql-remove::before:focus,
.ql-tooltip .ql-remove::before.active:focus,
.ql-tooltip .ql-remove::before:active:focus,
.show>.ql-tooltip .ql-remove::before.dropdown-toggle:focus {
	box-shadow: inset 0 3px 5px rgba(22, 28, 45, .125), 0 0 0 0 rgba(220, 221, 224, .5)
}

.ql-tooltip .ql-remove::before.disabled,
.ql-tooltip .ql-remove::before:disabled {
	color: #161c2d;
	background-color: #fff;
	border-color: #fff
}

.ql-tooltip.ql-editing .ql-remove::before {
	display: none
}

.ql-editor blockquote {
	margin-bottom: 1rem;
	font-size: 1.25rem
}

.ql-editor img {
	max-width: 100%;
	height: auto
}

.screenshot {
	border-radius: .625rem;
	box-shadow: 25px 60px 125px -25px rgba(148, 157, 166, .1), 16px 40px 75px -40px rgba(0, 0, 0, .2)
}

.section-border {
	position: relative
}

.section-border::before {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	border-width: 2px 0 0 0;
	border-style: solid;
	border-color: inherit;
	content: ""
}

.shape {
	position: absolute;
	pointer-events: none
}

.shape>* {
	display: block
}

.shape:not([class*=shape-blur]) {
	overflow: hidden
}

.shape:not([class*=shape-blur])>* {
	-webkit-transform: scale(2);
	transform: scale(2)
}

@media all and (-ms-high-contrast:none),
(-ms-high-contrast:active) {
	.shape>svg {
		display: none
	}
}

.shape-top {
	top: 0;
	right: 0;
	left: 0
}

.shape-top>* {
	-webkit-transform-origin: bottom center;
	transform-origin: bottom center
}

.shape-right {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0
}

.shape-right>* {
	position: inherit;
	top: inherit;
	bottom: inherit;
	right: inherit;
	-webkit-transform-origin: center left;
	transform-origin: center left
}

.shape-bottom {
	right: 0;
	bottom: 0;
	left: 0
}

.shape-bottom>* {
	-webkit-transform-origin: top center;
	transform-origin: top center
}

.shape-bottom-100 {
	right: 0;
	bottom: 100%;
	left: 0
}

.shape-bottom-100>* {
	-webkit-transform-origin: top center;
	transform-origin: top center
}

.shape-left {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0
}

.shape-left>* {
	position: inherit;
	top: inherit;
	bottom: inherit;
	left: inherit;
	-webkit-transform-origin: center right;
	transform-origin: center right
}

.shape-fluid-x>* {
	width: 100%;
	height: auto
}

.shape-fluid-y>* {
	width: auto;
	height: 100%
}

.shape-flip-x {
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1)
}

.shape-flip-y-rotate {
	-webkit-transform: scaleY(-1) rotate(180deg);
	transform: scaleY(-1) rotate(180deg)
}

.shape-flip-both {
	-webkit-transform: scale(-1, -1);
	transform: scale(-1, -1)
}

.shape-flip-one-side {
	-webkit-transform: scaleY(-1) rotate(0);
	transform: scaleY(-1) rotate(0)
}

.shape-blur-1 {
	top: 0;
	right: 0;
	left: 0
}

@media (min-width:768px) {
	.shape-blur-1 {
		bottom: 0;
		left: 50%;
		right: auto
	}
}

.shape-blur-1>* {
	width: 100%;
	height: auto
}

@media (min-width:768px) {
	.shape-blur-1>* {
		width: auto;
		height: 100%
	}
}

.shape-blur-2 {
	top: 0;
	right: 0;
	left: 0
}

@media (min-width:768px) {
	.shape-blur-2 {
		bottom: 0;
		right: 50%;
		left: auto
	}
}

.shape-blur-2>* {
	width: 100%;
	height: auto
}

@media (min-width:768px) {
	.shape-blur-2>* {
		width: auto;
		height: 100%;
		float: right
	}
}

.shape-blur-3 {
	top: 0;
	right: 0;
	left: 0
}

.shape-blur-3>* {
	width: 100%;
	height: auto
}

.shape-blur-4 {
	top: 0;
	bottom: 0;
	left: 50%;
	width: 100%;
	-webkit-transform: translateX(-50%) scale(1.3);
	transform: translateX(-50%) scale(1.3)
}

.shape-blur-4>* {
	width: auto;
	height: 100%;
	margin-left: auto;
	margin-right: auto
}

@media (min-width:992px) {
	.sidenav {
		position: fixed;
		top: 84px;
		height: calc(100vh - 90px);
		overflow: auto
	}
}

@media (min-width:992px) {
	.sidenav-left {
		left: 0
	}
}

@media (min-width:992px) {
	.sidenav-right {
		right: 0
	}
}

.star-rating {
	position: relative;
	vertical-align: middle;
	font-size: .875rem;
	font-family: 'Font Awesome 5 Pro';
	display: -webkit-inline-box;
	display: inline-flex;
	color: #ffc78b;
	width: 102px
}

.star-rating .rating::before,
.star-rating::before {
	content: "\f005 \f005 \f005 \f005 \f005";
	letter-spacing: 6px
}

.star-rating .rating {
	position: absolute;
	left: 0;
	top: 0;
	white-space: nowrap;
	overflow: hidden;
	color: #ffc78b
}

.star-rating .rating::before {
	font-weight: 900
}

.star-rating.star-rating-lg {
	width: 122px
}

.star-rating.star-rating-lg .rating::before,
.star-rating.star-rating-lg::before {
	font-size: 1rem;
	letter-spacing: 8px
}

.star-rating.secondary::before {
	color: #949da6
}

.slect-rating {
	border: none;
	float: left
}

.slect-rating>input {
	display: none
}

.slect-rating>label:before {
	margin: 5px;
	font-size: .875rem;
	font-family: 'Font Awesome 5 Pro';
	font-weight: 900;
	display: inline-block;
	content: "\f005"
}

.slect-rating>.half:before {
	content: "\f089";
	position: absolute
}

.slect-rating>label {
	color: #ddd;
	float: right
}

.slect-rating:not(:checked)>label:hover,
.slect-rating:not(:checked)>label:hover~label,
.slect-rating>input:checked~label {
	color: #ffc78b
}

.slect-rating>input:checked+label:hover,
.slect-rating>input:checked~label:hover,
.slect-rating>input:checked~label:hover~label,
.slect-rating>label:hover~input:checked~label {
	color: #ffbb72
}