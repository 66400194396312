/* @media only screen and (max-width: 992px) {
    .button-solid {
		will-change: transform;
		transition: transform 85ms ease-in-out;
	}
	
	.button-solid.ion-activated {
		--box-shadow: none !important;
		transform: scale(0.95);
	}
} */

/* .button-solid {
    will-change: transform;
    transition: transform 85ms ease-in-out;
}

.button-solid.ion-activated {
    --box-shadow: none !important;
    transform: scale(0.95);
} */

ion-button {
    border: none;
    height: 46px;
    font-size: 16px;
    font-weight: 400;
    --border-radius: 8px;
    font-family: 'Nunito';
    letter-spacing: 1px;
    text-transform: none;
}


.button-solid {
	--background: var(--ion-color-primary-gradient);
}

.button-solid.ion-activated {
	--box-shadow: none !important;
}