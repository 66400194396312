#login-page-controller {

    

    @media only screen and (min-width: 992px) {

        .w-100pr {
            width: 100%;
        }

    }

    @media only screen and (max-width: 992px) {

        .checkbox-longtext {
            text-overflow: unset;
            white-space: normal;
        }

    }

}