#home-page-controller {
    
@media only screen and (min-width: 992px) {
    .main-dropdown-desktop {
        display: block;
    }
    .main-dropdown-mobile {
        display: none;
    }
   
    .swiper.swiper-initialized {
        padding-bottom: 50px;
    }

    .swiper-pagination {
        bottom: 0;
    }
    
    .main-image {
        width: 815px;
        height: 525px;
    }

    .course-description {
        background: rgb(232, 248, 251);
        padding: 100px;
        border-radius: 50px;
    }

    #przedmioty-trigger-home input {
        font-size: 18px;
    }

}

@media only screen and (min-width: 768px) and (max-width: 992px) {

    .main-section {
        padding-top: 155px !important;
    }

}



@media only screen and (max-width: 992px) {

    .course-description {
        background: rgb(232, 248, 251);
        padding: 25px;
        border-radius: 50px;
    }

    .swiper.swiper-initialized {
        padding-bottom: 45px;
    }

    .main-dropdown-desktop {
        display: none;
    }
    .main-dropdown-mobile {
        display: block;
        font-weight: 500;
        letter-spacing: 1px;
        font-size: 21px;
        text-align: center;
        

        transition: ease-in-out max-height 400ms;
        will-change: max-height;
        transition-delay: 100ms;
        overflow: hidden;
        /* max-height: 0; */
    }
    .main-dropdown-mobile-animated {
        margin-bottom: 20px;
        /* max-height: 350px; */
    }
    .main-dropdown-mobile strong {
        font-weight: 800;
        color: var(--pxpMainColor);
    }
    .main-dropdown-mobile i {
        color: var(--pxpMainColor);
    }
    .pulse-button {
        height: 225px !important;
        padding: 10px 30px;
        height: auto !important;
    }
  
    .popup-dropdown-custom .mbsc-popup:not(.mbsc-popup-bottom) {
        max-width: 330px !important;
        width: 90vw !important;
    }
    .popup-dropdown-custom .mbsc-popup.mbsc-popup-bottom {
        width: 100vw !important;
        border-top: 0;
    }
    .popup-dropdown-custom .mbsc-popup.mbsc-popup-bottom .mbsc-popup-body {
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
    }
    .popup-dropdown-custom .mbsc-popup.mbsc-popup-bottom .mbsc-popup-body .mbsc-popup-button {
        font-weight: 600;
        font-size: 17px;
        margin-right: 9px;
    }

    .popup-dropdown-custom .mbsc-popup.mbsc-popup-bottom .mbsc-popup-body .mbsc-popup-button.mbsc-icon-button .mbsc-button-icon {
        font-size: 28px;
    }

    .mbsc-scroller-wheel-item.mbsc-active {
        background: none !important;
    }


    .main-image {
        width: 90vw;
        height: 90vw;
        margin-bottom: -30px !important;
    }
    
    .swiper .swiper-slide .card-container ion-item div.card{
        pointer-events: none;
    }

    .swiper .swiper-slide div.card{
        pointer-events: none;
    }

    .swiper .swiper-slide .card-img{
        transition: none;
    }

    .swiper .swiper-slide .card-img img{
        transform: none;
        top: unset;
        left: unset;
        width: 100%;
    }

}




}