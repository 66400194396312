ion-menu ion-content {
    --background: var(--ion-item-background, var(--ion-background-color, #fff));
  }
  
  ion-menu.md ion-content {
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
  }
  
  ion-menu.md ion-list {
    padding: 0;
  }
  
  ion-menu.md ion-list .ion-list-container {
    padding: 20px 0px 20px 10px;
  }
  
  
  ion-menu.md ion-note {
    margin-bottom: 10px;
  }
/*   
  ion-menu.md ion-list-header, ion-menu.md ion-note {
    padding-left: 10px;
  } */
  
  ion-menu.md ion-list#inbox-list {
    border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
  }
  
  ion-menu.md ion-list#inbox-list ion-list-header {
    font-size: 22px;
    font-weight: 600;
    min-height: 20px;
  }
  
  ion-menu.md ion-list#labels-list ion-list-header {
    font-size: 16px;
    margin-bottom: 18px;
    color: #757575;
    min-height: 26px;
  }
  
  ion-menu.md ion-item {
    --padding-start: 10px;
    --padding-end: 10px;
    border-radius: 4px;
  }
  
  ion-menu.md ion-item.selected {
    --background: rgba(var(--ion-color-primary-rgb), 0.14);
  }
  
  ion-menu.md ion-item.selected ion-icon {
    color: var(--ion-color-primary);
  }
/*   
  ion-menu.md ion-item ion-icon {
    color: #616e7e;
  } */
/*   
  ion-menu.md ion-item ion-label {
    font-weight: 500;
  } */
  
  ion-menu.ios ion-content {
    --padding-bottom: 20px;
  }
  
  ion-menu.ios ion-list {
    padding: 20px 0 0 0;
  }
  
  ion-menu.ios ion-note {
    line-height: 24px;
    margin-bottom: 20px;
  }
  
  ion-menu.ios ion-item {
    --padding-start: 16px;
    --padding-end: 16px;
    --min-height: 50px;
  }
  
  ion-menu.ios ion-item ion-icon {
    font-size: 24px;
    color: #73849a;
  }
  
  ion-menu.ios ion-item .selected ion-icon {
    color: var(--ion-color-primary);
  }
  
  ion-menu.ios ion-list#labels-list ion-list-header {
    margin-bottom: 8px;
  }
  
  ion-menu.ios ion-list-header,
  ion-menu.ios ion-note {
    padding-left: 16px;
    padding-right: 16px;
  }
  
  ion-menu.ios ion-note {
    margin-bottom: 8px;
  }
  
  ion-note {
    display: inline-block;
    font-size: 16px;
    color: var(--ion-color-medium-shade);
  }
  
  ion-item.selected {
    --color: var(--ion-color-primary);
  } 
  
  
  
  ion-router-link { color: #798bff; text-decoration: none; background-color: transparent; }
  
  ion-router-link:hover { color: #465fff; text-decoration: underline; }
  
  ion-router-link { color: inherit; text-decoration: none; }
  
  ion-router-link:hover { color: inherit; text-decoration: none; }
  
  ion-router-link.nk-ibx-menu-item {
    cursor: pointer;
  }
  
  
  .nk-ibx-menu-item .badge{
    min-width: unset !important;
  }
  
  
  @media (min-width: 995px) {
    ion-menu {
      width: 350px;
    }
    /*.nk-ibx-status{
      position: absolute;
    }
     #menu-container{
      height: calc( 100% - 100px );
    } */
  }
  
  
  @media (max-width: 995px) {
    ion-list-header {
      padding-left: 15px;
    }
    ion-list-header ion-label {
      margin-top: 10px;
      margin-bottom: 0px;
    }
  }
  
  
  #menu-container{
    position: relative;
    padding-bottom: 85px;
  }
  
  #menu-footer{
    position: absolute;
    bottom: 0;
  }
  
  #inbox-list{
    padding-bottom: 0;
  }
  
  #inbox-list .nk-ibx-menu li {
    margin: 6px 0 !important;
  }
  
  .nk-ibx-menu-text {
    font-size: 0.92rem !important;
  }
  
  #inbox-list{
    color: #526484 !important;
  }
  
  #inbox-list .btn-primary.btn-round{
    float: right;
    margin-right: 15px;
  }
  
  #inbox-list .nk-ibx-menu-item{
    padding: .8rem .75rem !important;
  }
  
  #acc-list .nk-ibx-menu-item{
    cursor: pointer;
  }
  

.pxp-nav-mobile ion-label {
  font-weight: 600 !important;
  font-size: 18px !important;
}

.pxp-nav-mobile .nav-item.dropdown {
  display: block;
}

.jobster .pxp-nav-mobile-container .offcanvas-body {
  padding: 20px;
  padding-top: 45px;
}

.jobster .pxp-nav-mobile-container .offcanvas-body ion-accordion-group ion-accordion ion-item {
  --border-width: 0 0 0 0;
}

.pxp-nav-mobile ion-list {
  padding-left: 25px !important;
}

.jobster .pxp-header {
  will-change: background-color,box-shadow;
}

ion-router-link.active ion-item {
  --ion-text-color-rgb: var(--ion-color-primary);
  --color: var(--ion-color-primary);
  font-weight: 600;
}

ion-router-link.active ion-item ion-label {
  background: var(--ion-color-primary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}