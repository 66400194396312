@media only screen and (max-width: 992px) {

    // #appointment-modal::part(content)
    // {
    //     position: absolute;
    //     bottom: 0;
    //     height: auto;
    // }

}

@media only screen and (min-width: 992px) {

    

}

#appointment-modal
{
    #appointment-button-exit {
        --padding-start: 5px;
        --padding-end: 5px;
    }

    .description {

        margin: auto;

        ion-icon {
            margin-left: 10px;
        }

        ion-label {
            font-weight: 500;
        }

        ion-label:nth-child(2) {
            text-align: right;
        }
        

    }

    .list-item {
        padding: 0;
    }

    .list-item .list-description {
        margin-left: 20px;
    }

    @media only screen and (min-width: 576px) {
        --height: min(1060px, 90vh);
    }

    @media only screen and (min-width: 992px) {

        

        .description {
            width: 90%;
        }

        .list-item ion-avatar {
            height: 65px;
            width: 65px;
            min-width: 65px;
            margin-left: 0;
        }

        .buttons-payment-container {
            justify-content: space-between;
        }

        .appointment-modal-content {
            margin: 40px 45px 35px;
        }
    
    }

    @media only screen and (max-width: 992px) {

        .appointment-button-back-container {
            justify-content: flex-end;
        }

        .buttons-payment-container {
            justify-content: center;
        }
    
        .appointment-modal-content {
            margin: 20px 15px 35px;
        }

    }
}


#corepetitions-page-controller, .corepetitions-page-controller {
    
    .swiper .appointment-button {
        background: transparent;
        font-size: 16px;
        letter-spacing: 1px;
        font-weight: 400;
        margin: 4px 0px;
        border-radius: 7px;
    }

    .swiper .appointment-button-primary {
        color: var(--ion-color-primary);
    }

    .swiper .appointment-button-primary:hover {
        background: rgba(0, 175, 170, 0.1);
    }

    .swiper .appointment-button-disabled {
        color: var(--ion-color-medium);
    }

    @media only screen and (max-width: 992px) {

        --padding-top: 56px;

        .list-item ion-avatar {
            height: 55px;
            width: 55px;
            min-width: 55px;
        }

        // .list-item .button-arrow {
        //     display: none;
        // }

        .list-item .swiper {
            margin: 0px;
            height: 0px;
            transition: ease-in-out 250ms height;
            will-change: height;
        }
       
    }

    @media only screen and (min-width: 992px) {

        --padding-top: 109px;

        .list-item ion-avatar {
            height: 115px;
            width: 115px;
            min-width: 115px;
            margin-left: 30px;
        }

        .list-item .swiper {
            width: calc(100% - 130px);
            margin: 0px;
            height: 205px;
            transition: ease-in-out 250ms height;
            will-change: height;
        }
        
    }


    // .list-container .list-item {
    //     --inner-padding-start: 0;
    //     --inner-padding-end: 0;
    //     --padding-start: 0;
    //     --padding-end: 0;
    //     --min-height: 200px;
    //     --padding-top: 30px;
    //     --padding-bottom: 30px;
    //     --background-hover: none
    // }

    .list-item {
        padding: 40px 15px 25px;
    }    

    .list-item .list-description {
        margin-left: 30px;
    }

    .list-item .list-description .name {
        margin-bottom: 0px;
    }

    .list-item .list-description .category {
        color: var(--ion-color-medium);
        margin-bottom: 10px;
    }

    .list-item .list-description .bio {
        color: var(--ion-color-secondary);
        margin-bottom: 0px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    }

    .list-item .swiper-slide p {
        margin: 0;
    }

    .list-item .swiper-slide ion-button::part(native) {
        z-index: 0;
    }

    

    .list-item .button-arrow {
        --border-radius: 46px;
        --padding-start: 0;
        --padding-end: 0;
        --inner-padding-end: 0;
    }

    .list-item .swiper .swiper-slide {
        display: block;
    }

    // .list-container .list-item .swiper.opened {
    //     max-height: 1000px !important;
    // }

    .list-item .swiper .swiper-wrapper {
        height: fit-content;
    }

}

