#list-courses-page-controller {

    @media only screen and (max-width: 992px) {

        .segment-container-custom {
            width: 100%;
        }

        // .container-filter .row > .col-6 {
        //     padding-right: 5px;
        //     padding-left: 25px;
        // }

        // .container-filter .row > .col-6 ~ .col-6 {
        //     padding-right: 25px;
        //     padding-left: 5px;
        // }

        // ion-item .card {
        //     pointer-events: none;
        // }

       
    }

    @media only screen and (min-width: 992px) {

        .segment-container-custom {
            width: 90%;
            margin: 0 auto;
        }
        
    }

}

.input-ch-16 input {
    width: 16ch;
}

.input-ch-23 input {
    width: 23ch;
}

#przedmioty-popover {
    --offset-y: 5px;
}

#przedmioty-popover ion-item.active {
    --color: var(--ion-color-primary)
}

#przedmioty-popover ion-item ion-icon {
    margin-right: 20px;
    margin-left: 8px;
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 28px;
}

#przedmioty-popover ion-item ion-label {
    font-size: 18px;
}

#przedmioty-popover ion-item {
    --min-height: 60px;
}

#przedmioty-popover ion-list {
    padding: 0
}

#przedmioty-popover ion-list ion-item[color="primary"] {
    --border-width: 0;
}
