#cart-page-controller {

    

    @media only screen and (min-width: 992px) {


    }

    @media only screen and (max-width: 992px) {



    }

    .order-complete .course {
        font-weight: 500;
    }

    .order-complete .course ion-label:first-child {
        text-overflow: clip;
        white-space: normal;
        line-height: 22px;
    }

    .order-complete .course ion-label:last-child {
        text-align: right;
    }

    .order-complete ion-list:first-child {
        padding-bottom: 0;
    }

}