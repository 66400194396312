#register-success-page-controller {

    

    @media only screen and (min-width: 992px) {

        .w-100pr {
            width: 100%;
        }

    }

}