#profile-page-controller {

    @media only screen and (min-width: 992px) {

        .w-100pr {
            width: 100%;
        }

        .h-100
        {
            height: 100%;
            overflow: auto;
        }

        ion-router-outlet:first-child{
            height: 100%;
            overflow: auto;
        }

    }

    @media only screen and (max-width: 992px) {

        .corepetitions-page-controller ion-item.list-item {
            --padding-top: 15px;
        }


        ion-router-outlet:first-child{
            height: calc( 100% - 104px );
            overflow: auto;
        }

    }

    


    ion-menu ion-list ion-item[color="primary"] {
        --border-width: 0;
    }

    ion-list ion-item[color="primary"] {
        --border-width: 0;
    }

}

#profile2-page-controller {



    @media only screen and (min-width: 992px) {

        ion-split-pane {
            max-height: 700px;
        }

        footer {
            margin-top: 100px;
        }

        .container {
            height: 700px;
        }

    }

    @media only screen and (max-width: 992px) {

        ion-split-pane {
            max-height: 100%;
        }

        footer {
            display: none;
        }

        .container {
            height: calc( 100% - 104px );
        }

    }

}